import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getGrpcClient, getGrpcPollingClient, getUuidv4, getDateWithFormat, checkActiveAdmin, checkMobileView } from "../../../config/Common";
import { ZiFetchRequest } from "../../../protobuf/generated/ZiFetchService_grpc_web_pb";
import GlobalMsgCons from "../../../config/GlobalMsgCons";
import WhiteBoard from "./WhiteBoard";
import ShareModal from "../../../components/ShareModal";
import ReactTooltip from 'react-tooltip';
import Loader from "../../../components/Loader";
import { toast } from 'react-toastify';
import PaginationDatatable from "../../../components/PaginationDatatable";
import Modal from "../../../components/Modal";
import WhiteBoardRead from "./WhiteBoardRead";
import ViewedByModal from "../../../components/ViewedByModal";
import CopyUrlButton from "../../../components/CopyUrlButton";
import GlobalDomainCons from "../../../config/GlobalDomainCons";
import GlobalEventCons from "../../../config/GlobalEventCons";


function TeqDrawWrite(props) {
    let { fromGuest } = props
    const auth = useSelector(state => fromGuest ? state.guestAuthReducer : state.authReducer);
    let { teqDrawId, teqDrawOrgId } = useParams()
    let [content, setContent] = useState("")
    let [filename, setFilename] = useState("")
    let [tempFilename, setTempFilename] = useState("")
    let [validate, setValidate] = useState("loading")
    let [teqDrawCreatedOrgId, setTeqDrawCreatedOrgId] = useState("")
    const [parentOrgInfo, setParentOrgInfo] = useState({});
    let [versionHistoryDetails, setVersionHistoryDetails] = useState({})
    let [teqDrawModalShow, setTeqDrawModalShow] = useState(false)
    let [restoreButtonLoading, setRestoreButtonLoading] = useState({});
    let [versionContentLoading, setVersionContentLoading] = useState(false)
    let [whiteBoardModal, setWhiteBoardModal] = useState(false)
    let [versionContent, setVersionContent] = useState("")

    let [editingUserId, setEditingUserId] = useState("")
    let [typingUser, setTypingUser] = useState("")
    let [ownerId, setOwnerId] = useState("");
    let [requestAccessShow, setRequestAccessShow] = useState(false)
    let [requestAccessData, setRequestAccessData] = useState({})

    if (teqDrawId === undefined) {
        if (props.pinTabInfo.id) {
            teqDrawId = props.pinTabInfo.id
        } else {
            teqDrawId = props.id
        }

    }

    useEffect(() => {
        let activeCompanyId = ""
        if (teqDrawOrgId) {
            activeCompanyId = teqDrawOrgId
        } else if (props.orgId) {
            activeCompanyId = props.orgId
        } else {
            activeCompanyId = auth.activeCompany;
        }
        setTeqDrawCreatedOrgId(activeCompanyId)
        return () => {
            ReactTooltip.hide()
        };
    }, [])

    useEffect(() => {
        if (teqDrawCreatedOrgId) {
            validateAccess()
            if (auth.parentOrgInfo && Object.keys(auth.parentOrgInfo).length > 0) {
                let dataObj = { ...auth.parentOrgInfo, ...{ docsOrgId: teqDrawCreatedOrgId } }
                setParentOrgInfo(dataObj)
            }
        }
    }, [teqDrawCreatedOrgId])

    useEffect(() => {
        if (validate === "verified") {
            if (props.setActiveDrawId) {
                props.setActiveDrawId(teqDrawId);
            }
            getContent();
            userSesssion("add");

            const interval = setInterval(() => {
                getDrawFrequent();
            }, 1000);

            return () => {
                userSesssion("delete");
                clearInterval(interval);
            };
        }
    }, [validate])

    function validateAccess() {
        let postJson = { reqId: getUuidv4(), orgId: teqDrawCreatedOrgId, userId: auth.loginId, teqDrawId, userOrgId: auth.activeCompany, dataOrgId: teqDrawCreatedOrgId };
        if (props.fromOrgId) {
            postJson.userOrgId = props.fromOrgId
        }
        if (postJson.userOrgId === auth.activeCompany) {
            setRequestAccessShow(true)
        }
        const request = new ZiFetchRequest();
        request.setDomain("teqdraw");
        request.setEvent("validateAccess");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let responseData = JSON.parse(response.getMessage());
                let { ownerId, dataName, response: res } = responseData
                if (res === GlobalMsgCons.success) {
                    if (responseData.result) {
                        setValidate("verified")
                        getEditingStatus()
                    } else {
                        setValidate("failed")
                        setRequestAccessData({ ownerId, dataName })
                    }
                } else if (res === GlobalMsgCons.notExist) {
                    setValidate("notexist")
                }
            }
        });
    }

    function userSesssion(actionType) {
        let postJson = { reqId: getUuidv4(), orgId: teqDrawCreatedOrgId, userId: auth.loginId, teqDrawId, actionType, sessionId: auth.sessionId };
        const request = new ZiFetchRequest();
        request.setDomain("teqdraw");
        request.setEvent("userOpenAndCloseTeqDraw");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let responseData = response.getMessage();

            }
        });
    }

    function notifyUsersChanges(listOfMessageTypesWaiting) {
        listOfMessageTypesWaiting.map((item) => {
            let itemArr = [];
            let id = ""
            if (item.includes("::")) {
                itemArr = item.split("::");
                item = itemArr[0];
                id = itemArr[1];
            }

            switch (item) {
                case "TeqDrawContentUpdated":
                    if (id === teqDrawId) {
                        getContent()
                    }
                    break;
                case "TeqDrawEditStatusUpdated":
                    getEditingStatus()
                    break;
            }
        })
    }

    function getDrawFrequent() {
        let postJson = { reqId: getUuidv4(), orgId: auth.activeCompany, userId: auth.loginId, teqDrawId, sessionId: auth.sessionId };;
        const request = new ZiFetchRequest();
        request.setDomain("poll");
        request.setEvent("user");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcPollingClient(props.docsGrpcPollingClient).pollZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request.Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                let listOfMessageTypesWaiting = responseData.listOfMessageTypesWaiting;
                if (listOfMessageTypesWaiting !== undefined) {
                    notifyUsersChanges(listOfMessageTypesWaiting)
                }
            }
        });
    }

    function write(content) {
        let postJson = { reqId: getUuidv4(), orgId: auth.activeCompany, userId: auth.loginId, teqDrawId, content };
        setContent(JSON.parse(content))
        const request = new ZiFetchRequest();
        request.setDomain("teqdraw");
        request.setEvent("write");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let responseData = response.getMessage();
                console.log("write responseData", responseData)
            }
        });
    }

    function getContent() {
        let postJson = { reqId: getUuidv4(), userOrgId: auth.activeCompany, userId: auth.loginId, teqDrawId, dataOrgId: teqDrawCreatedOrgId, };
        if (props.fromOrgId) {
            postJson.userOrgId = props.fromOrgId
        }
        const request = new ZiFetchRequest();
        request.setDomain("teqdraw");
        request.setEvent("getContent");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let responseData = JSON.parse(response.getMessage());
                if (responseData) {
                    let { name, content, ownerId } = responseData.response
                    setOwnerId(ownerId)
                    if (filename === "") {
                        setFilename(name)
                    }

                    console.log("responseData", responseData)
                    if (content) {
                        setContent(JSON.parse(content))
                    }
                }
            }
        });
    }

    function updateName() {
        let postJson = { reqId: getUuidv4(), orgId: auth.activeCompany, userId: auth.loginId, teqDrawId, name: tempFilename };
        const request = new ZiFetchRequest();
        request.setDomain("teqdraw");
        request.setEvent("update");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let responseData = JSON.parse(response.getMessage());
                console.log("responseData", responseData)
            }
        });
    }

    function doVersioningForDocs() {
        let postJson = { reqId: getUuidv4(), orgId: teqDrawCreatedOrgId, userId: auth.loginId, teqDrawId, flowType: "manual" };
        const request = new ZiFetchRequest();
        request.setDomain("teqdraw");
        request.setEvent("doVersioning");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                if (response.getMessage() === GlobalMsgCons.success) {
                    toast.success("Updated successfully.", {
                        position: "top-center",
                        autoClose: GlobalMsgCons.autoClose,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        });
    }

    function getVersionContent(versionId, type) {
        let checkType = type === "restore"
        if (checkType) {
            restoreButtonLoading[versionId] = true
            setRestoreButtonLoading(restoreButtonLoading)
        }
        else {
            setVersionContentLoading(true)
        }
        let postJson = { reqId: getUuidv4(), userId: auth.loginId, teqDrawId, versionId, userOrgId: auth.activeCompany, dataOrgId: teqDrawCreatedOrgId };
        console.log("postJson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain("teqdraw");
        request.setEvent("getVersionContent");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let responseData = JSON.parse(response.getMessage());

                let { content } = responseData.response;
                let responseContentTxt = content ? JSON.parse(content) : ""
                if (checkType) {
                    write(content)
                    setContent(responseContentTxt)
                    setRestoreButtonLoading({})
                    setTeqDrawModalShow(false)
                } else {
                    setVersionContentLoading(false)
                    setVersionContent(responseContentTxt)

                }
                setVersionHistoryDetails(responseData.response)
            }
        });
    }

    function updateEditingStatus(status) {
        let postJson = { reqId: getUuidv4(), orgId: teqDrawCreatedOrgId, editinguserId: auth.loginId, teqDrawId, status };
        const request = new ZiFetchRequest();
        request.setDomain("teqdraw");
        request.setEvent("updateEditingStatus");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let responseData = response.getMessage();
                if (responseData === GlobalMsgCons.success) {
                    let editingUserId = auth.loginId;
                    if (status === "end") {
                        editingUserId = "";
                    }
                    setEditingUserId(editingUserId)
                }

            }
        });
    }

    function getEditingStatus() {
        let postJson = { reqId: getUuidv4(), orgId: teqDrawCreatedOrgId, editinguserId: auth.loginId, teqDrawId };
        const request = new ZiFetchRequest();
        request.setDomain("teqdraw");
        request.setEvent("getEditingStatus");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let responseData = JSON.parse(response.getMessage());
                let { editingUserId, editingUserEmail } = responseData;
                setEditingUserId(editingUserId)
                setTypingUser(editingUserEmail)
            }
        });
    }


    function documentRequestAccess() {
        let postJson = { reqId: getUuidv4(), orgId: auth.activeCompany, requestedUserId: auth.loginId, dataId: teqDrawId, dataName: requestAccessData.dataName, dataType: "draw", ownerId: requestAccessData.ownerId };
        if (!requestAccessData.dataName || !requestAccessData.ownerId) {
            return false
        }
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.companySetDomain);
        request.setEvent(GlobalEventCons.accessRequestNotificationToInbox);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.log("Invalid Request. Please try again later")
            } else {
                let responseData = response.getMessage();
                responseData = JSON.parse(responseData)
                if (responseData.response === GlobalMsgCons.success) {
                    toast.success("Requested successfully.", {
                        position: "top-center",
                        autoClose: GlobalMsgCons.autoClose,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        });
    }


    function getContentElement() {
        let contentElement = <Loader />
        if (validate === "failed") {
            contentElement = <div className="failed-setup">
                <i className="fas fa-times-circle"></i>
                <div className="mt-5">
                    You don't currently have to access this Docs
                </div>
                <div className="my-2">
                    <button className="btn btn-primary btn-sm" onClick={() => {
                        props.history.push(props.path + "/teqdraw")
                    }}>
                        Back to Whiteboard
                    </button>
                </div>
                {requestAccessShow ? <div className="my-2">
                    <button className="btn btn-success btn-sm" onClick={() => {
                        documentRequestAccess()
                    }}>
                        Request to access
                    </button>
                </div> : null}
            </div>
        } else if (validate === "notexist") {
            contentElement = <div className="failed-setup">
                <i className="fas fa-times-circle"></i>
                <div className="mt-5">
                    The file does not exist
                </div>
                <div className="my-2">
                    <button className="btn btn-primary btn-sm" onClick={() => {
                        props.history.push(props.path + "/teqdraw")
                    }}>
                        Back to Whiteboard
                    </button>
                </div>
            </div>

        } else if (validate === "verified") {
            contentElement = <React.Fragment>
                <div className={`header ${props.fromMobileView ? "flex-row d-flex-wrap" : ""}`}>
                    <ReactTooltip place="bottom" type="dark" effect="float" html={true} />
                    <div className="icon" onClick={() => {
                        if (!auth.fromMobileAccess) {
                            updateEditingStatus("end")
                            if (fromGuest) {
                                props.history.goBack()
                            } else {
                                props.history.push(props.path + "/teqdraw")
                            }
                        }
                    }} data-tip={"Whiteboard Home"} >
                        <i className="fa fa-chalkboard-teacher" />
                    </div>
                    <div className="input">
                        <input
                            onChange={(e) => {
                                setTempFilename(e.target.value)
                            }}
                            onBlur={(e) => {
                                if (tempFilename !== "") {
                                    updateName()
                                }
                            }}
                            defaultValue={filename}
                            disabled={fromGuest ? fromGuest : false} />
                    </div>
                    <div className="extra">
                        {
                            !fromGuest && <React.Fragment>
                                <CopyUrlButton className={"mr-2"} />
                                <button className="btn btn-sm btn-info share-button mr-2" disabled={editingUserId !== "" && editingUserId !== auth.loginId} onClick={() => {
                                    let status = "end";
                                    if (editingUserId === "") {
                                        status = "start";
                                    }
                                    updateEditingStatus(status)
                                }}>{editingUserId === auth.loginId ? "Done" : "Start Editing"}</button>

                                <button className={`btn btn-sm btn-secondary share-button mr-2`} onClick={() => {
                                    setTeqDrawModalShow(true)
                                }}><i className="fa fa-history" /></button>
                                <button className="btn btn-sm btn-success share-button mx-2" onClick={() => {
                                    doVersioningForDocs()
                                }}>Backup</button>
                                {
                                    !checkMobileView() && <button className={`btn btn-sm mr-2 btn-${props.pinTabInfo.id === teqDrawId && props.pinTabInfo.type === "teqdraw" ? "success" : "secondary"} pin-button`} onClick={() => {
                                        if (props.pinTabInfo.id === teqDrawId && props.pinTabInfo.type === "teqdraw") {
                                            props.setPinTabInfo({
                                                id: "",
                                                type: ""
                                            })
                                        } else {
                                            props.setPinTabInfo({
                                                id: teqDrawId,
                                                type: "teqdraw"
                                            })
                                        }
                                    }} data-tip={"Pin your Whiteboard"}><i className="fas fa-thumbtack" /></button>
                                }
                                <ShareModal
                                    domain={"teqdraw"}
                                    getSharedUserDetailsEvent={"getSharedUsersDetails"}
                                    sharingEvent={"sharing"}
                                    postJsonInfo={{ teqDrawId }}
                                    dataId={teqDrawId}
                                    dataName={filename}
                                    dataType={"draw"}
                                    grpcClient={props.grpcClient}
                                    searchGrpcClient={props.searchGrpcClient}
                                    docsGrpcClient={props.docsGrpcClient}
                                    parentOrgInfo={parentOrgInfo}
                                    ownerId={ownerId}
                                />
                                <ViewedByModal
                                    domain={"teqdraw"}
                                    getSharedUserDetailsEvent={"getSharedUsersDetails"}
                                    postJsonInfo={{ teqDrawId }}
                                    dataId={teqDrawId}
                                    dataName={filename}
                                    dataType={"draw"}
                                    grpcClient={props.grpcClient}
                                    searchGrpcClient={props.searchGrpcClient}
                                    docsGrpcClient={props.docsGrpcClient}
                                    parentOrgInfo={parentOrgInfo}
                                />
                            </React.Fragment>
                        }
                    </div>
                </div>
                {(editingUserId === auth.loginId) && <WhiteBoard content={content} write={write} viewMode={false} />}

                {(editingUserId !== auth.loginId) && <div className="row">
                    <div className="col-sm-12 white-board-save-hide">
                        <WhiteBoardRead content={content} viewMode={true} />
                    </div>
                </div>}

            </React.Fragment>
        }

        return contentElement;
    }
    return <div className="docs-write">
        {
            getContentElement()
        }
        {
            (editingUserId && editingUserId !== auth.loginId && typingUser) && <div className="typing-info-display">{`${typingUser} is typing`}</div>
        }
        {
            teqDrawModalShow && <Modal
                visible={teqDrawModalShow}
                size={"modal-xl"}
                closeModal={() => {
                    setVersionContentLoading(false)
                    setTeqDrawModalShow(false)
                    setWhiteBoardModal(false)
                }}
                heading={"Version History"}
                body={<React.Fragment>
                    {whiteBoardModal && !versionContentLoading && <>

                        <div className="row">
                            <div className="col-sm-1" >
                                <i className="fa fa-chevron-left" style={{ fontSize: 25, cursor: "pointer" }} aria-hidden="true" onClick={() => {
                                    setWhiteBoardModal(false)
                                }}></i>
                            </div>
                            <div className="col-sm-5">
                                <div className="form-group">
                                    <label>File Name : </label>
                                    {versionHistoryDetails.name}
                                </div>
                            </div>
                            <div className="col-sm-5">
                                <div className="form-group">
                                    <label>Created At : </label>
                                    {getDateWithFormat(versionHistoryDetails.createdAt, auth.activeCompanyDetails)}
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 white-board-save-hide">
                                <WhiteBoardRead content={versionContent} viewMode={true} />
                            </div>
                        </div>
                    </>}

                    {versionContentLoading && <div style={{ height: 200 }}>
                        <Loader />
                    </div>}

                    {!whiteBoardModal && <div className="row">
                        <div className="col-sm-12">
                            <PaginationDatatable
                                grpcClient={props.docsGrpcClient}
                                domain={"teqdraw"}
                                event={"getVersionList"}
                                data={[
                                    {
                                        column: "Created User",
                                        cell: (row, key) => {
                                            return <div>{row.createdByUserEmail ? row.createdByUserEmail : "-"}</div>
                                        }
                                    },
                                    {
                                        column: "Created At",
                                        cell: (row, key) => {
                                            return <div>{getDateWithFormat(row.createdAt, auth.activeCompanyDetails)}</div>
                                        }
                                    },
                                    {
                                        column: "Version Type",
                                        cell: (row, key) => {
                                            return <div>{row.flowType}</div>
                                        }
                                    },
                                    {
                                        column: "View",
                                        cell: (row, key) => {
                                            return <button className="btn btn-sm btn-primary" onClick={() => {
                                                setWhiteBoardModal(true)
                                                getVersionContent(row.versionId)

                                            }}>View</button>
                                        }
                                    },
                                    {
                                        column: "Restore",
                                        cell: (row, key) => {
                                            return (checkActiveAdmin(auth.activeUserType, auth.systemLevelRole)) ? <button className="btn btn-sm btn-info" onClick={() => {
                                                if (window.confirm("Are you sure restore this version data ?")) {
                                                    getVersionContent(row.versionId, "restore")
                                                }
                                            }}>Restore {restoreButtonLoading[row.versionId] ? <>
                                                <i className="fa fa-spinner fa-spin"></i>
                                            </> : ""} </button> : "-"
                                        }
                                    }
                                ]}
                                postJsonInfo={{ orgId: teqDrawCreatedOrgId, userId: auth.loginId, teqDrawId }}
                                refresh={false}
                            />
                        </div>
                    </div>}


                </React.Fragment>
                }
            />
        }
    </div>
}
export default TeqDrawWrite;
