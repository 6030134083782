import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import { getGrpcClient, getUuidv4, searchDropdownOptionConvert, checkActiveAdmin, capitalize, equalCommunityService } from "../config/Common";
import { ZiFetchRequest } from "../protobuf/generated/ZiFetchService_grpc_web_pb";
import GlobalMsgCons from "../config/GlobalMsgCons";
import GlobalDomainCons from "../config/GlobalDomainCons";
import { useSelector } from "react-redux";
import {  toast } from 'react-toastify';
import SearchDropdown from "./SearchDropdown";
import GlobalSearchDataTypes from "../config/GlobalSearchDataTypes";
import CustomEditor from "./CustomEditor";
import DropDown from './DropDown';
import ReactTooltip from 'react-tooltip';


function ShareModal(props) {
    const auth = useSelector(state => state.authReducer);

    let [docsShareModal, setDocsShareModal] = useState(false)
    let [userIds, setUserIds] = useState([])
    let [submitLoader, setSubmitLoader] = useState(false)
    let [existUsers, setExistUsers] = useState([])
    let [shareDescription, setShareDescription] = useState("");
    let [activeTab, setActiveTab] = useState("");
    let [parentInfoShow, setParentInfoShow] = useState(false);
    let [switchCompanyOrgId, SetSwitchCompanyOrgId] = useState(auth.activeCompany);
    let [dataOrgId, setDataOrgId] = useState("");
    let [childOption, setChildOption] = useState([]);
    let [childSelected, setChildSelected] = useState("");
    let postJsonInfo = props.postJsonInfo !== undefined ? props.postJsonInfo : {}

    useEffect(() => {
        let sourceOrgId = auth.activeCompany
        if ((props.dataType === "docs" || props.dataType === "draw" || props.dataType === "sheet") && props.parentOrgInfo && props.parentOrgInfo.docsOrgId && Object.keys(props.parentOrgInfo).length > 0) {
            sourceOrgId = props.parentOrgInfo.docsOrgId
            setActiveTab("activeCompany")
            setParentInfoShow(true)
        }
        setDataOrgId(sourceOrgId)
        getChildOrgCompanyList()
        ReactTooltip.rebuild();
    }, [])

    function getSharedUsers() {
        let postJson = { reqId: getUuidv4(), orgId: auth.activeCompany, userId: auth.loginId };
        postJson = { ...postJson, ...postJsonInfo }
        const request = new ZiFetchRequest();
        request.setDomain(props.domain);
        request.setEvent("getSharedUsers");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.log("Invalid Request. Please try again later")
            } else {
                let responseData = JSON.parse(response.getMessage());
                setExistUsers(responseData.response)
            }
        });
    }

    function getSharedUsersDetails(switchComId) {
        let postJson = { reqId: getUuidv4(), orgId: switchCompanyOrgId, userId: auth.loginId };
        postJson = { ...postJson, ...postJsonInfo }
        if (switchComId) {
            postJson.orgId = switchComId
        }
        const request = new ZiFetchRequest();
        request.setDomain(props.domain);
        request.setEvent(props.getSharedUserDetailsEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.log("Invalid Request. Please try again later")
            } else {
                let responseData = JSON.parse(response.getMessage());
                console.log("responseData", responseData)
                setExistUsers(responseData.result)
                setDocsShareModal(true)
            }
        });
    }

    function save() {
        let valUserIds = [];
        for (let i = 0; i < userIds.length; i++) {
            valUserIds.push(userIds[i].value)
        }
        setSubmitLoader(true)
        let postJson = { reqId: getUuidv4(), orgId: auth.activeCompany, userIds: valUserIds, sharedBy: auth.loginId };
        postJson = { ...postJson, ...{ srcOrgId: dataOrgId, descOrgId: switchCompanyOrgId } }
        postJson = { ...postJson, ...postJsonInfo }
        console.log(postJson)
        const request = new ZiFetchRequest();
        request.setDomain(props.domain);
        request.setEvent(props.sharingEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let responseData = response.getMessage();
                if (responseData === GlobalMsgCons.success) {
                    docsShareNotificationToInbox(valUserIds)
                    setSubmitLoader(false)
                    setUserIds([])
                    setDocsShareModal(false)
                    toast.success("Shared successfully.", {
                        position: "top-center",
                        autoClose: GlobalMsgCons.autoClose,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        });
    }

    function docsShareNotificationToInbox(memberIdList) {
        let postJson = { reqId: getUuidv4(), orgId: auth.activeCompany, shareDescription, sharedUserId: auth.loginId, memberIdList, dataId: props.dataId, dataName: props.dataName, dataType: props.dataType, memberOrgId: switchCompanyOrgId, dataOrgId: dataOrgId };
        console.log("-->post", postJson);
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.companySetDomain);
        request.setEvent("shareNotificationToInbox");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let responseData = response.getMessage();
                console.log("responseData", responseData)
                if (responseData === GlobalMsgCons.success) {

                }
            }
        });
    }

    function getChildOrgCompanyList() {
        let postJson = { reqId: getUuidv4(), orgId: auth.activeCompany, userId: auth.loginId };
        console.log("test", postJson)
        const request = new ZiFetchRequest();
        request.setDomain("company");
        request.setEvent("getChildOrganizationListWithoutPagination");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.log("Invalid Request. Please try again later")
            } else {
                let responseData = JSON.parse(response.getMessage());
                let { result } = responseData
                if (Array.isArray(result) && result.length > 0) {
                    let opt = [];
                    opt = result.map((e) => {
                        return { label: e.organizationName, value: e.organizationId }
                    })
                    setChildOption(opt)
                    setActiveTab("activeCompany")

                }
            }
        });
    }

    function unShareApi(user) {
        let postJson = { reqId: getUuidv4(), orgId: auth.activeCompany, userId: auth.loginId, userIds: [user] };
        postJson = { ...postJson, ...postJsonInfo }
        console.log(props.domain)
        console.log(postJson)
        const request = new ZiFetchRequest();
        request.setDomain(props.domain);
        request.setEvent("unShare");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.log("Invalid Request. Please try again later")
            } else {
                let responseData = response.getMessage();
                if (responseData === GlobalMsgCons.success) {
                    getSharedUsersDetails()
                    toast.success("Removed successfully.", {
                        position: "top-center",
                        autoClose: GlobalMsgCons.autoClose,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

            }
        });
    }

    return <React.Fragment>
        <ReactTooltip place="bottom" type="dark" effect="float" html={true} />
         <button className="btn btn-sm btn-primary share-button" onClick={() => {
            getSharedUsersDetails()
        }} data-tip={"Share your document"}>Share</button>
        {
            docsShareModal && <Modal
                visible={docsShareModal}
                closeModal={() => {
                    setDocsShareModal(false)

                }}
                size={`modal-` + (parentInfoShow && childOption.length > 0) ? "lg" : "md"}
                heading={"Share"}
                body={<div>
                    {(parentInfoShow || childOption.length > 0) && <div className="">
                        <div className="tabs-bar">
                            <div className="tabs">
                                <div className={"tab " + (activeTab === "activeCompany" ? "active" : "")}
                                    onClick={() => {
                                        setActiveTab("activeCompany")
                                        setUserIds([])
                                        setExistUsers([])
                                        setShareDescription("")
                                        SetSwitchCompanyOrgId(auth.activeCompany)
                                        getSharedUsersDetails(auth.activeCompany)
                                    }}>
                                    <div>
                                        Active Workspace
                                    </div>
                                    ({auth.activeCompanyName})
                                </div>
                                {parentInfoShow && <div className={"tab " + (activeTab === "parentCompany" ? "active" : "")}
                                    onClick={() => {
                                        setActiveTab("parentCompany")
                                        setUserIds([])
                                        setExistUsers([])
                                        setShareDescription("")
                                        SetSwitchCompanyOrgId(props.parentOrgInfo.parentOrgId)
                                        getSharedUsersDetails(props.parentOrgInfo.parentOrgId)
                                    }}
                                >
                                    <div>
                                        Parent Workspace
                                    </div>
                                    ({props.parentOrgInfo.parentOrgName})
                                </div>}

                                {childOption.length > 0 && <div className={"tab " + (activeTab === "childCompany" ? "active" : "")}
                                    onClick={() => {
                                        setActiveTab("childCompany")
                                        setShareDescription("")
                                        if (childOption[childOption.length - 1]) {
                                            setChildSelected(childOption[childOption.length - 1].value)
                                            SetSwitchCompanyOrgId(childOption[childOption.length - 1].value)
                                            getSharedUsersDetails(childOption[childOption.length - 1].value)
                                        }

                                    }}>
                                    <div>
                                        Child Workspace
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>}

                    {activeTab === "childCompany" && <>
                        <div className="form-group">
                            <label>{"Company"}</label>
                            <DropDown
                                options={childOption}
                                isSearchable={true}
                                placeholder={"Choose company"}
                                onChange={(e) => {
                                    setChildSelected(e.value)
                                    setUserIds([])
                                    setExistUsers([])
                                    setShareDescription("")
                                    SetSwitchCompanyOrgId(e.value)
                                    getSharedUsersDetails(e.value)

                                }}
                                selectedOption={childOption.filter((val => { return val.value === childSelected }))}
                                isMulti={false}
                            />
                        </div>
                    </>}

                    <div className="form-group">
                        <label>{"Member"}</label>
                        <SearchDropdown
                            selectedOption={userIds}
                            onChange={(e) => {
                                setUserIds(e)
                            }}
                            isDisabled={false}
                            isMulti={true}
                            placeholder={"Select Member"}
                            buildOptions={(e) => {
                                let options = [];
                                e.forEach((option) => {
                                    option = searchDropdownOptionConvert(option)
                                    if (auth.loginId !== option.userId) {
                                        console.log("option",option);
                                        let label = capitalize(option.firstName)+ " " + capitalize(option.lastName)+ " " + "( "+ option.email +" )";
                                        options.push({ value: option.userId, label, disabled: Object.keys(existUsers).includes(option.userId) })
                                    }
                                })
                                return options

                            }}
                            grpcClient={props.grpcClient}
                            searchGrpcClient={props.searchGrpcClient}
                            parentId={equalCommunityService(auth.organizationType) ? GlobalSearchDataTypes.CommunityServiceAdmin : GlobalSearchDataTypes.User}
                            cacheDataType={GlobalSearchDataTypes.User}
                            isOptionDisabled={(option) => option.disabled}
                            parentOrgIdSwitch={(parentInfoShow || childOption.length > 0) ? switchCompanyOrgId : false}
                        />
                    </div>
                    <div className="form-group">
                        <label>{"Message"}</label>
                        <CustomEditor
                            title={"Message"}
                            text={shareDescription}
                            textOnchange={(e) => {
                                setShareDescription(e)
                            }}
                            changeText={shareDescription}
                        />
                    </div>

                    {
                        Object.keys(existUsers).length > 0 && <div className="">
                            <label>
                                Users
                            </label>
                            {
                                Object.keys(existUsers).map((user, index) => {
                                    return <div className="mb-2" key={user}>
                                        <i className="fas fa-user mr-2"></i> {existUsers[user].emailId + " (" + existUsers[user].orgName + ")"} {user === props.ownerId && <span className="badge badge-info">Owner</span>}
                                        {(user !== props.ownerId && auth.loginId === props.ownerId) ? <span className="share-remove" onClick={() => {
                                            if (window.confirm("Are you sure unshare for user ?")) {
                                                unShareApi(user)
                                            }
                                        }}>
                                            <i className="fa fa-times-circle ml-2"></i>
                                        </span> : ""}

                                    </div>
                                })
                            }
                        </div>
                    }

                </div>}
                footer={<React.Fragment>
                    <button type="button"
                        className={"btn btn-primary"}
                        onClick={() => save()}
                        disabled={userIds.length === 0}
                    >{submitLoader && <i className="fa fa-spinner fa-spin"></i>} Save</button>
                </React.Fragment>}
            />
        }
    </React.Fragment>

}

export default ShareModal
