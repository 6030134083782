import {
  SET_DRIVE_DATA,
  SET_MULTIPLE_DRIVE_DATA,
  RESET_DRIVE_DATA
} from '../constants/actionTypes';

const initialState = {
  parentFolderData: {},
  folderViewHistory: {}
};

const driveReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DRIVE_DATA: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_DRIVE_DATA: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_DRIVE_DATA: {
      return {
        ...state,
        ...initialState
      };
    }
    default: {
      return state;
    }
  }
};

export default driveReducer;
