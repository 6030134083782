import React, { useState, useEffect } from "react";
import { getDateCustomFormate, getGrpcClient, getPageText, getUuidv4, searchDropdownOptionConvert } from "../../../config/Common";
import { useSelector } from "react-redux";
// import MyConstant from "../../../config/MyConstant";
import { ZiFetchRequest } from "../../../protobuf/generated/ZiFetchService_grpc_web_pb";
import GlobalMsgCons from "../../../config/GlobalMsgCons";
// import GlobalDomainCons from "../../../config/GlobalDomainCons";
// import GlobalEventCons from "../../../config/GlobalEventCons";
import DropDown from "../../../components/DropDown";
// import Loader from "../../../components/Loader";
import { toast } from 'react-toastify';
// import Modal from "../../../components/Modal";
import CustomDatePicker from "../../../components/CustomDatePicker";
import GlobalSearchDataTypes from "../../../config/GlobalSearchDataTypes";
// import SearchDropdown from '../../../components/SearchDropdown';
import GlobalEventCons from "../../../config/GlobalEventCons";
import GlobalDomainCons from "../../../config/GlobalDomainCons";
import SearchDropdownCreatable from "../../../components/SearchDropdownCreatable";
import InvitePeople from "../component/InvitePeople";


const therapyList = [
    { label: "individual", value: "individual" },
    { label: "family", value: "family" },
    { label: "group", value: "group" },
    { label: "other", value: "other" }
]
const diseaseList = [
    { label: "symptoms of depression", value: "symptomsofdepression" },
    { label: "symptoms of anxiety", value: "symptomsofanxiety" },
    { label: "symptoms of postraumatic stress", value: "symptomsofpostraumaticstress" },
    { label: "psychosocial distress", value: "psychosocialdistress" },
    { label: "interpersonal challenges", value: "interpersonalchallenges" }
]

const clientPresented = [
    { label: "appearance WNL (within normal limits)", value: "appearanceWNLwithinnormallimits" },
    { label: "thoughts (content and processes) WNL", value: "thoughts(content and processes)WNL" },
    { label: "insight/judgment WNL", value: "insight/judgmentWNL" },
    { label: "attention/memory WNL", value: "attention/memoryWNL" },
    { label: "mood/affect WNL", value: "mood/affectWNL" }
]

const suicideRiskConfirmOptions = [
    { label: "was", value: "was" },
    { label: "was not", value: "wasnot" },
]
const suicideRiskOptions = [
    { label: "no intervention was indicated", value: "nointerventionwasindicated" },
    { label: "safety plan was created", value: "safetyplanwascreated" },
    { label: "safety plan was reviewed", value: "safetyplanwasreviewed" },
    { label: "resources were given", value: "resourcesweregiven" },
]

const interventionOptions = [
    { label: "supportive psychotherapy", value: "supportivepsychotherapy" },
    { label: "risk management", value: "riskmanagement" },
    { label: "coping skills training", value: "copingskillstraining" }
]
const clientdataWasGatheredOptions = [
    { label: "vitals", value: "vitals" },
    { label: "labs", value: "labs" },
    { label: "heart rate", value: "heartrate" },
    { label: "blood pressure", value: "bloodpressure" },
    { label: "temperature", value: "temperature" },
    { label: "breathing", value: "breathing" },
]

const clientparticipateOptions = [
    { label: "was", value: "was" },
    { label: "was not", value: "was not" },
]

function MigrantForm(props) {
    let { mentalAddFormShow, physicalAddFormShow } = props
    const auth = useSelector(state => state.authReducer);
    let [therapySelected, setTherapySelected] = useState("");
    let [clientReportedExperiencingSelected, setClientReportedExperiencingSelected] = useState("");
    let [clientReportedBriefQuote, setClientReportedBriefQuote] = useState("");
    let [therapistName, setTherapistName] = useState("");
    let [therapistInfo, setTherapistInfo] = useState("");
    let [therapistCredential, setTherapistCredential] = useState("");
    let [therapistAppointedDate, setTherapistAppointedDate] = useState(new Date().getTime());
    let [therapistOrgName, setTherapistOrgName] = useState("");
    let [therapistOrgCity, setTherapistOrgCity] = useState("");
    let [therapistOrgCountry, setTherapistOrgCountry] = useState("");
    let [clientPresentMentalHealthSelected, setClientPresentMentalHealthSelected] = useState([]);
    let [therapistNotes, setTherapistNotes] = useState("");
    let [suicideRiskConfirmSelected, setSuicideRiskConfirmSelected] = useState("");
    let [suicideRiskSelected, setSuicideRiskSelected] = useState("");
    let [interventionSelected, setInterventionSelected] = useState("");
    let [therapistInterventionText, setTherapistInterventionText] = useState("");

    let [clientActivelyChecked, setClientActivelyChecked] = useState("");
    let [clientActivelyText, setClientActivelyText] = useState("");
    let [clientReportedChecked, setClientReportedChecked] = useState("");
    let [clientReportedText, setClientReportedText] = useState("");
    let [clientDemonstratedChecked, setClientDemonstratedChecked] = useState("");
    let [clientDemonstratedText, setClientDemonstratedText] = useState("");
    let [clientEngagedChecked, setClientEngagedChecked] = useState("");
    let [clientEngagedText, setClientEngaged] = useState("");
    let [clientProviderText, setClientProviderText] = useState("");
    let [clientProviderDate, setClientProviderDate] = useState(new Date().getTime());
    let [recommendationsNextStepText, setRecommendationsNextStepText] = useState("");
    let [activeTab, setActiveTab] = useState("mentalhealth");
    let [doctorName, setDoctorName] = useState("");
    let [doctorInfo, setDoctorInfo] = useState("");
    let [doctorCredential, setDoctorCredential] = useState("");
    let [doctorTreatmentText, setDoctorTreatmentText] = useState("");
    let [doctorOrgName, setDoctorOrgName] = useState("");
    let [doctorOrgCity, setDoctorOrgCity] = useState("");
    let [doctorCountry, setDoctorCountry] = useState("");
    let [doctorScheduleDate, setDoctorScheduleDate] = useState(new Date().getTime());

    let [clientReportedSubjectText, setClientReportedSubjectText] = useState("");
    let [clientReportedSubjectBeganText, setClientReportedSubjectBeganText] = useState("");
    let [relevantMedicalText, setRelevantMedicalText] = useState("");
    let [clientIscurrentlyTakingMedications, setClientIscurrentlyTakingMedications] = useState("");

    let [clientExhibitedText, setClientExhibitedText] = useState("");
    let [physicianObservedText, setPhysicianObservedText] = useState("");
    let [clientparticipateSelected, setClientparticipateSelected] = useState("");
    let [clientDataWasGatheredChecked, setClientDataWasGatheredChecked] = useState([]);
    let [clientDataWasGatheredText, setClientDataWasGatheredText] = useState({});
    let [clientDiagnosisText, setClientDiagnosisText] = useState("");
    let [clientPrecipitatingFactorsText, setClientPrecipitatingFactorsText] = useState("");
    let [clientPerpetuatingFactorsText, setClientPerpetuatingFactorsText] = useState("");
    let [clientProtectiveFactorsText, setClientProtectiveFactorsText] = useState("");
    let [clientProblemsAddressedText, setClientProblemsAddressedText] = useState("");

    let [planPhysicianRecommendsText, setPlanPhysicianRecommendsText] = useState("");
    let [planPhysicianRequestsText, setPlanPhysicianRequestsText] = useState("");
    let [planNextStepsTreatmentText, setPlanNextStepsTreatmentText] = useState("");

    let [migrantUserSelected, setMigrantUserSelected] = useState("");
    let [getText, setGetText] = useState("");
    let [inviteModal, setInviteModal] = useState(false);
    let [pageText, setPageText] = useState({});

    //let paramsData = props.appsData;

    useEffect(() => {
        pageTextSetup()
    }, [])

    useEffect(() => {
        setActiveTab(props.activeTab)
    }, [props.activeTab])

    useEffect(() => {
        setMigrantUserSelected(props.selectBoxMember)
    }, [props.selectBoxMember])



    function mentalNotesSave() {
        let data = {
            data: {
                therapyType: therapySelected,
                therapistName,
                therapistCredential,
                therapistOrgName,
                therapistOrgCity,
                therapistOrgCountry,
                therapistAppointedDate,
                clientReportedExperiencing: clientReportedExperiencingSelected,
                clientReportedBriefQuote,
                clientPresentMentalHealthChecked: clientPresentMentalHealthSelected,
                clientPresentMentalHealthTherapistNotes: therapistNotes,
                suicideRiskConfirm: suicideRiskConfirmSelected,
                suicideRisk: suicideRiskSelected
            },
            intervention: {
                TherapistProvided: interventionSelected,
                TherapistProvidedContent: therapistInterventionText
            },
            response: {
                clientActively: clientActivelyText,
                clientReported: clientReportedText,
                clientDemonstrated: clientDemonstratedText,
                clientEngaged: clientEngagedText
            },
            plan: {
                clientProviderText: clientProviderText,
                clientProviderDate: clientProviderDate,
                recommendationsNextStepText
            }
        }

        if (!migrantUserSelected || !migrantUserSelected.value) {
            props.validationSelectBox()
            return false
        }
        let postJson = { reqId: getUuidv4(), userId: migrantUserSelected.value, data: JSON.stringify(data), createByUserId: auth.loginId, createByOrgId: auth.activeCompany };

        const request = new ZiFetchRequest();
        request.setDomain("user");
        request.setEvent("createMentalHealthData");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let json = JSON.parse(response.getMessage());
                if (json.response === GlobalMsgCons.success) {
                    toast.success("Created successfully", {
                        position: "top-center",
                        autoClose: GlobalMsgCons.autoClose,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    setTimeout(() => {
                        props.goBack()
                    }, 2500);

                }

            }
        });

    }

    function savePhysicalMental() {
        let data = {
            patientReceived: {
                doctorTreatment: doctorTreatmentText,
                doctorName,
                doctorCredential,
                doctorOrgName,
                doctorOrgCity,
                doctorCountry,
                doctorScheduleDate
            },
            subjective: {
                clientReportedSubject: clientReportedSubjectText,
                clientReportedSubjectBegan: clientReportedSubjectBeganText,
                relevantMedical: relevantMedicalText,
                clientIscurrentlyTakingMedications
            },
            objective: {
                clientExhibited: clientExhibitedText,
                physicianObserved: physicianObservedText,
                clientparticipate: clientparticipateSelected,
                clientDataWasGatheredChecked,
                clientDataWasGatheredText
            },
            assessment: {
                clientDiagnosis: clientDiagnosisText,
                clientPrecipitatingFactors: clientPrecipitatingFactorsText,
                clientPerpetuatingFactors: clientPerpetuatingFactorsText,
                clientProtectiveFactors: clientProtectiveFactorsText,
                clientProblemsAddressed: clientProblemsAddressedText
            },
            plan: {
                planPhysicianRecommends: planPhysicianRecommendsText,
                planPhysicianRequests: planPhysicianRequestsText,
                planNextStepsTreatment: planNextStepsTreatmentText
            }
        }
        if (!migrantUserSelected || !migrantUserSelected.value) {
            props.validationSelectBox()
            return false
        }
        let postJson = { reqId: getUuidv4(), userId: migrantUserSelected.value, data: JSON.stringify(data), createByUserId: auth.loginId, createByOrgId: auth.activeCompany };
        console.log("postJson", postJson)

        const request = new ZiFetchRequest();
        request.setDomain("user");
        request.setEvent("createPhysicalHealthData");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let json = JSON.parse(response.getMessage());
                if (json.response === GlobalMsgCons.success) {
                    toast.success("Created successfully", {
                        position: "top-center",
                        autoClose: GlobalMsgCons.autoClose,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTimeout(() => {
                        props.goBack()
                    }, 2500);

                }

            }
        });


    }


    function getUserProfile(userid, type) {
        let postJson = { reqId: getUuidv4(), sessionId: '123', userId: userid };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.userSetDomain);
        request.setEvent(GlobalEventCons.userProfileSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                var responseMsg = responseData.response;
                let { providerData } = responseMsg
                let refillQualification = "", refillWorkingOrgCity = "", refillWorkingOrgCountry = "", refillWorkingOrgName = "";
                if (providerData) {
                    let { qualification, workingOrgCity, workingOrgCountry, workingOrgName } = providerData
                    refillQualification = qualification;
                    refillWorkingOrgCity = workingOrgCity;
                    refillWorkingOrgCountry = workingOrgCountry;
                    refillWorkingOrgName = workingOrgName;
                }
                if (type === "health") {
                    setTherapistCredential(refillQualification)
                    setTherapistOrgName(refillWorkingOrgName)
                    setTherapistOrgCity(refillWorkingOrgCity)
                    setTherapistOrgCountry(refillWorkingOrgCountry)
                } else {
                    setDoctorCredential(refillQualification)
                    setDoctorOrgName(refillWorkingOrgName)
                    setDoctorOrgCity(refillWorkingOrgCity)
                    setDoctorCountry(refillWorkingOrgCountry)
                }
            }
        });
    }


    function getContent() {
        return <>
            <div className="container-fluid">
                <div className="d-flex justify-content-center">
                    <div className="tabs-container">
                        <div className="tabs">

                            {mentalAddFormShow ? <div className={`tab ${activeTab === "mentalhealth" ? "active" : "border-0"} `}
                                style={activeTab !== "mentalhealth" ? { backgroundColor: "#dcd7d7" } : {}}
                                onClick={() => {
                                    setActiveTab("mentalhealth")
                                }}>Mental Health Notes</div> : null}

                            {physicalAddFormShow ? <div className={`tab ${activeTab === "physicalhealth" ? "active" : "border-0"} `}
                                style={activeTab !== "physicalhealth" ? { backgroundColor: "#dcd7d7" } : {}}
                                onClick={() => {
                                    setActiveTab("physicalhealth")
                                }}
                            >Physical Health Notes</div> : null}


                        </div>
                        <div className="tabs-body my-2">
                            <div className="d-flex justify-content-end">
                                <button className="btn btn-sm btn-primary mx-2" onClick={() => {
                                    props.goBack()
                                }}>
                                    Back
                                </button>
                            </div>
                            {activeTab === "mentalhealth" && mentalAddFormShow ? getMentalContent() : ""}
                            {activeTab === "physicalhealth" && physicalAddFormShow ? getPhysicalContent() : ""}
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    function getPhysicalContent() {
        return <>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            Patient received
                        </div>
                        <div className="mx-1 my-2">
                            <input className="form-control" placeholder="treatment type" value={doctorTreatmentText} onChange={(e) => {
                                setDoctorTreatmentText(e.target.value)
                            }} />

                        </div>
                        <div className="m-2">
                            from
                        </div>
                        <div className="mx-1 my-2" style={{ width: 300 }}>
                            {/* <input className="form-control" placeholder="doctor/nurse name" value={doctorName} onChange={(e) => {
                                setDoctorName(e.target.value)
                            }} /> */}
                            <SearchDropdownCreatable
                                selectedOption={doctorInfo}
                                onChange={(e) => {
                                    setDoctorName(e.label)
                                    setDoctorInfo(e)
                                    getUserProfile(e.value, "physical")

                                }}
                                isDisabled={false}
                                isMulti={false}
                                placeholder={"Search doctor/nurse name"}
                                error={false}
                                buildOptions={(e) => {
                                    let options = [];
                                    e.forEach((option) => {
                                        option = searchDropdownOptionConvert(option)
                                        if (option.userId !== auth.loginId) {
                                            let lastName = option.lastName ? option.lastName[0].toUpperCase() + option.lastName.slice(1) : ""
                                            options.push({ value: option.userId, label: option.firstName[0].toUpperCase() + option.firstName.slice(1) + " " + lastName })
                                        }
                                    })
                                    return options
                                }}
                                grpcClient={props.grpcClient}
                                searchGrpcClient={props.searchGrpcClient}
                                parentId={GlobalMsgCons.roleTypeProvider}
                                cacheDataType={GlobalSearchDataTypes.User}
                                createLabel={"Invite"}
                                handleCreate={(e) => {
                                    return handleCreate(e)
                                }}
                            />
                        </div>
                        <div className="mx-1 my-2">
                            <input className="form-control" placeholder="credential" disabled value={doctorCredential} onChange={(e) => {
                                setDoctorCredential(e.target.value)
                            }} />
                        </div>
                        <div className="m-2">
                            at
                        </div>
                        <div className="mx-1 my-2">
                            <input className="form-control" placeholder="org name" disabled value={doctorOrgName} onChange={(e) => {
                                setDoctorOrgName(e.target.value)
                            }} />
                        </div>
                        <div className="m-2">
                            located in
                        </div>
                        <div className="mx-1 my-2">
                            <input className="form-control" placeholder="org city" disabled value={doctorOrgCity} onChange={(e) => {
                                setDoctorOrgCity(e.target.value)
                            }} />
                        </div>
                        <div className="mx-1 my-2">
                            <input className="form-control" placeholder="country" disabled value={doctorCountry} onChange={(e) => {
                                setDoctorCountry(e.target.value)
                            }} />
                        </div>
                        <div className="m-2">
                            on
                        </div>
                        <div className="mx-1 my-2">
                            <CustomDatePicker
                                className={`form-control`}
                                dateFormat="dd/MM/yyyy"
                                selected={doctorScheduleDate}
                                onChange={(date) => {
                                    setDoctorScheduleDate(new Date(date).getTime())
                                }}
                                dateFormatType={"custom"}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="ml-1">
                1. Subjective
            </div>
            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            1. Client reported
                        </div>
                        <div className="mx-1 my-2">
                            <input className="form-control" placeholder="" value={clientReportedSubjectText} onChange={(e) => {
                                setClientReportedSubjectText(e.target.value)
                            }} />
                        </div>
                        <div className="m-2">
                            which began
                        </div>
                        <div className="mx-1 my-2">
                            <input className="form-control" placeholder="" value={clientReportedSubjectBeganText} onChange={(e) => {
                                setClientReportedSubjectBeganText(e.target.value)
                            }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            2. Relevant medical history includes
                        </div>
                        <div className="mx-1 my-2">
                            <input className="form-control" placeholder="" value={relevantMedicalText} onChange={(e) => {
                                setRelevantMedicalText(e.target.value)
                            }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            3. Client is currently taking the following medications
                        </div>
                        <div className="mx-1 my-2">
                            <input className="form-control" placeholder="" value={clientIscurrentlyTakingMedications} onChange={(e) => {
                                setClientIscurrentlyTakingMedications(e.target.value)
                            }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="ml-1">
                2. Objective
            </div>
            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            1. Client exhibited
                        </div>
                        <div className="mx-1 my-2">
                            <input className="form-control" placeholder="" value={clientExhibitedText} onChange={(e) => {
                                setClientExhibitedText(e.target.value)
                            }} />
                        </div>
                        <div className="m-2">
                            symptoms
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            2. Physician observed the following
                        </div>
                        <div className="mx-1 my-2">
                            <input className="form-control" placeholder="" value={physicianObservedText} onChange={(e) => {
                                setPhysicianObservedText(e.target.value)
                            }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            3. sleep, skin, fatigue, fever, headache, visual disturbances, hearing disturbaces, respiratory, cardiovascular, gastrointestinal, gen/touriary, musculoskeletal, extremities, neurological, psychiatric
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            4. Client
                        </div>
                        <div className="mx-1 my-2">
                            <DropDown
                                options={clientparticipateOptions}
                                isSearchable={true}
                                placeholder=""
                                onChange={(e) => {
                                    setClientparticipateSelected(e.value)
                                }}
                                value={clientparticipateOptions.filter(option => option.value === clientparticipateSelected)}
                                className="d-width"
                            />
                        </div>
                        <div className="m-2">
                            able to participate fully in their care
                        </div>
                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            5. The following data was gathered
                        </div>
                        {
                            clientdataWasGatheredOptions.map((data) => {
                                return <div className="mx-1 my-2" key={data.value}>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id={data.value} checked={clientDataWasGatheredChecked.includes(data.value)} onChange={() => {
                                            if (clientDataWasGatheredChecked.includes(data.value)) {
                                                clientDataWasGatheredChecked = clientDataWasGatheredChecked.filter((fe) => {
                                                    return fe !== data.value
                                                })
                                                delete clientDataWasGatheredText[data.value]
                                            } else {

                                                clientDataWasGatheredText[data.value] = "";
                                                clientDataWasGatheredChecked.push(data.value)
                                            }
                                            setClientDataWasGatheredChecked([...clientDataWasGatheredChecked])
                                            setClientDataWasGatheredText({ ...clientDataWasGatheredText })
                                        }} />
                                        <label className="form-check-label" htmlFor={data.value}>
                                            {data.label}
                                        </label>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        {clientdataWasGatheredOptions.map((obj) => {
                            let { label, value } = obj
                            return clientDataWasGatheredChecked.includes(value) && <div className="m-2" key={value + "_checkeddata"}>
                                <label className="ml-1">{label}</label>
                                <textarea type="text" rows="2" cols="20" className={`form-control `} placeholder={`${label}`} value={clientDataWasGatheredText[value]} onChange={(e) => {
                                    clientDataWasGatheredText[value] = e.target.value;
                                    setClientDataWasGatheredText({ ...clientDataWasGatheredText })
                                }} />
                            </div>
                        })}
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            Assessment
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 ml-4">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            1. Client has
                        </div>
                        <div className="mx-1 my-2">
                            <input className="form-control" placeholder="diagnosis" value={clientDiagnosisText} onChange={(e) => {
                                setClientDiagnosisText(e.target.value)
                            }} />
                        </div>
                        <div className="m-2">
                            caused by
                        </div>
                        <div className="m-2">
                            <input className="form-control" placeholder="precipitating factors" value={clientPrecipitatingFactorsText} onChange={(e) => {
                                setClientPrecipitatingFactorsText(e.target.value)
                            }} />
                        </div>
                        <div className="m-2">
                            and exacerbated by
                        </div>
                        <div className="m-2">
                            <input className="form-control" placeholder="perpetuating factors" value={clientPerpetuatingFactorsText} onChange={(e) => {
                                setClientPerpetuatingFactorsText(e.target.value)
                            }} />
                        </div>
                        <div className="m-2">
                            <input className="form-control" placeholder="strengths/protective factors" value={clientProtectiveFactorsText} onChange={(e) => {
                                setClientProtectiveFactorsText(e.target.value)
                            }} />
                        </div>
                        <div className="m-2">
                            may mitigate these symptoms
                        </div>
                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            2. Problems to be addressed include
                        </div>
                        <div className="mx-1 my-2">
                            <input className="form-control" placeholder="" value={clientProblemsAddressedText} onChange={(e) => {
                                setClientProblemsAddressedText(e.target.value)
                            }} />
                        </div>
                    </div>
                </div>
            </div>


            <div className="ml-1">
                3. Plan
            </div>

            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            1. Physician recommends that client receive information on
                        </div>
                        <div className="mx-1 my-2">
                            <input className="form-control" placeholder="" value={planPhysicianRecommendsText} onChange={(e) => {
                                setPlanPhysicianRecommendsText(e.target.value)
                            }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            2. Physician requests that client complete
                        </div>
                        <div className="mx-1 my-2">
                            <input className="form-control" placeholder="" value={planPhysicianRequestsText} onChange={(e) => {
                                setPlanPhysicianRequestsText(e.target.value)
                            }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            3. Next steps for treatment include
                        </div>
                        <div className="mx-1 my-2">
                            <input className="form-control" placeholder="" value={planNextStepsTreatmentText} onChange={(e) => {
                                setPlanNextStepsTreatmentText(e.target.value)
                            }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-center" onClick={() => {
                savePhysicalMental()
            }}>
                <button className="btn btn-primary">
                    Save
                </button>
            </div>
        </>
    }

    function handleCreate(e) {
        if (window.confirm("Please confirm to invite to this user?")) {
            setInviteModal(true)
        }
    }

    function getMentalContent() {
        return <>
            <div className="ml-1">
                1. Data
            </div>
            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            1.Client received
                        </div>
                        <div className="mx-1 my-2">
                            <DropDown
                                options={therapyList}
                                isSearchable={true}
                                placeholder="Select therapy"
                                onChange={(e) => {
                                    setTherapySelected(e.value)
                                }}
                                value={therapyList.filter(option => option.value === therapySelected)}
                                className="d-width"
                            />
                        </div>
                        <div className="m-2">
                            from
                        </div>
                        <div className="mx-1 my-2" style={{ width: 300 }}>
                            <SearchDropdownCreatable
                                selectedOption={therapistInfo}
                                onChange={(e) => {
                                    setTherapistName(e.label)
                                    setTherapistInfo(e)
                                    getUserProfile(e.value, "health")

                                }}
                                isDisabled={false}
                                isMulti={false}
                                placeholder={"Search Therapist name"}
                                error={false}
                                buildOptions={(e) => {
                                    let options = [];
                                    e.forEach((option) => {
                                        option = searchDropdownOptionConvert(option)
                                        if (option.userId !== auth.loginId) {
                                            let lastName = option.lastName ? option.lastName[0].toUpperCase() + option.lastName.slice(1) : ""
                                            options.push({ value: option.userId, label: option.firstName[0].toUpperCase() + option.firstName.slice(1) + " " + lastName })
                                        }
                                    })
                                    return options
                                }}
                                grpcClient={props.grpcClient}
                                searchGrpcClient={props.searchGrpcClient}
                                parentId={GlobalMsgCons.roleTypeProvider}
                                cacheDataType={GlobalSearchDataTypes.User}
                                createLabel={"Invite"}
                                handleCreate={(e) => {
                                    return handleCreate(e)
                                }}
                            />
                        </div>
                        <div className="mx-1 my-2">
                            <input className="form-control" placeholder="Therapist credential" value={therapistCredential} disabled onChange={(e) => {
                                setTherapistCredential(e.target.value)
                            }} />
                        </div>
                        <div className="m-2">
                            at
                        </div>
                        <div className="mx-1 my-2">
                            <input className="form-control" disabled placeholder="Org name" value={therapistOrgName} onChange={(e) => {
                                setTherapistOrgName(e.target.value)
                            }} />
                        </div>
                        <div className="m-2"> located in </div>
                        <div className="mx-1 my-2">
                            <input className="form-control" placeholder="Org City" value={therapistOrgCity}
                                disabled onChange={(e) => {
                                    setTherapistOrgCity(e.target.value)
                                }} />
                        </div>
                        <div className="mx-1 my-2">
                            <input className="form-control" placeholder="Country" value={therapistOrgCountry} disabled onChange={(e) => {
                                setTherapistOrgCountry(e.target.value)
                            }} />
                        </div>
                        <div className="m-2"> on</div>
                        <div className="mx-1 my-2">
                            <CustomDatePicker
                                className={`form-control`}
                                dateFormat="dd/MM/yyyy"
                                selected={therapistAppointedDate}
                                onChange={(date) => {
                                    setTherapistAppointedDate(new Date(date).getTime())
                                }}
                                dateFormatType={"custom"}
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            2.Client reported that they are experiencing
                        </div>
                        <div className="mx-1 my-2">
                            <DropDown
                                options={diseaseList}
                                isSearchable={true}
                                placeholder="Client reported that they are experiencing"
                                onChange={(e) => {
                                    setClientReportedExperiencingSelected(e.value)
                                }}
                                value={diseaseList.filter(option => option.value === clientReportedExperiencingSelected)}
                                className="d-width-client-report"
                            />
                        </div>
                        <div className="m-2">
                            and are seeking care to
                        </div>
                        <div className="mx-1 my-2">
                            <textarea type="text" rows="2" cols="20" className={`form-control `} placeholder={"Brief goals quote"} value={clientReportedBriefQuote} onChange={(e) => {
                                setClientReportedBriefQuote(e.target.value)
                            }} />
                        </div>
                    </div>
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            3.Client presented as follows
                        </div>
                        <div className="mx-1 my-2">
                            {
                                clientPresented.map((data) => {
                                    return <div className="form-check" key={data.value}>
                                        <input className="form-check-input" type="checkbox" id={data.value} checked={clientPresentMentalHealthSelected.includes(data.value)} onChange={() => {
                                            if (clientPresentMentalHealthSelected.includes(data.value)) {
                                                clientPresentMentalHealthSelected = clientPresentMentalHealthSelected.filter((fe) => {
                                                    return fe !== data.value
                                                })
                                            } else {
                                                clientPresentMentalHealthSelected.push(data.value)
                                            }
                                            setClientPresentMentalHealthSelected([...clientPresentMentalHealthSelected])
                                        }} />
                                        <label className="form-check-label" htmlFor={data.value}>
                                            {data.label}
                                        </label>
                                    </div>
                                })
                            }
                        </div>
                        <div className="mx-1 my-2">
                            Therapist noticed
                        </div>
                        <div className="mx-1 my-2">
                            <textarea type="text" rows="2" cols="40" className={`form-control `} placeholder={"observations if client is not WNL for any of the above]"} value={therapistNotes} onChange={(e) => {
                                setTherapistNotes(e.target.value)
                            }} />
                        </div>
                    </div>
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            4.Suicide risk
                        </div>
                        <div className="mx-1 my-2">
                            <DropDown
                                options={suicideRiskConfirmOptions}
                                isSearchable={true}
                                placeholder="Suicide risk"
                                onChange={(e) => {
                                    setSuicideRiskConfirmSelected(e.value)
                                }}
                                value={suicideRiskConfirmOptions.filter(option => option.value === suicideRiskConfirmSelected)}
                                className="d-width"
                            />
                        </div>
                        <div className="m-2">
                            assessed and
                        </div>
                        <div className="mx-1 my-2">
                            <DropDown
                                options={suicideRiskOptions}
                                isSearchable={true}
                                placeholder="assessed"
                                onChange={(e) => {
                                    setSuicideRiskSelected(e.value)
                                }}
                                value={suicideRiskOptions.filter(option => option.value === suicideRiskSelected)}
                                className="d-width"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="ml-1">
                2. Intervention
            </div>
            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            1.Therapist provided
                        </div>
                        <div className="mx-1 my-2">
                            <DropDown
                                options={interventionOptions}
                                isSearchable={true}
                                placeholder="intervention type"
                                onChange={(e) => {
                                    setInterventionSelected(e.value)
                                }}
                                value={interventionOptions.filter(option => option.value === interventionSelected)}
                                className="d-width"
                            />
                        </div>
                        <div className="m-2">
                            with a focus on
                        </div>
                        <div className="m-2">
                            <input className="form-control" placeholder="content" value={therapistInterventionText} onChange={(e) => {
                                setTherapistInterventionText(e.target.value)
                            }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="ml-1">
                3. Response
            </div>
            <div className="row">
                <div className="col-12 ml-3">

                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox"
                                    id="flexClientActively" checked={clientActivelyChecked} onChange={(e) => {
                                        setClientActivelyChecked(e.target.checked)
                                    }} />
                                <div className="form-check-label" for="flexClientActively">
                                </div>
                            </div>
                        </div>
                        <div className="m-2">
                            1. Client actively participated in discussion on
                        </div>
                        <div className="mx-1 my-2">
                            {clientActivelyChecked ? <>
                                <input className="form-control" placeholder=" " value={clientActivelyText} onChange={(e) => {
                                    setClientActivelyText(e.target.value)
                                }} />
                            </> : "***"}
                        </div>
                    </div>

                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox"
                                    id="flexClientReported" checked={clientReportedChecked} onChange={(e) => {
                                        setClientReportedChecked(e.target.checked)
                                    }} />
                                <div className="form-check-label" for="flexClientReported">
                                </div>
                            </div>
                        </div>
                        <div className="m-2">
                            2. Client reported
                        </div>
                        <div className="mx-1 my-2">
                            {clientReportedChecked ? <>
                                <input className="form-control" placeholder=" " value={clientReportedText} onChange={(e) => {
                                    setClientReportedText(e.target.value)
                                }} />
                            </> : "***"}
                        </div>
                        <div className="m-2">
                            was helpful
                        </div>
                    </div>

                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox"
                                    id="flexClientDemonstrated" checked={clientDemonstratedChecked} onChange={(e) => {
                                        setClientDemonstratedChecked(e.target.checked)
                                    }} />
                                <div className="form-check-label" for="flexClientDemonstrated">
                                </div>
                            </div>
                        </div>
                        <div className="m-2">
                            3. Client demonstrated insight related to
                        </div>
                        <div className="mx-1 my-2">
                            {clientDemonstratedChecked ? <>
                                <input className="form-control" placeholder=" " value={clientDemonstratedText} onChange={(e) => {
                                    setClientDemonstratedText(e.target.value)
                                }} />
                            </> : "***"}
                        </div>
                    </div>


                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox"
                                    id="flexClientEngaged" checked={clientEngagedChecked} onChange={(e) => {
                                        setClientEngagedChecked(e.target.checked)
                                    }} />
                                <div className="form-check-label" for="flexClientEngaged">
                                </div>
                            </div>
                        </div>
                        <div className="m-2">
                            4. Client engaged in
                        </div>
                        <div className="mx-1 my-2">
                            {clientEngagedChecked ? <>
                                <input className="form-control" placeholder=" " value={clientEngagedText} onChange={(e) => {
                                    setClientEngaged(e.target.value)
                                }} />
                            </> : "***"}
                        </div>
                        <div className="m-2">
                            activity
                        </div>
                    </div>

                </div>
            </div>

            <div className="ml-1 mt-3">
                4. Plan
            </div>
            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            1. Client will attend the next session with
                        </div>
                        <div className="mx-1 my-2">
                            <input className="form-control" placeholder="provider" value={clientProviderText} onChange={(e) => {
                                setClientProviderText(e.target.value)
                            }} />
                        </div>
                        <div className="mx-1 my-2">
                            on
                        </div>
                        <div className="m-2">
                            <CustomDatePicker
                                className={`form-control`}
                                dateFormat="dd/MM/yyyy"
                                selected={clientProviderDate}
                                onChange={(date) => {
                                    setClientProviderDate(new Date(date).getTime())
                                }}
                                dateFormatType={"custom"}
                            />
                        </div>
                    </div>

                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            2. Recommendations for next steps include
                        </div>
                        <div className="mx-1 my-2">
                            <input className="form-control" placeholder="Recommendations" value={recommendationsNextStepText} onChange={(e) => {
                                setRecommendationsNextStepText(e.target.value)
                            }} />
                        </div>
                    </div>

                </div>
            </div>
            <button className="btn btn-success" onClick={() => {
                setGetText(true)
            }}>
                Generated Text
            </button>
            {getText ? GenText() : ""}
            <div className="text-center" onClick={() => {
                mentalNotesSave()
            }}>
                <button className="btn btn-primary">
                    Save
                </button>
            </div>
        </>
    }

    function GenText() {
        let [suicideFilter] = suicideRiskConfirmOptions.filter(fe => fe.value == suicideRiskConfirmSelected)

        let [suicideRiskFilter] = suicideRiskOptions.filter(fe => fe.value == suicideRiskSelected)

        let [interventionFilter] = interventionOptions.filter(fe => fe.value == interventionSelected)



        return <div className="d-flex flex-wrap justify-content-start my-2">
            <div>
                Client received {therapySelected} from {therapistName}, {therapistCredential}. at {therapistOrgName} located in {therapistOrgCity}, {therapistOrgCountry} on  {getDateCustomFormate(therapistAppointedDate, "DD/MM/YYYY")}. Client reported that they are experiencing {clientReportedExperiencingSelected} and are seeking care to {clientReportedBriefQuote}. Client presented as follows:
            </div>
            {clientPresentMentalHealthSelected.map((map) => {
                let [filterText] = clientPresented.filter(fe => fe.value == map)
                return <div key={map}>
                    {filterText.label}
                </div>
            })}
            <div>
                Therapist noticed {therapistNotes}. Suicide risk  {suicideFilter ? suicideFilter.label : ""}assessed,
            </div>
            <div>
                {suicideRiskFilter ? suicideRiskFilter.label : ""} . Therapist provided {interventionFilter ? interventionFilter.label : ""} focus on {therapistInterventionText}. Client actively participated in discussion on {clientActivelyText}. Client engaged in {clientEngagedText} activity. Client will attend the next session on {getDateCustomFormate(clientProviderDate, "DD/MM/YYYY")} if possible. Recommendations for next steps include {recommendationsNextStepText}.
            </div>
        </div>
    }

    async function pageTextSetup() {
        let pageText = await getPageText('team');
        setPageText(pageText)
    }


    return <React.Fragment>
        {
            props.fromView === "mobile" ? <div className='mobile-container'>
                <div className="header">
                    <div style={{ display: "flex", flex: 1, padding: 5, flexDirection: "row", fontSize: 18 }}>
                        <div style={{ padding: "0 10px" }} onClick={() => {
                            props.history.push(props.path + "/home/more")
                        }}>
                            <i className='fas fa-chevron-left' />
                        </div>
                        <div style={{ flex: 1 }}>
                            <div>Migrant Workspace</div>
                        </div>
                    </div>
                </div>
                <div className="body">
                    {getContent()}
                </div>
            </div> : <React.Fragment>
                {getContent()}
            </React.Fragment>
        }
        {
            inviteModal && <InvitePeople
                inviteModal={inviteModal}
                pageText={pageText}
                grpcClient={props.grpcClient}
                modalClose={() => {
                    setInviteModal(false)
                }}
                companyType={auth.organizationType}
                sessionId={auth.sessionId}
                userId={auth.loginId}
                companyId={auth.activeCompany}
            />
        }

    </React.Fragment>
}

export default MigrantForm