import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkMobileView } from '../config/Common';

var timeout = setTimeout(function () { }, 0)
const AUTOBULLET_TRIGGER_CHARACTER = "1.";
const AUTOBULLET_VALID_CONTAINERS = [
    "HTMLTableCellElement",
    "HTMLParagraphElement",
    "HTMLDivElement",
    "HTMLElement",
];
class CustomEditorMinimized extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: "",
            editView: false,
            fullView: false,
            eventCapture: "",
            newPageReload: false,
        }
        this.editorRef = React.createRef();
    }

    componentDidMount() {
        let editorState = '';
        if (JSON.stringify(this.props.text)) {
            editorState = this.props.text
        }
        if (this.editorRef.current) {
            this.editorRef.current.focus();
        }
        // console.log(this.editorRef)
        // this.editorRef.current.focus()
        this.setState({
            editorState: editorState,
            editView: false,
            newPageReload: true
        })
    }


    componentDidUpdate(prevProps) {
        if (this.props.channelId !== prevProps.channelId && this.editorRef.current != null) {
            this.editorRef.current.focus();
            this.editorRef.current.execCommand('mceReplaceContent', false, this.props.text);
        }
        if (this.props.insertTextToggle !== prevProps.insertTextToggle) {
            let text = this.props.insertText;
            this.editorRef.current.execCommand('mceInsertContent', false, text);
        }
        if (this.props.setEmptyText !== prevProps.setEmptyText) {
            this.setState({
                editorState: ""
            });
        }
        if (this.props.changeText !== prevProps.changeText) {
            let editorState = this.props.changeText;
            this.setState({
                editorState: editorState
            });
        }
    }

    insertText = (text, editorValue) => {
        let compineText = text + editorValue;
        return compineText
    };


    onChange(content) {
        this.setState({ editorState: content }, () => {
            if (typeof this.props.textOnchange !== "undefined") {
                this.props.textOnchange(content)
            }
        })
    }


    // Strips HTML and PHP tags from a string 
    // returns 1: 'Kevin <b>van</b> <i>Zonneveld</i>'
    // example 2: strip_tags('<p>Kevin <img src="someimage.png" onmouseover="someFunction()">van <i>Zonneveld</i></p>', '<p>');
    // returns 2: '<p>Kevin van Zonneveld</p>'
    // example 3: strip_tags("<a href='http://kevin.vanzonneveld.net'>Kevin van Zonneveld</a>", "<a>");
    // returns 3: '<a href='http://kevin.vanzonneveld.net'>Kevin van Zonneveld</a>'
    // example 4: strip_tags('1 < 5 5 > 1');
    // returns 4: '1 < 5 5 > 1'
    strip_tags(str, allowed_tags) {

        var key = '', allowed = false;
        var matches = []; var allowed_array = [];
        var allowed_tag = '';
        var i = 0;
        var k = '';
        var html = '';
        var replacer = function (search, replace, str) {
            return str.split(search).join(replace);
        };
        // Build allowes tags associative array
        if (allowed_tags) {
            allowed_array = allowed_tags.match(/([a-zA-Z0-9]+)/gi);
        }
        str += '';

        // Match tags
        matches = str.match(/(<\/?[\S][^>]*>)/gi);
        // Go through all HTML tags
        for (key in matches) {
            if (isNaN(key)) {
                // IE7 Hack
                continue;
            }

            // Save HTML tag
            html = matches[key].toString();
            // Is tag not in allowed list? Remove from str!
            allowed = false;

            // Go through all allowed tags
            for (k in allowed_array) {            // Init
                allowed_tag = allowed_array[k];
                i = -1;

                if (i != 0) { i = html.toLowerCase().indexOf('<' + allowed_tag + '>'); }
                if (i != 0) { i = html.toLowerCase().indexOf('<' + allowed_tag + ' '); }
                if (i != 0) { i = html.toLowerCase().indexOf('</' + allowed_tag); }

                // Determine
                if (i == 0) {
                    allowed = true;
                    break;
                }
            }
            if (!allowed) {
                str = replacer(html, "", str); // Custom replace. No regexing
            }
        }
        return str;
    }

    getKey() {
        let { theme } = this.props;
        let key = "theme-white";

        if (theme === "dark") {
            key = "theme-dark"
        }
        return key;
    }

    getThemeBg() {
        let { activeCompanyColor, theme } = this.props;
        let returnTheme = "#fff";

        if (!window.location.pathname.includes("/manage/")) {
            if (theme === "dark") {
                returnTheme = "#1d2127"
            }
        }
        return returnTheme;
    }

    getThemeText() {
        let { activeCompanyColor, theme } = this.props;
        let returnTheme = "#000";

        if (!window.location.pathname.includes("/manage/")) {
            if (theme === "dark") {
                returnTheme = "#fff"
            }
        }
        return returnTheme;
    }



    render() {
        const { editorState, editView, fullView } = this.state;
        const { pagename, title, editorClassName, updateChatTypingInfo } = this.props;
        // let editorClassCheck = `editor-className editor-border ${editorClassName !== undefined ? editorClassName : ""}`;
        // let editorClass = editorClassName !== undefined && editorClassName !== "" ? true : false;
        var that_ = this;
        return (<Editor
            key={this.getKey()}
            onInit={(evt, editor) => this.editorRef.current = editor}
            // initialValue={""}
            value={editorState}
            onLoadContent={() => {
            }}
            init={{
                id: "tinymce",
                selector: "textarea.tinymce",
                auto_focus: typeof (this.props.channelId) !== "undefined" ? true : "",
                height: 100,
                menubar: false,
                promotion: false,
                branding: false,
                statusbar: false,
                max_height: 350,
                browser_spellcheck: true,
                relative_urls: false,
                remove_script_host: false,
                // lists_indent_on_tab: false,
                // setup: function (editor) {
                //     editor.on("click", function () {
                //         console.log("editor was clciked")
                //     })
                // },
                setup: function (editor) {
                    editor.on("init", function () {
                        // console.log(editor.id)
                        // editor.execCommand('mceFocus');  
                        editor.selection.select(editor.getBody(), true);
                        editor.selection.collapse(false);
                        editor.focus();

                    })
                },
                paste_preprocess: function (pl, o) {
                    //example: keep bold,italic,underline and paragraphs
                    o.content = that_.strip_tags(o.content, '<b><u><i><p><pre><ol><ul><li><br>');

                    // remove all tags => plain text
                    // o.content = that_.strip_tags(o.content, '');
                },
                setup: function (editor) {
                    editor.on('focus', function (e) {
                        if (pagename === "chat" && that_.props.editorFocused) {
                            that_.props.editorFocused(e)
                        }
                    });

                    editor.on('keydown', function (e) {
                        if (pagename === "chat" && updateChatTypingInfo !== undefined) {
                            clearTimeout(timeout);
                            updateChatTypingInfo("add")
                            timeout = setTimeout(function () {
                                updateChatTypingInfo("delete")
                            }, 1000)
                        }
                        let checkDevice = checkMobileView()

                        if (e.key === 'Enter' && e.shiftKey) {
                            editor.execCommand('mceInsertNewLine');
                            return false
                        }

                        if (checkDevice && e.keyCode === 13 && e.key === "Enter") {
                            editor.execCommand('mceInsertNewLine');
                            return true;
                        }
                        else if (e.keyCode === 13 && e.key === "Enter" && !e.shiftKey && !e.ctrlKey && that_.props.submit !== undefined) {
                            console.log("ENTER PRESSED")
                            that_.props.submit(e);
                            return false;
                        }
                    });

                    editor.on("KeyUp", function (e) {
                        var sel = editor.selection.getSel();
                        var caretPos = sel.anchorOffset;
                        var txtData = sel.anchorNode.textContent;

                        // Char code 160 is a non breaking space
                        const lineMatch = `${AUTOBULLET_TRIGGER_CHARACTER}${String.fromCharCode(
                            160
                        )}`;

                        if (e.key === " " && caretPos === 3 && txtData === lineMatch) {
                            if (
                                AUTOBULLET_VALID_CONTAINERS.includes(
                                    sel.focusNode.parentElement.constructor.name
                                )
                            ) {
                                // Create an unordered list
                                editor.execCommand('InsertOrderedList', false, {
                                    'list-style-type': 'decimal',
                                });

                                // Delete the last two characters from the cursor position,
                                // which we know are "- " since that's what triggered the autobullet
                                //
                                // Modified from https://stackoverflow.com/a/43798749
                                const edRange = editor.selection.getRng();
                                const edNode = edRange.commonAncestorContainer;
                                let range = document.createRange(); // create a new range object for the deletion
                                range.selectNodeContents(edNode);
                                range.setStart(edNode, edRange.endOffset - 3); // current caret pos - 3
                                range.setEnd(edNode, edRange.endOffset); // current caret pos
                                range.deleteContents();
                            }
                        }
                    });

                },
                plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'charmap', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'fullscreen',
                    'insertdatetime', 'table', 'textpattern', 'nonbreaking'
                ],
                toolbar: (pagename === "docs" ? '' : 'fullscreen |') + ' blocks | ' +
                    'bold italic underline forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' + 'removeformat|link|',
                content_style: `
                    body { -webkit-font-smoothing: antialiased;font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif; font-size:14px;}      
                    p { margin: 0}
                    dl, ol, ul { margin: 0 }     
                    .mce-content-body {
                        background: ${this.getThemeBg()};
                        color: ${this.getThemeText()}
                    }                 
                  `,
                nonbreaking_force_tab: true
            }}
            onEditorChange={(e) => {
                this.onChange(e)
            }}
        />)
    }
}

const mapStateToProps = (state) => {
    return {
        ...state,
        activeCompanyColor: state.authReducer.activeCompanyColor,
        theme: state.authReducer.theme,
    }
}
export default withRouter(connect(mapStateToProps)(CustomEditorMinimized));
