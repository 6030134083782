import React, { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import { getGrpcClient, getUuidv4, getValidLabels, getValidTooltip, readSessionData } from "../../../config/Common";
import ReactTooltip from 'react-tooltip';
import DropDown from "../../../components/DropDown";
import { ZiFetchRequest } from "../../../protobuf/generated/ZiFetchService_grpc_web_pb";
import GlobalDomainCons from "../../../config/GlobalDomainCons";
import GlobalEventCons from "../../../config/GlobalEventCons";
import GlobalMsgCons from "../../../config/GlobalMsgCons";
import CustomEditor from "../../../components/CustomEditor";
import { useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import { toast } from 'react-toastify';
import Dragplaceholder from '../../../assets/Dragfile.png';
import MyConstant from "../../../config/MyConstant";
import MyConfig from "../../../config/MyConfig";

let videos = [".mp4", ".3gp", ".ogg", ".mov", ".webm"]
let audios = [".mp3"]
export default function TvAdd(props) {
    const auth = useSelector(state => state.authReducer);
    let { pageText, formModal, setFormModal, refreshData, editId, languageList, videoFileObj } = props;

    const [isDemo, setIsDemo] = useState(false);
    const [errors, setErrors] = useState({});
    let [keyWordArray, setKeyWordArray] = useState([""]);
    const [documentVideoFiles, setDocumentVideoFiles] = useState([]);
    const [documentFiles, setDocumentFiles] = useState([]);
    const [documentJson, setDocumentJson] = useState([]);
    const [accessType, setAccessType] = useState("public");
    const [sourceType, setSourceType] = useState("video");
    const [workspaceType, setWorkspaceType] = useState(props.workspaceType ? props.workspaceType : "work");
    const [companyOptions, setCompanyOptions] = useState([]);
    const [orgId, setOrgId] = useState(props.fromAdmin ? "" : auth.activeCompany);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [languageSelected, setLanguageSelected] = useState(auth.languagePreference);
    const [documentVideoJson, setDocumentVideoJson] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (props.fromAdmin) {
            getAllCompanyList()
        }
    }, [])

    useEffect(() => {
        if(videoFileObj instanceof File) {
            documentOnDrop([videoFileObj], "video")
        }
    }, [videoFileObj])

    async function fileUpload(i, dataJson) {
        const postFileUpload = new FormData();
        postFileUpload.append('file' + (i + 1), dataJson.path);
        postFileUpload.append('fileName' + (i + 1), dataJson.fileName);
        postFileUpload.append('processType' + (i + 1), dataJson.processType);
        postFileUpload.append('docsId' + (i + 1), dataJson.docsId);
        await fetch(MyConstant.keyList.apiTvURL + "uploads/" + dataJson.processType + "/" + dataJson.docsId, {
            method: "POST",
            mode: 'cors',
            redirect: 'follow',
            body: postFileUpload,
        }).then(
            function (response) {
                return response;
            }
        ).then(json => {
            return json.response;
        }).catch(error => console.warn(error));
    }

    async function fileVideoUpload(i, dataJson) {
        const postFileUpload = new FormData();
        postFileUpload.append('file' + (i + 1), dataJson.path);
        postFileUpload.append('fileName' + (i + 1), dataJson.fileName);
        postFileUpload.append('processType' + (i + 1), dataJson.processType);
        postFileUpload.append('docsId' + (i + 1), dataJson.docsId);
        await fetch(MyConstant.keyList.apiTvURL + "uploads/" + dataJson.processType + "/" + dataJson.docsId, {
            method: "POST",
            mode: 'cors',
            redirect: 'follow',
            body: postFileUpload,
        }).then(
            function (response) {
                return response;
            }
        ).then(json => {
            return json.response;
        }).catch(error => console.warn(error));
    }

    async function save() {

        let errors = {};
        if (title.trim() === '') {
            errors["title"] = getValidLabels(pageText, 'titleRequiredErr_msg')
        }
        if (description.trim() === '') {
            errors["description"] = getValidLabels(pageText, 'descriptionRequiredErr_Msg')
        }
        if (documentVideoJson.length === 0) {
            errors["video"] = getValidLabels(pageText, 'videoAudioRequiredErr_msg')
        }

        setErrors(errors)
        if (Object.keys(errors).length === 0) {
            setLoading(true)

            if (documentJson.length === 0) {
                const cover = await getVideoCover(documentVideoFiles[0], 1);
                let file = generateThumbnail(cover)
                await fileUpload(0, file);
                documentJson[0] = file
            } else {
                for (let i = 0; i < documentJson.length; i++) {
                    if (documentJson[i].path) {
                        await fileUpload(i, documentJson[i]);
                    }
                }
            }
            for (let i = 0; i < documentVideoJson.length; i++) {
                if (documentVideoJson[i].path) {
                    await fileVideoUpload(i, documentVideoJson[i]);
                }
            }

            let updatedBy = localStorage.getItem(MyConfig.project_LocalStorage + "manage_mUserId");

            let postJson = {
                reqId: getUuidv4(),
                fileType: sourceType,
                accessType,
                orgType: workspaceType,
                orgId,
                title,
                description,
                createdby: props.fromAdmin ? updatedBy : auth.loginId,
                fileObj: documentVideoJson[0],
                thumbnailObj: documentJson[0],
                createdbyUserType: props.fromAdmin ? "adminuser" : "user",
                language: languageSelected,
                isDemo,
                keywords: keyWordArray.join(",")
            }
            let action = "add";
            let message = getValidLabels(pageText, 'addedSuccess_msg');
            if (editId) {
                action = "edit";
                message = getValidLabels(pageText, 'updateSuccess_msg');
                postJson["id"] = editId
            }
            console.log("postJson", postJson)
            const request = new ZiFetchRequest();
            request.setDomain("teqitTv");
            request.setEvent(action);
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let json = JSON.parse(response.getMessage());
                    if (json.response === GlobalMsgCons.success) {
                        toast.success(message, {
                            position: "top-center",
                            autoClose: GlobalMsgCons.autoClose,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                        setLoading(false)
                        setTitle("")
                        setDescription("")
                        setDocumentFiles([])
                        setDocumentJson([])
                        setDocumentVideoFiles([])
                        setDocumentVideoJson([])
                        setFormModal(false)
                        setIsDemo(false)
                        setKeyWordArray([""])
                        refreshData()
                    }
                }
            });
        }
    }

    function getVideoCover(file, seekTo = 0.0) {
        console.log("getting video cover for file: ", file);
        return new Promise((resolve, reject) => {
            // load the file to a video player
            const videoPlayer = document.createElement('video');
            videoPlayer.setAttribute('src', URL.createObjectURL(file));
            videoPlayer.load();
            videoPlayer.addEventListener('error', (ex) => {
                reject("error when loading video file", ex);
            });
            // load metadata of the video to get video duration and dimensions
            videoPlayer.addEventListener('loadedmetadata', () => {
                // seek to user defined timestamp (in seconds) if possible
                if (videoPlayer.duration < seekTo) {
                    reject("video is too short.");
                    return;
                }
                // delay seeking or else 'seeked' event won't fire on Safari
                setTimeout(() => {
                    videoPlayer.currentTime = seekTo;
                }, 200);
                // extract video thumbnail once seeking is complete
                videoPlayer.addEventListener('seeked', () => {
                    console.log('video is now paused at %ss.', seekTo);
                    // define a canvas to have the same dimension as the video
                    const canvas = document.createElement("canvas");
                    canvas.width = videoPlayer.videoWidth;
                    canvas.height = videoPlayer.videoHeight;
                    // draw the video frame to canvas
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
                    // return the canvas image as a blob
                    ctx.canvas.toBlob(
                        blob => {
                            resolve(blob);
                        },
                        "image/jpeg",
                        0.75 /* quality */
                    );
                });
            });
        });
    }

    function generateThumbnail(blob) {
        let uid = getUuidv4();
        let thumbnailFile = new File([blob], "thumbnail_" + uid + ".jpeg")
        let fileUrl = URL.createObjectURL(thumbnailFile);
        let file = thumbnailFile;
        let filename = thumbnailFile.name;
        let ext = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
        let fileObj = {
            path: file,
            file: file,
            origFileName: filename,
            fileUrl: fileUrl,
            fileName: uid + "." + ext,
            docsId: uid,
            processType: "thumbnails",
            fileType: ext,
        };

        return fileObj
    }

    function getAllCompanyList() {
        let resData = readSessionData()
        let { manage_session } = resData
        let postJson = { session: manage_session, reqId: getUuidv4(), }
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.adminSetDomain);
        request.setEvent(GlobalEventCons.allCompanyListWithoutPaginationSE);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                let responseData = json.result
                let companyOptions = [];
                responseData.map((key) => {
                    let organizationName = key.organizationName
                    let organizationId = key.organizationId
                    companyOptions.push({ label: organizationName, value: organizationId, extra: key })
                })

                setCompanyOptions(companyOptions)
            }
        });
    }

    function keywordAdd() {
        return <div className="row teqit-tv-create-video">
            {keyWordArray.map((e, ei) => {
                return <div className="col-sm-3" key={ei + "_key"}>
                    <div className="form-group">
                        #<label data-tip={getValidLabels(pageText, 'keywordTxt')}>{getValidLabels(pageText, 'keywordTxt') + (parseInt(ei) + 1)}</label>

                        {ei > 0 ? <div className="tv-key-delete pl-2" onClick={() => {
                            keyWordArray.splice(parseInt(ei), 1);
                            setKeyWordArray([...keyWordArray])
                        }}>
                            <i className="fa fa-trash" aria-hidden="true"></i>
                        </div> : ""}
                        {ei === keyWordArray.length - 1 ? <div className="tv-key-add" onClick={() => {
                            keyWordArray.push("")
                            setKeyWordArray([...keyWordArray])
                        }}>
                            <i className="fa fa-plus-circle" aria-hidden="true"></i>
                        </div> : ""}
                        <input type="text" className={`form-control ${errors['keyword'] ? "is-invalid" : ""}`} placeholder={"#Keyword"} value={e} onChange={(e) => {
                            keyWordArray[ei] = e.target.value
                            setKeyWordArray([...keyWordArray])
                        }} />
                    </div>
                </div>
            })}
        </div>
    }

    function documentOnDrop(newFiles, type) {
        var documentFiles = [];
        var documentJson = [];

        for (var i = 0; i < newFiles.length; i++) {
            let file = newFiles[i];
            console.log("file", file)
            let filename = file.name;
            let ext = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
            let uid = getUuidv4();

            let processType = "";
            if (type === "thumbnail") {
                processType = "thumbnails";
            } else if (type === "video") {
                processType = sourceType;
            }

            documentFiles.push(newFiles[i]);
            documentJson.push({
                name: "",
                path: newFiles[i],
                status: 'active',
                progress: '0',
                origFileName: filename,
                fileName: uid + "." + ext,
                docsId: uid,
                processType,
                fileType: ext
            })
        }

        console.log("file", documentFiles, documentJson)
        if (type === "thumbnail") {
            setDocumentFiles(documentFiles)
            setDocumentJson(documentJson)
        } else if (type === "video") {
            setDocumentVideoFiles(documentFiles)
            setDocumentVideoJson(documentJson)
        }
    }

    function documentOnRemove(loopIndex, type) {

        if (type === "thumbnail") {
            var files = [...documentFiles];
            var json = [...documentJson];
            json.splice(loopIndex, 1);
            files.splice(loopIndex, 1);

            setDocumentFiles(files)
            setDocumentJson(json)
        } else if (type === "video") {
            var files = [...documentVideoFiles];
            var json = [...documentVideoJson];
            json.splice(loopIndex, 1);
            files.splice(loopIndex, 1);

            setDocumentVideoFiles(files)
            setDocumentVideoJson(json)
        }
    }

    return <React.Fragment>
        {
            formModal && <Modal
                visible={formModal}
                size={"modal-xl"}
                closeModal={() => {
                    setFormModal(false)
                    setIsDemo(false)
                    setErrors({})
                    setKeyWordArray([""])
                    setDocumentVideoFiles([])
                    setDocumentFiles([])
                }}
                heading={getValidLabels(pageText, 'addSourceHeaderTxt')}
                body={<React.Fragment>
                    <ReactTooltip place="bottom" type="dark" effect="float" html={true} />
                    <div className="row">
                        <div className="col-sm-3">
                            <div className="form-group">
                                <label data-tip={getValidTooltip(pageText, 'accessLabelTxt')}>{getValidLabels(pageText, 'accessLabelTxt')}</label>
                                <div>
                                    <div className="btn-group">
                                        <input type="radio" className="btn-check" name="accessbtn" id="accessbtnradio1" autoComplete="off" checked={accessType === "public"} onChange={() => {
                                            setAccessType("public")
                                        }} />
                                        <label className={`btn btn${accessType === "public" ? "" : "-outline"}-primary`} htmlFor="accessbtnradio1">{getValidLabels(pageText, 'publicLabelTxt')}</label>

                                        <input type="radio" className="btn-check" name="accessbtn" id="accessbtnradio2" autoComplete="off" checked={accessType === "private"} onChange={() => {
                                            setAccessType("private")
                                        }} />
                                        <label className={`btn btn${accessType === "private" ? "" : "-outline"}-primary`} htmlFor="accessbtnradio2">{getValidLabels(pageText, 'privateLabelTxt')}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="form-group">
                                <label data-tip={getValidTooltip(pageText, 'typeLabelTxt')}>{getValidLabels(pageText, 'typeLabelTxt')}</label>
                                <div>
                                    <div className="btn-group">
                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" checked={sourceType === "video"} onChange={() => {
                                            setSourceType("video")
                                        }} />
                                        <label className={`btn btn${sourceType === "video" ? "" : "-outline"}-primary`} htmlFor="btnradio1">{getValidLabels(pageText, 'VideoBtnTxt')}</label>

                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" checked={sourceType === "audio"} onChange={() => {
                                            setSourceType("audio")
                                        }} />
                                        <label className={`btn btn${sourceType === "audio" ? "" : "-outline"}-primary`} htmlFor="btnradio2">{getValidLabels(pageText, 'AudioBtnTxt')}</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            props.fromAdmin && <div className="col-sm-3">
                                <div className="form-group">
                                    <label data-tip={getValidTooltip(pageText, 'workspaceTypeLabelTxt')}>{getValidLabels(pageText, 'workspaceTypeLabelTxt')}</label>
                                    <div>
                                        <div className="btn-group">
                                            <input type="radio" className="btn-check" name="workspaceBtn" id="workspaceBtnradio1" autoComplete="off" checked={workspaceType === "work"} onChange={() => {
                                                setWorkspaceType("work")
                                            }} />
                                            <label className={`btn btn${workspaceType === "work" ? "" : "-outline"}-primary`} htmlFor="workspaceBtnradio1">{getValidLabels(pageText, 'workLabelTxt')}</label>

                                            <input type="radio" className="btn-check" name="workspaceBtn" id="workspaceBtnradio2" autoComplete="off" checked={workspaceType === "education"} onChange={() => {
                                                setWorkspaceType("education")
                                            }} />
                                            <label className={`btn btn${workspaceType === "education" ? "" : "-outline"}-primary`} htmlFor="workspaceBtnradio2">{getValidLabels(pageText, 'educationLabelTxt')}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }


                        {
                            props.fromAdmin && accessType === "private" && <div className="col-sm-3">
                                <div className="form-group">
                                    <label data-tip={getValidTooltip(pageText, 'WorkspaceLabelTxt')}>{getValidLabels(pageText, 'WorkspaceLabelTxt')}</label>
                                    <DropDown
                                        options={companyOptions}
                                        isSearchable={true}
                                        placeholder={getValidLabels(pageText, 'selectWorkspace_Ph_Txt')}
                                        onChange={(e) => {
                                            setOrgId(e.value)
                                        }}
                                        value={companyOptions.filter(option => option.value === orgId)}
                                    />
                                </div>
                            </div>
                        }
                    </div>

                    <div className="row">
                        <div className="col-sm-3">
                            <div className="form-group">
                                <label data-tip={getValidTooltip(pageText, 'language_LabelTxt')}>{getValidLabels(pageText, 'language_LabelTxt')}</label>
                                <DropDown
                                    options={languageList}
                                    isSearchable={true}
                                    placeholder={getValidLabels(pageText, 'language_LabelTxt')}
                                    onChange={(e) => {
                                        setLanguageSelected(e.value)
                                    }}
                                    value={languageList.filter(option => option.value === languageSelected)}
                                />
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="form-group">
                                <label data-tip={getValidTooltip(pageText, 'titleLabelTxt')}>{getValidLabels(pageText, 'titleLabelTxt')}</label>
                                <input type="text" className={`form-control ${errors['title'] ? "is-invalid" : ""}`} placeholder={getValidLabels(pageText, 'enterTitleLabelTxt')} value={title} onChange={(e) => setTitle(e.target.value)} />
                                {errors['title'] && <div className="invalid-feedback">{errors['title']}</div>}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label data-tip={getValidTooltip(pageText, 'descriptionLabelTxt')}>{getValidLabels(pageText, 'descriptionLabelTxt')}</label>
                                <CustomEditor
                                    title={getValidLabels(pageText, 'descriptionLabelTxt')}
                                    text={description}
                                    textOnchange={(e) => {
                                        setDescription(e)
                                    }}
                                    changeText={description}
                                />
                                {errors['description'] && <div className="invalid-feedback d-block">{errors['description']}</div>}
                            </div>
                        </div>
                    </div>

                    {keywordAdd()}

                    <div className="row">
                        <div className="col-sm-6">
                            <label data-tip={getValidTooltip(pageText, 'thumbnailLabelTxt')}>{getValidLabels(pageText, 'thumbnailLabelTxt')}</label>
                            {
                                <div className="dropback">
                                    <Dropzone accept=".png, .jpg, .jpeg" multiple={false}
                                        onDrop={(acceptedFiles) => {
                                            documentOnDrop(acceptedFiles, "thumbnail")
                                        }} onDropRejected={(reject) => {
                                            toast.error(getValidLabels(pageText, 'thumbnselectValidImage_TxtailLabelTxt'), {
                                                position: "top-right",
                                                autoClose: GlobalMsgCons.autoClose,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true
                                            });
                                        }}  >
                                        {({ getRootProps, getInputProps }) => (
                                            <section>
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <img src={Dragplaceholder} className='dragimgph' />
                                                        </div>
                                                        <div className="col-sm-9">
                                                            <p className='dragtext'>
                                                                {getValidLabels(pageText, 'dragfilesTxt')}
                                                                <br />
                                                                {getValidLabels(pageText, 'filesupportTxt')}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>

                                </div>
                            }
                            {errors['thumbnail'] && <div className="invalid-feedback d-block">{errors['thumbnail']}</div>}
                            {
                                documentFiles.map((f, loopIndex) => {
                                    let filePath = ""
                                    let fileName = ""
                                    let fileType = ""

                                    if (f instanceof File) {
                                        filePath = URL.createObjectURL(f)
                                        fileName = f.name
                                        fileType = fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
                                    }
                                    else {
                                        let buildUrl = MyConstant.keyList.apiTvURL + "vp?processType=" + f.processType + "&fileName=" + f.fileName;
                                        filePath = buildUrl
                                        fileType = f.fileType
                                    }

                                    return (<div className="my-2" key={loopIndex}>
                                        <div className="row rowstructure p-2">
                                            <div className="col-sm-3 file-box ">
                                                <img src={filePath} alt="no img" style={{ width: 100, height: 100, cursor: "pointer" }}
                                                />
                                                <i className="fa fa-trash delete-upload-file" onClick={() => documentOnRemove(loopIndex, "thumbnail")}></i>
                                            </div>
                                            <div className="col-sm-7">
                                                {f.name ? f.name : f.origFileName}
                                            </div>
                                        </div>
                                    </div>)
                                }
                                )
                            }
                        </div>
                        <div className="col-sm-6">
                            <label data-tip={getValidTooltip(pageText, 'videoAudioLabelTxt')}>{getValidLabels(pageText, 'videoAudioLabelTxt')}</label>
                            {
                                <div className="dropback">
                                    <Dropzone accept={sourceType === "video" ? videos.join(",") : audios.join(",")} multiple={false}
                                        onDrop={(acceptedFiles) => {
                                            documentOnDrop(acceptedFiles, "video")
                                        }} onDropRejected={(reject) => {
                                            toast.error(getValidLabels(pageText, 'pleaseSelectValidVideoErr_Txt'), {
                                                position: "top-right",
                                                autoClose: GlobalMsgCons.autoClose,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true
                                            });
                                        }}  >
                                        {({ getRootProps, getInputProps }) => (
                                            <section>
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <img src={Dragplaceholder} className='dragimgph' />
                                                        </div>
                                                        <div className="col-sm-9">
                                                            <p className='dragtext'>
                                                                {getValidLabels(pageText, 'dragfilesTxt')}
                                                                <br />
                                                                {getValidLabels(pageText, 'fileVideoSupportTxt')} ({sourceType === "video" ? videos.join(",") : audios.join(",")})
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>

                                </div>
                            }
                            {errors['video'] && <div className="invalid-feedback d-block">{errors['video']}</div>}
                            {
                                documentVideoFiles.map((f, loopIndex) => {
                                    let filePath = ""
                                    let fileName = ""
                                    let fileType = ""

                                    if (f instanceof File) {
                                        filePath = URL.createObjectURL(f)
                                        fileName = f.name
                                        fileType = fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
                                    }
                                    else {
                                        let buildUrl = MyConstant.keyList.apiTvURL + "vp?processType=" + f.processType + "&fileName=" + f.fileName;
                                        filePath = buildUrl
                                        fileType = f.fileType
                                    }
                                    return (<div className="my-2" key={loopIndex}>
                                        <div className="row rowstructure  p-2">
                                            <div className="col-sm-5 file-box">
                                                <video controls style={{ width: 200 }}>
                                                    <source src={filePath} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                                <i className="fa fa-trash delete-upload-file" onClick={() => documentOnRemove(loopIndex, "video")}></i>
                                            </div>
                                            <div className="col-sm-6" style={{ wordWrap: "break-word" }}>
                                                {f.name ? f.name : f.origFileName}
                                            </div>
                                        </div>
                                    </div>)
                                }
                                )
                            }
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-sm-12">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" checked={isDemo} id="flexCheckDefault" onChange={(e) => {
                                    setIsDemo(e.target.checked)
                                }} />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    {getValidLabels(pageText, 'isDemoVideoCheckboxTxt')}
                                </label>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
                }
                footer={<React.Fragment>
                    <button type="button" disabled={loading} className={'btn btn-primary'} onClick={() => {
                        save()
                    }}>{loading && <i className="fas fa-spinner fa-spin"></i>} {getValidLabels(pageText, 'saveBtnTxt')}</button>
                </React.Fragment>}
            />

        }
    </React.Fragment>;
}
