module.exports = {
    addGuestUser: 'addGuestUser',
    getExternalEndpoint: 'getExternalEndpoint',
    getMailConfig: 'getMailConfig',
    updateMailConfig: 'updateMailConfig',
    deleteMailConfig: 'deleteMailConfig',
    addExternalEndpoint: 'addExternalEndpoint',
    updateExternalEndpoint: 'updateExternalEndpoint',
    checkCalendlyEventValidation: 'checkCalendlyEventValidation',
    checkCalendlyEventValidationForOwner: 'checkCalendlyEventValidationForOwner',
    createMailChannelForUser: 'createMailChannelForUser',
    addCalendlyEvent: 'addCalendlyEvent',
    addGuestToCalendlyEvent: 'addGuestToCalendlyEvent',
    getCalendlyEventDetails: 'getCalendlyEventDetails',
    guestUserJoinToCalendlyEvent: 'guestUserJoinToCalendlyEvent',
    getGuestUserList: 'getGuestUserList',
    getCalendlyEventList: 'getCalendlyEventList',
    getCalendlyEventJoinedUserList: 'getCalendlyEventJoinedUserList',
    getCalendlyEventPollUserList: 'getCalendlyEventPollUserList',
    getTimeLimitWindowSetupData: 'getTimeLimitWindowSetupData',
    getMembersEvent: 'getMembers',
    listSetEvent: 'list',
    checkDayEntryExist: 'checkDayEntryExist',
    getPublicAppsList: 'getPublicAppsList',
    getPrivateAppsList: 'getPrivateAppsList',
    getPublicStudyMaterialList: 'getPublicStudyMaterialList',
    getTeqReqList: 'getTeqReqList',
    organizationSetEvent: 'getOrganizationDetails',
    createOrgApiKey: 'createOrgApiKey',
    addEncareOrgRegistry: 'addEncareOrgRegistry',
    getEncareOrgRegistryList: 'getEncareOrgRegistryList',
    getOrgApiKey: 'getOrgApiKey',
    getAdministrationUrl: 'getAdministrationUrl',
    updateAdministrationUrl: 'updateAdministrationUrl',
    organizationListSetEvent: 'getOrganizationList',
    meetingChennalIdSetEvent: 'getMeetingChannelId',
    createOrganizationSetEvent: 'createOrganization',
    updateOrganizationSetEvent: 'updateOrganization',
    membersPaginationSetEvent: "getMembersPagination",
    inviteMemberListSetEvent: "getInviteMemberList",
    searchInviteMember: "searchInviteMember",
    inviteMemberSetEvent: "inviteMember",
    inviteResendSetEvent: "inviteResend",
    getOrganizationOwnerInfo: "getOrganizationOwnerInfo",
    deleteMemberInviteSetEvent: "deleteMemberInvite",
    memberBasedOnRoleTypeSetEvent: "getMemberBasedOnRoleType",
    updateOrganizationStatusSetEvent: "updateOrganizationStatus",
    subjectListWithSimpleDataSetEvent: "getSubjectListWithSimpleData",
    subjectListWithPaginationSetEvent: "getSubjectListWithPagination",
    addSetEvent: "add",
    updateSetEvent: "update",
    deleteSetEvent: "delete",
    deleteTeam: "deleteTeam",
    get: "get",
    multiChannelsGet: "multiChannelsGet",
    feedback: "feedback",
    systemFeedback: "systemFeedback",
    dailyReport: "dailyReport",
    addDailyReport: "addDailyReport",
    updateDailyReport: "updateDailyReport",
    getUserDailyReport: "getUserDailyReport",
    getOrgDailyReport: "getOrgDailyReport",
    updateReviewerDataForDailyReport: "updateReviewerDataForDailyReport",
    listBetweenDateRangeSetEvent: "listBetweenDateRange",
    dayEntryListBetweenDateRangeSetEvent: "dayEntryListBetweenDateRange",
    educationDayEntryListBetweenDateRangeSetEvent: "educationDayEntryListBetweenDateRange",
    getMappedEducationTaskListForMembersSetEvent: "getMappedEducationTaskListForMemberBasedOnHierarchical",
    studyMaterialMappedWithSubjectSetEvent: "getStudyMaterialMappedWithSubject",
    updateStudyMaterialAndSubjectMappingSetEvent: "updateStudyMaterialAndSubjectMapping",
    subjectMapToMemberListSetEvent: "getSubjectMapToMemberList",
    subjectMapToMembersSetEvent: "subjectMapToMembers",
    removeMemberFromSubjectMappingSetEvent: "removeMemberFromSubjectMapping",
    updateSubjectTeacherMappingSetEvent: "updateSubjectTeacherMapping",
    updateProjectManagerMappingSetEvent: "updateProjectManagerMapping",
    projectListWithPaginationSetEvent: "getProjectListWithPagination",
    teamListSetEvent: "getTeamList",
    userSignUpViaDirectRegisterToOrgFlow: "userSignUpViaDirectRegisterToOrgFlow",
    getOrganizationShortData: "getOrganizationShortData",
    languagesDataSetEvent: "getLanguagesData",
    mappedTeamListSetEvent: "getMappedTeamList",
    uiListSetEvent: "ui-list",
    updateLanguagesJsonSetEvent: "updateLanguagesJson",
    mappedMemberListForTaskSetEvent: "getMappedMemberListForTask",
    createSetEvent: 'create',
    boardChangesStatusSetEvent: "getBoardChangesStatus",
    updateBoardTaskStatusSetEvent: "updateBoardTaskStatus",
    detailsSetEvent: "details",
    updateMemberTaskStatusSetEvent: "updateMemberTaskStatus",
    channelSetEvent: "channel",
    educationBoardChangesStatusSetEvent: "getEducationBoardChangesStatus",
    updateEducationBoardTaskStatusSetEvent: "updateEducationBoardTaskStatus",
    educationBoardDetailsSetEvent: "educationBoardDetails",
    updateMemberEducationTaskStatusSetEvent: "updateMemberEducationTaskStatus",
    channelDetailsSetEvent: "getChannelDetails",
    channelMembersSetEvent: "getChannelMembers",
    getChannelMembersWithoutPagination: "getChannelMembersWithoutPagination",
    updateChannelHost: "updateChannelHost",
    updateChatTypingInfo: "updateChatTypingInfo",
    issuesReportListBasedOnStatusSetEvent: "getIssuesReportListBasedOnStatus",
    allAppIssuesReportListSetEvent: "getAllAppIssuesReportList",
    getAllAppIssuesReportListBasedOnOrg: "getAllAppIssuesReportListBasedOnOrg",
    appIssuesReportLogsListSetEvent: "getAppIssuesReportLogsList",
    appIssuesReportVersionDataSetEvent: "getAppIssuesReportVersionData",
    updateAppIssuesReportStatusSetEvent: "updateAppIssuesReportStatus",
    appIssuesReportDetailsSetEvent: "getAppIssuesReportDetails",
    appIssuesStatusCounterSetEvent: "getAppIssuesStatusCounter",
    generateAllAppIssuesReportExcelSetEvent: "generateAllAppIssuesReportExcel",
    editAppIssuesReportSetEvent: "editAppIssuesReport",
    teqReqDataSetEvent: "getTeqReqData",
    getWorkFlowMessageLogs: "getWorkFlowMessageLogs",
    getAIWorkFlowLog: "getAIWorkFlowLog",
    getWorkflowDomainDetails: "getWorkflowDomainDetails",
    getAllWorkflowDomainList: "getAllWorkflowDomainList",
    getAIWorkFlowLogBasedOnReqId: "getAIWorkFlowLogBasedOnReqId",
    getLastWorkFlowMessageLogForUser: "getLastWorkFlowMessageLogForUser",
    getLastWorkFlowMessageLogForBlock: "getLastWorkFlowMessageLogForBlock",
    getAIWorkFlowLogDetails: "getAIWorkFlowLogDetails",
    setUpTeqReqSetEvent: "setUpTeqReq",
    deployWorkFlowForUser: "deployWorkFlowForUser",
    sendWorkFlowDef: "sendWorkFlowDef",
    updateTeqReqEditingStatus:"updateTeqReqEditingStatus",
    getTeqReqEditingStatus:"getTeqReqEditingStatus",
    userSetEvent: "user",
    createChatChannelSetEvent: "createChatChannel",
    checkDirectChannelExistSetEvent: "checkDirectChannelExist",
    channelListenerSetEvent: "channelListener",
    directChannelListSetEvent: "getDirectChannelList",
    getGameChannelList: "getGameChannelList",
    getCSMemberChannelList: "getCSMemberChannelList",
    workTaskChannelListSetEvent: "getWorkTaskChannelList",
    educationTaskChannelListSetEvent: "getEducationTaskChannelList",
    channelUnReadCountListSetEvent: "getChannelUnReadCountList",
    createAppIssuesReportSetEvent: "createAppIssuesReport",
    educationExpenseCategorySetEvent: "educationExpenseCategory",
    getChannelListSetEvent: "getChannelList",
    projectListWithSimpleDataSetEvent: "getProjectListWithSimpleData",
    cloudProviderListSetEvent: "getCloudProviderList",
    allCloudProviderIPListSetEvent: "getAllCloudProviderIPList",
    loginSetEvent: "login",
    areThereMessagesWaitingSetEvent: "areThereMessagesWaiting",
    appIssuesReportListForUserSetEvent: "getAppIssuesReportListForUser",
    updateFeedbackLogs: "updateFeedbackLogs",
    appIssuesReportListForCompanySetEvent: "getAppIssuesReportListForCompany",
    getAppIssuesBasedOnOrgWithUser: "getAppIssuesBasedOnOrgWithUser",
    userProfileSetEvent: "getUserProfile",
    editUserProfileSetEvent: "editUserProfile",
    updateUserSystemConfigSetEvent: "updateUserSystemConfig",
    userSignInSetEvent: "userSignIn",
    updateMemberRoleTypeSetEvent: "updateMemberRoleType",
    deactivateMemberSetEvent: "deactivateMember",
    activateInviteMemberSetEvent: "activateInviteMember",
    educationCategorizedReportSetEvent: "getEducationDayEntrysbCategorizedReport",
    educationTaskDetailsWithMemberInfoSE: "getEducationTaskDetailsWithMemberInfo",
    educationExpensesDataBetweenDateRangeSE: "getEducationExpensesDataBetweenDateRange",
    createEducationBoardStatusSetEvent: "createEducationBoardStatus",
    updateEducationBoardStatusSetEvent: "updateEducationBoardStatus",
    fetchAnalyticsSetEvent: "fetchAnalytics",
    educationBoardStatusDeleteSetEvent: "educationBoardStatusDelete",
    educationBoardStatusListSetEvent: "educationBoardStatusList",
    educationBoardStatusOrderSetEvent: "educationBoardStatusOrder",
    updateEducationBoardFinalStageMappingSE: "updateEducationBoardFinalStageMapping",
    checkMemberJoinedToOrgSetEvent: "checkMemberJoinedToOrg",
    userSignUpSetEvent: "userSignUp",
    userSignUpViaCommunityServiceFlow: "userSignUpViaCommunityServiceFlow",
    getFeedbackSearch: "getFeedbackSearch",
    getOrganizationFeedbackSearch: "getOrganizationFeedbackSearch",
    allCompanyListWithoutPaginationSE: "getAllCompanyListWithoutPagination",
    companyChatChannelListSetEvent: "getCompanyChatChannelList",
    fetchFilterAnalyticsSetEvent: "fetchFilterAnalytics",
    fetchDataFilterAnalyticsSetEvent: "fetchDataFilterAnalytics",
    updateChannelUnReadCountIsEmptySE: "updateChannelUnReadCountIsEmpty",
    getGameData: "getGameData",
    getChatTypingInfo: "getChatTypingInfo",
    channelChatViaListenerSetEvent: "getChannelChatViaListener",
    getChannelChatsSetEvent: "getChannelChats",
    getChatThread: "getChatThread",
    updateAiAgentChat: "updateAiAgentChat",
    editChannelChatSetEvent: "editChannelChat",
    editChatThread: "editChatThread",
    updateAcceptStatusSetEvent: "updateAcceptStatus",
    deleteChannelChatSetEvent: "deleteChannelChat",
    deleteThreadChat: "deleteThreadChat",
    updateCallStatusSetEvent: "updateCallStatus",
    updateEmoJiReactionForMsg: "updateEmoJiReactionForMsg",
    updateEmoJiReactionForThreadMsg: "updateEmoJiReactionForThreadMsg",
    addChannelMembersSetEvent: "addChannelMembers",
    sendMsgASyncSetEvent: "sendMsgASync",
    sendThreadMsgASync: "sendThreadMsgASync",
    addChatThread: "addChatThread",
    sendMsgSyncSetEvent: "sendMsgSync",
    updateChannelNotificationSoundStatusSE: "updateChannelNotificationSoundStatus",
    getAppsMappedWithProjectSetEvent: "getAppsMappedWithProject",
    updateAppsAndProjectMappingSetEvent: "updateAppsAndProjectMapping",
    getTeamMembersSetEvent: "getTeamMembers",
    getTaskMembersSetEvent: "getTaskMembers",
    getTeamTaskSetEvent: "getTeamTask",
    getProjectTeamSetEvent: "getProjectTeam",
    getMappedTaskListForMemberBasedOnHierarchicalSetEvent: "getMappedTaskListForMemberBasedOnHierarchical",
    getAllQATReportListSetEvent: "getAllQATReportList",
    getQATReportLogsListSetEvent: "getQATReportLogsList",
    getQATReportVersionDataSetEvent: "getQATReportVersionData",
    updateQATReportStatusSetEvent: "updateQATReportStatus",
    getQATReportStatusCounterSetEvent: "getQATReportStatusCounter",
    getQATReportListForUserSetEvent: "getQATReportListForUser",
    updateCloudProviderSetEvent: "updateCloudProvider",
    getQATReportListBasedOnStatusSetEvent: "getQATReportListBasedOnStatus",
    qatReportSetEvent: "qatReport",
    getQATReportDetailsSetEvent: "getQATReportDetails",
    editQATReportSetEvent: "editQATReport",
    createQATReportSetEvent: "createQATReport",
    getAllOrganizationFeedbackListSetEvent: "getAllOrganizationFeedbackList",
    getOrganizationFeedbackLogsListSetEvent: "getOrganizationFeedbackLogsList",
    updateOrganizationFeedbackStatusSetEvent: "updateOrganizationFeedbackStatus",
    getOrganizationFeedbackStatusCounterSetEvent: "getOrganizationFeedbackStatusCounter",
    createOrganizationFeedbackSetEvent: "createOrganizationFeedback",
    getOrganizationFeedbackVersionDataSetEvent: "getOrganizationFeedbackVersionData",
    getOrganizationFeedbackListForUserSetEvent: "getOrganizationFeedbackListForUser",
    deleteOrganizationFeedback: "deleteOrganizationFeedback",
    getCsvExtractionDataSetEvent: "getCsvExtractionData",
    getStatusBasedCompanyExpensesCounterSetEvent: "getStatusBasedCompanyExpensesCounter",
    getCompanyExpensesSetEvent: "getCompanyExpenses",
    UpdateExpensesStatusSetEvent: "UpdateExpensesStatus",
    categorizedReportListSetEvent: "dayEntryCategorizedReportList",
    boardStatusDeleteSetEvent: "boardStatusDelete",
    boardStatusListSetEvent: "boardStatusList",
    boardStatusOrderSetEvent: "boardStatusOrder",
    updateBoardFinalStageMappingSetEvent: "updateBoardFinalStageMapping",
    updateUserWireguardConfigStatusSetEvent: "updateUserWireguardConfigStatus",
    restartWireguardSetupSetEvent: "restartWireguardSetup",
    callSetEvent: "call",
    userMapWithVSCodeByOrgAdminSE: "getUserMapWithVSCodeByOrgAdmin",
    cloudIpAccessSetEvent: "getCloudIpAccess",
    deleteCloudIpMapWithCompanySE: "deleteCloudIpMapWithCompany",
    cloudIpMapWithCompanySetEvent: "cloudIpMapWithCompany",
    deleteUserMapWithVSCodeServerSetEvent: "deleteUserMapWithVSCodeServer",
    setDeviceInfoSetEvent: "setDeviceInfo",
    userSessionSetEvent: "userSession",
    userSignOutSetEvent: "userSignOut",
    generateJwtSetEvent: "generateJwt",
    mappedVSCodeServerDetailsForUserSE: "getMappedVSCodeServerDetailsForUser",
    updateLanguageSetEvent: "updateLanguage",
    userUpdateForgotPasswordSetEvent: "userUpdateForgotPassword",
    userForgotPasswordSendSetEvent: "userForgotPasswordSend",
    setUserTimeZoneInfoSetEvent: "setUserTimeZoneInfo",
    directJoinToOrganization: "directJoinToOrganization",
    joinOrganizationSetEvent: "joinOrganization",
    initCallSetEvent: "initCall",
    getChatMessageDetailsSetEvent: "getChatMessageDetails",
    getOrganizationFeedbackDetailsSetEvent: "getOrganizationFeedbackDetails",
    editOrganizationFeedbackSetEvent: "editOrganizationFeedback",
    getOrganizationFeedbackListBasedOnStatusSetEvent: "getOrganizationFeedbackListBasedOnStatus",
    setupOrganizationTimeLimitWindow: "setupOrganizationTimeLimitWindow",
    userAllowedTimingNotificationSetupSetEvent: "userAllowedTimingNotificationSetup",
    getUserAllowedTimingNotificationSetupDataSetEvent: "getUserAllowedTimingNotificationSetupData",
    getUserTimeZoneInfoSetEvent: "getUserTimeZoneInfo",
    deleteCloudProviderSetEvent: "deleteCloudProvider",
    ui_addSetEvent: "ui-add",
    ui_editSetEvent: "ui-edit",
    ui_deleteSetEvent: "ui-delete",
    deactivateSetEvent: "deactivate",
    activateSetEvent: "activate",
    changeRoleSetEvent: "changeRole",
    mapToRollTypeSetEvent: "mapToRollType",
    deleteIPToCloudProviderSetEvent: "deleteIPToCloudProvider",
    createCloudProviderSetEvent: "createCloudProvider",
    userMapWithVSCodeServerSetEvent: "userMapWithVSCodeServer",
    getTaskDetailsWithMemberInfoSetEvent: "getTaskDetailsWithMemberInfo",
    getUILanguageDataSetEvent: "getUILanguageData",
    updateStudyMaterialOpenDetailsSetEvent: "updateStudyMaterialOpenDetails",
    getOrganizationEducationExpensesSetEvent: "getOrganizationEducationExpenses",
    updateEducationExpensesStatusSetEvent: "updateEducationExpensesStatus",
    getUIStructureSetEvent: "getUIStructure",
    updateUILanguageDataSetEvent: "updateUILanguageData",
    doSinglePageTranslation: "doSinglePageTranslation",
    makeLanguagesDataZipSetEvent: "makeLanguagesDataZip",
    updateUIStructureDataSetEvent: "updateUIStructureData",
    getAccessSetEvent: "getAccess",
    getMappedToRollTypeSetEvent: "getMappedToRollType",
    getAllCloudProviderIPListWithPaginationSetEvent: "getAllCloudProviderIPListWithPagination",
    getUserMapWithVSCodeServerListWithFilterSetEvent: "getUserMapWithVSCodeServerListWithFilter",
    updateUserAppOpenDetailsSetEvent: "updateUserAppOpenDetails",
    taskMapToMembersSetEvent: "taskMapToMembers",
    removeMemberFromTaskMappingSetEvent: "removeMemberFromTaskMapping",
    teamMapToMembersSetEvent: "teamMapToMembers",
    removeMemberFromTeamMappingSetEvent: "removeMemberFromTeamMapping",
    checkChannelNameExistSetEvent: "checkChannelNameExist",
    getMemberOnlineStatusSetEvent: "getMemberOnlineStatus",
    updateUserOnlineStatusSetEvent: "updateUserOnlineStatus",
    getInboxMessageListSetEvent: "getInboxMessageList",
    updateInboxAction: "updateInboxAction",
    updateInboxMessageReadStatus: "UpdateInboxMessageReadStatus",
    getLeaveRequestDetails: "getLeaveRequestDetails",
    createMembershipPackage: "createMembershipPackage",
    getMembershipPackage: "getMembershipPackage",
    addNotes: "addNotes",
    editNotes: "editNotes",
    getUserNotesList: "getUserNotesList",
    deleteNotes: "deleteNotes",
    notes: "notes",
    updateUserConfig: "updateUserConfig",
    getUserConfigData: "getUserConfigData",
    updateSystemLevelRoleType: "updateSystemLevelRoleType",
    userTriggerVerificationSetEvent: "userTriggerVerification",
    userUpdateVerificationSetEvent: "userUpdateVerification",
    createWorkSpaceRequest: "createWorkSpaceRequest",
    updateWorkSpaceRequest: "updateWorkSpaceRequest",
    getWorkSpaceRequestDataList: "getWorkSpaceRequestDataList",
    updateWorkSpaceRequestStatusSetEvent: "updateWorkSpaceRequestStatus",
    updateWorkSpaceRequestDocumentSetEvent: "updateWorkSpaceRequestDocument",
    updateControlFeaturesForOrganizationSetEvent: "updateControlFeaturesForOrganization",
    updateHideChannelStatusSetEvent: "updateHideChannelStatus",
    getUnreadFeedBackInboxMessageList: "getUnreadFeedBackInboxMessageList",
    getOrgUnReadCountList: "getOrgUnReadCountList",
    updateChatSurveyReplySetEvent: "updateChatSurveyReply",
    hiddenChannelListSetEvent: "getHiddenChannelList",
    chatSurveyReplyDetailsSetEvent: "getChatSurveyReplyDetails",
    activeSessionDataSetEvent: "getActiveSessionData",
    getSessionsLastAccessBasedOnPlatform: "getSessionsLastAccessBasedOnPlatform",
    removeActiveSessionSetEvent: "removeActiveSession",
    globalPoll: "globalPoll",
    userPollSetEvent: "userPoll",
    updateDailyReportNotificationSetEvent: "updateDailyReportNotificationSetup",
    deactivatedOrganizationSetEvent: "getDeactivatedOrganizationList",
    userLevelControlFeaturesSetEvent: "getUserLevelControlFeaturesData",
    updateUserLevelControlSetEvent: "updateUserLevelControlFeatures",
    getHolidayDataSetEvent: "getHolidayData",
    getMemberBirthdayList: "getMemberBirthdayList",
    updateHolidayDataSetEvent: "updateHolidayData",
    removeClassTeacherMappingSetEvent: "removeClassTeacherMapping",
    removeSubjectTeacherMappingSetEvent: "removeSubjectTeacherMapping",
    updateWorspaceOwnerAccessStatusSetEvent: "updateWorspaceOwnerAccessStatus",
    updateOwnerSwichWorkspaceSetEvent: "updateOwnerSwichWorkspace",
    updateChatReadStatusSetEvent: "updateChatReadStatus",
    getChatReadedMemeberDataSetEvent: "getChatReadedMemeberData",
    adminUserUpdateForgotPasswordSetEvent: "adminUserUpdateForgotPassword",
    adminUserForgotPasswordSendSetEvent: "adminUserForgotPasswordSend",
    getPrivateSupportChannelList: "getPrivateSupportChannelList",
    getCommunitySupportChannelList: "getCommunitySupportChannelList",
    addContactSetEvent: "addContact",
    editContactSetEvent: "editContact",
    deleteContacSetEvent: "deleteContact",
    getContactListSetEvent: "getContactList",
    checkSurvivorIsExistSetEvent: "checkSurvivorIsExist",
    globalFrontendLogAccumulation: "addGlobalFrontendLogAccumulation",
    listWithoutPagination: "listWithoutPagination",
    externalAppUserIdMappingEvent: "externalAppUserIdMapping",
    updatePinCodeMapping: "updatePinCodeMapping",
    getPinCodeMappingList: "getPinCodeMappingList",
    getExternalAppUserIdMappingEvent: "getExternalAppUserIdMapping",
    getTeqitTvContentData: "getTeqitTvContentData",
    eventLogs: "eventLogs",
    getClientProjectList: "getClientProjectList",
    getProjectTeamList: "getProjectTeam",
    getTeamTask: "getTeamTask",
    getTeamProgressReport: "getTeamProgressReport",
    checkTeamNameExist: "checkTeamNameExist",
    updateChatThreadReadStatusSetEvent: "updateChatThreadReadStatus",
    getChatThreadReadedMemeberDataSetEvent: "getChatThreadReadedMemeberData",
    fetchFrontendLogsSetEvent: "fetchFrontendLogsAnalytics",
    fetchFrontendLogsFilterSetEvent: "fetchFrontendLogsFilterAnalytics",
    updateTeqitTvAccumulationSetEvent: "updateTeqitTvAccumulation",
    checkOrganizationNameExist: "checkOrganizationNameExist",
    UpdateUserUnreadChatReminderNotificationSetupSetEvent: "UpdateUserUnreadChatReminderNotificationSetup",
    addFormRequest: "addFormRequest",
    getFormMappedOrgList: "getFormMappedOrgList",
    getFormStructureData: "getFormStructureData",
    updateFormStructureData: "updateFormStructureData",
    getAllFormList: "getAllFormList",
    getOptionGroupData: "getOptionGroupData",
    deleteOptionGroupValue: "deleteOptionGroupValue",
    getAllFormOptionGroup: "getAllFormOptionGroup",
    updateOptionGroupValue: "updateOptionGroupValue",
    formMapToOrg: "formMapToOrg",
    addOptionGroup: "addOptionGroup",
    getUserUnreadChatReminderNotificationSetupSetEvent: "getUserUnreadChatReminderNotificationSetup",
    updateChannelImage: "updateChannelImage",
    createBot: "createBot",
    getBotList: "getBotList",
    updateBot: "updateBot",
    getPatientList: "getPatientList",
    getAllPatientList: "getAllPatientList",
    addPatient: "addPatient",
    extApiDataFetch: "extApiDataFetch",
    getBotLogNotificationList: "getBotLogNotificationList",
    listEHRSetEvent: "listEHR",
    createEHRSetEvent: "createEHR",
    UpdateEHRSetEvent: "updateEHR",
    refreshPatientStateSetEvent: "refreshPatientState",
    getPatientStateSetEvent: "getPatientState",
    getReferFriendListSetEvent: "getReferFriendList",
    getNextStepFromList: "getNextStepFromList",
    createSMSGatewaySetEvent: "createSMSGateway",
    updateSMSGatewaySetEvent: "updateSMSGateway",
    getSMSGatewayListSetEvent: "getSMSGatewayList",
    getTempCSEventList: "getTempCSEventList",
    addProjectProposalSetEvent: "addProjectProposal",
    addAiAgentType: "addAiAgentType",
    updateAiAgentType: "updateAiAgentType",
    getAiAgentType: "getAiAgentType",
    fetchCommunityServiceAnalytics: "fetchCommunityServiceAnalytics",
    addIssueType: "addIssueType",
    updateIssueType: "updateIssueType",
    getIssueTypeList: "getIssueTypeList",
    addDesignationRoleType: "addDesignationRoleType",
    updateDesignationRoleType: "updateDesignationRoleType",
    getDesignationRoleTypeList: "getDesignationRoleTypeList",
    getUserTypeList: "getUserTypeList",
    addUserType: "addUserType",
    updateScreenPrivilegesBasedOnUserType: "updateScreenPrivilegesBasedOnUserType",
    getScreenPrivilegesBasedOnUserTypeList: "getScreenPrivilegesBasedOnUserTypeList",
    getEirReportList: "getEirReportList",
    getEirReportListBasedOnDateFilter: "getEirReportListBasedOnDateFilter",
    fetchCommunityServiceFilterAnalytics: "fetchCommunityServiceFilterAnalytics",
    getAiAgent: "getAiAgent",
    addAiAgent: "addAiAgent",
    updateAiAgent: "updateAiAgent",
    getChildOrganizationList: "getChildOrganizationList",
    getChildOrganizationListWithoutPagination: "getChildOrganizationListWithoutPagination",
    getCsEventBasedOnCaseNo: "getCsEventBasedOnCaseNo",
    updateIntakeForm: "updateIntakeForm",
    updateCSEventCaseStage: "updateCSEventCaseStage",
    updateIretiCaseNumber: "updateIretiCaseNumber",
    getProcessIncomingSMSList: "getProcessIncomingSMSList",
    getProcessIncomingUssdList: "getProcessIncomingUssdList",
    updateForm: "updateForm",
    updateOptionGroup: "updateOptionGroup",
    createCustomerFeedback: "createCustomerFeedback",
    editCustomerFeedback: "editCustomerFeedback",
    mappingCustomerFeedbackToManager: "mappingCustomerFeedbackToManager",
    getCustomerFeedbackList: "getCustomerFeedbackList",
    getCustomerFeedbackListWithoutPagination: "getCustomerFeedbackListWithoutPagination",
    userSignUpViaCustomerServiceGuestFlow: "userSignUpViaCustomerServiceGuestFlow",
    getMappedMemberList: "getMappedMemberList",
    getAlreadyMappedMemberList: "getAlreadyMappedMemberList",
    getCustomerFeedbackDetails: "getCustomerFeedbackDetails",
    docsMaptoCustomerFeedback: "docsMaptoCustomerFeedback",
    teqDrawMaptoCustomerFeedback: "teqDrawMaptoCustomerFeedback",
    getCustomerFeedbackStatusLogsList: "getCustomerFeedbackStatusLogsList",
    updateCustomerSupportManagerAndMembersMapping: "updateCustomerSupportManagerAndMembersMapping",
    updateCustomerFeedbackCsMembers: "updateCustomerFeedbackCsMembers",
    updateCustomerFeedbackStatus: "updateCustomerFeedbackStatus",
    updateMsgTemplate: "updateMsgTemplate",
    getMsgTemplateData: "getMsgTemplateData",
    mapDesignationRoleTypeToUser: "mapDesignationRoleTypeToUser",
    getMsgTemplateList: "getMsgTemplateList",
    createMsgTemplateData: "createMsgTemplateData",
    deleteMsgTemplate: "deleteMsgTemplate",
    getBatchTranslationList: "getBatchTranslationList",
    createBatchTranslation: "createBatchTranslation",
    startBatchTranslation: "startBatchTranslation",
    stoptBatchTranslation: "stoptBatchTranslation",
    getAllCommunityServiceEventList: "getAllCommunityServiceEventList",
    getInviteNotesRegistryDataList: "getInviteNotesRegistryDataList",
    addInviteNotesRegistryData: "addInviteNotesRegistryData",
    deleteInviteNotesRegistryData: "deleteInviteNotesRegistryData",
    shareInviteNotesRegistryData: "shareInviteNotesRegistryData",
    mapToInternalApp: "mapToInternalApp",
    getMappedToInternalApp: "getMappedToInternalApp",
    makeLanguagesDataZipReleaseBasedOnInternalApp: "makeLanguagesDataZipReleaseBasedOnInternalApp",
    makeAllLangZipReleaseForInternalApp: "makeAllLangZipReleaseForInternalApp",
    makeAllLangZipRelease: "makeAllLangZipRelease",
    getAppForceUpdateInfraDataForInternalApp: "getAppForceUpdateInfraDataForInternalApp",
    updateCSEventNotAuthenticStatus: "updateCSEventNotAuthenticStatus",
    updateMultipleCSEventNotAuthenticStatus: "updateMultipleCSEventNotAuthenticStatus",
    getAppForceUpdateInfraDataForInternalApp: "getAppForceUpdateInfraDataForInternalApp",
    getExternalEndpointAccessLogstList: "getExternalEndpointAccessLogstList",
    getExternalAccessPointLogsList: "getExternalAccessPointLogsList",
    fetchTsquareAnalyticsBasedOnDate: "fetchTsquareAnalyticsBasedOnDate",
    fetchTsquareFilterAnalytics: "fetchTsquareFilterAnalytics",
    checkEmailAlreadyExist: "checkEmailAlreadyExist",
    checkEmailAlreadyExistInOrg: "checkEmailAlreadyExistInOrg",
    checkGuestEmailAlreadyExistInOrg: "checkGuestEmailAlreadyExistInOrg",
    accessRequestNotificationToInbox: "accessRequestNotificationToInbox",
    getFolderList: "getFolderList",
    createFolder: "createFolder",
    getFolderFileList: "getFolderFileList",
    getVideoTranscriberData: "getVideoTranscriberData",
    addVideoTranscriberData: "addVideoTranscriberData",
    createOrgViaAppbuilderProcessFlow: "createOrgViaAppbuilderProcessFlow",
    createMigrantUser: "createMigrantUser",
    getGuidSearch: "getGuidSearch",
    migrantUserMoveOrganization: "migrantUserMoveOrganization",
    filterCsEventDataFromSqlite: "filterCsEventDataFromSqlite",
    getDriveSize: "getDriveSize",
    addTeqitDriveData: "addTeqitDriveData",
    getTeqitDriveDataList: "getTeqitDriveDataList",
    getRecentTeqitDriveDataList: "getRecentTeqitDriveDataList",
    getStarredTeqitDriveDataList: "getStarredTeqitDriveDataList",
    getDeletedTeqitDriveDataList: "getDeletedTeqitDriveDataList",
    deleteTeqitDriveData: "deleteTeqitDriveData",
    deleteTeqitDriveDataFromTrash: "deleteTeqitDriveDataFromTrash",
    updateRecentTeqitDriveData: "updateRecentTeqitDriveData",
    updateStarredTeqitDriveData: "updateStarredTeqitDriveData",
    teqitDriveDataShareToUsers: "teqitDriveDataShareToUsers",
    getTeqitDriveSharedDataList: "getTeqitDriveSharedDataList",
    getTeqitDriveSharedData: "getTeqitDriveSharedData",
    getSharedUsersDetails: "getSharedUsersDetails",
    removeTeqitDriveSharedData: "removeTeqitDriveSharedData",
    removeSessionTab: "removeSessionTab",
    addSessionTab: "addSessionTab",
    updateUserProviderData: "updateUserProviderData",
    updateMemberBirthday: "updateMemberBirthday",
    getEncareCommunitySensitizationSessionList: "getEncareCommunitySensitizationSessionList",
    getMailChannelLogs: "getMailChannelLogs",
    updateMailChannelLogs: "updateMailChannelLogs",
    getAvatarVoiceSessionList: "getAvatarVoiceSessionList"
};