import React from 'react';
import teqbahnImg from '../../assets/WhiteTransparent.png'
import loginImg from '../../assets/logo/logo.png'
import logoImg from '../../assets/Logo_WhiteTrans.png'
import { ZiFetchRequest } from '../../protobuf/generated/ZiFetchService_grpc_web_pb';
import { Link, withRouter } from 'react-router-dom';
import MyConstant from '../../config/MyConstant';
import {  toast } from 'react-toastify';
import { getGrpcClient, getPageText, getValidLabels, getUuidv4, getAppInfo, checkMobileView, userFromTeqitMeDomain } from '../../config/Common';
import MyConfig from '../../config/MyConfig';
import {
    setLoginData, setMultipleAuthData, reSetAuthData,
    reSetSwitchAccountData, reSetWorkpaceData, reSetExpensesData, reSetQatListData, reSetQatCreateData,
    reSetQatEditData, reSetAppsData, reSetProjectDiagramData, reSetOrgFeedbackListData, reSetOrgFeedbackEditData, reSetUserOrgFeedbackListData, reSetReportData,
    reSetEntryReportData, reSetExpenseReportData, reSetCalendarData, reSetMembersData, reSetClientData, reSetExpensesCategoryData, reSetBoardStatusTypeData,
    reSetClientMasterData, reSetProjectData, reSetTeamData, reSetTaskData, reSetCloudProviderUserMapData, reSetBoardData, reSetPreferencesData, reSetNotificationData,
    reSetTimezoneData, reSetChatData, reSetAnalyticsData, reSetExpenseManageData, reSetSubjectData, reSetClassRoomData, reSetEducationTaskData,
    reSetEducationWorkpaceData, reSetEducationEntryReportData, reSetEducationBoardData, reSetEducationBoardStatustTypeBoardData, reSetEducationExpensesData,
    reSetEducationExpenseManageData, reSetEducationExpensesCategoryData, reSetEducationCsvFileHistoryData, reSetEducationExpensesReportData, reSetEducationReportsData
    , reSetFaqData, reSetEducationAppsData, reSetOrgFeedbackAddData, reSetDailyUpdateData, reSetLangugeData


} from '../../redux/actions';
import { connect } from 'react-redux';
import GlobalMsgCons from '../../config/GlobalMsgCons';
import GlobalDomainCons from '../../config/GlobalDomainCons';
import GlobalEventCons from '../../config/GlobalEventCons';
import Modal from '../../components/Modal';
import UpdateView from '../../components/UpdateView';
import Loader from '../../components/Loader';


class AuthEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageText: null,
            verifyAccountModal: false,
            emailInput: false,
            loaderCom: true
        }
    }
    componentDidMount() {
        let { email } = this.props.match.params;
        this.reSetReduxData();
        this.getPageText()
        this.getEmail(email)
    }

    reSetReduxData() {
        this.props.setLoginData("password", "")
        this.props.reSetAuthData();
        this.resetPageData()
    }

    resetPageData() {

        this.props.reSetSwitchAccountData()
        this.props.reSetLangugeData()
        this.props.reSetWorkpaceData()
        this.props.reSetExpensesData()
        this.props.reSetQatListData()
        this.props.reSetQatCreateData()
        this.props.reSetQatEditData()
        this.props.reSetAppsData()
        this.props.reSetProjectDiagramData()
        this.props.reSetOrgFeedbackListData()
        this.props.reSetOrgFeedbackAddData()
        this.props.reSetOrgFeedbackEditData()
        this.props.reSetUserOrgFeedbackListData()
        this.props.reSetReportData()
        this.props.reSetEntryReportData()
        this.props.reSetExpenseReportData()
        this.props.reSetCalendarData()
        this.props.reSetMembersData()
        this.props.reSetClientData()
        this.props.reSetExpensesCategoryData()
        this.props.reSetBoardStatusTypeData()
        this.props.reSetClientMasterData()
        this.props.reSetProjectData()
        this.props.reSetTeamData()
        this.props.reSetTaskData()
        this.props.reSetCloudProviderUserMapData()
        this.props.reSetBoardData()
        this.props.reSetPreferencesData()
        this.props.reSetNotificationData()
        this.props.reSetTimezoneData()
        this.props.reSetChatData()
        this.props.reSetAnalyticsData()
        this.props.reSetExpenseManageData()
        this.props.reSetSubjectData()
        this.props.reSetClassRoomData()
        this.props.reSetEducationTaskData()
        this.props.reSetEducationWorkpaceData()
        this.props.reSetEducationEntryReportData()
        this.props.reSetEducationBoardData()
        this.props.reSetEducationBoardStatustTypeBoardData()
        this.props.reSetEducationExpensesData()
        this.props.reSetEducationExpenseManageData()
        this.props.reSetEducationExpensesCategoryData()
        this.props.reSetEducationCsvFileHistoryData()
        this.props.reSetEducationExpensesReportData()
        this.props.reSetEducationReportsData()
        this.props.reSetFaqData()
        this.props.reSetEducationAppsData()
        this.props.reSetDailyUpdateData()
    }

    async setUserTimeLocation(userId) {
        if (userId) {
            //userId: String, timezoneInfo: String,timezoneType:String
            let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
            let postJson = {
                reqId: getUuidv4(),
                userId: userId,
                timezoneInfo: timeZone,
                timezoneType: "dynamic",
                isForced: false
            };
            // console.log("postJson", postJson)
            const request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.userSetDomain);
            request.setEvent(GlobalEventCons.setUserTimeZoneInfoSetEvent);
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    // let responseData = JSON.parse(response.getMessage());
                    // console.log("setUserZone!", responseData)
                }
            });
        }
    }

    loginCheck() {
        let { email, password } = this.props;
        let { pageText } = this.state;
        var reg = MyConfig.emaildIdValidation;
        let passwordReg =MyConfig.passwordValidation;
        let errors = {};
        if (email.trim() === '') {
            errors["email"] = getValidLabels(pageText, 'pleaseEnterEmailErr_msg');
        } else if (reg.test(email) === false) {
            errors["email"] = getValidLabels(pageText, 'enterValidEmailErr_msg');
        }
        if (password.trim() === '') {
            errors["password"] = getValidLabels(pageText, 'pleaseEnterPasswordErr_msg');
        } else if (passwordReg.test(password) === false) {
            errors["password"] = getValidLabels(pageText, 'enterMin4CharactersErr_msg');
        }

        if (Object.keys(errors).length > 0) {
            this.props.setLoginData("errors", errors)
        } else {
            this.props.setLoginData("errors", {
                email: "",
                password: ""
            })
            this.props.setLoginData("loginLoader", true);

            let deviceInfo = getAppInfo()
            let postJson = { reqId: getUuidv4(), email: email.toLowerCase(), password, deviceInfo };
            const request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.manageSetDomain);
            request.setEvent(GlobalEventCons.userSignInSetEvent);
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    var response = responseData.response;
                    if (response === GlobalMsgCons.success) {
                        this.props.setLoginData("loginLoader", false)
                        if (responseData.status === GlobalMsgCons.unVerified) {
                            this.setState({
                                verifyAccountModal: true
                            })
                        } else {
                            this.props.setMultipleAuthData({
                                isLoggedIn: '1',
                                loginId: responseData.id,
                                firstName: responseData.firstName,
                                lastName: responseData.lastName,
                                profilePhoto: responseData.profilePhoto,
                                sessionId: responseData.sessionId,
                                loggedEmail: email,
                                userThemeColor: responseData.themeColor,
                                languagePreference: responseData.languagePreference,
                                activeStatus: responseData.status,
                                hoursSelected: responseData.hourFormat ? responseData.hourFormat : 12,
                                phone: responseData.phone ? responseData.phone : ""
                            })


                            localStorage.setItem(MyConfig.project_LocalStorage + "languagePreference", responseData.languagePreference);
                            localStorage.setItem(MyConfig.project_LocalStorage + "_userLogin", true);
                            this.setUserTimeLocation(responseData.id)
                            this.props.history.push(MyConstant.keyList.projectUrl + `/${this.props.pageType}/login/switch-account`);
                        }
                    } else if (response === GlobalMsgCons.passwordMismatch) {
                        this.props.setLoginData("loginLoader", false)
                        toast.error(getValidLabels(pageText, 'passwordNotMatchErr_msg'), {
                            position: "top-center",
                            autoClose: GlobalMsgCons.autoClose,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (response === GlobalMsgCons.notExist) {
                        this.props.setLoginData("loginLoader", false)
                        toast.error(getValidLabels(pageText, 'emailNotExistErr_msg'), {
                            position: "top-center",
                            autoClose: GlobalMsgCons.autoClose,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                    else if (response === GlobalMsgCons.inActiveAccount) {
                        this.props.setLoginData("loginLoader", false)
                        toast.error("Account has been deactivated, please contact admin.", {
                            position: "top-center",
                            autoClose: GlobalMsgCons.autoClose,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }
            });
        }
    };

    resendEmail() {
        let { email } = this.props;
        let postJson = { reqId: getUuidv4(), verifyMethod: "email", mobileNumber: "", email: email.toLowerCase(), countryCode: "", emailSend: true, smsSend: false };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.manageSetDomain);
        request.setEvent(GlobalEventCons.userTriggerVerificationSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                var response = responseData.responseMessage;
                if (response === GlobalMsgCons.success) {
                    this.setState({
                        verifyAccountModal: false,
                    })

                    toast.success("The verification email has been sent successfully.", {
                        position: "top-center",
                        autoClose: GlobalMsgCons.autoClose,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        });
    }

    async getPageText() {
        let pageText = await getPageText('login');
        if (Object.keys(pageText).length > 0) {
            this.setState({ pageText: pageText })
        } else {
            window.location.reload()
        }
    }
    getEmail(email) {
        let postJson = { reqId: getUuidv4(), email: email };
        console.log("postJson", postJson);
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.manageSetDomain);
        request.setEvent(GlobalEventCons.checkEmailAlreadyExist);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                this.setState({ loaderCom: false })
                if (responseData.response === "mappingExist") {
                    this.props.setLoginData("email", email)
                    this.setState({ emailInput: true })
                }
            }
        })
    }
    render() {
        let { pageText, verifyAccountModal, emailInput, loaderCom } = this.state;
        let { email, password, errors, passwordVisible, loginLoader } = this.props;
        let langCode = localStorage.getItem(MyConfig.project_LocalStorage + "languagePreference");

        return <>{loaderCom ? <Loader /> : <React.Fragment>
            {
                checkMobileView() ? <div className='mobile-view-login'>
                    <div className='logo'>
                        {
                            userFromTeqitMeDomain() ? <Link to={"/"}>
                                <img src={logoImg} className="img-fluid" />
                            </Link> : <img src={logoImg} className="img-fluid" />
                        }
                    </div>
                    <div className='mt-5 text-center' style={{ fontSize: 26 }}>
                        <b>The future of work <br />
                            is here</b>
                    </div>
                    {emailInput ? <form className='login'>
                        <div className="form-group mb-3">
                            <label className="form-label fw-bolder">{getValidLabels(pageText, 'emailLabelTxt')}</label>
                            <input type="email" value={email} className={`form-control form-control-solid form-control-lg ${errors['email'] ? "is-invalid" : ""}`} placeholder={getValidLabels(pageText, 'emailPlaceholderTxt')} autoComplete="new-password"
                                onChange={(e) => {
                                    this.props.setLoginData("email", e.target.value)
                                }} disabled={emailInput} />
                            {
                                errors['email'] && <div className="invalid-feedback">{errors['email']}</div>
                            }
                        </div>

                        <div className="form-group mb-3">
                            <div className="row">
                                <div className="col-6">
                                    <label className="form-label fw-bolder" >{getValidLabels(pageText, 'passwordLabelTxt')}</label>
                                </div>
                            </div>
                            <div className="input-group">
                                <input type={passwordVisible ? "text" : "password"} value={password} className={`form-control form-control-solid form-control-lg ${errors['password'] ? "is-invalid" : ""}`} placeholder={getValidLabels(pageText, 'passwordPlaceholderTxt')} autoComplete="new-password"
                                    onChange={(e) => {
                                        this.props.setLoginData("password", e.target.value)
                                    }} />
                                <div className="input-group-append" onClick={() => {
                                    this.props.setLoginData("passwordVisible", !passwordVisible)
                                }}>
                                    <span className="input-group-text border-0 cursor-pointer"><i className={`fa ${passwordVisible ? "fa-eye-slash" : "fa-eye"}`} /></span>
                                </div>
                                {
                                    errors['password'] && <div className="invalid-feedback">{errors['password']}</div>
                                }
                            </div>
                        </div>
                        <button type="button" className="btn btn-lg btn-theme btn-block" disabled={loginLoader} onClick={() => {
                            this.loginCheck()

                        }}>
                            {loginLoader && <i className="fa fa-spinner fa-spin"></i>}
                            <span className={`${loginLoader && ("pl-1")}`}>{getValidLabels(pageText, 'signinBtnTxt')}</span>
                        </button>

                        <div className='row my-2'>
                            <div className='col-4 text-capitalize'>
                                <Link to={MyConstant.keyList.projectUrl + "/switch-language"} className="link-primary fw-bolder">
                                    <i className="fas fa-globe"></i> {langCode}
                                </Link>
                            </div>
                            {/* <div className='col-8 text-right'>
                                <Link to={MyConstant.keyList.projectUrl + "/forgot-password"} className="link-primary fw-bolder">{getValidLabels(pageText, 'forgotPasswordLinkTxt')}</Link>
                            </div> */}
                        </div>
                        {/* <div className='my-2'>
                            <UpdateView className='text-right' />
                        </div> */}
                    </form> : <div className='d-flex flex-column align-items-center justify-content-center px-2 text-center mt-3'>
                        <h6>Your email does not exist, Please contact someone who has a Teqit Workspace.</h6>
                        <p>If you would like have your own team or organization - please send an email to <a style={{ color: "#3388ff" }} href="mailto:needworkspace@teqit.me" target="_blank">needworkspace@teqit.me</a></p>
                    </div>}

                </div> : <div className="d-flex align-items-center main-box">
                    <div className="row mx-0" style={{ flex: 1, height: "100%" }}>
                        <div className="col-sm-12 col-md-6 login d-flex align-items-center justify-content-center">
                            <div className='login-avatar'>
                                {
                                    userFromTeqitMeDomain() ? <Link to={"/"}>
                                        <img src={loginImg} className="img-fluid" />
                                    </Link> : <img src={loginImg} className="img-fluid" />
                                }
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 login-form my-auto">
                            <div className=" text-center mb-5">
                                <h1 className="text-dark mb-3">{getValidLabels(pageText, 'signInTxt')}</h1>
                            </div>
                            {emailInput ? <form>
                                <div className="form-group mb-3">
                                    <label className="form-label text-dark fw-bolder">{getValidLabels(pageText, 'emailLabelTxt')}</label>
                                    <input type="email" value={email} className={`form-control form-control-solid form-control-lg ${errors['email'] ? "is-invalid" : ""}`} placeholder={getValidLabels(pageText, 'emailPlaceholderTxt')} autoComplete="new-password"
                                        onChange={(e) => {
                                            this.props.setLoginData("email", e.target.value)
                                        }} disabled={emailInput} />
                                    {
                                        errors['email'] && <div className="invalid-feedback">{errors['email']}</div>
                                    }
                                </div>

                                <div className="form-group mb-3">
                                    <div className="row">
                                        <div className="col-6">
                                            <label className="form-label text-dark fw-bolder" >{getValidLabels(pageText, 'passwordLabelTxt')}</label>
                                        </div>
                                        {/* <div className="col-6 text-right">
                                            <Link to={MyConstant.keyList.projectUrl + "/forgot-password"} className="link-primary fw-bolder px-2">{getValidLabels(pageText, 'forgotPasswordLinkTxt')}</Link>
                                        </div> */}
                                    </div>
                                    <div className="input-group">
                                        <input type={passwordVisible ? "text" : "password"} value={password} className={`form-control form-control-solid form-control-lg ${errors['password'] ? "is-invalid" : ""}`} placeholder={getValidLabels(pageText, 'passwordPlaceholderTxt')} autoComplete="new-password"
                                            onChange={(e) => {
                                                this.props.setLoginData("password", e.target.value)
                                            }} />
                                        <div className="input-group-append" onClick={() => {
                                            this.props.setLoginData("passwordVisible", !passwordVisible)
                                        }}>
                                            <span className="input-group-text border-0 cursor-pointer"><i className={`fa ${passwordVisible ? "fa-eye-slash" : "fa-eye"}`} /></span>
                                        </div>
                                        {
                                            errors['password'] && <div className="invalid-feedback">{errors['password']}</div>
                                        }
                                    </div>
                                </div>
                                <button type="button" className="btn btn-lg btn-theme btn-block" disabled={loginLoader} onClick={() => {
                                    this.loginCheck()

                                }}>
                                    {loginLoader && <i className="fa fa-spinner fa-spin"></i>}
                                    <span className={`${loginLoader && ("pl-1")}`}>{getValidLabels(pageText, 'signinBtnTxt')}</span>
                                </button>
                            </form> : <div className='d-flex flex-column align-items-center justify-content-center text-center'>
                                <h6>Your email does not exist, Please contact someone who has a Teqit Workspace.</h6>
                                <p>If you would like have your own team or organization - please send an email to <a href="mailto:needworkspace@teqit.me" target="_blank" style={{ color: "#3388ff",cursor:"pointer" }}>needworkspace@teqit.me</a> </p>
                            </div>}

                            {/* <UpdateView className='text-right' /> */}
                        </div>
                    </div>
                </div>
            }
            {
                verifyAccountModal && <Modal
                    visible={verifyAccountModal}
                    closeModal={() => {
                        this.setState({
                            verifyAccountModal: false
                        })
                    }}
                    heading={"Verify Account"}
                    body={<React.Fragment>
                        Please check your email and confirm your account by clicking the verification link. <br />
                        If you missed it check your <b>spam</b> or click below to resend your verification email.

                        <div className='mt-5 mb-2'>
                            <button type="button" className="btn btn-lg btn-theme btn-block" onClick={() => {
                                this.resendEmail()
                            }}>
                                Resend Email
                            </button>
                        </div>
                    </React.Fragment>}
                />
            }
        </React.Fragment>}</>;
    }
}


const mapStateToProps = (state) => {
    return {
        email: state.loginReducer.email,
        password: state.loginReducer.password,
        passwordVisible: state.loginReducer.passwordVisible,
        errors: state.loginReducer.errors,
        loginLoader: state.loginReducer.loginLoader,
        state,
    };
};

const mapDispatchToProps = {
    setLoginData, setMultipleAuthData, reSetAuthData,
    reSetSwitchAccountData, reSetWorkpaceData, reSetExpensesData, reSetQatListData, reSetQatCreateData,
    reSetQatEditData, reSetAppsData, reSetProjectDiagramData, reSetOrgFeedbackListData, reSetOrgFeedbackEditData, reSetUserOrgFeedbackListData, reSetReportData,
    reSetEntryReportData, reSetExpenseReportData, reSetCalendarData, reSetMembersData, reSetClientData, reSetExpensesCategoryData, reSetBoardStatusTypeData,
    reSetClientMasterData, reSetProjectData, reSetTeamData, reSetTaskData, reSetCloudProviderUserMapData, reSetBoardData, reSetPreferencesData, reSetNotificationData,
    reSetTimezoneData, reSetChatData, reSetAnalyticsData, reSetExpenseManageData, reSetSubjectData, reSetClassRoomData, reSetEducationTaskData,
    reSetEducationWorkpaceData, reSetEducationEntryReportData, reSetEducationBoardData, reSetEducationBoardStatustTypeBoardData, reSetEducationExpensesData,
    reSetEducationExpenseManageData, reSetEducationExpensesCategoryData, reSetEducationCsvFileHistoryData, reSetEducationExpensesReportData, reSetEducationReportsData,
    reSetFaqData, reSetEducationAppsData, reSetOrgFeedbackAddData, reSetDailyUpdateData, reSetLangugeData
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthEmail));