import React from 'react';
import { Link, withRouter, } from 'react-router-dom';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import Modal from './Modal';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { getGrpcClient, equalEducation, searchDropdownOptionConvert, equalCommunityService, updateCsChannelName, checkActiveAdmin } from '../config/Common';
import { getValidLabels, getUuidv4, getValidTooltip, coversionED, getPageText } from '../config/Common';
import MyConfig from '../config/MyConfig';
import messageSound from '../assets/message.wav';
import { connect } from 'react-redux';
import GlobalDomainCons from '../config/GlobalDomainCons';
import GlobalEventCons from '../config/GlobalEventCons';
import GlobalMsgCons from '../config/GlobalMsgCons';
import SearchDropdown from './SearchDropdown';
import GlobalSearchDataTypes from '../config/GlobalSearchDataTypes';
import SharedMessageModal from './SharedMessageModal';
import DropDown from './DropDown';



let intervalId;
let unreadCountFetchIntervalId;

const gameTypeOptions = [
    { label: "R3F", value: "R3F" }
]
const originalTitle = "Teqit - Teqbahn"
class ChatMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            toggleMenu: [],
            channelUnreadMessageCount: {},
            totalDmUnreadMessageCount: 0,
            totalChannelUnreadMessageCount: 0,
            totalTaskChannelUnreadMessageCount: 0,
            taskChannelList: [],
            channelList: [],
            dmList: [],
            gameList: [],
            createChannelModal: false,
            createChannelType: "",
            errors: [],
            userListOption: [],
            noOfPage: 1,
            channelName: "",
            channelDescription: "",
            channelUser: [],
            userId: "",
            companyId: "",
            channelId: "",
            menuToggleFirst: true,
            pageText: null,
            companyType: this.props.companyType,
            channelSubmitLoader: false,
            shareSubmitLoader: false,
            dirMsgStatus: { status: true, channelId: "" },
            groupedChannelOptions: [
                {
                    label: "Messages",
                    options: []
                },
                {
                    label: "Direct Messages",
                    options: []
                },
                {
                    label: "Task Messages",
                    options: []
                },
            ],
            selectedGroup: "",
            pageTextNew: null,
            tabFocused: true,
            dmChannelListLoader: false,
            gmChannelListLoader: false,
            taskChannelLoader: false,
            gameType: "R3F",
            hiddenChannelArr: []
        }
        this.getChannelMessagesFrequent = this.getChannelMessagesFrequent.bind(this);
        this.onFocus = this.onFocus.bind(this);
    }

    componentDidMount() {
        this.init()
        window.addEventListener("visibilitychange", this.onFocus)
        this.channelMenuCheck()
        this.chatMenuText()
        this.hiddenChannelsFun()
    }

    componentDidUpdate(prevProps) {

        if (this.props.location !== prevProps.location) {
            this.channelMenuCheck()
            let { pathname } = this.props.location;
            this.setState({
                channelId: this.getChannelId(pathname)
            }, () => {
                this.chatMsgStateUnreadCountEmpty()
            })
        }

        if (this.props.activeCompanyId !== prevProps.activeCompanyId) {
            this.init();
        }
        if (this.props.newChannelArrived !== prevProps.newChannelArrived || this.props.companyType !== prevProps.companyType) {
            this.getChannelList();
        }

        if (this.props.taskChannelUpdated !== prevProps.taskChannelUpdated) {
            let { companyType } = this.props
            if (equalEducation(companyType)) {
                this.getEducationTaskChannelList()
            } else {
                this.getWorkTaskChannelList("")
            }
        }
        if (this.props.newMsgArrived !== prevProps.newMsgArrived) {
            this.notficationShow()
        }
        if (this.props.pollUser !== prevProps.pollUser) {
            this.getChannelMessagesFrequent(this.props.pollUser)
        }
        if (this.props.pollChannelUnreadCount !== prevProps.pollChannelUnreadCount) {
            this.getChannelUnReadCountList(this.props.pollChannelUnreadCount)
        }
        if (this.props.languagePreference !== prevProps.languagePreference) {
            this.chatMenuText()
        }

    }
    componentWillUnmount() {
        // chatStream.cancel()
        // channelListStream.cancel()
        window.removeEventListener("visibilitychange", this.onFocus)
        clearTimeout(intervalId);
        clearTimeout(unreadCountFetchIntervalId);
        unreadCountFetchIntervalId = undefined
        intervalId = undefined
    }
    onFocus() {
        this.setState({
            tabFocused: document.visibilityState === "visible"
        }, () => {
            this.getChannelUnReadCountList(this.props.pollChannelUnreadCount)
        })
    }
    async chatMenuText() {
        let pageTextNew = await getPageText('chat');
        this.setState({ pageTextNew: pageTextNew })
    }
    async init() {
        let { pathname } = this.props.location;
        let { userId, companyId } = this.props;
        let _this = this;
        this.setState({
            userId,
            companyId,
            channelId: this.getChannelId(pathname)
        }, () => {
            this.getChannelList(true);
            this.chatMsgStateUnreadCountEmpty()
            this.getChannelUnReadCountList(this.props.pollChannelUnreadCount)
        })
    }

    getChannelId(pathname) {
        let channelId = "";

        if (pathname.includes("/chat/") || pathname.includes("/game/")) {
            let pathArr = pathname.split("/");
            channelId = pathArr.slice(-1)[0]
        }

        return channelId;
    }




    getChannelMessagesFrequent(responseData) {
        let { listOfMessageTypesWaiting, polldataMapWaiting } = responseData;
        if (listOfMessageTypesWaiting !== undefined) {
            listOfMessageTypesWaiting.map((item) => {
                if (item === "ChannelArrived" || item === "ChannelUpdated") {
                    if (typeof this.props.notifyNewChannelArrived !== "undefined") {
                        this.props.notifyNewChannelArrived();
                    }
                }
                if (item === "BoardArrived" || item === "EducationBoardArrived" || item === "BoardUpdated") {
                    if (typeof this.props.notifyNewBoardArrived !== "undefined") {
                        this.props.notifyNewBoardArrived();
                    }
                }
            })
        }

        if (polldataMapWaiting !== undefined) {
            let i = 0;
            Object.keys(polldataMapWaiting).map((item) => {
                let data = polldataMapWaiting[item];
                let { msgData, msgType } = data;
                msgData = JSON.parse(msgData);

                if (msgType === "UnReadMsgArrived") {
                    if (msgData.muteStatus === "unmute") {
                        let soundList = MyConfig.messageSound;
                        let sound = messageSound;
                        let soundData = msgData.sound;

                        soundList.map((item) => {
                            if (item.value === soundData) {
                                sound = item.audio
                            }
                        })

                        if (i === 0) {
                            //multiple sound control
                            this.props.notifyNewMessage(sound);
                            i++;
                        }
                    }
                }
            })
        }
    }

    menuToggle(type) {
        let { toggleMenu } = this.state;
        if (toggleMenu.includes(type)) {
            const index = toggleMenu.indexOf(type);
            toggleMenu.splice(index, 1);
        } else {
            toggleMenu.push(type);
        }

        this.setState({
            toggleMenu
        }, () => {
            ReactTooltip.rebuild()
        })
    }
    createRoom(e, type) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            createChannelModal: true,
            createChannelType: type,
            userListOption: [],
            dirMsgStatus: { status: true, channelId: "" },
            channelUser: [],
            errors: {}
        })
    }

    setUserSelected(event) {
        this.setState({
            channelUser: event
        }, () => {
            this.validateRunTime()
        })
    }

    async validateRunTime() {
        let { errors } = this.state
        if (Object.keys(errors).length > 0) {
            let errors = await this.checkValidation(false);
            this.setState({
                errors,
            })
        }
    }

    async checkValidation(checkBackend) {
        let errors = {};
        let { pageText } = this.props;
        let { createChannelType: type, hiddenChannelArr } = this.state;
        if (type === "channel" || type === "game") {
            if (this.state.channelName.trim() === "") {
                errors["channelName"] = getValidLabels(pageText, 'nameRequiredErr_msg');
            } else if (this.state.channelName.length > 0 && checkBackend) {
                let result = await this.checkGroupChannelExist();
                if (!result) {
                    errors["channelName"] = getValidLabels(pageText, 'channelExistsErr_msg');
                }
            }

            if (this.state.channelUser.length === 0) {
                errors["channelUser"] = getValidLabels(pageText, 'usersRequiredErr_msg');
            }
        } else {
            if (this.state.channelUser.length === 0) {
                errors["channelUser"] = getValidLabels(pageText, 'usersRequiredErr_msg');
            } else if (this.state.channelUser.length > 0 && checkBackend) {
                let result = await this.checkDirectChannelExist();
                if (!result.status) {
                    errors["channelUser"] = getValidLabels(pageText, 'alreadyCreatedCombinationErr_msg');
                    let existMsg = hiddenChannelArr.some(msgId => msgId.id === result.channelId)
                    if (existMsg) {
                        this.hideChannelPost(result.channelId, "unhide", GlobalMsgCons.channelType2)
                    }
                    await this.setState({ dirMsgStatus: result })
                }
            }
        }
        return errors
    }

    async channelSubmit() {
        let { createChannelType: type } = this.state;
        this.setState({ channelSubmitLoader: true })
        let errors = await this.checkValidation(true);

        if (Object.keys(errors).length === 0) {
            let name = "";
            let internIds = [];
            let channelType = "";
            let channelDescription = "";
            if (type === "channel" || type === "game") {
                name = this.state.channelName;
                channelDescription = this.state.channelDescription;

                if (type === "game") {
                    channelType = "game"
                } else {
                    channelType = GlobalMsgCons.channelType1;
                }
            } else if (type === "dm") {
                let internName = [];
                this.state.channelUser.forEach(function (entry) {
                    internName.push(entry.label);
                });
                name = internName.join(",");
                channelType = GlobalMsgCons.channelType2;
            }
            this.state.channelUser.forEach(function (entry) {
                internIds.push(entry.value);
            });
            this.createChatChannel(name, internIds, channelType, channelDescription)
        } else {
            this.setState({
                errors,
                channelSubmitLoader: false
            })
        }
    }

    createChatChannel(channelName, membersUserIds, channelType, channelDescription) {
        let { userId, currentOrganization, companyId, gameType } = this.state;
        let { pageText } = this.props;
        let postJson = {
            reqId: getUuidv4(),
            channelName,
            membersUserIds,
            channelType,
            channelDescription,
            createdByUserId: userId,
            organizationId: currentOrganization,
            orgId: companyId,
        };

        if (channelType === "game") {
            postJson.metaInfo = {
                gameType
            }
        }
        console.log("postJson", postJson)

        postJson = coversionED("encrypt", postJson).toString();
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.createChatChannelSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request.Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                if (responseData.response === GlobalMsgCons.success) {
                    let message = "Channel created successfully.";
                    if (channelType === GlobalMsgCons.channelType1) {
                        message = getValidLabels(pageText, 'channelCreatedSuccess_msg');
                    } else if (channelType === GlobalMsgCons.channelType2) {
                        message = getValidLabels(pageText, 'directMessageCreatedSuccess_msg');
                    }
                    toast.success(message, {
                        position: "top-center",
                        autoClose: GlobalMsgCons.autoClose,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    this.setState({
                        createChannelModal: false,
                        errors: {},
                        channelName: "",
                        channelDescription: "",
                        channelUser: [],
                        channelSubmitLoader: false
                    }, () => {
                        // this.getChannelList(channelType);
                    })
                }
            }
        });
    }

    getGroupChannelList(value) {
        let { userId, channelId, toggleMenu, companyId, menuToggleFirst, groupedChannelOptions } = this.state;
        if (value === "gmLoad") {
            this.setState({ gmChannelListLoader: true })
        }
        let postJson = { reqId: getUuidv4(), userId, orgId: companyId, };
        // console.log("-->postJson getChannelList", postJson)
        postJson = coversionED("encrypt", postJson).toString()
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.getChannelListSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request.Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                let channelList = [];
                let channelOption = [];
                if (responseData && responseData.result) {
                    let groupChannelData = responseData.result
                    groupChannelData.map((ival, i) => {
                        if (channelId === ival.id) {
                            let menuType = "channels";
                            if (!toggleMenu.includes(menuType) && menuToggleFirst) {
                                toggleMenu.push(menuType);
                                menuToggleFirst = false
                            }
                        }
                        channelList.push({
                            name: ival.channelName,
                            unreadMessageCount: 0,
                            id: ival.id
                        });
                        channelOption.push({
                            label: ival.channelName,
                            value: ival.id,
                            msgType: "channel"
                        });

                    })

                }
                groupedChannelOptions[0].options = channelOption
                this.props.setupChannelList("channels", channelList)
                this.setState({
                    channelList,
                    gmChannelListLoader: false,
                    menuToggleFirst,
                    toggleMenu,
                    groupedChannelOptions
                }, () => {
                    this.getChannelUnReadCountList(this.props.pollChannelUnreadCount)
                    ReactTooltip.rebuild();
                })
            }
        })
    }


    getDirectChannelList(value) {
        let { companyType, activeUserType } = this.props;
        let { userId, channelId, toggleMenu, companyId, menuToggleFirst, groupedChannelOptions } = this.state;
        if (value === "dmLoad") {
            this.setState({ dmChannelListLoader: true })
        }
        let postJson = { reqId: getUuidv4(), userId, orgId: companyId, };
        postJson = coversionED("encrypt", postJson).toString()
        // console.log("-->postJson getDirectChannelList", postJson)

        let event = GlobalEventCons.directChannelListSetEvent;
        if (equalCommunityService(companyType)) {
            event = GlobalEventCons.getCSMemberChannelList;
        }
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(event);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request.Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                let dmList = [];
                let channelOption = [];
                if (responseData && responseData.result) {
                    let directChannelData = responseData.result
                    directChannelData.map((ival, i) => {
                        if (channelId === ival.id) {
                            let menuType = "dm";
                            if (!toggleMenu.includes(menuType) && menuToggleFirst) {
                                toggleMenu.push(menuType);
                                menuToggleFirst = false
                            }
                        }

                        dmList.push({
                            name: updateCsChannelName(companyType, activeUserType, ival.channelName),
                            unreadMessageCount: 0,
                            id: ival.id,
                            memberNameAndEmail: ival.memberNameAndEmail ? ival.memberNameAndEmail : ""
                        });
                        channelOption.push({
                            label: ival.channelName,
                            value: ival.id,
                            msgType: "direct"
                        });
                    })
                    this.props.setupChannelList("directMessages", dmList)
                }
                groupedChannelOptions[1].options = channelOption
                this.setState({
                    dmList,
                    menuToggleFirst,
                    toggleMenu,
                    groupedChannelOptions,
                    dmChannelListLoader: false
                }, () => {
                    this.getChannelUnReadCountList(this.props.pollChannelUnreadCount)
                    ReactTooltip.rebuild();
                })
            }
        })
    }

    getGameChannelList(value) {
        let { companyType, activeUserType } = this.props;
        let { userId, channelId, toggleMenu, companyId, menuToggleFirst, groupedChannelOptions } = this.state;

        let postJson = { reqId: getUuidv4(), userId, orgId: companyId, };
        postJson = coversionED("encrypt", postJson).toString()

        let event = GlobalEventCons.getGameChannelList;
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(event);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request.Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                let gameList = [];
                if (responseData && responseData.result) {
                    let directChannelData = responseData.result
                    directChannelData.map((ival, i) => {
                        if (channelId === ival.id) {
                            let menuType = "game";
                            if (!toggleMenu.includes(menuType) && menuToggleFirst) {
                                toggleMenu.push(menuType);
                                menuToggleFirst = false
                            }
                        }

                        gameList.push({
                            name: updateCsChannelName(companyType, activeUserType, ival.channelName),
                            unreadMessageCount: 0,
                            id: ival.id,
                            gameChatChannelId: ival.gameChatChannelId
                        });
                    })
                }
                this.setState({
                    gameList,
                    menuToggleFirst,
                    toggleMenu,
                })
            }
        })
    }

    getWorkTaskChannelList(value) {
        let { userId, channelId, toggleMenu, companyId, menuToggleFirst, groupedChannelOptions } = this.state;
        if (value === "taskLoad") {
            this.setState({ taskChannelLoader: true })
        }
        let postJson = { reqId: getUuidv4(), userId, orgId: companyId, };
        // console.log("-->postJson getWorkTaskChannelList", postJson)
        postJson = coversionED("encrypt", postJson).toString()
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.workTaskChannelListSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request.Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                let taskChannelList = [];
                let channelOption = [];
                if (responseData && responseData.result) {
                    let taskChannelData = responseData.result
                    taskChannelData.map((ival, i) => {
                        let { projectName, teamName, channelName } = ival
                        if (channelId === ival.id) {
                            let menuType = "taskChannels";
                            if (!toggleMenu.includes(menuType) && menuToggleFirst) {
                                toggleMenu.push(menuType);
                                menuToggleFirst = false
                            }
                        }
                        let tooltipName = "";
                        if (projectName) {
                            tooltipName += "<i>Project</i> - " + projectName + "<br/>"
                        }
                        if (teamName) {
                            tooltipName += "<i>Team</i> - " + teamName + "<br/>"
                        }
                        if (channelName) {
                            tooltipName += "<i>Task</i> - " + channelName + "<br/>"
                        }

                        if (ival.status === "deleted") {
                            tooltipName += "Task Deleted"
                        } else if (ival.completed === "completed") {
                            tooltipName += "Task Completed"
                        }

                        taskChannelList.push({
                            name: channelName,
                            unreadMessageCount: 0,
                            id: ival.id,
                            completed: ival.taskStatus === "completed",
                            deleted: ival.status === "deleted",
                            tooltipName
                        });

                        channelOption.push({
                            label: channelName,
                            value: ival.id,
                            msgType: "task"
                        });
                    })

                    this.props.setupChannelList("taskChannels", taskChannelList)
                }
                groupedChannelOptions[2].options = channelOption
                this.setState({
                    taskChannelList,
                    menuToggleFirst,
                    toggleMenu,
                    channelOption,
                    taskChannelLoader: false
                }, () => {
                    this.getChannelUnReadCountList(this.props.pollChannelUnreadCount)
                    ReactTooltip.rebuild();
                })
            }
        })
    }

    getEducationTaskChannelList() {
        let { userId, channelId, toggleMenu, companyId, menuToggleFirst, groupedChannelOptions } = this.state;
        let postJson = { reqId: getUuidv4(), userId, orgId: companyId, };
        // console.log("-->postJson getEducationTaskChannelList", postJson)
        postJson = coversionED("encrypt", postJson).toString()
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.educationTaskChannelListSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request.Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                // console.log("responseData getEducationTaskChannelList",responseData)
                let taskChannelList = [];
                let channelOption = [];
                if (responseData && responseData.result) {
                    let taskChannelData = responseData.result
                    taskChannelData.map((ival, i) => {
                        if (channelId === ival.id) {
                            let menuType = "taskChannels";
                            if (!toggleMenu.includes(menuType) && menuToggleFirst) {
                                toggleMenu.push(menuType);
                                menuToggleFirst = false
                            }
                        }
                        taskChannelList.push({
                            name: ival.channelName,
                            unreadMessageCount: 0,
                            id: ival.id,
                            completed: ival.status === "completed"
                        });

                        channelOption.push({
                            label: ival.channelName,
                            value: ival.id,
                            msgType: "task"
                        });
                    })

                }
                groupedChannelOptions[2].options = channelOption
                this.setState({
                    taskChannelList,
                    menuToggleFirst,
                    toggleMenu,
                    groupedChannelOptions
                }, () => {
                    this.getChannelUnReadCountList(this.props.pollChannelUnreadCount)
                    ReactTooltip.rebuild();
                })
            }
        })
    }


    getChannelList() {
        let { companyType } = this.props;
        if (companyType === GlobalMsgCons.orgTypeMultiplayerGaming) {
            this.getGameChannelList("")
        } else {
            this.getDirectChannelList("")
            if (!equalCommunityService(companyType)) {
                this.getGroupChannelList("")
                if (equalEducation(companyType)) {
                    this.getEducationTaskChannelList()
                } else {
                    this.getWorkTaskChannelList("")
                }
            }
        }
    }

    getChannelUnReadCountList(responseData) {
        if (responseData && responseData.result) {
            let { userId, channelList, dmList, taskChannelList, tabFocused } = this.state;
            let unReadMsgdata = responseData.result;
            let { communitySupportChannelId, privateSupportChannelId, requestSendingChannelId, requestReceivingChannelId, openMrsChannelId } = this.props;
            if (communitySupportChannelId && unReadMsgdata[communitySupportChannelId]) {
                this.props.setCommunityChannelUnreadCount(parseInt(unReadMsgdata[communitySupportChannelId]))
            }
            if (privateSupportChannelId && unReadMsgdata[privateSupportChannelId]) {
                this.props.setPrivateChannelUnreadCount(parseInt(unReadMsgdata[privateSupportChannelId]))
            }
            if (requestSendingChannelId && unReadMsgdata[requestSendingChannelId]) {
                this.props.setRequestSendingChannelUnreadCount(parseInt(unReadMsgdata[requestSendingChannelId]))
            }
            if (requestReceivingChannelId && unReadMsgdata[requestReceivingChannelId]) {
                this.props.setRequestReceivingChannelUnreadCount(parseInt(unReadMsgdata[requestReceivingChannelId]))
            }
            if (openMrsChannelId && unReadMsgdata[openMrsChannelId]) {
                this.props.setRequestReceivingOpenMRSChannelUnreadCount(parseInt(unReadMsgdata[openMrsChannelId]))
            }


            let { userOnlineStatus } = this.props;
            let loginUserOnlineStatus = responseData.userOnlineStatus;

            if (loginUserOnlineStatus !== userOnlineStatus && typeof (this.props.UserOnlineChange) !== "undefined") {
                this.props.UserOnlineChange(loginUserOnlineStatus)
            }
            this.props.setCalendarUnreadMessageCount(unReadMsgdata);
            let totalChannelUnreadMessageCount = 0,
                totalDmUnreadMessageCount = 0,
                totalTaskChannelUnreadMessageCount = 0;

            channelList.map((channel, channelIndex) => {
                if (typeof unReadMsgdata[channel.id] !== "undefined" && unReadMsgdata[channel.id] > 0) {
                    totalChannelUnreadMessageCount += parseInt(unReadMsgdata[channel.id]);
                }
            });
            dmList.map((channel, channelIndex) => {
                if (typeof unReadMsgdata[channel.id] !== "undefined" && unReadMsgdata[channel.id] > 0) {
                    totalDmUnreadMessageCount += parseInt(unReadMsgdata[channel.id])
                }
            });
            taskChannelList.map((channel, channelIndex) => {
                if (typeof unReadMsgdata[channel.id] !== "undefined" && unReadMsgdata[channel.id] > 0) {
                    totalTaskChannelUnreadMessageCount += parseInt(unReadMsgdata[channel.id])
                }
            });

            let totalUnreadMessageCount = totalChannelUnreadMessageCount + totalDmUnreadMessageCount + totalTaskChannelUnreadMessageCount;
            //Add red dot to title in browser tab
            var links = document.getElementsByTagName('link');
            if (totalUnreadMessageCount > 0) {
                document.title = "(" + totalUnreadMessageCount + ") " + originalTitle;
                for (var i = 0; i < links.length; i++) {
                    if (links[i].href.includes("/favicon/")) {
                        links[i].href = links[i].href.replace("/favicon/", "/favicon-message/")
                    }
                }

            } else if (document.title.includes("(") && tabFocused) {
                document.title = originalTitle;
                for (var i = 0; i < links.length; i++) {
                    if (links[i].href.includes("/favicon-message/")) {
                        links[i].href = links[i].href.replace("/favicon-message/", "/favicon/")
                    }
                }
            }

            this.props.setAllChannelUnreadCount(totalDmUnreadMessageCount + totalChannelUnreadMessageCount + totalTaskChannelUnreadMessageCount)
            this.setState({
                channelUnreadMessageCount: unReadMsgdata,
                totalDmUnreadMessageCount,
                totalChannelUnreadMessageCount,
                totalTaskChannelUnreadMessageCount,
            });
        }
    }


    checkDirectChannelExist() {
        return new Promise(resolve => {
            let { userId, channelUser, companyId } = this.state;
            let members = [];
            channelUser.forEach(function (entry) {
                members.push(entry.value);
            });
            let postJson = { reqId: getUuidv4(), userId, members, orgId: companyId };
            postJson = coversionED("encrypt", postJson).toString()
            const request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.chatSetDomain);
            request.setEvent(GlobalEventCons.checkDirectChannelExistSetEvent);
            request.setMessage(postJson);
            request.setDatetime(new Date().toString());
            getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request.Please try again later');
                } else {
                    let responseData = response.getMessage();
                    responseData = coversionED("decrypt", responseData)
                    responseData = JSON.parse(responseData)
                    if (responseData.response === GlobalMsgCons.alreadyExist) {
                        resolve({ status: false, channelId: responseData.channelId });
                    } else {
                        resolve({ status: true, channelId: responseData.channelId });
                    }
                }
            });
        });
    }

    checkGroupChannelExist() {
        //reqId: String, channelName: String, orgId: String,userId: String
        return new Promise(resolve => {
            let { userId, companyId, channelName } = this.state;
            let postJson = { reqId: getUuidv4(), userId, channelName: channelName.toLowerCase(), orgId: companyId };
            postJson = coversionED("encrypt", postJson).toString()
            const request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.chatSetDomain);
            request.setEvent(GlobalEventCons.checkChannelNameExistSetEvent);
            request.setMessage(postJson);
            request.setDatetime(new Date().toString());
            getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request.Please try again later');
                } else {
                    let responseData = response.getMessage();
                    responseData = coversionED("decrypt", responseData)
                    responseData = JSON.parse(responseData)
                    console.log("test", responseData)
                    if (responseData.response === GlobalMsgCons.alreadyExist) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                }
            });
        });
    }

    currentChannel(name) {
        localStorage.setItem("currentChannelName", name)
    }


    getUsersPagination(search, noOfPage, pageLimit) {
        return new Promise(resolve => {
            let { userId, companyId, userListOption } = this.state;
            let { companyType } = this.props
            let postJson = { reqId: getUuidv4(), organizationId: companyId, search: "", pageLimit, noOfPage };
            const request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.companySetDomain);
            request.setEvent(GlobalEventCons.membersPaginationSetEvent);
            request.setMessage(JSON.stringify(postJson));
            getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage().toLowerCase() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    let result = responseData.result;
                    let newUserListOption = [];
                    result.map((userData) => {
                        let showRoleType = "";
                        if (equalEducation(companyType)) {
                            let roleType = userData.roleType;
                            showRoleType = roleType ? `(${roleType})` : "(admin)";
                        }
                        if (userId !== userData.userId) {
                            newUserListOption.push({ value: userData.userId, label: userData.firstName + " " + userData.lastName + " - " + userData.email + showRoleType })
                        }
                    })

                    let hasMore = false;
                    let totalPagesResults = responseData.totalResult;
                    let currentResult = noOfPage * pageLimit;
                    if (currentResult < totalPagesResults) {
                        hasMore = true;
                    }

                    this.setState({
                        userListOption: userListOption.concat(newUserListOption)
                    })
                    resolve({
                        options: newUserListOption,
                        hasMore
                    })
                }
            })
        });
    }

    loadOptions = async (search, prevOptions) => {
        let noOfPage = prevOptions.length / 10;
        let result = { options: [], hasMore: false }
        if (!search) {
            result = await this.getUsersPagination(search, noOfPage + 1, 10)
        } else {
            const searchLower = search.toLowerCase();
            let { userListOption } = this.state
            let filteredOptions = userListOption.filter(({ label }) =>
                label.toLowerCase().includes(searchLower)
            );
            result = {
                options: filteredOptions,
                hasMore: false
            }
        }
        return result;
    }

    hideChannelPost(cId, type, channelType) {
        let typeMsg = "If you hide this chat, it will be removed from your channels list, although you can retrieve your hidden channels on the Hidden Channels page.";

        let successMsg = "Message successfully hidden";

        if (type === "unhide") {
            typeMsg = "This message combination is already exists and it is in hidden channel list, kindly confirm to remove from hidden channel list.";
            successMsg = "Message retrieved successfully"
        }

        if (window.confirm(typeMsg) === true) {
            let { userId, companyId } = this.state;
            let postJson = { reqId: getUuidv4(), userId, orgId: companyId, channelId: cId, actionType: type, channelType };
            //reqId: String, orgId: String, userId: String, channelId: String
            postJson = coversionED("encrypt", postJson).toString()
            const request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.chatSetDomain);
            request.setEvent(GlobalEventCons.updateHideChannelStatusSetEvent);
            request.setMessage(postJson);
            request.setDatetime(new Date().toString());
            getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request.Please try again later');
                } else {
                    let responseData = response.getMessage();
                    responseData = coversionED("decrypt", responseData)
                    responseData = JSON.parse(responseData)
                    if (responseData.response) {
                        toast.success(successMsg, {
                            position: "top-center",
                            autoClose: GlobalMsgCons.autoClose,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                    this.hiddenChannelsFun()
                }
            })
        }
        else {
            this.setState({
                createChannelModal: false,
                errors: {}
            })
        }
    }

    chatMsgStateUnreadCountEmpty() {
        let { channelId, channelUnreadMessageCount } = this.state;
        if ((typeof channelUnreadMessageCount[channelId] !== "undefined" && channelUnreadMessageCount[channelId] > 0)) {
            channelUnreadMessageCount[channelId] = "0";
            this.setState({ channelUnreadMessageCount })

        }
    }

    notifyMe(txt) {
        let msg = ""
        if (!("Notification" in window)) {
            // Check if the browser supports notifications
            alert("This browser does not support desktop notification");
        } else if (Notification.permission === "granted") {
            // Check whether notification permissions have already been granted;
            // if so, create a notification
            const notification = new Notification(txt);
            msg = notification;
            // …
        } else if (Notification.permission !== "denied") {
            // We need to ask the user for permission
            Notification.requestPermission().then((permission) => {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                    const notification = new Notification(txt);
                    msg = notification;

                }
            });
        } else if (Notification.permission === "denied") {
            // alert("You Have Denied Notification");
            console.warn("You Have Denied Notification")
        }

        // At last, if the user has denied notifications, and you
        // want to be respectful there is no need to bother them anymore.
        return msg
    }


    notficationShow() {
        let { tabFocused, channelId } = this.state;
        let { pathname } = this.props.location
        let { path, chatIncommingMsgData, activeCompanyName, companyId, companyList } = this.props;
        let checkPath = path + "/chat/" + channelId;
        let currentPageCheck = (checkPath === pathname);
        if (typeof (tabFocused) !== "undefined" && !tabFocused || !currentPageCheck) {
            let cid = '', msgtype = '', cname = activeCompanyName;
            if (chatIncommingMsgData.includes("::")) {
                cid = chatIncommingMsgData.split("::")[0];
                msgtype = chatIncommingMsgData.split("::")[1];
                if (companyId && cid && companyId !== cid) {
                    let filterCname = companyList.filter((fe) => fe.organizationId === cid)
                    if (filterCname.length) {
                        cname = filterCname[0].organizationName;
                    }
                }
            }
            let message = `${cname}: New message arrived`;
            if (msgtype === GlobalMsgCons.messageTypeCall) {
                message = `${cname}: You have received a call`;
            }
            this.notifyMe(originalTitle + " - " + message)
        }

    }
    channelMenuCheck() {
        let { toggleMenu } = this.state
        let getType = localStorage.getItem(MyConfig.project_LocalStorage + "channelSharedType");
        if (getType && getType !== "") {
            if (getType === "channel" && !toggleMenu.includes("channels")) {
                this.menuToggle("channels")
                console.log("menu load-->", getType)

            } else if (getType === "direct" && !toggleMenu.includes("dm")) {
                this.menuToggle("dm")
                console.log("menu load-->", getType)
            }
            else if (getType === "task" && !toggleMenu.includes("taskChannels")) {
                this.menuToggle("taskChannels")
                console.log("menu load-->", getType)
            }
            //console.log("remove")
            //localStorage.removeItem(MyConfig.project_LocalStorage + "channelSharedType");

        }
    }

    getHeader() {
        let { createChannelType } = this.state;
        let { pageText } = this.props;

        let str;
        switch (createChannelType) {
            case "channel":
                str = getValidLabels(pageText, 'channelsHeaderTxt')
                break
            case "dm":
                str = getValidLabels(pageText, 'directMessagesHeaderTxt');
                break
            case "game":
                str = "Create a Game Room";
                break
        }

        return str;
    }
    hiddenChannelsFun() {
        let { companyId, userId, organizationType } = this.props
        let postJson = { reqId: getUuidv4(), userId, orgId: companyId };
        postJson = coversionED("encrypt", postJson).toString()
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.hiddenChannelListSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                response = responseData;
                let result = response.result
                let groupChannels = result.groupChannels
                let directChannels = result.directChannels;
                let taskChannels = result.task;
                if (equalEducation(organizationType)) {
                    taskChannels = result.educationTask
                }

                let hiddenChannelArr = [];
                for (let i = 0; i < groupChannels.length; i++) {
                    let channel = groupChannels[i]
                    channel.type = "Channels";
                    hiddenChannelArr.push(channel);
                }
                for (let i = 0; i < directChannels.length; i++) {
                    let channel = directChannels[i]
                    channel.type = "Direct Messages";
                    hiddenChannelArr.push(channel);
                }
                for (let i = 0; i < taskChannels.length; i++) {
                    let channel = taskChannels[i]
                    channel.type = "Task Messages";
                    hiddenChannelArr.push(channel);
                }
                this.setState({
                    hiddenChannelArr
                })
            }
        })
    }
    render() {
        let { groupedChannelOptions, selectedGroup, channelId, toggleMenu, channelUser, taskChannelList, createChannelModal, errors, createChannelType, channelList, dmList, gameList, totalChannelUnreadMessageCount, totalTaskChannelUnreadMessageCount, totalDmUnreadMessageCount, channelUnreadMessageCount, channelSubmitLoader, shareSubmitLoader, pageTextNew, dmChannelListLoader, gmChannelListLoader, taskChannelLoader, gameType } = this.state;
        let { companyType, appsLayout, pageText, path, shareMessage, userId, communityOrganization, controlFeature, activeUserType, systemLevelRole } = this.props;

        return <div className="chat-menu">

            {
                appsLayout ? <div className='container'>
                    {
                        (!equalCommunityService(companyType) && !communityOrganization && activeUserType !== GlobalMsgCons.roleTypeForwardDeployedEngineer) && <React.Fragment>
                            <div className='my-3'>
                                <h3>Task Messages</h3>
                                <div className="row">
                                    {
                                        taskChannelList.map((row, index) => {
                                            return <div className="col-sm-2" key={index}>
                                                <div className="launchpad">
                                                    <Link to={path + "/chat/" + row.id} onClick={() => this.currentChannel(row.name)}>
                                                        <div className="link-icon"><i className={`fa fa-hashtag`}></i></div>
                                                        <div className="link-text">{row.name}</div>
                                                    </Link>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            <hr />
                        </React.Fragment>
                    }

                    <div className='my-3'>
                        <h3>{equalEducation(companyType) ? "Group" : "Messages"}</h3>
                        <div className="row">
                            <div className="col-sm-2">
                                <div className="launchpad add" onClick={(e) => this.createRoom(e, "channel")}>
                                    <div className="link-icon"><i className={`fa fa-plus`}></i></div>
                                    <div className="link-text">Create Messages</div>
                                </div>
                            </div>
                            {
                                channelList.map((row, index) => {
                                    return <div className="col-sm-2" key={index}>
                                        <div className="launchpad">
                                            <Link to={path + "/chat/" + row.id} onClick={() => this.currentChannel(row.name)}>
                                                <div className="link-icon"><i className={`fa fa-hashtag`}></i></div>
                                                <div className="link-text">{row.name}</div>
                                            </Link>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    <hr />
                    <div className='my-3'>
                        <h3>Direct Message</h3>
                        <div className="row">
                            <div className="col-sm-2">
                                <div className="launchpad add" onClick={(e) => this.createRoom(e, "dm")}>
                                    <div className="link-icon"><i className={`fa fa-plus`}></i></div>
                                    <div className="link-text">Create Direct Message</div>
                                </div>
                            </div>
                            {
                                dmList.map((row, index) => {
                                    return <div className="col-sm-2" key={index}>
                                        <div className="launchpad">
                                            <Link to={path + "/chat/" + row.id} onClick={() => this.currentChannel(row.name)}>
                                                <div className="link-icon"><i className={`fa fa-hashtag`}></i></div>
                                                <div className="link-text">{row.name}</div>
                                            </Link>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div> : <React.Fragment>
                    {
                        (!equalCommunityService(companyType) && companyType !== GlobalMsgCons.orgTypeMultiplayerGaming && !communityOrganization && controlFeature.projectManagementEnable && activeUserType !== GlobalMsgCons.roleTypeForwardDeployedEngineer) && <li className="sub-menu-header" onClick={() => {
                            this.menuToggle("taskChannels")
                        }} data-tip={getValidTooltip(pageText, 'taskChannelsSideMenuTxt')}>
                            <span className="link-icon"><i className="fa fa-tasks"></i></span>
                            <span className="link-text">{getValidLabels(pageText, 'taskChannelsSideMenuTxt')}</span>
                            <span className="float-right">
                                {
                                    totalTaskChannelUnreadMessageCount > 0 && <span className="count">{totalTaskChannelUnreadMessageCount <= 99 ? totalTaskChannelUnreadMessageCount : "99+"}</span>
                                }
                                <span className="add refIconSt" onClick={(e) => {
                                    e.stopPropagation();
                                    this.getWorkTaskChannelList("taskLoad")
                                }} data-tip={getValidTooltip(pageText, 'refreshIconTxt')} >{taskChannelLoader ? <i className="fa fa-spinner fa-spin" /> : <i className="fa fa-sync" />}</span>

                                <span className="arrow">
                                    <i className={`fa ${toggleMenu.includes("taskChannels") ? "fa-chevron-down" : "fa-chevron-right"}`}></i>
                                </span>
                            </span>
                        </li>
                    }

                    {
                        (toggleMenu.includes("taskChannels") && taskChannelList.length > 0) && <React.Fragment>
                            <ul className="sub-menu">
                                {
                                    taskChannelList.map((row, index) => {
                                        return <li key={index} className={`nav-item ${channelId === row.id ? "active" : ""}`} data-tip={row.name[0].toUpperCase() + row.name.slice(1)}>
                                            <div className='gchannel-close-icon'>{(typeof channelUnreadMessageCount[row.id] === "undefined" || channelUnreadMessageCount[row.id] && channelUnreadMessageCount[row.id] === "0") &&
                                                <span className='close-icon' onClick={() => {
                                                    this.hideChannelPost(row.id, "hide", equalEducation(companyType) ? GlobalMsgCons.channelType6 : GlobalMsgCons.channelType5)
                                                }} data-tip={"Click to hide this message"}>
                                                    <i className="fa fa-times"></i>
                                                </span>
                                            }</div>
                                            <div className='gchannel-name'><Link
                                                to={path + "/chat/" + row.id} onClick={() => this.currentChannel(row.name)}>
                                                <span className="mx-1"><i className="fa fa-hashtag"></i></span>
                                                <span className="channel-name">{row.name[0].toUpperCase() + row.name.slice(1)}</span>
                                            </Link></div>
                                            <div className='gchannel-count'>{(typeof channelUnreadMessageCount[row.id] !== "undefined" && channelUnreadMessageCount[row.id] > 0) && <div className="count-block">
                                                <span className="count">{channelUnreadMessageCount[row.id] <= 99 ? channelUnreadMessageCount[row.id] : "99+"}</span>
                                            </div>
                                            }</div>
                                        </li>
                                    })
                                }
                            </ul>
                        </React.Fragment>
                    }
                    {
                        companyType === GlobalMsgCons.orgTypeMultiplayerGaming ? <React.Fragment>
                            <li className="sub-menu-header" onClick={() => this.menuToggle("game")} >
                                <span data-tip={"Games"}>
                                    <span className={`link-text`} >
                                        <span className="link-icon"><i className="fa fa-gamepad"></i></span> Games
                                    </span>
                                </span>
                                <span className="float-right">


                                    <span className="add" data-tip={"Add Game Room"} onClick={(e) => this.createRoom(e, "game")}><i className="fa fa-plus" style={{ fontSize: 12 }}></i></span>

                                    <span className="arrow">
                                        <i className={`fa ${toggleMenu.includes("game") ? "fa-chevron-down" : "fa-chevron-right"}`}></i>
                                    </span>
                                </span>
                            </li>
                            {
                                (toggleMenu.includes("game") && gameList.length > 0) && <ul className="sub-menu">
                                    {
                                        gameList.map((row, index) => {
                                            return <li key={index} className={`nav-item ${channelId === row.id ? "active" : ""}`} data-tip={row.name}>
                                                <Link
                                                    to={path + "/game/" + row.id + "/" + row.gameChatChannelId} onClick={() => this.currentChannel(row.name)}>
                                                    <span className="pr-2"><i className="fa fa-hashtag"></i></span>
                                                    <span className="channel-name">{row.name}</span>
                                                </Link>
                                            </li>
                                        })
                                    }
                                </ul>
                            }
                        </React.Fragment> : <React.Fragment>
                            <li className="sub-menu-header" onClick={() => this.menuToggle("channels")} >
                                <span data-tip={getValidTooltip(pageText, 'groupSidemenuTxt')}>
                                    <span className="link-icon"><i className="fa fa-users"></i></span>
                                    <span className="link-text">{getValidLabels(pageText, 'groupSidemenuTxt')}</span>
                                </span>
                                <span className="float-right">
                                    {
                                        totalChannelUnreadMessageCount > 0 && <span className="count">{totalChannelUnreadMessageCount <= 99 ? totalChannelUnreadMessageCount : "99+"}</span>
                                    }
                                    <span className="add refIconSt" onClick={(e) => {
                                        e.stopPropagation();
                                        this.getGroupChannelList("gmLoad")
                                    }} data-tip={getValidTooltip(pageText, 'refreshIconTxt')}>{gmChannelListLoader ? <i className="fa fa-spinner fa-spin" /> : <i className="fa fa-sync" />}</span>

                                    {
                                        (!equalCommunityService(companyType) || checkActiveAdmin(activeUserType, systemLevelRole)) && <span className="add" data-tip={getValidTooltip(pageText, 'addGroupChannelsIconTollTip')} onClick={(e) => this.createRoom(e, "channel")}><i className="fa fa-plus" style={{ fontSize: 12 }}></i></span>
                                    }
                                    <span className="arrow">
                                        <i className={`fa ${toggleMenu.includes("channels") ? "fa-chevron-down" : "fa-chevron-right"}`}></i>
                                    </span>
                                </span>
                            </li>
                            {
                                (toggleMenu.includes("channels") && channelList.length > 0) && <ul className="sub-menu">
                                    {
                                        channelList.map((row, index) => {
                                            return <li key={index} className={`nav-item ${channelId === row.id ? "active" : ""}`} data-tip={row.name[0].toUpperCase() + row.name.slice(1)}>
                                                <div className='gchannel-close-icon'>{(typeof channelUnreadMessageCount[row.id] === "undefined" || channelUnreadMessageCount[row.id] && channelUnreadMessageCount[row.id] === "0") &&
                                                    <span className='close-icon' onClick={() => {
                                                        this.hideChannelPost(row.id, "hide", GlobalMsgCons.channelType1)
                                                    }} data-tip={"Click to hide this message"}>
                                                        <i className="fa fa-times"></i>
                                                    </span>
                                                }</div>
                                                <div className='gchannel-name'><Link
                                                    // data-tip={row.name} 
                                                    to={path + "/chat/" + row.id} onClick={() => this.currentChannel(row.name)}>
                                                    <span className="mx-1"><i className="fa fa-hashtag"></i></span>
                                                    <span className="channel-name">{row.name[0].toUpperCase() + row.name.slice(1)}</span>
                                                </Link></div>
                                                <div className='gchannel-count'>{(typeof channelUnreadMessageCount[row.id] !== "undefined" && channelUnreadMessageCount[row.id] > 0) && <div className="count-block">
                                                    <span className="count">{channelUnreadMessageCount[row.id] <= 99 ? channelUnreadMessageCount[row.id] : "99+"}</span>
                                                </div>
                                                }</div>
                                            </li>
                                        })
                                    }
                                </ul>
                            }
                            <li className="sub-menu-header" onClick={() => this.menuToggle("dm")} >
                                <span data-tip={equalCommunityService(companyType) ? getValidLabels(pageText, 'directChannelsToAllAdmin') : getValidTooltip(pageText, 'directMessagesSideMenuTxt')}>
                                    {/* <span className="link-icon"><i className="fa fa-comment"></i></span> */}
                                    <span className={`link-text ${(equalCommunityService(companyType) && totalDmUnreadMessageCount > 0) ? "d-channel-name" : ""}`} style={{ letterSpacing: equalCommunityService(companyType) ? -0.6 : 0 }}>
                                        <span className="link-icon"><i className="fa fa-comment"></i></span>{equalCommunityService(companyType) ? getValidLabels(pageText, 'directChannelsToAllAdmin') : getValidLabels(pageText, 'directMessagesSideMenuTxt')}
                                    </span>
                                </span>
                                <span className="float-right">
                                    {
                                        totalDmUnreadMessageCount > 0 && <span className="count">{totalDmUnreadMessageCount <= 99 ? totalDmUnreadMessageCount : "99+"}</span>
                                    }
                                    <span className="add refIconSt" onClick={(e) => {
                                        e.stopPropagation();
                                        this.getDirectChannelList("dmLoad")
                                    }} data-tip={getValidTooltip(pageText, 'refreshIconTxt')}>{dmChannelListLoader ? <i className="fa fa-spinner fa-spin" /> : <i className="fa fa-sync" />}</span>

                                    {
                                        !equalCommunityService(companyType) && <span className="add" data-tip={getValidTooltip(pageText, 'directMessagesSideMenuTxt')} onClick={(e) => this.createRoom(e, "dm")}><i className="fa fa-plus" style={{ fontSize: 12 }}></i></span>
                                    }

                                    <span className="arrow">
                                        <i className={`fa ${toggleMenu.includes("dm") ? "fa-chevron-down" : "fa-chevron-right"}`}></i>
                                    </span>
                                </span>
                            </li>
                            {
                                (toggleMenu.includes("dm") && dmList.length > 0) && <ul className="sub-menu">
                                    {
                                        dmList.map((row, index) => {
                                            let emailUser = row.memberNameAndEmail.split("_").join(" - ");
                                            let emailTool = emailUser.split(",").join("," + "<br/>")
                                            return <li key={index} className={`nav-item ${channelId === row.id ? "active" : ""}`} data-tip={emailTool}>
                                                <div className='gchannel-close-icon'>
                                                    {(typeof channelUnreadMessageCount[row.id] === "undefined" || channelUnreadMessageCount[row.id] && channelUnreadMessageCount[row.id] === "0") &&
                                                        <span className='close-icon' onClick={() => {
                                                            this.hideChannelPost(row.id, "hide", GlobalMsgCons.channelType2)
                                                        }} data-tip={"Click to hide this message"}>
                                                            <i className="fa fa-times"></i>
                                                        </span>
                                                    }
                                                </div>
                                                <div className='gchannel-name'>
                                                    <Link
                                                        // data-tip={row.name} 
                                                        to={path + "/chat/" + row.id} onClick={() => this.currentChannel(row.name)}>
                                                        <span className="mx-2"><i className="fa fa-hashtag"></i></span>
                                                        <span className="channel-name">{row.name}</span>
                                                    </Link>
                                                </div>
                                                <div className='gchannel-count'>
                                                    {(typeof channelUnreadMessageCount[row.id] !== "undefined" && channelUnreadMessageCount[row.id] > 0) && <div className="count-block">
                                                        <span className="count">{channelUnreadMessageCount[row.id] <= 99 ? channelUnreadMessageCount[row.id] : "99+"}</span>
                                                    </div>

                                                    }
                                                </div>

                                            </li>
                                        })
                                    }
                                </ul>
                            }
                        </React.Fragment>
                    }



                </React.Fragment>
            }



            {shareMessage && <SharedMessageModal
                shareMessage={shareMessage}
                closeModal={() => this.props.setShareMessage("")}
                heading={getValidLabels(pageTextNew, 'shareMessageHeaderTxt')}
                groupedChannelOptions={groupedChannelOptions}
                grpcClient={this.props.grpcClient}
                path={this.props.path}
                channelId={this.state.channelId}
                pageText={pageTextNew}
                languagePreference={this.props.languagePreference}
            />}


            {
                createChannelModal && <Modal
                    visible={createChannelModal}
                    closeModal={() => this.setState({ createChannelModal: false, errors: "", })}
                    heading={this.getHeader()}
                    body={<React.Fragment>
                        {
                            (createChannelType === "channel" || createChannelType === "game") && <React.Fragment>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label>{getValidLabels(pageText, 'nameLabelTxt')}</label>
                                            <input type="text"
                                                className={`form-control ${errors['channelName'] ? "is-invalid" : ""}`}
                                                placeholder={getValidLabels(pageText, 'enterNamePlaceholderTxt')}
                                                onChange={e => {
                                                    this.setState({
                                                        channelName: e.target.value
                                                    }, () => {
                                                        this.validateRunTime()
                                                    })
                                                }} />
                                            {
                                                errors['channelName'] && <div className="invalid-feedback">{errors['channelName']}</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label>{getValidLabels(pageText, 'descriptionLabelTxt')}</label>
                                            <input type="text" className="form-control" placeholder={createChannelType === "game" ? "What's this game about?" : getValidLabels(pageText, 'channelAboutPlaceholderTxt')} onChange={e => {
                                                this.setState({
                                                    channelDescription: e.target.value
                                                })
                                            }} />
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>{getValidLabels(pageText, 'selectUsersLabelTxt')}</label>
                                    <SearchDropdown
                                        selectedOption={channelUser}
                                        onChange={(e) => {
                                            this.setUserSelected(e)
                                        }}
                                        isDisabled={false}
                                        isMulti={true}
                                        placeholder={getValidLabels(pageText, 'selectUsersPlaceholderTxt')}
                                        error={errors['channelUser'] ? true : false}
                                        buildOptions={(e) => {
                                            let options = [];
                                            e.forEach((option) => {
                                                option = searchDropdownOptionConvert(option)
                                                if (userId !== option.userId) {
                                                    options.push({ value: option.userId, label: option.firstName[0].toUpperCase() + option.firstName.slice(1) + " " + option.lastName[0].toUpperCase() + option.lastName.slice(1) + " " + "( " + option.email + " )" })
                                                }
                                            })
                                            return options
                                        }}
                                        grpcClient={this.props.grpcClient}
                                        searchGrpcClient={this.props.searchGrpcClient}
                                        parentId={equalCommunityService(companyType) ? GlobalSearchDataTypes.CommunityServiceAdmin : GlobalSearchDataTypes.User}
                                        cacheDataType={GlobalSearchDataTypes.User}
                                    />
                                    {
                                        errors['channelUser'] && <div className='d-flex'>
                                            <div className="invalid-feedback d-block flex-1">{errors['channelUser']}</div>
                                            {this.state.dirMsgStatus.status === false && <div className='my-2'>
                                                <Link className='channel-exist-msg' onClick={() => {
                                                    this.setState({ createChannelModal: false, errors: "" })
                                                }}
                                                    to={path + "/chat/" + this.state.dirMsgStatus.channelId} >
                                                    <span className="">{"Go to channel"}</span>
                                                </Link>
                                            </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                        {
                            createChannelType === "game" && <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label>{"Select Game"}</label>
                                        <DropDown
                                            options={gameTypeOptions}
                                            isSearchable={true}
                                            placeholder={"Choose company"}
                                            onChange={(e) => {
                                                this.setState({
                                                    gameType: e.value
                                                })
                                            }}
                                            selectedOption={gameTypeOptions.filter((val => { return val.value === gameType }))}
                                            isMulti={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </React.Fragment>
                    }
                    footer={<React.Fragment>
                        <button type="button" className="btn theme-bg" disabled={channelSubmitLoader} onClick={() => this.channelSubmit()}>
                            {channelSubmitLoader && <i className="fa fa-spinner fa-spin"></i>}
                            <span className={`${channelSubmitLoader && ("pl-1")}`}>
                                {getValidLabels(pageText, 'saveChangesBtnTxt')}
                            </span>
                        </button>
                    </React.Fragment>}
                />
            }
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        userId: state.authReducer.loginId,
        companyId: state.authReducer.activeCompany,
        sessionId: state.authReducer.sessionId,
        firstName: state.authReducer.firstName,
        openMrsChannelId: state.authReducer.openMrsChannelId,
        activeUserType: state.authReducer.activeUserType,
        controlFeature: state.authReducer.controlFeature,
        organizationType: state.authReducer.organizationType,
        activeCompanyName: state.authReducer.activeCompanyName,
    }
}
export default withRouter(connect(mapStateToProps)(ChatMenu));