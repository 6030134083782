import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getGrpcClient, getGrpcPollingClient, getUuidv4, getDateWithFormat, checkActiveAdmin, checkMobileView } from "../../../config/Common";
import { ZiFetchRequest } from "../../../protobuf/generated/ZiFetchService_grpc_web_pb";
import GlobalMsgCons from "../../../config/GlobalMsgCons";
import ShareModal from "../../../components/ShareModal";
import ReactTooltip from 'react-tooltip';
import Loader from "../../../components/Loader";
import SheetsSetup from "./SheetsSetup";
import { toast } from 'react-toastify';
import PaginationDatatable from "../../../components/PaginationDatatable";
import Modal from "../../../components/Modal";
import ViewedByModal from "../../../components/ViewedByModal";
import "./xspreadsheet.css";
import CopyUrlButton from "../../../components/CopyUrlButton";
import GlobalDomainCons from "../../../config/GlobalDomainCons";
import GlobalEventCons from "../../../config/GlobalEventCons";


function SheetsWrite(props) {
    const auth = useSelector(state => state.authReducer);
    let { teqSheetId, teqOrgId } = useParams()
    let [firstInit, setFirstInit] = useState(false)
    let [content, setContent] = useState("")
    let [filename, setFilename] = useState("")
    let [tempFilename, setTempFilename] = useState("")
    let [validate, setValidate] = useState("loading")
    let [downloadInc, setDownloadInc] = useState(0)
    let [sheetCreatedOrgId, setSheetCreatedOrgId] = useState("")
    const [parentOrgInfo, setParentOrgInfo] = useState({});
    let [versionModal, setVersionModal] = useState(false)
    let [versionContentLoading, setVersionContentLoading] = useState(false)
    let [versionContent, setVersionContent] = useState("")
    let [versionHistoryDetails, setVersionHistoryDetails] = useState({})
    let [SheetModalShow, setSheetModalShow] = useState(false)
    let [restoreButtonLoading, setRestoreButtonLoading] = useState({});
    let [editingUserId, setEditingUserId] = useState("")
    let [typingUser, setTypingUser] = useState("")
    let [ownerId, setOwnerId] = useState("");
    let [sheetStartEdit, setSheetStartEdit] = useState(false);
    let [requestAccessShow, setRequestAccessShow] = useState(false)
    let [requestAccessData, setRequestAccessData] = useState({})


    if (teqSheetId === undefined) {
        if (props.pinTabInfo.id) {
            teqSheetId = props.pinTabInfo.id
        } else {
            teqSheetId = props.id
        }
    }

    useEffect(() => {
        let activeCompanyId = ""
        if (teqOrgId) {
            activeCompanyId = teqOrgId
        } else if (props.orgId) {
            activeCompanyId = props.orgId
        } else {
            activeCompanyId = auth.activeCompany;
        }
        setSheetCreatedOrgId(activeCompanyId)

        ReactTooltip.rebuild();
        return () => {
            ReactTooltip.hide()
        };
    }, [])

    useEffect(() => {
        if (sheetCreatedOrgId) {
            if (auth.parentOrgInfo && Object.keys(auth.parentOrgInfo).length > 0) {
                let dataObj = { ...auth.parentOrgInfo, ...{ docsOrgId: sheetCreatedOrgId } }
                setParentOrgInfo(dataObj)
            }
            validateAccess()
        }
    }, [sheetCreatedOrgId])

    useEffect(() => {
        if (validate === "verified") {
            if (props.history.location.state) {
                let { content } = props.history.location.state;
                if (content) {
                    write(JSON.stringify(content))
                    setContent(content)
                }

            }

            if (props.setActiveSheetId) {
                props.setActiveSheetId(teqSheetId);
            }
            getContent();
            userSesssion("add");

            const interval = setInterval(() => {
                getSheetFrequent();
            }, 1000);

            return () => {
                userSesssion("delete");
                clearInterval(interval);
            };
        }
    }, [validate])

    function validateAccess() {
        let postJson = { reqId: getUuidv4(), userId: auth.loginId, teqSheetId, userOrgId: auth.activeCompany, dataOrgId: sheetCreatedOrgId };
        if (props.fromOrgId) {
            postJson.userOrgId = props.fromOrgId
        }
        if (postJson.userOrgId === auth.activeCompany) {
            setRequestAccessShow(true)
        }
        const request = new ZiFetchRequest();
        request.setDomain("teqsheet");
        request.setEvent("validateAccess");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let responseData = JSON.parse(response.getMessage());
                let { ownerId, dataName, response: res } = responseData
                if (res === GlobalMsgCons.success) {
                    if (responseData.result) {
                        setValidate("verified")
                        getEditingStatus()
                    } else {
                        setValidate("failed")
                        setRequestAccessData({ ownerId, dataName })
                    }
                } else if (res === GlobalMsgCons.notExist) {
                    setValidate("notexist")
                }
            }
        });
    }

    function userSesssion(actionType) {
        let postJson = { reqId: getUuidv4(), orgId: sheetCreatedOrgId, userId: auth.loginId, teqSheetId, actionType, sessionId: auth.sessionId };
        const request = new ZiFetchRequest();
        request.setDomain("teqsheet");
        request.setEvent("userOpenAndCloseTeqSheet");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let responseData = response.getMessage();

            }
        });
    }

    function notifyUsersChanges(listOfMessageTypesWaiting) {
        listOfMessageTypesWaiting.map((item) => {
            let itemArr = [];
            let id = ""
            if (item.includes("::")) {
                itemArr = item.split("::");
                item = itemArr[0];
                id = itemArr[1];
            }

            switch (item) {
                case "TeqSheetContentUpdated":
                    if (id === teqSheetId) {
                        getContent()
                    }
                    break;
                case "TeqSheetEditStatusUpdated":
                    getEditingStatus()
                    break;
            }
        })
    }

    function getSheetFrequent() {
        let postJson = { reqId: getUuidv4(), orgId: auth.activeCompany, userId: auth.loginId, teqSheetId, sessionId: auth.sessionId };;
        const request = new ZiFetchRequest();
        request.setDomain("poll");
        request.setEvent("user");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcPollingClient(props.docsGrpcPollingClient).pollZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request.Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                let listOfMessageTypesWaiting = responseData.listOfMessageTypesWaiting;
                if (listOfMessageTypesWaiting !== undefined) {
                    notifyUsersChanges(listOfMessageTypesWaiting)
                }
            }
        });
    }

    function write(content) {
        let postJson = { reqId: getUuidv4(), orgId: sheetCreatedOrgId, userId: auth.loginId, teqSheetId, content };
        const request = new ZiFetchRequest();
        request.setDomain("teqsheet");
        request.setEvent("write");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let responseData = response.getMessage();
            }
        });
    }

    function getContent() {
        let postJson = { reqId: getUuidv4(), userId: auth.loginId, teqSheetId, dataOrgId: sheetCreatedOrgId, userOrgId: auth.activeCompany };
        if (props.fromOrgId) {
            postJson.userOrgId = props.fromOrgId
        }
        const request = new ZiFetchRequest();
        request.setDomain("teqsheet");
        request.setEvent("getContent");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let responseData = JSON.parse(response.getMessage());
                if (responseData) {
                    let { name, content, ownerId } = responseData.response
                    setOwnerId(ownerId)
                    if (filename === "") {
                        setFilename(name)
                    }

                    if (content) {
                        setContent(JSON.parse(content))
                    }
                    setFirstInit(true)
                }
            }
        });
    }

    function updateName() {
        let postJson = { reqId: getUuidv4(), orgId: auth.activeCompany, userId: auth.loginId, teqSheetId, name: tempFilename };
        const request = new ZiFetchRequest();
        request.setDomain("teqsheet");
        request.setEvent("update");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let responseData = JSON.parse(response.getMessage());
            }
        });
    }

    function doVersioningForDocs() {
        let postJson = { reqId: getUuidv4(), orgId: sheetCreatedOrgId, userId: auth.loginId, teqSheetId, flowType: "manual" };
        const request = new ZiFetchRequest();
        request.setDomain("teqsheet");
        request.setEvent("doVersioning");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                if (response.getMessage() === GlobalMsgCons.success) {
                    toast.success("Updated successfully.", {
                        position: "top-center",
                        autoClose: GlobalMsgCons.autoClose,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        });
    }

    function getVersionContent(versionId, type) {
        let checkType = type === "restore"
        if (checkType) {
            restoreButtonLoading[versionId] = true
            setRestoreButtonLoading(restoreButtonLoading)
        }
        else {
            setVersionContentLoading(true)
        }
        let postJson = { reqId: getUuidv4(), userId: auth.loginId, teqSheetId, versionId, userOrgId: auth.activeCompany, dataOrgId: sheetCreatedOrgId };
        const request = new ZiFetchRequest();
        request.setDomain("teqsheet");
        request.setEvent("getVersionContent");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let responseData = JSON.parse(response.getMessage());
                let { content } = responseData.response;
                let responseContentTxt = content ? JSON.parse(content) : ""
                if (checkType) {
                    write(JSON.stringify(responseContentTxt))
                    setContent(responseContentTxt)
                    setRestoreButtonLoading({})
                    setVersionModal(false)
                } else {
                    setVersionContentLoading(false)
                    setVersionContent(responseContentTxt)
                }

                setVersionHistoryDetails(responseData.response)
            }
        });
    }

    function updateEditingStatus(status) {
        let postJson = { reqId: getUuidv4(), orgId: sheetCreatedOrgId, editinguserId: auth.loginId, teqSheetId, status };
        const request = new ZiFetchRequest();
        request.setDomain("teqsheet");
        request.setEvent("updateEditingStatus");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let responseData = response.getMessage();
                if (responseData === GlobalMsgCons.success) {
                    let editingUserId = auth.loginId;
                    if (status === "end") {
                        editingUserId = "";
                    }
                    setEditingUserId(editingUserId)

                }

            }
        });
    }

    function getEditingStatus() {
        let postJson = { reqId: getUuidv4(), orgId: sheetCreatedOrgId, editinguserId: auth.loginId, teqSheetId };
        const request = new ZiFetchRequest();
        request.setDomain("teqsheet");
        request.setEvent("getEditingStatus");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let responseData = JSON.parse(response.getMessage());
                let { editingUserId, editingUserEmail } = responseData;
                setEditingUserId(editingUserId)
                setTypingUser(editingUserEmail)
            }
        });
    }

    function documentRequestAccess() {
        let postJson = { reqId: getUuidv4(), orgId: auth.activeCompany, requestedUserId: auth.loginId, dataId: teqSheetId, dataName: requestAccessData.dataName, dataType: "sheet", ownerId: requestAccessData.ownerId };
        if (!requestAccessData.dataName || !requestAccessData.ownerId) {
            return false
        }
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.companySetDomain);
        request.setEvent(GlobalEventCons.accessRequestNotificationToInbox);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.log("Invalid Request. Please try again later")
            } else {
                let responseData = response.getMessage();
                responseData = JSON.parse(responseData)
                if (responseData.response === GlobalMsgCons.success) {
                    toast.success("Requested successfully.", {
                        position: "top-center",
                        autoClose: GlobalMsgCons.autoClose,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        });
    }


    function getContentElement() {
        let contentElement = <Loader />
        if (validate === "failed") {
            contentElement = <div className="failed-setup">
                <i className="fas fa-times-circle"></i>
                <div className="mt-5">
                    You don't currently have to access this Docs
                </div>
                <div className="my-2">
                    <button className="btn btn-primary btn-sm" onClick={() => props.history.push(props.path + "/sheets")}>
                        Back to Sheets
                    </button>
                </div>
                {requestAccessShow ? <div className="my-2">
                    <button className="btn btn-success btn-sm" onClick={() => {
                        documentRequestAccess()
                    }}>
                        Request to access
                    </button>
                </div> : null}
            </div>
        } else if (validate === "notexist") {
            contentElement = <div className="failed-setup">
                <i className="fas fa-times-circle"></i>
                <div className="mt-5">
                The file does not exist
                </div>
                <div className="my-2">
                    <button className="btn btn-primary btn-sm" onClick={() => props.history.push(props.path + "/sheets")}>
                        Back to Sheets
                    </button>
                </div>
            </div>
        }
        else if (validate === "verified") {
            contentElement = <React.Fragment>
                <div className={`header ${props.fromMobileView ? "flex-row d-flex-wrap" : ""}`}>
                    <ReactTooltip place="bottom" type="dark" effect="float" html={true} />
                    <div className="icon" onClick={() => {
                        if (!auth.fromMobileAccess) {
                            if (sheetStartEdit) {
                                updateEditingStatus("end")
                            }

                            props.history.push(props.path + "/sheets")
                        }
                    }} data-tip={"Sheets Home"}>
                        <i className="fa fa-table" />
                    </div>
                    <div className="input">
                        <input
                            onChange={(e) => {
                                setTempFilename(e.target.value)
                            }}
                            onBlur={(e) => {
                                if (tempFilename !== "") {
                                    updateName()
                                }
                            }}
                            defaultValue={filename} />
                    </div>
                    <div className="extra">
                        <CopyUrlButton className={"mr-2"} />
                        {
                            !props.fromMobileView && <button className="btn btn-sm btn-info share-button mr-2" disabled={editingUserId !== "" && editingUserId !== auth.loginId} onClick={() => {
                                let status = "end";
                                if (editingUserId === "") {
                                    status = "start";
                                }
                                setSheetStartEdit(true)
                                updateEditingStatus(status)
                            }} data-tip={editingUserId === auth.loginId ? "Click done to complete editing" : "Click to start editing"}>{editingUserId === auth.loginId ? "Done" : "Start Editing"}</button>
                        }

                        <button className={`btn btn-sm btn-secondary share-button mr-2`} onClick={() => {
                            setVersionModal(true)
                        }} data-tip={"Check Version History"}><i className="fa fa-history" /></button>
                        <button className="btn btn-sm btn-success share-button mr-2" onClick={() => {
                            setDownloadInc(downloadInc + 1)
                        }} data-tip={"Click here to download sheet"}>Download</button>
                        <button className="btn btn-sm btn-success share-button mr-2" onClick={() => {
                            doVersioningForDocs()
                        }} data-tip={"Backup your sheet"}>Backup</button>
                        <ShareModal
                            domain={"teqsheet"}
                            getSharedUserDetailsEvent={"getSharedUsersDetails"}
                            sharingEvent={"sharing"}
                            postJsonInfo={{ teqSheetId }}
                            dataId={teqSheetId}
                            dataName={filename}
                            dataType={"sheet"}
                            grpcClient={props.grpcClient}
                            searchGrpcClient={props.searchGrpcClient}
                            docsGrpcClient={props.docsGrpcClient}
                            parentOrgInfo={parentOrgInfo}
                            ownerId={ownerId}
                        />
                        <ViewedByModal
                            domain={"teqsheet"}
                            getSharedUserDetailsEvent={"getSharedUsersDetails"}
                            postJsonInfo={{ teqSheetId }}
                            dataId={teqSheetId}
                            dataName={filename}
                            dataType={"sheet"}
                            grpcClient={props.grpcClient}
                            searchGrpcClient={props.searchGrpcClient}
                            docsGrpcClient={props.docsGrpcClient}
                            parentOrgInfo={parentOrgInfo}
                        />
                        {
                            !checkMobileView() && <button className={`btn btn-sm mr-2 btn-${props.pinTabInfo.id === teqSheetId && props.pinTabInfo.type === "sheets" ? "success" : "secondary"} pin-button`} onClick={() => {
                                if (props.pinTabInfo.id === teqSheetId && props.pinTabInfo.type === "sheets") {
                                    props.setPinTabInfo({
                                        id: "",
                                        type: ""
                                    })
                                } else {
                                    props.setPinTabInfo({
                                        id: teqSheetId,
                                        type: "sheets"
                                    })
                                }
                            }} data-tip={"Pin your sheet"}><i className="fas fa-thumbtack" /></button>
                        }
                    </div>
                </div>
                {
                    firstInit && editingUserId === auth.loginId && <SheetsSetup filename={filename} downloadInc={downloadInc} content={content} write={write} accessType="edit" />
                }

                {
                    (firstInit && editingUserId !== auth.loginId && !SheetModalShow) && <SheetsSetup filename={filename} downloadInc={0} content={content} accessType={"read"}
                    />
                }
                {
                    versionModal && <Modal
                        visible={versionModal}
                        size={"modal-xl"}
                        closeModal={() => {
                            setVersionModal(false)
                            setVersionContentLoading(false)
                            setVersionContent("")
                            setSheetModalShow(false)
                        }}
                        heading={"Version History"}
                        body={<React.Fragment>
                            {SheetModalShow && <>
                                <div className="row">
                                    <div className="col-sm-1" >
                                        <i className="fa fa-chevron-left" style={{ fontSize: 25, cursor: "pointer" }} aria-hidden="true" onClick={() => {
                                            setSheetModalShow(false)
                                        }}></i>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="form-group">
                                            <label>File Name : </label>
                                            {versionHistoryDetails.name}
                                        </div>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="form-group">
                                            <label>Created At : </label>
                                            {getDateWithFormat(versionHistoryDetails.createdAt, auth.activeCompanyDetails)}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-8 version-view">
                                        {
                                            versionContentLoading ? <Loader /> : versionContent && <SheetsSetup downloadInc={0} content={versionContent} accessType="read" />
                                        }
                                    </div>
                                </div>
                            </>}

                            {!SheetModalShow && <div className="row">
                                <div className="col-sm-12">
                                    <PaginationDatatable
                                        grpcClient={props.docsGrpcClient}
                                        domain={"teqsheet"}
                                        event={"getVersionList"}
                                        data={[
                                            {
                                                column: "Created User",
                                                cell: (row, key) => {
                                                    return <div>{row.createdByUserEmail ? row.createdByUserEmail : "-"}</div>
                                                }
                                            },
                                            {
                                                column: "Created At",
                                                cell: (row, key) => {
                                                    return <div>{getDateWithFormat(row.createdAt, auth.activeCompanyDetails)}</div>
                                                }
                                            },
                                            {
                                                column: "Version Type",
                                                cell: (row, key) => {
                                                    return <div>{row.flowType}</div>
                                                }
                                            },
                                            {
                                                column: "View",
                                                cell: (row, key) => {
                                                    return <button className="btn btn-sm btn-primary" onClick={() => {
                                                        setSheetModalShow(true)
                                                        getVersionContent(row.versionId)
                                                    }}>View</button>
                                                }
                                            },
                                            {
                                                column: "Restore",
                                                cell: (row, key) => {
                                                    return (checkActiveAdmin(auth.activeUserType, auth.systemLevelRole)) ? <button className="btn btn-sm btn-info" onClick={() => {
                                                        if (window.confirm("Are you sure restore this version data ?")) {
                                                            getVersionContent(row.versionId, "restore")
                                                        }
                                                    }}>Restore {restoreButtonLoading[row.versionId] ? <>
                                                        <i className="fa fa-spinner fa-spin"></i>
                                                    </> : ""} </button> : "-"
                                                }
                                            }
                                        ]}
                                        postJsonInfo={{ orgId: sheetCreatedOrgId, userId: auth.loginId, teqSheetId }}
                                        refresh={false}
                                    />
                                </div>
                            </div>}


                        </React.Fragment>
                        }
                    />
                }

            </React.Fragment>
        }

        return contentElement;
    }
    return <div className="docs-write">
        {
            props.fromMobileView && <div className="mobile-view-feature">Temporarily does not support editing on the mobile browsers / App, but can browse</div>
        }
        {
            getContentElement()
        }
        {
            (editingUserId && editingUserId !== auth.loginId && typingUser) && <div className="typing-info-display">{`${typingUser} is typing`}</div>
        }

    </div>
}
export default SheetsWrite;
