import React, { useEffect, useRef, useState } from "react";
import { getUuidv4 } from "../config/Common";
import MyConstant from "../config/MyConstant";
import { useSelector } from "react-redux";
import { QuillToolbarDropDown } from "./QuillToolbar";

let editor;
const THROTTLE_API_REQUESTS = false;
const API_FIRE_FREQ_THRESH = 200; // millisecs
var LAST_REQUEST_TIMESTAMP = 0;

var timeout = setTimeout(function () { }, 0)
export default function CustomQuillEditor(props) {
    let { pagename, updateChatTypingInfo, insertText, insertTextToggle, setEmptyText, changeText, parentClassName } = props;
    const auth = useSelector(state => state.authReducer);
    const guestAuth = useSelector(state => state.guestAuthReducer);
    let { loginId, activeCompany } = auth;
    if (loginId === "" || activeCompany === "") {
        loginId = guestAuth.loginId
        activeCompany = guestAuth.activeCompany
    }

    let targetLanguage = useRef("")
    let settingExpand = useRef(false)

    useEffect(() => {
        let List = window.Quill.import('formats/list');
        List.className = 'ql-editor-list'
        window.Quill.register({
            'formats/list': List,
        }, true);

        const Delta = window.Quill.import('delta')
        /*expand icon */
        if(props.hideChatMenu){   
        var icons = window.Quill.import('ui/icons');
        icons['custom-icon'] = '<i class="fas fa-expand" aria-hidden="true"/>'
        }
        /*expand icon */

        editor = new window.Quill("#editor", {
            theme: "snow",
            modules: {
                // Equivalent to { toolbar: { container: '#toolbar' }}
                toolbar: [
                    ["custom-icon"],
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                    ['bold', 'italic', 'underline'],
                    [{ 'color': [] }],       // toggled buttons
                    [{ 'align': [] }],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
                    [{ 'indent': '-1' }, { 'indent': '+1' }],
                    ['link']
                ],
                clipboard: {
                    matchers: [
                        [Node.ELEMENT_NODE, function (node, delta) {
                            let ops = []
                            delta.ops.forEach((op, index) => {
                                if (op.insert && typeof op.insert === 'string') {
                                    ops.push(op)
                                }
                            })
                            delta.ops = ops

                            return delta.compose(new Delta().retain(delta.length(),
                                {
                                    color: false,
                                    background: false,
                                    bold: false,
                                    strike: false,
                                    underline: false
                                }
                            ));
                        }
                        ]
                    ]
                },
                mention: {
                    allowedChars: /^.*[A-Za-z0-9\\.\sÅÄÖåäö]+.*$/,
                    mentionDenotationChars: [" ", "\n"],
                    autoSelectOnSpace: true,
                    spaceAfterInsert: false,
                    blotName: "text",
                    numberList: false,
                    source: handleChangesInText,
                },
                keyboard: {
                    bindings: {
                        shift_enter: {
                            key: 13,
                            shiftKey: true,
                            handler: (range, ctx) => {
                                editor.insertText(range.index, '\n');
                            }
                        },
                        enter: {
                            key: 13,
                            handler: () => { // submit form }
                                console.log("ENTER PRESSED")
                                if (props.submit) {
                                    props.submit("");
                                }
                            }
                        }
                    }
                },
            }
        });

        // editor.clipboard.addMatcher(Node.TEXT_NODE, (node, delta) => {
        //     console.log("Test", node.data)
        //     return new Delta().insert("test");
        // });

        if (pagename === "chat") {
            const dropDownItems = {
                "Select Language": "",
                "Assamese - অসমীয়া": "as",
                "Bangla - বাংলা": "bn",
                "Boro - बड़ो": "brx",
                "Goan Konkani - कोंकणी": "gom",
                "Gujarati - ગુજરાતી": "gu",
                "Hindi - हिंदी": "hi",
                "Kannada - ಕನ್ನಡ": "kn",
                "Kashmiri - كٲشُر": "ks",
                "Maithili - मैथिली": "mai",
                "Malayalam - മലയാളം": "ml",
                "Manipuri - ꯃꯤꯇꯩꯂꯣꯟ": "mni",
                "Marathi - मराठी": "mr",
                "Nepali - नेपाली": "ne",
                "Oriya - ଓଡ଼ିଆ": "or",
                "Panjabi - ਪੰਜਾਬੀ": "pa",
                "Sanskrit - संस्कृतम्": "sa",
                "Sindhi - سنڌي": "sd",
                "Sinhala - සිංහල": "si",
                "Tamil - தமிழ்": "ta",
                "Telugu - తెలుగు": "te",
                "Urdu - اُردُو": "ur"
            }

            const myDropDown = new QuillToolbarDropDown({
                label: "Select Language",
                rememberSelection: true
            })

            myDropDown.setItems(dropDownItems)

            myDropDown.onSelect = function (label, value, quill) {
                targetLanguage.current = value
            }

            myDropDown.attach(editor)
        }

        editor.focus()
        editor.on('text-change', (delta, oldDelta, source) => {
            if (typeof props.textOnchange !== "undefined") {
                const content = editor.root.innerHTML;
                props.textOnchange(content)
            }
        });

        const editorChild = document.getElementById("editor").firstChild

        editorChild.addEventListener('focusin', () => {
            if (pagename === "chat" && props.editorFocused) {
                props.editorFocused()
            }
        })

        editorChild.addEventListener('focusout', () => {
            console.log('FOCUS OUT')
        })

        editorChild.addEventListener('keydown', (e) => {
            if (pagename === "chat" && updateChatTypingInfo !== undefined) {
                clearTimeout(timeout);
                updateChatTypingInfo("add")
                timeout = setTimeout(function () {
                    updateChatTypingInfo("delete")
                }, 1000)
            }
        })

        /*expand onclick */
        var customButton = document.querySelector('.ql-custom-icon');
        if (customButton) {
            customButton.addEventListener('click', function () {
                var prevExpState = settingExpand.current ? false : true;
                settingExpand.current = prevExpState;
                if(props.hideChatMenu){
                props.hideChatMenu(prevExpState);
                }
            });
        }
        /*expand onclick */



        // window.addEventListener("click", handleClick);
        return () => {
            // window.removeEventListener("click", handleClick);
        }
    }, [])

    useEffect(() => {
        editor.setContents([])

        if (changeText) {
            editor.clipboard.dangerouslyPasteHTML(changeText, "user");
            editor.setSelection(editor.getLength());
        }
    }, [setEmptyText, changeText])

    useEffect(() => {
        if (insertText) {
            editor.insertText(editor.getLength() - 1, insertText);
            editor.setSelection(editor.getLength());
        }
    }, [insertTextToggle])

    function handleClick(e) {
        if (e.target.className && e.target.className.indexOf('ql-picker-label') >= 0) {
            let { x, y } = e.target.getBoundingClientRect()
        }
    }

    async function getTransliterationSuggestions(lang, searchTerm) {

        if (searchTerm == '.' || searchTerm == '..') {
            searchTerm = ' ' + searchTerm;
        }
        const postFileUpload = new FormData();
        postFileUpload.append('reqId', getUuidv4());
        postFileUpload.append('orgId', activeCompany);
        postFileUpload.append('userId', loginId);
        postFileUpload.append('prompt', searchTerm);
        postFileUpload.append('numSuggestions', 5);
        postFileUpload.append('targetLanguage', lang);
        postFileUpload.append('src_script_type', "en");
        postFileUpload.append('beam_width', 4);
        postFileUpload.append('dataTracking', "1");

        let result = await fetch(MyConstant.keyList.translateUrl + "transliteration/generate", {
            method: "POST",
            mode: 'cors',
            redirect: 'follow',
            body: postFileUpload,
        })
            .then((response) => response.json())
            .then((result) => {
                return result;
            })
            .catch((error) => {
                console.error('Error:', error);
            });

        return result;
    }

    async function handleChangesInText(searchTerm, renderList, mentionChar) {
        if (pagename !== "chat" || targetLanguage.current === "" || searchTerm.length < 2) return;
        const lang = targetLanguage.current

        if (THROTTLE_API_REQUESTS) {
            // Fire Transliteration API only once in certain frequency
            LAST_REQUEST_TIMESTAMP = Date.now();
            setTimeout(displaySuggestions, API_FIRE_FREQ_THRESH, searchTerm, renderList, lang);
        } else {
            displaySuggestions(searchTerm, renderList, lang);
        }

    }

    function displaySuggestions(searchTerm, renderList, lang_code) {

        // To avoid the API getting throttled, we don't fire API
        // at the rate at which the user is typing
        if (THROTTLE_API_REQUESTS && Date.now() - LAST_REQUEST_TIMESTAMP < API_FIRE_FREQ_THRESH) {
            // console.log('die hey');
            return;
        }

        // Display Transliterated Suggestions
        getTransliterationSuggestions(lang_code, searchTerm)
            .then(response => {
                var suggestions = [];
                var result = response.response[lang_code];
                for (let i = 0; i < result.length; i++) {
                    suggestions.push({ id: i, value: decodeURIComponent(result[i]) });
                }
                renderList(suggestions, searchTerm);
            });
    }

    return <React.Fragment>
        <div className={`quill-default-height ${parentClassName} ${settingExpand.current ? "quill-full-width" : ""}`}>
            <div className={`quill-editor-parent`} id={"editor"}></div>
        </div>
    </React.Fragment>;
}