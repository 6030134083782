import React, { useState, useEffect } from "react";
import { getGrpcClient, getUuidv4 } from "../../../config/Common";
import { useSelector } from "react-redux";
import MyConstant from "../../../config/MyConstant";
import { ZiFetchRequest } from "../../../protobuf/generated/ZiFetchService_grpc_web_pb";
import GlobalMsgCons from "../../../config/GlobalMsgCons";
import GlobalDomainCons from "../../../config/GlobalDomainCons";
import GlobalEventCons from "../../../config/GlobalEventCons";
import DropDown from "../../../components/DropDown";
import Loader from "../../../components/Loader";
import {  toast } from 'react-toastify';
import Modal from "../../../components/Modal";


let pageLimit = 36;
function TeqitTv(props) {
    const auth = useSelector(state => state.authReducer);

    let [noOfPage, setNoOfPage] = useState(1);
    let [loading, setLoading] = useState(true);
    let [teqitTvResult, setTeqitTvResult] = useState([]);
    let [selectedVideo, setSelectedVideo] = useState("");
    let [teqitFiles, setTeqitFiles] = useState({});
    let [processing, setProcessing] = useState(true);
    let [next, setNext] = useState(false);
    let [allteqitFiles, setAllTeqitTvResult] = useState([]);
    let [finalGroupResult, setFinalGroupResult] = useState([]);
    let [activeColumn, setActiveColumn] = useState([]);
    let [tabType, setTabType] = useState("public");
    let [languageList, setLanguageList] = useState([]);
    let [languageFilterSelected, setLanguageFilterSelected] = useState(auth.languagePreference);
    let [keywordText, setKeywordText] = useState("");
    let [keyWordSearchLoader, setKeyWordSearchLoader] = useState(false);
    let [viewModal, setViewModal] = useState(false);
    let [modalType, setModalType] = useState("");
    let paramsData = props.appsData;

    useEffect(() => {
        getList(false, tabType, {}, languageFilterSelected, true)
        getLanguageList()
    }, [])

    useEffect(() => {
        let chunkRowResult = []
        let chunkRowSize = 12;
        for (let i = 0; i < teqitTvResult.length; i += chunkRowSize) {
            let chunk = teqitTvResult.slice(i, i + chunkRowSize);
            chunkRowResult.push(chunk)
        }

        let chunkColumnSize = 4;
        if (window.outerWidth <= 768) {
            chunkColumnSize = 2;
        }
        let activeColumn = []
        let finalGroupResult = []
        for (let i = 0; i < chunkRowResult.length; i++) {
            let chunkColumnResult = [];
            for (let j = 0; j < chunkRowResult[i].length; j += chunkColumnSize) {
                let chunk = chunkRowResult[i].slice(j, j + chunkColumnSize);
                chunkColumnResult.push(chunk)
            }
            finalGroupResult.push(chunkColumnResult)
            activeColumn.push(0)
        }

        setFinalGroupResult(finalGroupResult)
        setActiveColumn(activeColumn)
    }, [teqitTvResult])

    function getLanguageList() {
        let postJson = { reqId: getUuidv4(), };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.uiSetDomain);
        request.setEvent(GlobalEventCons.languagesDataSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                let resMapData = json.uiMap
                let languageOptions = []
                if (json && json.uiMap && Object.keys(json.uiMap).length !== 0) {
                    Object.keys(json.uiMap).map((key) => {
                        let lan = json.uiMap[key]
                        var obj = JSON.parse(lan)
                        languageOptions.push({ label: obj["name"], value: key })
                    })
                }
                // Object.keys(resMapData).map((key) => {
                //     let data = resMapData[key]
                //     languageOptions.push({ label: data, value: key })
                // })

                setLanguageList(languageOptions)
            }
        });
    }


    async function getList(page, atype, allteqitFiles, language, onpageInit) {
        let filterType = "private-all";
        if (atype === "public") {
            filterType = "public-all";
        }

        let postJson = { reqId: getUuidv4(), language, orgId: auth.activeCompany, userId: auth.loginId, pageLimit: pageLimit, noOfPage: noOfPage, filterType, orgType: props.orgType, accessType: tabType };
        if (page) {
            postJson.noOfPage = page
        }
        const request = new ZiFetchRequest();
        request.setDomain("teqitTv");
        request.setEvent("list");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let json = JSON.parse(response.getMessage());
                // console.log("json", json)
                if (Array.isArray(json.result) && json.result.length) {
                    setLoading(false)
                    let resultMessage = json.result;
                    if (json.result.length > 0) {
                        let obj = {}
                        resultMessage.map((e) => {
                            return obj[e.id] = e
                        })

                        let totalResult = json.totalResult
                        let currentResult = noOfPage * pageLimit;
                        next = false
                        if (currentResult < totalResult) {
                            next = true;
                        }
                        allteqitFiles = { ...allteqitFiles, ...obj }
                        let firstIndex = Object.values(allteqitFiles)[0]
                        setNext(next)
                        let { thumbnailObj, fileObj, fileType } = firstIndex;
                        let buildThumbnailUrl = MyConstant.keyList.apiTvURL + "vp?processType=" + thumbnailObj.processType + "&fileName=" + thumbnailObj.fileName;
                        let buildVideoUrl = MyConstant.keyList.apiTvURL + "files?processType=" + fileObj.processType + "&fileName=" + fileObj.fileName;
                        setTeqitFiles({ buildThumbnailUrl, buildVideoUrl, fileType })
                        setTeqitTvResult(Object.values(allteqitFiles))
                        setAllTeqitTvResult(allteqitFiles)
                        setProcessing(false)
                        updateTeqitTvAccumulation(firstIndex.id)
                    } else if (onpageInit) {
                        setTabType("private")
                        getList(false, "private", {}, languageFilterSelected, false)
                    }
                } else {
                    setTeqitFiles({})
                    setTeqitTvResult([])
                    setAllTeqitTvResult([])
                    setProcessing(false)
                }

            }
        });
    }

    let findIndex = teqitTvResult.findIndex((e) => {
        return e.id === selectedVideo
    })


    function movePage(type) {
        if (type == "next") {
            noOfPage = noOfPage + 1
        }
        setNext(false)
        setProcessing(true)
        setNoOfPage(noOfPage)
        getList(noOfPage, tabType, allteqitFiles, languageFilterSelected)
    }

    function getLanguage() {
        let langSel = [];
        if (teqitTvResult[findIndex]) {
            langSel = languageList.filter(option => option.value === teqitTvResult[findIndex].language)
        }

        return langSel.length > 0 ? langSel[0].label : "-"
    }

    async function getSearchTvContent(page, allteqitFiles) {
        let postJson = { reqId: getUuidv4(), orgId: tabType === "public" ? "public" : auth.activeCompany, channelId: tabType, memberId: auth.loginId, msgStr: keywordText, pageLimit: pageLimit, noOfPage: noOfPage, domain: "teqitTvContent" };
        if (page) {
            postJson.noOfPage = page
        }
        const request = new ZiFetchRequest();
        request.setDomain("textSearch");
        request.setEvent("teqitTv");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let json = JSON.parse(response.getMessage());
                if (json.result) {
                    let resultMessage = json.result;
                    if (json.result[0]) {
                        let obj = {}
                        resultMessage.map((e) => {
                            return obj[e.id] = e
                        })
                        let totalResult = json.totalResult
                        let currentResult = noOfPage * pageLimit;
                        next = false
                        if (currentResult < totalResult) {
                            next = true;
                        }
                        allteqitFiles = { ...allteqitFiles, ...obj }
                        setNext(next)
                        let firstIndex = Object.values(allteqitFiles)[0]
                        updateTeqitTvAccumulation(firstIndex.id)
                        let { thumbnailObj, fileObj, fileType } = firstIndex;
                        let buildThumbnailUrl = MyConstant.keyList.apiTvURL + "vp?processType=" + thumbnailObj.processType + "&fileName=" + thumbnailObj.fileName;
                        let buildVideoUrl = MyConstant.keyList.apiTvURL + "files?processType=" + fileObj.processType + "&fileName=" + fileObj.fileName;
                        setTeqitFiles({ buildThumbnailUrl, buildVideoUrl, fileType })

                    }
                    setProcessing(false)
                    setKeyWordSearchLoader(false)
                    setTeqitTvResult(Object.values(allteqitFiles))
                    setAllTeqitTvResult(allteqitFiles)
                }

            }
        });
    }

    function scrollToView(index, action) {
        let tempActiveColumn = [...activeColumn]
        if (action === "next") {
            tempActiveColumn[index] = tempActiveColumn[index] + 1;
        } else if (action === "prev") {
            tempActiveColumn[index] = tempActiveColumn[index] - 1;
        }
        let id = `#group-${index}-${tempActiveColumn[index]}`
        setActiveColumn(tempActiveColumn)

        const section = document.querySelector(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
        }
    }

    function handleScroll(e) {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom && next) {
            movePage("next")
        }
    }

    function updateTeqitTvAccumulation(id) {
        let postJson = { reqId: getUuidv4(), id: id, memberId: auth.loginId, orgId: auth.activeCompany };
        // console.log("-->",postJson)
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.teqitTv);
        request.setEvent(GlobalEventCons.updateTeqitTvAccumulationSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {




            }
        });
    }

    function getContent() {
        return <div className="teqittv">
            <div className="teqittv-container">
                <div className="video-box">
                    <div className="player-content d-flex flex-column">
                        <div>
                            <div className="row mx-0">
                                <div className="offset-sm-6 col-sm-6">
                                    <div className="text-right pt-2">
                                        <span className="video-type-box">{tabType}</span>
                                        <button style={{ color: "#fff" }} className="btn btn-sm btn-warning ml-2" onClick={() => {
                                            setViewModal(true)
                                            setModalType("search")
                                        }}>
                                            <i className="fas fa-search"></i> Search
                                        </button>
                                        <button className="btn btn-sm btn-primary ml-2" onClick={() => {
                                            setViewModal(true)
                                            setModalType("filter")
                                        }}>
                                            <i className="fas fa-filter"></i> Filter
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"video-list-container " + ((teqitTvResult.length === 0 && !processing) ? "d-flex-text-center" : "")}>
                            {
                                finalGroupResult.map((chunkRowResult, index) => {
                                    let activeColumnIndex = activeColumn[index]
                                    return <div className="media-container" key={index}>
                                        <div className="media-scroller">
                                            {
                                                activeColumnIndex > 0 && <div className="previous" onClick={() => scrollToView(index, "prev")}>
                                                    <i className="fa fa-chevron-left"></i>
                                                </div>
                                            }
                                            {
                                                chunkRowResult.map((chunk, columnIndex) => {
                                                    return <div className="media-group" id={`group-${index}-${columnIndex}`} key={columnIndex}>
                                                        {
                                                            chunk.map((e, i) => {
                                                                let { title, id, thumbnailObj, fileObj, fileType } = e;
                                                                // console.log(description)
                                                                let buildThumbnailUrl = MyConstant.keyList.apiTvURL + "vp?processType=" + thumbnailObj.processType + "&fileName=" + thumbnailObj.fileName;
                                                                let buildVideoUrl = MyConstant.keyList.apiTvURL + "files?processType=" + fileObj.processType + "&fileName=" + fileObj.fileName;
                                                                return <div key={i} className={`media-element video ${selectedVideo === id ? "active" : ""}`}
                                                                    onClick={() => {
                                                                        setSelectedVideo(id)
                                                                        updateTeqitTvAccumulation(id)
                                                                        setTeqitFiles({ buildThumbnailUrl, buildVideoUrl, fileType })
                                                                    }}>
                                                                    <img src={buildThumbnailUrl} alt='loading...' />
                                                                    <div className="video-title">
                                                                        {title}
                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                })
                                            }
                                            {
                                                (activeColumnIndex + 1) !== chunkRowResult.length && <div className="next" onClick={() => scrollToView(index, "next")}>
                                                    <i className="fa fa-chevron-right"></i>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                })
                            }
                            {
                                processing ? <Loader /> : <React.Fragment>
                                    {
                                        next && <button className="btn btn-primary" style={{ display: "flex", margin: "auto" }} onClick={() => {
                                            movePage("next")
                                        }}>Load more</button>
                                    }
                                </React.Fragment>
                            }

                            {(teqitTvResult.length === 0 && !processing) ? <div> There are no more videos available at this time. </div> : ""}

                        </div>

                    </div>
                </div>
            </div>
            <Modal
                visible={viewModal}
                closeModal={() => setViewModal(false)}
                heading={getModalTitle()}
                body={<React.Fragment>
                    {getModalContent()}
                </React.Fragment>}
            />

        </div>
    }

    function getModalContent() {
        let str = "";
        if (modalType === "view") {
            str = <React.Fragment>
                <div className="text-right">
                    <span className="language-box">{getLanguage()}</span>
                </div>
                <label>{findIndex !== "-1" ? teqitTvResult[findIndex] && teqitTvResult[findIndex].title : ""}</label>
                <div className="mt-2" dangerouslySetInnerHTML={{ __html: findIndex !== "-1" ? teqitTvResult[findIndex] && teqitTvResult[findIndex].description : "" }} />
            </React.Fragment>
        } else if (modalType === "filter") {
            str = <div className="video-filter">
                <div className="form-group">
                    <label>Language</label>
                    <DropDown
                        options={languageList}
                        isSearchable={true}
                        placeholder="Select Language"
                        onChange={(e) => {
                            setLanguageFilterSelected(e.value)
                        }}
                        value={languageList.filter(option => option.value === languageFilterSelected)}
                    />
                </div>
                <div className="form-group">
                    <label>Video Type</label>
                    <div>
                        <div className="btn-group">
                            <input type="radio" className="btn-check" name="btnradio" id="sourceFromType1" autoComplete="off" checked={tabType === "public"} onChange={() => {
                                setTabType("public")
                            }} />
                            <label className={`btn btn${tabType === "public" ? "" : "-outline"}-primary`} htmlFor="sourceFromType1">Public</label>
                            <input type="radio" className="btn-check" name="btnradio" id="sourceFromType2" autoComplete="off" checked={tabType === "private"} onChange={() => {
                                setTabType("private")
                            }} />
                            <label className={`btn btn${tabType === "private" ? "" : "-outline"}-primary`} htmlFor="sourceFromType2">Private</label>
                        </div>
                    </div>
                </div>
                <div className="text-right">
                    <button className="btn btn-success" onClick={() => {
                        getList(1, tabType, {}, languageFilterSelected)
                        setKeywordText("")
                        setViewModal(false)
                        setModalType("")
                        setTeqitFiles({})
                        setTeqitTvResult([])
                        setAllTeqitTvResult([])
                        setProcessing(true)
                    }}>Filter</button>
                </div>
            </div>
        } else if (modalType === "search") {
            str = <div className="p-1">
                <div className="form-group">
                    <label>Keyword Search</label>
                    <div className="input-group mb-3">
                        <input type="text" className="form-control" placeholder="Keyword Search" aria-describedby="basic-addon2" value={keywordText} onChange={(e) => {
                            setKeywordText(e.target.value)
                        }} />
                        <div className="input-group-append">
                            <span className="input-group-text cursor-pointer" onClick={() => {
                                setProcessing(true)
                                setKeyWordSearchLoader(true)
                                setAllTeqitTvResult({})
                                setTeqitTvResult([])
                                getSearchTvContent(false, {})
                                setViewModal(false)
                                setModalType("")
                            }}
                            >
                                <i className="fa fa-search" aria-hidden="true"></i>
                            </span>
                            <span className="input-group-text cursor-pointer" onClick={() => {
                                setKeywordText("")
                                setKeyWordSearchLoader(false)
                                getList(1, tabType, {}, languageFilterSelected)
                                setViewModal(false)
                                setModalType("")
                            }}>
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        }

        return str;
    }
    function getModalTitle() {
        let str = "";
        if (modalType === "view") {
            str = "View"
        } else if (modalType === "filter") {
            str = "Filter"
        } else if (modalType === "search") {
            str = "Search"
        }

        return str;
    }

    return <React.Fragment>
        {
            props.fromView === "mobile" ? <div className='mobile-container'>
                <div className="header">
                    <div style={{ display: "flex", flex: 1, padding: 5, flexDirection: "row", fontSize: 18 }}>
                        <div style={{ padding: "0 10px" }} onClick={() => {
                            props.history.push(props.path + "/home/more")
                        }}>
                            <i className='fas fa-chevron-left' />
                        </div>
                        <div style={{ flex: 1 }}>
                            <div>Teqit TV</div>
                        </div>
                    </div>
                </div>
                <div className="body">
                    {getContent()}
                </div>
            </div> : <React.Fragment>
                {getContent()}
            </React.Fragment>
        }
        {
            teqitTvResult[findIndex] && <div className="teqittv-player-box">
                <div className="row mx-0 py-2" style={{ backgroundColor: "#8ac4f2" }}>
                    <div className="col-sm-6" style={{ color: "#fff" }}>
                        <div className="p-2">
                            <label>{findIndex !== "-1" ? teqitTvResult[findIndex] && teqitTvResult[findIndex].title : ""}</label>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="text-right pt-2">
                            <button className="btn btn-sm btn-info ml-2" onClick={() => {
                                setViewModal(true)
                                setModalType("view")
                            }}>
                                <i className="fas fa-info-circle"></i> Info
                            </button>
                            <button className="btn btn-sm btn-success mx-2" onClick={() => {
                                let textMsg = window.location.origin + props.path + "/teqit-tv-view/" + teqitTvResult[findIndex].id
                                navigator.clipboard.writeText(textMsg);
                                toast.success("Video URL copied Successfully.", {
                                    position: "top-center",
                                    autoClose: GlobalMsgCons.autoClose,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                })
                            }}>
                                <i className="fas fa-share"></i> Share
                            </button>
                            <button className="btn btn-sm btn-danger mx-2" onClick={() => setSelectedVideo("")}>
                                <i className="fa fa-times" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="video-main">
                    {
                        <React.Fragment>
                            {
                                teqitTvResult[findIndex] && teqitTvResult[findIndex].fileType == "video" && <video width="100%" controls key={selectedVideo}  >
                                    <source src={teqitFiles.buildVideoUrl} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            }

                            {
                                teqitTvResult[findIndex] && teqitTvResult[findIndex].fileType == "audio" && <audio width="100%" controls onError={(e) => {
                                }}>
                                    <source src={teqitFiles.buildVideoUrl} />
                                </audio>
                            }
                            {
                                teqitTvResult[findIndex] && teqitTvResult[findIndex].isDemo && <div className="ribbon ribbon-top-left"><span>Demo Purpose</span></div>
                            }
                        </React.Fragment>
                    }
                </div>
            </div>
        }

    </React.Fragment>
}

export default TeqitTv