import React, { useEffect, useState } from "react";
import DropDown from "../../../components/DropDown";
import { ZiFetchRequest } from "../../../protobuf/generated/ZiFetchService_grpc_web_pb";
import { toast } from 'react-toastify';
import Modal from "../../../components/Modal";
import FileView from "../../../components/FileView";
import MyConstant from "../../../config/MyConstant";
import { capitalize, checkActiveAdmin, getDateWithFormat, getGrpcClient, getGuestFeedbackId, getUuidv4, removeUniqueFromArray, searchDropdownOptionConvert } from "../../../config/Common";
import GlobalEventCons from "../../../config/GlobalEventCons";
import GlobalMsgCons from "../../../config/GlobalMsgCons";
import GlobalDomainCons from "../../../config/GlobalDomainCons";
import SearchDropdown from "../../../components/SearchDropdown";
import GlobalSearchDataTypes from "../../../config/GlobalSearchDataTypes";
import Loader from "../../../components/Loader";
import MyConfig from "../../../config/MyConfig";

const statusOption = MyConfig.customerFeedbackStatus
export default function ViewFeedback(props) {
    let { feedbackId, visible, viewData, closeModal, auth, refresh, setRefresh } = props;

    const [data, setData] = useState(viewData);
    const [viewType, setViewType] = useState("refNo");
    const [selectedManager, setSelectedManager] = useState([]);
    const [selectedMember, setSelectedMember] = useState("");
    const [csMembers, setCsMembers] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState("");
    const [selectedStatusDescription, setSelectedStatusDescription] = useState("");
    const [statusLoader, setStatusLoader] = useState(false);
    const [memberAccess, setMemberAccess] = useState([]);
    const [historyData, setHistoryData] = useState([]);
    const { loginId, activeCompany, activeCompanyDetails, activeUserType, systemLevelRole } = auth;

    useEffect(() => {
        if (data) {
            let { csManagerInfo, csMemberInfoList, status } = data
            setSelectedStatus(status)
            if (csManagerInfo) {
                let { userId, firstName } = csManagerInfo
                let members = csMemberInfoList ? csMemberInfoList.map(a => a.userId) : [];
                members.push(userId)
                setMemberAccess(members)
                if (loginId === userId) {
                    getMappedMemberList(userId, members)
                }
                setSelectedManager({ value: userId, label: capitalize(firstName) })
            } else {
                setSelectedManager("")
            }
        } else if (feedbackId) {
            getCustomerFeedbackDetails(feedbackId, "refNo")
        }
    }, [data])

    useEffect(() => {
        if (viewType === "history") {
            getCustomerFeedbackStatusLogsList()
        }
    }, [viewType])

    function getCustomerFeedbackDetails(feedbackId, viewType) {
        let postJson = {
            reqId: getUuidv4(),
            feedbackId
        };

        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.customerFeedback);
        request.setEvent(GlobalEventCons.getCustomerFeedbackDetails);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                setData(responseData.result)

                if (viewType) {
                    setViewType(viewType)
                }
            }
        });
    }

    function getCustomerFeedbackStatusLogsList() {
        let { feedbackId } = data
        let postJson = {
            reqId: getUuidv4(),
            feedbackId
        };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.customerFeedback);
        request.setEvent(GlobalEventCons.getCustomerFeedbackStatusLogsList);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                setHistoryData(responseData.response.reverse())
            }
        });
    }

    function mappingCustomerFeedbackToManager() {
        let { feedbackId } = data

        let postJson = {
            reqId: getUuidv4(),
            orgId: activeCompany,
            feedbackId,
            managerId: selectedManager.value
        };

        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.customerFeedback);
        request.setEvent(GlobalEventCons.mappingCustomerFeedbackToManager);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                if (responseData.response === GlobalMsgCons.success) {
                    toast.success("Manager mapped successfully.", {
                        position: "top-center",
                        autoClose: GlobalMsgCons.autoClose,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (setRefresh) {
                        setRefresh(!refresh)
                    }
                    getCustomerFeedbackDetails(feedbackId)

                    if (loginId === selectedManager.value) {
                        getMappedMemberList(selectedManager.value, [])
                    }
                }
            }
        });
    }

    function updateCustomerFeedbackCsMembers() {
        let { feedbackId } = data

        let postJson = {
            reqId: getUuidv4(),
            orgId: activeCompany,
            feedbackId,
            csMemberId: selectedMember.value,
            actionType: "add"
        };

        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.customerFeedback);
        request.setEvent(GlobalEventCons.updateCustomerFeedbackCsMembers);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                if (responseData.response === GlobalMsgCons.success) {
                    toast.success("Member mapped successfully.", {
                        position: "top-center",
                        autoClose: GlobalMsgCons.autoClose,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (setRefresh) {
                        setRefresh(!refresh)
                    }
                    getCustomerFeedbackDetails(feedbackId)
                }
            }
        });
    }
    function updateCustomerFeedbackStatus() {
        let { feedbackId } = data

        let postJson = {
            reqId: getUuidv4(),
            orgId: activeCompany,
            feedbackId,
            status: selectedStatus,
            statusDescription: selectedStatusDescription,
            updatedBy: loginId
        };
        setStatusLoader(true)
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.customerFeedback);
        request.setEvent(GlobalEventCons.updateCustomerFeedbackStatus);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                if (responseData.response === GlobalMsgCons.success) {
                    toast.success("Updated successfully.", {
                        position: "top-center",
                        autoClose: GlobalMsgCons.autoClose,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (setRefresh) {
                        setRefresh(!refresh)
                    }
                    setStatusLoader(false)
                    setSelectedStatusDescription("")
                    getCustomerFeedbackDetails(feedbackId)
                }
            }
        });
    }

    function getMappedMemberList(managerId, csMamberIdList) {
        let postJson = { reqId: getUuidv4(), orgId: activeCompany, managerId }
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.userSetDomain);
        request.setEvent(GlobalEventCons.getMappedMemberList);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.log("Invalid Request. Please try again later")
            } else {
                let responseData = JSON.parse(response.getMessage());
                let users = responseData.result

                let members = []
                for (let i = 0; i < users.length; i++) {
                    let { userId, firstName, email } = users[i]
                    members.push({ value: userId, label: capitalize(firstName) + " - " + email, disabled: csMamberIdList.includes(userId) })
                }
                setCsMembers(members)
            }
        });
    }

    function viewContent() {
        let str;
        let { feedbackNo, productInformation, feedback, fileList, userId, status, statusDescription, csManagerInfo, customerInfo, csMemberInfoList } = data
        let { email } = customerInfo

        switch (viewType) {
            case "refNo":
                str = <div>{getGuestFeedbackId(feedbackNo)}</div>
                break;
            case "email":
                str = <div>{email}</div>
                break;
            case "productInformation":
                str = <div className="text-capitalize">{productInformation}</div>
                break;
            case "description":
                str = <div dangerouslySetInnerHTML={{ __html: feedback }} />
                break;
            case "files":
                str = <div className="row " >
                    {
                        fileList.map((fileStr, fileIndex) => {
                            let file = JSON.parse(fileStr)
                            let buildUrl = MyConstant.keyList.apiURL + "vp?action=" + file.processType + "&key=" + file.docsId + "." + file.fileType + "&id=" + userId
                            return <div className="col-6 my-2 px-2" key={fileIndex} >
                                <div style={{ border: "1px solid #ddd", textAlign: "center", background: "#9e9e9e" }} >

                                    {
                                        <div >
                                            <FileView bodyClassRemove={false} name="Feedback Files Preview" type={file.fileType} src={buildUrl} />
                                        </div>
                                    }

                                </div>
                            </div>
                        })
                    }
                </div>
                break;
            case "status":
                str = status === "Issue Fixed" ? <div>Fixed - We will update next release.</div> : <div>
                    {`${status} `}
                    <br />
                    <div>{statusDescription}</div>
                </div>
                break;
            case "createdAt":
                str = <div>{getDateWithFormat(data.createdAt, activeCompanyDetails)}</div>
                break;
            case "assign":
                str = <React.Fragment>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label>{"Select Manager"}</label>
                                <SearchDropdown
                                    selectedOption={selectedManager}
                                    onChange={(e) => {
                                        setSelectedManager(e)
                                    }}
                                    isDisabled={false}
                                    isMulti={false}
                                    placeholder={"Select Manager"}
                                    error={false}
                                    buildOptions={(e) => {
                                        let options = [];
                                        e.forEach((option) => {
                                            option = searchDropdownOptionConvert(option)
                                            if (userId !== option.userId) {
                                                options.push({ value: option.userId, label: option.firstName[0].toUpperCase() + option.firstName.slice(1) + " " + option.lastName[0].toUpperCase() + option.lastName.slice(1)+ " " + "(" + option.email + ")" },)
                                            }
                                        })
                                        return options
                                    }}
                                    grpcClient={props.grpcClient}
                                    searchGrpcClient={props.searchGrpcClient}
                                    parentId={GlobalMsgCons.roleTypeCustomerSupportMemberAdmin}
                                    cacheDataType={GlobalSearchDataTypes.User}
                                />
                            </div>
                            <div className="text-center mt-3">
                                <button className="btn btn-primary" disabled={selectedManager.length === 0} onClick={() => mappingCustomerFeedbackToManager()}>Done</button>
                            </div>
                        </div>
                    </div>
                    {
                        (csManagerInfo && csManagerInfo.userId === loginId) && <React.Fragment>
                            <hr />
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>{"Select Member"}</label>
                                        <DropDown
                                            options={csMembers}
                                            isSearchable={true}
                                            placeholder={"Select Members"}
                                            onChange={(e) => {
                                                setSelectedMember(e)
                                            }}
                                            selectedOption={selectedMember}
                                            isDisabled={false}
                                            isMulti={false}
                                            error={false}
                                            isOptionDisabled={(option) => option.disabled}
                                        />
                                    </div>
                                    <div className="text-center mt-3">
                                        <button className="btn btn-primary" disabled={selectedMember.length === 0} onClick={() => updateCustomerFeedbackCsMembers()}>Done</button>
                                    </div>
                                </div>
                            </div>
                            {
                                (csMemberInfoList && csMemberInfoList.length > 0) && <div className="mt-3">
                                    <label>
                                        Members
                                    </label>
                                    {
                                        removeUniqueFromArray(csMemberInfoList, "userId").map((user) => {
                                            let { userId, firstName, email } = user
                                            return <div className="mb-2" key={userId}>
                                                <i className="fas fa-user mr-2"></i> {capitalize(firstName) + " - " + email}
                                            </div>
                                        })
                                    }
                                </div>
                            }
                        </React.Fragment>
                    }
                </React.Fragment>
                break;
            case "history":
                str = <React.Fragment>
                    <ul className="timeline">
                        {
                            historyData.map((history, index) => {
                                let { status, updatedByEmailId, updatedByUserName, createdAt, statusDescription } = history
                                let selected = statusOption.filter(option => option.value === status)
                                return <li key={index} className={status.toLowerCase()}>
                                    <span>{selected.length > 0 ? selected[0].label : status}</span>
                                    <span className="float-right">{getDateWithFormat(createdAt, activeCompanyDetails)}</span>
                                    <p className="status-description" style={{ whiteSpace: "pre-wrap" }}>{statusDescription}</p>
                                    {(updatedByUserName && updatedByEmailId) && <div>
                                        <b>Updated by</b> {updatedByUserName + " - " + updatedByEmailId}
                                    </div>}
                                </li>
                            })
                        }
                    </ul>
                </React.Fragment>
                break;
            case "action":
                str = <React.Fragment>
                    <div className="form-group">
                        <label>Status</label>
                        <DropDown
                            options={statusOption}
                            isSearchable={true}
                            placeholder="Select"
                            onChange={(e) => {
                                setSelectedStatus(e.value)
                            }}
                            value={statusOption.filter(option => option.value === selectedStatus)}
                        />
                    </div>
                    <div className="form-group">
                        <label>Description</label>
                        <textarea
                            className="form-control"
                            value={selectedStatusDescription}
                            onChange={(e) => {
                                setSelectedStatusDescription(e.target.value)
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary" onClick={() => updateCustomerFeedbackStatus()} disabled={statusLoader}>{statusLoader && <i className="fa fa-spinner fa-spin mr-1"></i>}Save</button>
                    </div>
                </React.Fragment>
        }
        return str;
    }

    return <Modal
        visible={visible}
        size={"modal-xl"}
        closeModal={() => closeModal()}
        heading={`Feedback ${data ? " - " + data.productInformation : ""}`}
        body={data ? <React.Fragment>
            <div className="row">
                <div className="col-8">
                    {
                        viewContent()
                    }
                </div>
                <div className="col-4">
                    <div className="tabs-vertical">
                        <div className={`tab ${viewType === "refNo" ? "active" : ""}`} onClick={() => setViewType("refNo")}>{"Ref No"}</div>
                        <div className={`tab ${viewType === "email" ? "active" : ""}`} onClick={() => setViewType("email")}>{"Email"}</div>
                        <div className={`tab ${viewType === "productInformation" ? "active" : ""}`} onClick={() => setViewType("productInformation")}>{"Domain Type"}</div>
                        <div className={`tab ${viewType === "description" ? "active" : ""}`} onClick={() => setViewType("description")}>{"Chat Title"}</div>
                        <div className={`tab ${viewType === "files" ? "active" : ""}`} onClick={() => setViewType("files")}>{"Files"}</div>
                        <div className={`tab ${viewType === "status" ? "active" : ""}`} onClick={() => setViewType("status")}>{"Status"}</div>
                        <div className={`tab ${viewType === "createdAt" ? "active" : ""}`} onClick={() => setViewType("createdAt")}>{"Created At"}</div>
                        {
                            (checkActiveAdmin(activeUserType, systemLevelRole) || activeUserType === GlobalMsgCons.roleTypeCustomerSupportMemberAdmin) && <React.Fragment>
                                <div className={`tab ${viewType === "assign" ? "active" : ""}`} onClick={() => setViewType("assign")}>{"Assign"}</div>
                            </React.Fragment>
                        }
                        {
                            memberAccess.includes(loginId) && <React.Fragment>
                                <div className={`tab ${viewType === "action" ? "active" : ""}`} onClick={() => setViewType("action")}>{"Action"}</div>
                                <div className={`tab ${viewType === "history" ? "active" : ""}`} onClick={() => setViewType("history")}>{"History"}</div>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </React.Fragment> : <div style={{ minHeight: "50vh" }}><Loader /></div>
        }
    />;
}
