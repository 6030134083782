import {
    SET_PROJECT_DIAGRAM,
    SET_MULTIPLE_PROJECT_DIAGRAM,
    RESET_PROJECT_DIAGRAM
} from '../constants/actionTypes';

const initialState = {
    userId: "",
    companyId: "",
    selectedClient: "",
    selectedProject: "",
    projectOptions: [],
    diagramData: [],
    swimlaneModal: false,
    editSwimlaneId: "",
    errors: {},
    swimlaneName: "",
    swimlaneColor: "#14213e",
    editTextChanges: false,
    blockModal: false,
    blockName: "",
    editBlockId: "",
    blockDetails: "",
    blockRules: "",
    blockParentFlow: "",
    blockParentFlowOptions: [],
    documentFiles: [],
    document_Json: [],
    selectedLane: "",
    laneOptions: [],
    submitLoader: false,
    viewBlockModal: false,
    viewBlockDetails: {},
    dragLaneIndex: "",
    dragBlockIndex: "",
    blockType: "taskBlock",
    blockTypeOptions: [
        { value: "startEvent", label: "Start Event" },
        { value: "taskBlock", label: "Task" },
        { value: "aiAgent", label: "AI Agent" },
        { value: "conditionalBlock", label: "Conditional" },
        { value: "endEvent", label: "End Event" },
    ],
    blockApprovedFlow: "",
    blockRejectedFlow: "",
    viewLaneName: "",
    editLaneDetails: {},
    exisSelectedLane: "",
    isMultiple: false,
    multiTaskDetails: [
        {
            blockName: "",
            blockRules: "",
            blockDetails: "",
            documentFiles: [],
            document_Json: []
        }
    ],
    diagramType: "flowDiagram",
    editingUserId: ""
};

const projectDiagramReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROJECT_DIAGRAM: {
            return {
                ...state,
                [action.key]: action.value,
            };
        }
        case SET_MULTIPLE_PROJECT_DIAGRAM: {
            return {
                ...state,
                ...action.data,
            };
        }
        case RESET_PROJECT_DIAGRAM: {
            return {
                ...state,
                userId: "",
                companyId: "",
                selectedClient: "",
                selectedProject: "",
                projectOptions: [],
                diagramData: [],
                swimlaneModal: false,
                editSwimlaneId: "",
                errors: {},
                swimlaneName: "",
                swimlaneColor: "#14213e",
                editTextChanges: false,
                blockModal: false,
                blockName: "",
                editBlockId: "",
                blockDetails: "",
                blockRules: "",
                blockParentFlow: "",
                blockParentFlowOptions: [],
                documentFiles: [],
                document_Json: [],
                selectedLane: "",
                laneOptions: [],
                submitLoader: false,
                viewBlockModal: false,
                viewBlockDetails: {},
                dragLaneIndex: "",
                dragBlockIndex: "",
                blockType: "taskBlock",
                blockTypeOptions: [
                    { value: "startEvent", label: "Start Event" },
                    { value: "taskBlock", label: "Task" },
                    { value: "aiAgent", label: "AI Agent" },
                    { value: "conditionalBlock", label: "Conditional" },
                    { value: "endEvent", label: "End Event" },
                ],
                blockApprovedFlow: "",
                blockRejectedFlow: "",
                viewLaneName: "",
                isMultiple: false,
                multiTaskDetails: [
                    {
                        blockName: "",
                        blockRules: "",
                        blockDetails: "",
                        documentFiles: [],
                        document_Json: []
                    }
                ],
                diagramType: "flowDiagram",
                editingUserId: ""
            };
        }
        default: {
            return state;
        }
    }
};

export default projectDiagramReducer;
