import { SET_BOB_ANANLYTICS, RESET_BOB_ANANLYTICS, SET_MULTIPLE_BOB_ANANLYTICS } from '../constants/actionTypes';

const initialState = {
  customerSelected: {}
};

const bobAnalyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BOB_ANANLYTICS: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_BOB_ANANLYTICS: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_BOB_ANANLYTICS: {
      return {
        ...state,
        customerSelected: {}
      };
    }
    default: {
      return state;
    }
  }
};

export default bobAnalyticsReducer;
