import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';

import { checkMobileView, getUuidv4, grpcRequest } from "../../../config/Common";
import GlobalMsgCons from "../../../config/GlobalMsgCons";
import GlobalDomainCons from "../../../config/GlobalDomainCons";
import GlobalEventCons from "../../../config/GlobalEventCons";
import Card from "../../../components/Card";
import ProviderRegister from "./ProviderRegister";




export default function ProviderUpdate(props) {
    let { onCloseModal, pageType, path } = props;
    const auth = useSelector(state => state.authReducer);
    let [providerInfo, setProviderInfo] = useState({
        providerQualification: "",
        providerOrgName: "",
        providerOrgCity: "",
        providerCountry: ""
    })
    let [errors, setErrors] = useState({})
    let [loader, setLoader] = useState(false)


    useEffect(() => {
        if (pageType == "update") {
            getProfileInfo()
        }
    }, [])

    async function getProfileInfo() {
        let postJson = { reqId: getUuidv4(), sessionId: '123', userId: auth.loginId }
        let responseMsg = await grpcRequest(props.grpcClient, GlobalDomainCons.userSetDomain, GlobalEventCons.userProfileSetEvent, postJson)
        if (responseMsg.responseStatus === GlobalMsgCons.success) {
            if (responseMsg.response) {
                let { providerData } = responseMsg.response
                if (providerData) {
                    let { qualification, workingOrgCity, workingOrgCountry, workingOrgName } = providerData
                    providerInfo.providerQualification = qualification
                    providerInfo.providerOrgName = workingOrgName
                    providerInfo.providerOrgCity = workingOrgCity
                    providerInfo.providerCountry = workingOrgCountry
                    setProviderInfo({ ...providerInfo })
                    setErrors({})
                }

            }
        }
    }


    async function updateProviderApi() {
        let { providerQualification, providerOrgName, providerOrgCity, providerCountry } = providerInfo

        let errors = {}
        if (providerQualification.trim() === '') {
            errors["providerQualification"] = "Please Enter Qualification";
        }
        if (providerOrgName.trim() === '') {
            errors["providerOrgName"] = "Please Enter Org name";
        }
        if (providerOrgCity.trim() === '') {
            errors["providerOrgCity"] = "Please Enter Org city";
        }
        if (providerCountry.trim() === '') {
            errors["providerCountry"] = "Please Enter Org country";
        }
        setErrors(errors)

        if (Object.keys(errors).length === 0) {
            setLoader(true)
            let postJson = { reqId: getUuidv4(), userId: auth.loginId, }
            postJson.providerData = {
                qualification: providerQualification,
                workingOrgName: providerOrgName,
                workingOrgCity: providerOrgCity,
                workingOrgCountry: providerCountry
            }
            let responseMsg = await grpcRequest(props.grpcClient, GlobalDomainCons.userSetDomain, GlobalEventCons.updateUserProviderData, postJson)
            if (responseMsg.responseStatus === GlobalMsgCons.success) {
                setLoader(false)
                if (onCloseModal) {
                    onCloseModal()
                }
                toast.success('Updated successfully.', {
                    position: "top-center",
                    autoClose: GlobalMsgCons.autoClose,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    }

    function getContent() {
        return <>
            <ProviderRegister
                providerInfo={providerInfo}
                errors={errors}
                changeText={(type, text) => {
                    providerInfo[`${type}`] = text
                    setProviderInfo({ ...providerInfo })
                }}
            />
            <div className='row mx-0 mb-3 mt-3'>
                <div className='col-sm-3' />
                <div className='col-sm-5'>
                    <button type="button" disabled={loader} className="btn btn-lg btn-theme btn-block" onClick={() => updateProviderApi()}>
                        {loader && <i className="fa fa-spinner fa-spin"></i>}
                        <span className={`${loader ? "pl-1" : ""}`}>Update</span>
                    </button>
                </div>
                <div className='col-sm-3' />
            </div>
        </>
    }
    function getPageContent() {
        let returnPage = <>
            {getContent()}
        </>;
        if (pageType === "update") {
            returnPage = <div className="container">
                <Card
                    title={"Provider Information"}
                >
                    {getContent()}
                </Card>
            </div>
        }
        if (checkMobileView()) {
            returnPage = <>
                <div className='mobile-container'>
                    {pageType === "update" && <div className="header">
                        <div style={{ display: "flex", flex: 1, padding: 5, flexDirection: "row", fontSize: 18 }}>
                            <div style={{ padding: "0 10px" }} onClick={() => {
                                props.history.push(path + "/home/more")
                            }}>
                                <i className='fas fa-chevron-left' />
                            </div>
                            <div style={{ flex: 1 }}>
                                <div>{"Provider Information"}</div>
                            </div>
                        </div>
                    </div>}
                    <div className='body'>
                        {getContent()}
                    </div>
                </div>
            </>;
        }
        return returnPage
    }

    return <div className="provider-update-page">
        {getPageContent()}
    </div>;
}
