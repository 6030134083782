import React, { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import ReactTooltip from 'react-tooltip';
import DropDown from "../../../components/DropDown";
import CustomEditor from "../../../components/CustomEditor";
import GlobalMsgCons from "../../../config/GlobalMsgCons";
import { ZiFetchRequest } from "../../../protobuf/generated/ZiFetchService_grpc_web_pb";
import GlobalDomainCons from "../../../config/GlobalDomainCons";
import GlobalEventCons from "../../../config/GlobalEventCons";
import { toast } from 'react-toastify';
import MyConfig from "../../../config/MyConfig";
import { useSelector } from "react-redux";
import DropDownCreatable from "../../../components/DropDownCreatable";
import Datatable from "../../../components/Datatable";

const { getValidLabels, getValidTooltip, equalEducation, getUuidv4, getGrpcClient, userFromTeqitMe } = require("../../../config/Common");

const companyRoleType = [{ label: "Member", value: GlobalMsgCons.roleTypeMember }, { label: "Manager", value: GlobalMsgCons.roleTypeMemberAdmin }];
const educationRoleType = [{ label: "Student", value: GlobalMsgCons.roleTypeStudent }, { label: "Teacher", value: GlobalMsgCons.roleTypeTeacher }];
const communityServiceRoleType = MyConfig.communityServiceRolesInvite;
const healthcareServiceRoleType = MyConfig.healthcareServiceRolesInvite;
const customerSupportRoleType = MyConfig.customerSupportRolesInvite;
const multiplayerGamingRolesInvite = MyConfig.multiplayerGamingRolesInvite;
const volunteersTypes = MyConfig.volunteersTypes
const providerOptions = [{ label: "Provider", value: GlobalMsgCons.roleTypeProvider }]
const lenderOptions = [{ label: "Lender", value: GlobalMsgCons.roleTypeLender }]
const ERPCustomerOption = [{ label: "Customer", value: GlobalMsgCons.roleTypeERPCustomer }]




function InvitePeople(props) {
    const auth = useSelector(state => state.authReducer);
    let { requestFrom, controlFeature, loggedEmail, activeCompany, loginId } = auth
    let { inviteModal, modalClose, pageText, companyType, refreshTable, updateStatus, workspaceSubType, roleRegistryData } = props;
    let [inviteSubmitLoader, setInviteSubmitLoader] = useState(false)
    let [errorEmailId, setErrorEmailId] = useState({})
    let [emailIds, setEmailIds] = useState([""])
    let [selectedRoleType, setSelectedRoleType] = useState("")
    let [isAdmin, setIsAdmin] = useState(false)
    let [notes, setNotes] = useState("")
    let [roleTypeOption, setRoleTypeOption] = useState(companyRoleType)
    let [directJoin, setDirectJoin] = useState(false)
    let [existMemberList, setExistMemberList] = useState([])
    let [userOptionList, setUserOptionList] = useState([])
    let [pasteView, setPasteView] = useState(false)
    let [refresh, setRefresh] = useState(false)

    useEffect(() => {
        initData()
    }, [])

    useEffect(() => {
        if (inviteModal) {
            setExistMemberList([])
            getAllUserListApi()
        }
    }, [inviteModal])

    useEffect(() => {
        initData()
    }, [controlFeature.volunteerRoleTypeEnable])

    function getAllUserListApi() {
        let postJson = { session: "1234", reqId: getUuidv4() };
        const request = new ZiFetchRequest();
        request.setDomain("admin");
        request.setEvent("getAllUserListWithoutPagination");
        request.setMessage(JSON.stringify(postJson));
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, async (err, response) => {
            if (response === null || response.getMessage().toLowerCase() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                let userOptionList = [];
                responseData.result.map((ival) => {
                    let { userId, lastName, firstName, email } = ival;
                    if (email.trim()) {
                        userOptionList.push({ email: email.trim(), label: firstName + " " + lastName + " - " + email, value: userId })
                    }
                })
                setUserOptionList(userOptionList)
            }
        });
    }

    function addInviteNotesRegistryData() {
        if (notes) {
            let postJson = {
                reqId: getUuidv4(),
                notes,
                orgId: activeCompany,
                createBy: loginId
            };
            const request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.userSetDomain);
            request.setEvent(GlobalEventCons.addInviteNotesRegistryData);
            request.setMessage(JSON.stringify(postJson));
            getGrpcClient(props.grpcClient).sayZiFetch(request, {}, async (err, response) => {
                if (response === null || response.getMessage().toLowerCase() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    if (responseData.response === GlobalMsgCons.success) {
                        toast.success("Added Successfully.", {
                            position: "top-center",
                            autoClose: GlobalMsgCons.autoClose,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }
            });
        }
    }

    function shareInviteNotesRegistryData(notesId) {
        let postJson = {
            reqId: getUuidv4(),
            notesId,
            userId: loginId,
            desOrgId: activeCompany
        };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.userSetDomain);
        request.setEvent(GlobalEventCons.shareInviteNotesRegistryData);
        request.setMessage(JSON.stringify(postJson));
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, async (err, response) => {
            if (response === null || response.getMessage().toLowerCase() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                if (responseData.response === GlobalMsgCons.success) {
                    toast.success("Shared Successfully.", {
                        position: "top-center",
                        autoClose: GlobalMsgCons.autoClose,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setRefresh(!refresh)
                }
            }
        });
    }

    function deleteInviteNotesRegistryData(notesId) {
        let postJson = {
            reqId: getUuidv4(),
            notesId,
            userId: loginId
        };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.userSetDomain);
        request.setEvent(GlobalEventCons.deleteInviteNotesRegistryData);
        request.setMessage(JSON.stringify(postJson));
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, async (err, response) => {
            if (response === null || response.getMessage().toLowerCase() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                if (responseData.response === GlobalMsgCons.success) {
                    toast.success("Deleted Successfully.", {
                        position: "top-center",
                        autoClose: GlobalMsgCons.autoClose,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setRefresh(!refresh)
                }
            }
        });
    }

    function initData() {
        let roleTypeOption = companyRoleType;
        let selectedRoleType = { label: "Member", value: GlobalMsgCons.roleTypeMember }
        if (MyConfig.sundanceCompanyId === activeCompany) {
            selectedRoleType = { label: "Employee", value: GlobalMsgCons.roleTypeMember }
            roleTypeOption[0] = { label: "Employee", value: GlobalMsgCons.roleTypeMember }
            roleTypeOption[1] = { label: "Business owner", value: GlobalMsgCons.roleTypeMemberAdmin }
            roleTypeOption = [...roleTypeOption, ...lenderOptions]
            // if (workspaceSubType === MyConfig.subWorkSpaceCType) {
            //     roleTypeOption = [...roleTypeOption, ...ERPCustomerOption]
            // }
        }

        if (controlFeature.volunteerRoleTypeEnable) {
            roleTypeOption = [...roleTypeOption, ...volunteersTypes]
        }

        if (controlFeature.providerEnable) {
            roleTypeOption[0] = { label: "Staff", value: GlobalMsgCons.roleTypeMember }
            selectedRoleType = { label: "Staff", value: GlobalMsgCons.roleTypeMember }
            roleTypeOption = [...roleTypeOption, ...providerOptions]
        }

        if (equalEducation(companyType)) {
            roleTypeOption = educationRoleType;
            selectedRoleType = { label: "Student", value: "student" }
        } else if (GlobalMsgCons.orgTypeCommunityService === companyType) {
            roleTypeOption = communityServiceRoleType;
            selectedRoleType = { label: "Community Member", value: GlobalMsgCons.roleTypeCommunityMember }
        } else if (GlobalMsgCons.orgTypeHealthcareService === companyType) {
            roleTypeOption = healthcareServiceRoleType;
            selectedRoleType = { label: "Doctor", value: GlobalMsgCons.roleTypeDoctor }
        } else if (GlobalMsgCons.orgTypeCustomerSupport === companyType) {
            roleTypeOption = customerSupportRoleType;
            selectedRoleType = { label: "Customer Support Member", value: GlobalMsgCons.roleTypeCustomerSupportMember }
        } else if (GlobalMsgCons.orgTypeMultiplayerGaming === companyType) {
            roleTypeOption = multiplayerGamingRolesInvite;
            selectedRoleType = { label: "Player", value: GlobalMsgCons.roleTypePlayer }
        }
        if (workspaceSubType === MyConfig.subWorkSpaceCType && Array.isArray(roleRegistryData) && roleRegistryData.length) {
            roleTypeOption = [...roleTypeOption, ...roleRegistryData]
        }

        setRoleTypeOption(roleTypeOption)
        setSelectedRoleType(selectedRoleType)
    }


    function addEmail() {
        let objCopy = [...emailIds]
        objCopy.push("");
        setEmailIds(objCopy)
    }

    function setEmail(value, emailIndex) {
        let objCopy = [...emailIds]
        objCopy[emailIndex] = value;
        setEmailIds(objCopy)
    }

    function deleteEmail(index) {
        let objCopy = [...emailIds]
        objCopy.splice(index, 1);

        setEmailIds(objCopy)
    }

    function invitePeople() {
        let errorEmailId = {};
        emailIds.map((kval, k) => {
            var reg = MyConfig.emaildIdValidation;
            if (kval.trim() === '') {
                errorEmailId[k] = getValidLabels(pageText, 'enterEmailerr_msg');
            } else if (reg.test(kval) === false) {
                errorEmailId[k] = getValidLabels(pageText, 'enterValidEmail_msg');
            }
        })
        setErrorEmailId(errorEmailId)
        if (Object.keys(errorEmailId).length === 0) {
            setInviteSubmitLoader(true)

            emailIds = emailIds.map((e) => { return e.toLowerCase() })
            let { sessionId, userId, companyId } = props;
            let postJson = {
                reqId: getUuidv4(),
                sessionId,
                userId,
                emailIds,
                organizationId: companyId,
                roleType: selectedRoleType.value,
                systemLevelRoleType: isAdmin ? GlobalMsgCons.systemLevelRoleAdmin : GlobalMsgCons.systemLevelRoleMember,
                notes
            };
            if (controlFeature.directJoinFlowEnable || MyConfig.superadminEmails.includes(loggedEmail)) {
                postJson["directJoinFlow"] = directJoin;
            }

            const request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.companySetDomain);
            request.setEvent(GlobalEventCons.inviteMemberSetEvent);
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    if (responseData.response === GlobalMsgCons.success) {
                        toast.success((emailIds.length <= 1 ? getValidLabels(pageText, 'teamMemberInvitedsuccess_msg') : getValidLabels(pageText, 'teamMembersInvitedsuccess_msg')), {
                            position: "top-center",
                            autoClose: GlobalMsgCons.autoClose,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });

                        let { existMemberList } = responseData
                        if (existMemberList.length > 0 && props.memberStatusChange) {
                            setExistMemberList(existMemberList)
                        } else {
                            modalClose()
                        }
                        setInviteSubmitLoader(false)
                        setErrorEmailId({})
                        setEmailIds([""])
                        setSelectedRoleType("")
                        setIsAdmin(false)
                        setNotes("")
                        setDirectJoin(false)
                        if (refreshTable !== undefined) {
                            refreshTable()
                        }
                    }
                }
            });
        }
    }

    function handleCreate(e) {
        let option = { label: e, value: e, email: e }
        return option
    }
    return <React.Fragment>
        {
            inviteModal && <Modal
                visible={inviteModal}
                parentClass={"add-organization"}
                closeModal={() => modalClose()}
                heading={getValidLabels(pageText, 'invitePeople')}
                body={<React.Fragment>
                    <ReactTooltip place="bottom" type="dark" effect="float" html={true} />
                    {
                        existMemberList.length === 0 ? <React.Fragment>
                            {userFromTeqitMe(requestFrom) && <div className="alert alert-primary">Please remember that trial phase you can add only two members.</div>}

                            <div className="form-group">
                                <label className='pr-1' data-tip={getValidTooltip(pageText, 'userTypelabelTxt')}>{getValidLabels(pageText, 'userTypelabelTxt')}</label>
                                <DropDown
                                    selectedOption={selectedRoleType}
                                    onChange={(e) => setSelectedRoleType(e)}
                                    options={roleTypeOption}
                                    isDisabled={false}
                                    isMulti={false}
                                    placeholder={getValidLabels(pageText, 'chooseUserType_PHTxt')}
                                />
                            </div>
                            <div className="form-group">
                                <label data-tip={getValidTooltip(pageText, 'emailIdLabelTxt')}>{getValidLabels(pageText, 'emailIdLabelTxt')}</label>
                                {
                                    emailIds.map((email, emailIndex) => {
                                        return <div className="row mt-2" key={emailIndex}>
                                            <div className="col-10">
                                                {
                                                    MyConfig.superadminEmails.includes(loggedEmail) ? <DropDownCreatable
                                                        selectedOption={userOptionList.filter(option => option.email === email)}
                                                        onChange={(e) => {
                                                            setEmail(e.email, emailIndex)
                                                        }}
                                                        options={userOptionList}
                                                        isMulti={false}
                                                        placeholder={"Select User"}
                                                        creatable={true}
                                                        handleCreate={(e) => {
                                                            return handleCreate(e)
                                                        }}
                                                        createLabel={"Add"}
                                                    /> : <input type="email" className={`form-control ${(errorEmailId[emailIndex] !== undefined && errorEmailId[emailIndex] !== "") ? "is-invalid" : ""}`} placeholder={getValidLabels(pageText, 'enterEmailPlaceholderTxt')} value={email} onChange={(e) => setEmail(e.target.value, emailIndex)} />
                                                }
                                                {
                                                    (errorEmailId[emailIndex] !== undefined && errorEmailId[emailIndex] !== "") && <div className="invalid-feedback">{errorEmailId[emailIndex]}</div>
                                                }
                                            </div>

                                            {
                                                !userFromTeqitMe(requestFrom) && <div className="col-2">
                                                    {
                                                        emailIndex === 0 ? <button className="btn btn-success btn-sm" onClick={() => addEmail()} data-tip={getValidTooltip(pageText, 'invitePeople')}><i className="fas fa-plus"></i></button> :
                                                            <button className="btn btn-danger btn-sm" onClick={() => deleteEmail(emailIndex)} data-tip={getValidTooltip(pageText, 'deleteTableHeaderTxt')}><i className="fas fa-trash"></i></button>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    })
                                }
                            </div>

                            {
                                (GlobalMsgCons.orgTypeHealthcareService !== companyType && GlobalMsgCons.orgTypeCommunityService !== companyType) && <div className="form-group">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="isAdmin" value="" id="isAdmin" onChange={(e) => {
                                            setIsAdmin(e.target.checked)
                                        }}
                                            checked={isAdmin} />
                                        <label className="form-check-label" htmlFor="isAdmin">
                                            Is Admin Role?
                                        </label>
                                    </div>
                                </div>
                            }
                            {
                                (controlFeature.directJoinFlowEnable || MyConfig.superadminEmails.includes(loggedEmail)) && <div className="form-group">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" onChange={(e) => {
                                            setDirectJoin(e.target.checked)
                                        }}
                                            checked={directJoin} />
                                        <label className="form-check-label">
                                            Direct join
                                        </label>
                                    </div>
                                </div>
                            }

                            <div className="form-group">
                                <div className="d-flex mb-2">
                                    <label>{"Notes"}</label>
                                    <div style={{ flex: 1 }} className="text-right">
                                        {
                                            !pasteView && <button className="btn btn-sm btn-primary" onClick={() => addInviteNotesRegistryData()}>Save Note</button>
                                        }
                                        <button className={`btn btn-sm btn-${pasteView ? "info" : "success"} ml-2`} onClick={() => {
                                            setPasteView(!pasteView)
                                        }}>{pasteView ? "Close List" : "List Note"}</button>
                                    </div>
                                </div>
                                {
                                    pasteView ? <Datatable
                                        refresh={refresh}
                                        postJsonInfo={{ reqId: getUuidv4(), userId: loginId, orgId: activeCompany }}
                                        grpcClient={props.grpcClient}
                                        domain={GlobalDomainCons.userSetDomain}
                                        event={GlobalEventCons.getInviteNotesRegistryDataList}
                                        data={[
                                            {
                                                column: "Notes",
                                                cell: (row) => {
                                                    return <div className='editor-content' dangerouslySetInnerHTML={{ __html: row.notes }}></div>
                                                },
                                                toolTip: "Notes"
                                            },
                                            {
                                                column: "Paste Note",
                                                cell: (row) => {
                                                    return <button className="btn btn-sm btn-primary" onClick={() => {
                                                        setNotes(row.notes)
                                                        setPasteView(false)
                                                    }}>Paste</button>
                                                },
                                                toolTip: "Paste"
                                            },
                                            {
                                                column: "Share",
                                                cell: (row) => {
                                                    return <button className="btn btn-sm btn-success" onClick={() => {
                                                        shareInviteNotesRegistryData(row.id)
                                                    }}>
                                                        <i className="fa fa-share" />
                                                    </button>
                                                },
                                                toolTip: "Share"
                                            },
                                            {
                                                column: "Delete",
                                                cell: (row) => {
                                                    return <button className="btn btn-sm btn-danger" onClick={() => {
                                                        deleteInviteNotesRegistryData(row.id)
                                                    }}>
                                                        <i className="fa fa-trash" />
                                                    </button>
                                                },
                                                toolTip: "Delete"
                                            },
                                        ]}
                                        sortBy={"createdAt"}
                                    /> : <CustomEditor
                                        title={"Notes"}
                                        text={notes}
                                        textOnchange={(e) => {
                                            setNotes(e)
                                        }}
                                    />
                                }
                            </div>
                        </React.Fragment> : <React.Fragment>
                            <div className="form-group">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Email</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            existMemberList.map((data, index) => {
                                                let { emailId, memberStatus, memberId } = data
                                                return <tr key={index}>
                                                    <td>{emailId}</td>
                                                    <td>
                                                        {
                                                            memberStatus === GlobalMsgCons.active ? <span className="badge badge-primary">Already a Member</span> : <React.Fragment>
                                                                {
                                                                    updateStatus[memberId] ? <span className="badge badge-primary text-capitalize">{updateStatus[memberId]}</span> : <button className="btn btn-info btn-sm" onClick={() => {
                                                                        let row = {
                                                                            inviteDetailsData: data,
                                                                            memberId
                                                                        }
                                                                        props.memberStatusChange(row)
                                                                    }}>
                                                                        {getValidLabels(pageText, 'memberStatusActivateTxt')}
                                                                    </button>
                                                                }
                                                            </React.Fragment>

                                                        }
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>

                            </div>
                        </React.Fragment>
                    }
                </React.Fragment>}
                footer={existMemberList.length === 0 && <React.Fragment>
                    <ReactTooltip place="bottom" type="dark" effect="float" html={true} />
                    <button type="button" className="btn btn-primary" onClick={() => invitePeople()} disabled={inviteSubmitLoader} data-tip={getValidTooltip(pageText, 'saveChangesBtnTxt')}>
                        {inviteSubmitLoader && <i className="fa fa-spinner fa-spin"></i>}
                        <span className={`${inviteSubmitLoader && ("pl-1")}`}>
                            {getValidLabels(pageText, 'saveChangesBtnTxt')}
                        </span>
                    </button>
                </React.Fragment>}
            />
        }
    </React.Fragment>
}

export default InvitePeople;
