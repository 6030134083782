import React from "react";
import { ZiFetchRequest } from "../../protobuf/generated/ZiFetchService_grpc_web_pb";
import { v4 as uuidv4 } from 'uuid';
import MyConstant from "../../config/MyConstant";
import { toast } from 'react-toastify';
import Picker from 'emoji-picker-react';
import { Link, withRouter } from 'react-router-dom';
import CustomEditor from "../../components/CustomEditor";
import Modal from "../../components/Modal";
import { PDFViewer } from 'react-view-pdf';
import Recorder from "../../components/Recorder";
import GifPicker from "../../components/GifPicker";
import { getDateWithFormat, getGrpcClient, getPageText, getValidLabels, companyMemberType, equalEducation, memberTypeReturn, getUuidv4, getGrpcPollingClient, searchDropdownOptionConvert, urlify, capitalize, getAppInfo, checkActiveAdmin, getValidTooltip, getTimeFormate, surveyTimingRun, coversionED, getOnlyDateWithFormat, generateRoomId, getTabToken, getGuestFeedbackId } from "../../config/Common";
import { setMultipleChatData } from '../../redux/actions';
import { connect } from 'react-redux';
import GlobalDomainCons from '../../config/GlobalDomainCons';
import GlobalEventCons from '../../config/GlobalEventCons';
import GlobalMsgCons from "../../config/GlobalMsgCons";
import SearchDropdown from "../../components/SearchDropdown";
import GlobalSearchDataTypes from "../../config/GlobalSearchDataTypes";
import { pageNumbers } from "../../config/Common";
import Pagination from '../../components/Pagination';
import ReactTooltip from 'react-tooltip';
import DropDown from '../../components/DropDown';
import MyConfig from "../../config/MyConfig";
import VoiceToText from "../../components/VoiceToText";
import ProcessAudioToText from "./component/ProcessAudioToText";
import ViewFeedback from "../customerSupport/components/ViewFeedback";
import CreateFeedbackDocs from "../../components/CreateFeedbackDocs";
import CreateFeedbackWhiteboard from "../../components/CreateFeedbackWhiteboard";
import CustomQuillEditor from "../../components/CustomQuillEditor";
import ChatMessage from "../../components/ChatMessage";


let intervalId;
let intervalChannelId;
let activeStatusIntervalId;
let lastMessage;
let alive = true

let days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
let month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
let color = {
    "A": "#673ab7",
    "B": "#3f51b5",
    "C": "#2196f3",
    "D": "#00bcd4",
    "E": "#009688",
    "F": "#4caf50",
    "G": "#8bc34a",
    "H": "#cddc39",
    "I": "#ffeb3b",
    "J": "#ffc107",
    "K": "#ff9800",
    "L": "#795548",
    "M": "#673ab7",
    "N": "#3f51b5",
    "O": "#2196f3",
    "P": "#00bcd4",
    "Q": "#009688",
    "R": "#4caf50",
    "S": "#8bc34a",
    "T": "#cddc39",
    "U": "#ffeb3b",
    "V": "#ffc107",
    "W": "#ff9800",
    "X": "#795548",
    "Y": "#9e9e9e",
    "Z": "#9e9e9e",
}

let teqAgentsForGuest = MyConfig.teqAgentsForGuest
const aiAgentDataDomainTypeOptions = MyConfig.aiAgentDataDomainTypeOptions
const workflowUsers = ["406ad4d42e054e859a436bdac3f43dc2", "5e88cb3c17fb4b689f9dcbf179a923d7", "7b32789d3dca4da0a0957d57087e4da8", "a1164bfd87894c668ad4356f5f9e10ce"]
let pageLimit = 10
class Chat extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageText: null,
            typingUserList: {},
            messageEmojiPicker: false,
            messageIdEmojiPicker: "",
            messageEmojiPickerPosition: {},
            emojiHoverView: "",
            searchText: "",
            fileDrop: false,
            chatMinimized: false,
            readMore: false,
            readMoreId: "",
            processAudioToText: "",
            resultAudioToText: {},
            viewFeedback: false,
            welcomeModal: false,
            agentDropdown: false,
            expandQuillEditor:false
        }
        this.saveAudio = this.saveAudio.bind(this);
        this.processAudioToText = this.processAudioToText.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.messageEnd = React.createRef();

    }

    componentDidMount() {
        if (!this.props.fromCustomerSupport) {
            this.setState({ welcomeModal: true })
        }
        alive = true;
        window.addEventListener("click", this.handleClickOutside);
        this.getPageText()
        ReactTooltip.rebuild();

        let channelId = this.getChannelId()
        if (channelId && channelId !== "nill") {
            let currentChatText = localStorage.getItem("currentChatText_" + channelId);
            let message = currentChatText ? currentChatText : "";
            this.props.setMultipleChatData(channelId, {
                channelId,
                message,
                infoShow: window.innerWidth > 600
            })
            if (this.props.setChannelId) {
                this.props.setChannelId(channelId)
            }

            this.init(channelId);
            this.updateChannelUnReadCountIsEmpty(channelId);
        }
        this.surveyOptionSet()

    }

    componentDidUpdate(prevProps) {

        if (prevProps.languagePreference !== this.props.languagePreference) {
            this.getPageText()
        }

        let { channelId, loginId, callIcon } = this.props;
        if (channelId && channelId !== "nill" && loginId && loginId !== prevProps.loginId && callIcon !== prevProps.callIcon) {
            this.getChannelChats(channelId, 1, true, {});
            this.getChannelDetails(channelId);
        }

        if (prevProps.pollChannel !== this.props.pollChannel) {
            this.getChannelMessagesFrequent(this.props.pollChannel)
        }
    }

    componentWillUnmount() {
        // chatStream.cancel()
        // unreadCountUpdateStream.cancel()
        // deleteMsgStream.cancel()

        if (this.props.setChannelId) {
            this.props.setChannelId("")
        }

        window.removeEventListener("click", this.handleClickOutside);
        clearInterval(intervalId);
        clearInterval(intervalChannelId);
        clearTimeout(activeStatusIntervalId);
        alive = false;
    }

    getChannelId() {
        let { channelId } = this.props;
        if (!channelId) {
            channelId = this.props.match.params.channelId
        }

        return channelId;
    }

    handleScroll(event) {
        let channelId = this.getChannelId()
        const e = event.target;
        // let positionY = e.scrollHeight - (e.scrollTop + e.clientHeight)
        this.props.setMultipleChatData(channelId, {
            scrollPosition: e.scrollTop,
        })
    }

    saveAudio(type, blob) {
        let uid = uuidv4();
        let audioFile = new File([blob], "record_" + uid + ".mp3")
        let fileUrl = URL.createObjectURL(audioFile);
        let file = audioFile;
        let filename = audioFile.name;
        let ext = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
        let fileObj = {
            file: file,
            origFileName: filename,
            fileUrl: fileUrl,
            fileName: uid + "." + ext,
            docsId: uid,
            processType: "chat-record",
            fileType: ext,
        };

        let fileList = [];
        let inc = 0;
        fileList[inc] = fileObj;
        fileList[inc].uploadProcess = "pending";
        let channelId = this.getChannelId()
        this.props.setMultipleChatData(channelId, {
            fileList,
            recordingModal: false
        })
        inc++;
    }

    processAudioToText(type, blob) {
        let uid = uuidv4();
        let file = "",
            ext = "";
        if (type === "record") {
            let audioFile = new File([blob], "record_" + uid + ".mp3")
            file = audioFile;
            let filename = audioFile.name;
            ext = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
        } else {
            file = blob.path;
            ext = blob.fileType
        }
        let fileObj = {
            file: file,
            fileName: uid + "." + ext,
            docsId: uid,
            processType: "chat-record",
        };

        this.audioToTextUpload(fileObj)
    }


    audioToTextUpload(dataJson) {
        let { userId } = this.props;
        this.setState({
            processAudioToText: "process"
        }, () => {
            const postFileUpload = new FormData();
            postFileUpload.append('file', dataJson.file);
            postFileUpload.append('fileName', dataJson.fileName);
            postFileUpload.append('processType', dataJson.processType);
            postFileUpload.append('docsId', dataJson.docsId);
            postFileUpload.append('entryId', dataJson.docsId);
            postFileUpload.append('userId', userId);
            fetch(MyConstant.keyList.aiUrl + "voice-to-text/file", {
                method: "POST",
                mode: 'cors',
                redirect: 'follow',
                body: postFileUpload,
            })
                .then((response) => response.json())
                .then((result) => {
                    this.setState({
                        processAudioToText: "completed",
                        resultAudioToText: result
                    })
                    console.log('Success:', result);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        })
    }

    init(channelId) {
        let { channelKeys, getChannelChatsFetched, getChannelDetailsFetched, getChannelMembersFetched, scrollPosition, loginId, companyId } = this.props;
        if (!channelKeys.includes(channelId)) {
            this.getChannelChats(channelId, 1, true, {});
            this.getChannelDetails(channelId);
            this.getChannelMembers(channelId);
        } else {
            if (!getChannelChatsFetched) {
                this.getChannelChats(channelId, 1, true, {});
            }
            if (!getChannelDetailsFetched) {
                this.getChannelDetails(channelId);
            }
            if (!getChannelMembersFetched) {
                this.getChannelMembers(channelId);
            }
            this.scrollToPosition(scrollPosition);
        }

        this.getChannelMembersWithPaginate(channelId)
        this.getChannelChatsNew(channelId, 1, "MsgArrived");
        clearTimeout(activeStatusIntervalId);
    }

    getChannelMessagesFrequent(responseData) {
        let channelId = this.getChannelId()
        let { listOfMessageTypesWaiting } = responseData
        if (listOfMessageTypesWaiting !== undefined) {
            listOfMessageTypesWaiting.map((item) => {
                if (item === "MsgArrived" || item === "MsgEdited" || item === "MsgDeleted" || item === "ChannelMemberUpdated") {
                    this.getChannelChatsNew(channelId, 1, item === "MsgArrived");

                    if (item === "MsgArrived") {
                        this.updateChannelUnReadCountIsEmpty(channelId)
                    }
                }
                if (item === "MsgTyping") {
                    this.getChatTypingInfo(channelId)
                }
                if (item === "ChannelMemberUpdated") {
                    this.getChannelMembers(channelId)
                }
            })
        }
    }

    getChannelDetails = (channelId) => {
        let { loginId, firstName, fromCustomerSupport, companyId } = this.props;
        let postJson = { reqId: getUuidv4(), channelId, memberId: loginId };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.channelDetailsSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, async (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                let result = responseData.result;
                let { channelType } = result
                let existChannelMemberIds = result.membersUserIds;
                existChannelMemberIds.push(result.createdByUserId)
                let currentChannelName = result.channelName.replace(firstName + ",", "").replace(/,\s*$/, "");
                let channelMuteStatus = result.channelMuteStatus ? result.channelMuteStatus : "unmute";
                let selectedMessageSound = result.channelSound ? result.channelSound : "sound1";

                let aiAgentDataDomainType = ""
                if (fromCustomerSupport && channelType !== GlobalMsgCons.channelTypeEmail) {
                    let feedbackDetails = await this.getCustomerFeedbackDetails(channelId)
                    let { feedbackNo, productInformation } = feedbackDetails
                    currentChannelName = currentChannelName + " - " + getGuestFeedbackId(feedbackNo)
                    aiAgentDataDomainType = productInformation
                }
                let projectId = ""
                if (workflowUsers.includes(loginId)) {
                    projectId = "f9caf5d1e97b402ab6b4712269c828af"
                    if (companyId === "f9d096a42c8a4285aec48e39f706ea36") {
                        projectId = "b8b0a1f2acde4a679ca6a69bcee3bf20"
                    }

                    if (aiAgentDataDomainType === "documentAnalyzer") {
                        projectId = "5b6fb17f3b424f88bc5432b0d29b1410"
                    }
                    if (aiAgentDataDomainType === "hospitalManagement") {
                        projectId = "86a1a71146e74395b8638ca636f4c642"
                    }
                }

                let agentOptions = [
                    { label: "Default", value: "" },
                    { label: "Trip Easy", value: projectId, designType: "technology" },
                ]

                if (MyConfig.teqitAIAgentWorkspace.includes(companyId)) {
                    switch (aiAgentDataDomainType) {
                        case "documentAnalyzer":
                            agentOptions = [
                                { label: "Default", value: "" },
                                { label: "Docs Analysis", value: "5b6fb17f3b424f88bc5432b0d29b1410", designType: "technology" }
                            ]
                            break;
                        case "hospitalManagement":
                            agentOptions = [
                                { label: "Default", value: "" },
                                { label: "Hospital Management", value: "86a1a71146e74395b8638ca636f4c642", designType: "technology" }
                            ]
                            break;
                    }

                }

                this.props.setMultipleChatData(channelId, {
                    currentChannelName,
                    channelType: result.channelType,
                    channelMuteStatus,
                    selectedMessageSound,
                    deactivatedMembersIds: result.deactivatedMembersIds ? result.deactivatedMembersIds : [],
                    existChannelMemberIds,
                    getChannelDetailsFetched: true,
                    aiAgentDataDomainType,
                    selectedAgent: projectId,
                    agentOptions
                })

            }
        });
    }


    getCustomerFeedbackDetails(channelId) {
        let postJson = {
            reqId: getUuidv4(),
            feedbackId: channelId
        };

        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.customerFeedback);
        request.setEvent(GlobalEventCons.getCustomerFeedbackDetails);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        let result = new Promise(resolve => {
            getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    resolve(responseData.result)
                }
            });
        })

        return result
    }

    getChannelMembers = (channelId) => {
        let postJson = { reqId: getUuidv4(), channelId, pageLimit: 10, noOfPage: 1 };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.channelMembersSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                let result = responseData.result;
                this.props.setMultipleChatData(channelId, {
                    channelMembers: Object.values(result),
                    channelMembersTotalCount: responseData.totalResult,
                    getChannelMembersFetched: true
                });
            }
        });
    }

    updateChannelUnReadCountIsEmpty(channelId) {
        let { loginId, companyId } = this.props;
        let postJson = { reqId: getUuidv4(), userId: loginId, channelId, orgId: companyId };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.updateChannelUnReadCountIsEmptySE);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                // console.log("Updated")
            }
        });
    }

    getChatTypingInfo(channelId) {
        let { userId } = this.props;
        let postJson = { reqId: getUuidv4(), channelId };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.getChatTypingInfo);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                let result = responseData.result;
                let list = [];
                if (Object.keys(result).length > 0) {
                    Object.keys(result).map((id) => {
                        if (userId !== id) {
                            list.push(capitalize(result[id]))
                        }
                    })
                }
                this.setState({
                    typingUserList: list
                })
            }
        });
    }

    getChannelChatsNew(channelId, noOfPage, scrollBottom) {
        let { loginId, sessionId } = this.props;

        let postJson = {
            reqId: getUuidv4(),
            sessionTabId: getTabToken(),
            sessionId: sessionId, channelId, userId: loginId, pageLimit: 25, noOfPage: noOfPage
        };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.channelChatViaListenerSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let { userChatHistory, deleteMessageId } = this.props;
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                if (responseData.channelId === this.props.channelId) {
                    let totalMsgCount = responseData.totalResult
                    let result = userChatHistory;
                    let deletedMsg = false

                    let { deletedIdList, newMsgs, editedMsgs } = responseData
                    if (deleteMessageId.length > 0) {
                        let chat_his = {}
                        Object.keys(result).map((key) => {
                            if (!deleteMessageId.includes(result[key].id)) {
                                chat_his[key] = { ...result[key] }
                            }
                        })
                        result = chat_his
                    }

                    if (deletedIdList.length > 0) {
                        let chatHistory = {}
                        deletedIdList.map((msgId) => {
                            Object.keys(result).map((key) => {
                                if (result[key].chatMessage.id === msgId) {
                                    deleteMessageId.push(msgId)
                                    deletedMsg = true
                                }
                                else {
                                    chatHistory[key] = { ...result[key] }
                                }
                            })
                        })
                        result = chatHistory
                        totalMsgCount = Object.keys(result).length
                    }

                    if (newMsgs.length > 0 && !deletedMsg) {
                        newMsgs.map((ival, i) => {
                            result[ival.chatMessage.sendAt] = ival;
                        });
                    }


                    if (editedMsgs.length > 0 && !deletedMsg) {
                        responseData.editedMsgs.map((ival, i) => {
                            result[ival.chatMessage.sendAt] = ival;
                        });
                    }
                    this.props.setMultipleChatData(channelId, {
                        userChatHistory: result,
                        currentResult: totalMsgCount,
                        scrollBottom: false,
                        deleteMessageId,
                    })

                    if (newMsgs.length > 0) {
                        this.scrollBottom();
                    }
                }
            }

        });
    }

    getChannelChats(channelId, noOfPage, scrollBottom, userChatHistory) {
        let { loginId } = this.props
        let postJson = { reqId: getUuidv4(), channelId, pageLimit: pageLimit, noOfPage: noOfPage, memberId: loginId };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.getChannelChatsSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                let result = userChatHistory;
                responseData.result.map((ival, i) => {
                    result[ival.chatMessage.sendAt] = ival;
                });

                this.props.setMultipleChatData(channelId, {
                    userChatHistory: result,
                    currentResult: responseData.totalResult,
                    getChannelChatsFetched: true
                })

                if (scrollBottom) {
                    this.scrollBottom();
                } else if (Object.keys(result)[((noOfPage - 1) * pageLimit)]) {
                    //focus div
                    document.getElementById(Object.keys(result)[((noOfPage - 1) * pageLimit)]).scrollIntoView();
                }
            }
        });
    }

    async editChannelSubmit(event, gifURL) {
        if (event) {
            event.preventDefault();
        }

        let { channelId, fileList, loginId, editMessageText, editMessageId, userChatHistory, editMessageDetails, setEmptyText } = this.props;

        if ((editMessageText.trim() !== "") || fileList.length > 0 || gifURL !== "") {
            for (let i = 0; i < fileList.length; i++) {
                await this.fileUpload(i, fileList[i]);
            }

            if (gifURL !== "") {
                editMessageText = "";
                fileList = [];
            }

            editMessageDetails.message = editMessageText;
            editMessageDetails.fileList = fileList;
            userChatHistory[editMessageDetails.sendAt].chatMessage = editMessageDetails
            this.props.setMultipleChatData(channelId, {
                editMessageId: "",
                editMessageText: "",
                editMessageDetails: {},
                userChatHistory,
                setEmptyText: !setEmptyText,
                emojiPicker: false,
                fileList: [],
                gifURL: "",
                changeText: ""
            })

            let postJson = {
                reqId: getUuidv4(),
                messageId: editMessageId,
                channelId,
                senderId: loginId,
                message: editMessageText,
                editType: "chat",
                callData: "",
                fileList: fileList,
                objUrl: gifURL,
                deviceInfo: getAppInfo(),
                userType: GlobalMsgCons.type_user
            };
            postJson = coversionED("encrypt", postJson).toString()
            let request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.chatSetDomain);
            request.setEvent(GlobalEventCons.editChannelChatSetEvent);
            request.setMessage(postJson);
            request.setDatetime(new Date().toString());
            getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = response.getMessage();
                    responseData = coversionED("decrypt", responseData)
                    responseData = JSON.parse(responseData)
                    if (responseData.response === GlobalMsgCons.success) {
                        console.log("updated.")
                    }
                }
            });
        }
    }

    scrollBottom() {
        if (this.messageEnd && this.messageEnd.scrollHeight) {
            this.messageEnd.scrollTop = this.messageEnd.scrollHeight;
        }
    }

    scrollToPosition(position) {
        if (this.messageEnd && this.messageEnd.current) {
            this.messageEnd.scrollTop = position;
        }
    }

    handleClickOutside(e) {
        let { messageEmojiPicker } = this.state;
        if (messageEmojiPicker && !e.target.closest(".emoji-message") && !(e.target.className.includes("emoji-from-picker") || e.target.closest(".emoji-from-picker"))) {
            this.setState({
                messageEmojiPicker: false
            })
        }
    }

    setChatMessage(e) {
        let channelId = this.getChannelId()
        localStorage.setItem("currentChatText_" + channelId, e)
        this.props.setMultipleChatData(channelId, {
            message: e
        });
    }

    edit(data) {
        let details = JSON.parse(JSON.stringify(data));
        let { surveyData, surveyModal, surveyAction, channelId, chatSurveyTimeData } = this.props;
        if (details.messageType === GlobalMsgCons.surveyText) {
            surveyData.surveyQuestion = details.chatSurveyData.question;
            surveyData.surveyOption = details.chatSurveyData.optionMap;
            surveyData.surveyType = details.chatSurveyData.surveyType;
            let chatSurveyDuration = 0;
            if (typeof (details.chatSurveyData.duration) !== "undefined") {
                chatSurveyDuration = parseInt(details.chatSurveyData.duration);
            }
            surveyData.totalSeconds = chatSurveyDuration;
            surveyModal = true;
            surveyAction = "edit";
            let seconds = chatSurveyDuration;
            var d = Math.floor(seconds / (3600 * 24));
            var h = Math.floor(seconds % (3600 * 24) / 3600);
            var m = Math.floor(seconds % 3600 / 60);
            // var s = Math.floor(seconds % 60);
            chatSurveyTimeData.daySelected = parseInt(d)
            chatSurveyTimeData.hourSelected = parseInt(h)
            chatSurveyTimeData.minuteSelected = parseInt(m)

        }

        this.props.setMultipleChatData(channelId, {
            editMessageId: details.id,
            editMessageDetails: details,
            editMessageText: details.message,
            changeText: details.message,
            fileList: details.fileList ? details.fileList : [],
            editBlink: true,
            surveyModal,
            surveyData,
            surveyAction,
            chatSurveyTimeData
        });

        setTimeout(() => {
            this.props.setMultipleChatData(channelId, {
                editBlink: false
            })
        }, 3000, channelId)
    }

    async retryChatMessage(messageData) {
        let { channelId, userChatHistory, loginId, firstName, profilePhoto, companyId } = this.props;
        let { chatMessage } = messageData;
        let { sendAt, message, fileList, objUrl } = chatMessage

        delete userChatHistory[sendAt];
        let createdAt = new Date();

        let deviceInfo = getAppInfo();
        let messageId = getUuidv4();
        let tempMessage = {
            chatMessage: {
                id: messageId,
                userId: loginId,
                message,
                sendAt: createdAt.getTime(),
                createdAt: createdAt.getTime(),
                messageType: GlobalMsgCons.messageTypeChat,
                fileList,
                objUrl,
                deviceInfo
            },
            name: firstName,
            userPhoto: profilePhoto,
            tempMessage: true,
            tempMessageStatus: "loading"
        }
        userChatHistory[createdAt.getTime()] = tempMessage

        this.props.setMultipleChatData(channelId, {
            userChatHistory
        })
        this.scrollBottom()

        for (let i = 0; i < fileList.length; i++) {
            await this.fileUpload(i, fileList[i]);
        }

        let postJson = {
            reqId: getUuidv4(),
            messageId,
            channelId,
            orgId: companyId,
            senderId: loginId,
            message,
            fileList,
            sendAt: createdAt.getTime(),
            objUrl,
            messageType: GlobalMsgCons.messageTypeChat,
            deviceInfo,
            userType: GlobalMsgCons.type_user
        };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.sendMsgASyncSetEvent);
        request.setMessage(postJson);
        request.setDatetime(createdAt.toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
                userChatHistory[createdAt.getTime()].tempMessageStatus = "failed"

                this.props.setMultipleChatData(channelId, {
                    userChatHistory
                });
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                if (responseData === GlobalMsgCons.success) {

                }
            }
        });
    }

    async addChannelChat(event, gifURL) {
        if (event) {
            event.preventDefault();
        }
        let { loginId, channelId, message, fileList, setEmptyText, companyId, firstName, profilePhoto, userChatHistory, fromCustomerSupport, fromCustomerSupportTeam, aiAgentDataDomainType, selectedAgent, agentOptions } = this.props;
        let msg = message.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '')
        if ((msg.trim() !== "") || fileList.length > 0 || gifURL !== "") {
            let createdAt = new Date();
            let deviceInfo = getAppInfo();
            let messageId = getUuidv4();
            let messageType = GlobalMsgCons.messageTypeChat;
            if (fromCustomerSupport && !fromCustomerSupportTeam) {
                messageType = GlobalMsgCons.botRequest
            }

            let tempMessage = {
                chatMessage: {
                    id: messageId,
                    userId: loginId,
                    message,
                    sendAt: createdAt.getTime(),
                    createdAt: createdAt.getTime(),
                    messageType,
                    fileList: fileList,
                    objUrl: gifURL,
                    deviceInfo
                },
                name: firstName,
                userPhoto: profilePhoto,
                tempMessage: true,
                tempMessageStatus: "loading"
            }
            userChatHistory[createdAt.getTime()] = tempMessage
            localStorage.removeItem("currentChatText_" + channelId)
            await this.props.setMultipleChatData(channelId, {
                message: "",
                fileList: [],
                emojiPicker: false,
                setEmptyText: !setEmptyText,
                scrollBottom: true,
                gifURL: "",
                userChatHistory
            })
            this.scrollBottom()

            for (let i = 0; i < fileList.length; i++) {
                await this.fileUpload(i, fileList[i]);
            }


            let postJson = {
                reqId: getUuidv4(),
                messageId,
                channelId,
                orgId: companyId,
                senderId: loginId,
                message,
                fileList: fileList,
                sendAt: createdAt.getTime(),
                objUrl: gifURL,
                messageType,
                deviceInfo,
                userType: GlobalMsgCons.type_user,
            };
            if (fromCustomerSupport && !fromCustomerSupportTeam) {
                let selected = aiAgentDataDomainTypeOptions.filter((val => { return val.value === aiAgentDataDomainType }))
                let aiAgent = selected.length > 0 ? selected[0].value : "trip"

                postJson.aiAgentDataDomainType = aiAgent
                let aiAgentType = "teqAgent"

                if (teqAgentsForGuest[loginId] && teqAgentsForGuest[loginId][aiAgent]) {
                    aiAgentType = teqAgentsForGuest[loginId][aiAgent]
                }
                postJson.aiAgentType = aiAgentType

                if (selectedAgent) {
                    let { value, designType } = agentOptions.filter((e) => { return e.value === selectedAgent })[0]
                    postJson.workFlowRequestData = {
                        orgId: companyId,
                        projectId: value,
                        designType: designType
                    }
                }
            }
            console.log("postJson", postJson)
            postJson = coversionED("encrypt", postJson).toString()
            let request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.chatSetDomain);
            request.setEvent(GlobalEventCons.sendMsgASyncSetEvent);
            request.setMessage(postJson);
            request.setDatetime(createdAt.toString());
            getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                    userChatHistory[createdAt.getTime()].tempMessageStatus = "failed"

                    this.props.setMultipleChatData(channelId, {
                        userChatHistory
                    });
                } else {
                    let responseData = response.getMessage();
                    responseData = coversionED("decrypt", responseData)
                    if (responseData === GlobalMsgCons.success) {

                    }
                }
            });
        }
    }

    async fileChange(files) {
        let { pageText } = this.state;
        let { channelId, fileList } = this.props;
        let length = files.length;
        let validation = true;
        if (length > 0) {
            if (fileList.length <= 4) {
                let inc = fileList.length;
                for (var i = 0; i < length; i++) {
                    let fileUrl = URL.createObjectURL(files[i]);
                    let file = files[i];
                    let filename = file.name;
                    let valid = this.isValidFileExt(filename);
                    if (!valid) {
                        validation = false;
                    }
                    let ext = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
                    let uid = uuidv4();
                    let fileObj = {
                        file: file,
                        origFileName: filename,
                        fileUrl: fileUrl,
                        fileName: uid + "." + ext,
                        docsId: uid,
                        processType: "chat",
                        fileType: ext
                    };
                    if (validation) {
                        fileList[inc] = fileObj;
                        fileList[inc].uploadProcess = "pending";

                        this.props.setMultipleChatData(channelId, {
                            fileList
                        })
                        inc++;
                    } else {
                        toast.error(getValidLabels(pageText, 'fileNotValidErr_msg'), {
                            position: "top-center",
                            autoClose: GlobalMsgCons.autoClose,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }
            } else {

                toast.error("Maximum 5 files can be uploaded", {
                    position: "top-center",
                    autoClose: GlobalMsgCons.autoClose,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

        }
    }

    fileUpload(i, dataJson) {
        let { loginId } = this.props;
        const postFileUpload = new FormData();
        postFileUpload.append('file' + (i + 1), dataJson.file);
        postFileUpload.append('fileName' + (i + 1), dataJson.fileName);
        postFileUpload.append('processType' + (i + 1), dataJson.processType);
        postFileUpload.append('docsId' + (i + 1), dataJson.docsId);
        fetch(MyConstant.keyList.apiURL + "uploads/" + dataJson.processType + "/" + dataJson.docsId + "/" + loginId, {
            method: "POST",
            mode: 'cors',
            redirect: 'follow',
            body: postFileUpload,
        }).then(
            function (response) {
                return response;
            }
        ).then(json => {
            return json.response;
        }).catch(error => console.warn(error));
    }
    isValidFileExt(name) {
        const ext = ['.jpg', '.jpeg', '.bmp', '.gif', '.png', '.svg', '.csv', '.pdf', '.xls', '.xlsx', '.mp3', '.mp4', '.3gp', '.ogg', '.mov', ".webm", ".json", ".doc", ".docs"];
        return ext.some(el => name.endsWith(el));
    }

    onEmojiClick(event, emojiObject) {
        let { insertTextToggle, channelId } = this.props
        this.props.setMultipleChatData(channelId, {
            insertTextToggle: !insertTextToggle,
            insertText: emojiObject.emoji
        })
    }

    call() {
        let { channelId, loginId, companyId, path } = this.props;
        let roomId = generateRoomId();
        let createdAt = new Date();
        let messageId = getUuidv4();
        let deviceInfo = getAppInfo();
        let postJson = {
            reqId: getUuidv4(),
            messageId,
            channelId,
            orgId: companyId,
            senderId: loginId,
            message: "",
            fileList: [],
            sendAt: createdAt.getTime(),
            objUrl: "",
            messageType: "call",
            deviceInfo,
            callData: {
                startAt: 0,
                callStatus: "",
                endAt: 0,
                joinedPeople: [loginId],
                roomId,
                metaInfo: ""
            },
            userType: GlobalMsgCons.type_user
        };

        this.setState({
            callButtonLoader: true
        }, () => {
            this.props.setCallWindowData({
                channelId,
                roomId,
                messageId,
                calltype: "call"
            })
        })

        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.sendMsgASyncSetEvent);
        request.setMessage(postJson);
        request.setDatetime(createdAt.toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                if (responseData === GlobalMsgCons.success) {
                    this.setState({
                        callButtonLoader: false
                    })
                }
            }
        });

    }

    humanDateFormat(createdAt) {
        let { activeCompanyDetails, hoursSelected } = this.props;
        return getDateWithFormat(createdAt, activeCompanyDetails, hoursSelected)
    }


    loadMore() {
        let { channelId, noOfPage, userChatHistory } = this.props;
        this.props.setMultipleChatData(channelId, {
            noOfPage: noOfPage + 1
        })
        this.getChannelChats(channelId, (noOfPage + 1), false, userChatHistory);
    }


    previewContent(buildUrl) {
        let content;
        if (buildUrl !== undefined) {
            var re = /(?:\.([^.]+))?$/;
            let url = new URL(buildUrl)
            let params = new URLSearchParams(url.search);
            let fileType = re.exec(params.get('key'))[1];

            let images = ["jpg", "png", "jpeg", "gif"];
            let videos = ["mp4", "3gp", "ogg", "mov", "webm"]
            let pdf = ["pdf"]
            let txt = ["txt"]
            let audio = ["mp3"]
            let docs = ["doc", "docs", "json"]
            let fileContent = "";
            if (images.includes(fileType.toLowerCase())) {
                fileContent = <img src={buildUrl} className="img-fluid" />
            } else if (videos.includes(fileType.toLowerCase())) {
                fileContent = <video width="100%" controls>
                    <source src={buildUrl} />
                </video>
            } else if (pdf.includes(fileType.toLowerCase())) {
                fileContent = <PDFViewer url={buildUrl} />
            } else if (txt.includes(fileType.toLowerCase())) {
                fileContent = <iframe frameborder="0" style={{ height: "75vh" }} src={buildUrl} height="75vh" width="100%" />
            } else if (audio.includes(fileType.toLowerCase())) {
                fileContent = <audio width="100%" controls>
                    <source src={buildUrl} />
                </audio>
            } else {
                fileContent = <div>
                    <div className="text-right">
                        <button className="btn btn-secondary" onClick={() => this.downloadUrl(buildUrl)} >
                            <i className="fa fa-download"></i>
                        </button>
                    </div>
                    Not able to preview.
                </div>
            }

            content = <React.Fragment>
                {/* <div className="text-right">
                    <button className="btn btn-secondary" onClick={() => this.downloadUrl(buildUrl)} >
                        <i className="fa fa-download"></i>
                    </button>
                </div> */}
                <div style={{ textAlign: "center" }} >
                    {fileContent}
                </div>
            </React.Fragment>
        }

        return content;
    }

    downloadUrl(buildUrl) {
        var link = document.createElement("a");
        link.download = "feedback.xls";
        link.href = buildUrl;
        link.click();
    }


    fileIcon(buildUrl, fileType) {
        let images = ["jpg", "png"];
        let videos = ["mp4", "3gp", "ogg", "mov", "webm"]
        let pdf = ["pdf"]
        let txt = ["txt"]
        let docs = ["doc", "docs",]
        let json = ["json"]

        let fileContent = "";
        if (images.includes(fileType.toLowerCase())) {
            fileContent = <img src={buildUrl} className="img-fluid" />
        } else if (videos.includes(fileType.toLowerCase())) {
            fileContent = <i className="fa fa-video" />
        } else if (pdf.includes(fileType.toLowerCase())) {
            fileContent = <i className="fa fa-file-pdf" />
        } else if (txt.includes(fileType.toLowerCase())) {
            fileContent = <i className="fa fa-file-alt" />
        }
        else if (docs.includes(fileType.toLowerCase())) {
            fileContent = <i className="fa fa-file-word" />
        }
        else if (json.includes(fileType.toLowerCase())) {
            fileContent = <i className="far fa-file-alt" />
        }
        else {
            fileContent = <i className="fa fa-file" />
        }

        return fileContent;
    }

    previewBlobContent() {
        let { previewBlob } = this.props;
        let { filePath, fileType } = previewBlob;

        let images = ["jpg", "png", "jpeg", "gif"];
        let videos = ["mp4", "3gp", "ogg", "mov", "webm"]
        let pdf = ["pdf"]
        let txt = ["txt"]
        let audio = ["mp3"]
        let excel = ["csv", "xls", "xlsx"]
        let fileContent = "";
        if (images.includes(fileType.toLowerCase())) {
            fileContent = <img src={filePath} className="img-fluid" />
        } else if (videos.includes(fileType.toLowerCase())) {
            fileContent = <video width="100%" controls>
                <source src={filePath} />
            </video>
        } else if (pdf.includes(fileType.toLowerCase())) {
            fileContent = <PDFViewer url={filePath} />
        } else if (txt.includes(fileType.toLowerCase())) {
            fileContent = <iframe frameborder="0" style={{ height: "75vh" }} src={filePath} height="75vh" width="100%" />
        } else if (audio.includes(fileType.toLowerCase())) {
            fileContent = <audio width="100%" controls>
                <source src={filePath} />
            </audio>
        }

        let content = <div style={{ textAlign: "center" }} >
            {fileContent}
        </div>

        return content;
    }

    fileDelete(e, i) {
        e.stopPropagation();
        let { fileList } = this.props;
        fileList.splice(i, 1);

        let channelId = this.getChannelId()
        this.props.setMultipleChatData(channelId, {
            fileList
        })
    }

    toggleInfo() {
        let { infoShow, channelId } = this.props;
        this.props.setMultipleChatData(channelId, {
            infoShow: !infoShow
        })
    }

    chatFileView(ival) {
        let { userChatHistory, channelId } = this.props;
        let message = userChatHistory[ival].chatMessage;
        let tempMessage = userChatHistory[ival].tempMessage;
        tempMessage = tempMessage !== undefined ? tempMessage : false;
        let fileList = message.fileList;

        let images = ["jpg", "png", "jpeg", "gif"];
        let videos = ["mp4", "3gp", "ogg", "mov", "webm"]
        let pdf = ["pdf"]
        let txt = ["txt"]
        let audio = ["mp3"]

        let contentDiv = "";
        let showRowType = false;

        contentDiv = fileList.map((fileMap, fi) => {
            showRowType = false;
            let buildUrl = MyConstant.keyList.apiURL + "vp?action=" + fileMap.processType + "&key=" + fileMap.fileName + "&id=" + message.userId;
            if (tempMessage) {
                buildUrl = fileMap.fileUrl;
            }
            let downlodFileName = fileMap.origFileName;
            let downLoadUrl = MyConstant.keyList.apiURL + `download/${downlodFileName}?action=` + fileMap.processType + "&key=" + fileMap.fileName + "&id=" + message.userId;
            let fileType = fileMap.fileType;
            let content = <div className="message-file">
                <div className="file" onClick={() => {
                    this.props.setMultipleChatData(channelId, {
                        buildUrl,
                        previewModal: true
                    })
                }}>
                    <i className="fa fa-file"></i>
                    {fileMap.processType === "chat-record" ? "" : fileMap.origFileName}
                </div>
            </div>;

            if (images.includes(fileType.toLowerCase())) {
                showRowType = true;
                content = <div className=" chat-file-box-wrapper col-3 " style={{ display: "contents" }}>
                    <div className="chat-file-box p-3" >
                        <img src={buildUrl} className="img-fluid" onClick={() => {
                            this.props.setMultipleChatData(channelId, {
                                buildUrl,
                                previewModal: true
                            })
                        }} />
                    </div>
                    {!tempMessage && <div className="download-url">
                        <a href={downLoadUrl} download className="files-down">
                            <i className="fa fa-download "></i>
                        </a>
                    </div>
                    }
                </div>
            } else if (videos.includes(fileType.toLowerCase())) {
                content = <div className="d-flex chat-file-box-wrapper">
                    <div className="chat-file-box" onClick={() => {
                        this.props.setMultipleChatData(channelId, {
                            buildUrl,
                            previewModal: true
                        })
                    }}>
                        <video width="100%" controls style={{ width: "80%" }}>
                            <source src={buildUrl} />
                        </video>
                    </div>
                    {!tempMessage && <div className="download-url">
                        <a href={downLoadUrl} download className="files-down">
                            <i className="fa fa-download "></i>
                        </a>
                    </div>
                    }
                </div>
            } else if (audio.includes(fileType.toLowerCase())) {
                content = <div className="d-flex chat-file-box-wrapper">
                    <div>
                        <audio width="100%" controls onClick={() => {
                            this.props.setMultipleChatData(channelId, {
                                buildUrl,
                                previewModal: true
                            })
                        }}>
                            <source src={buildUrl} />
                        </audio>
                    </div>
                    {!tempMessage && <div className="download-url">
                        <a href={downLoadUrl} download className="files-down">
                            <i className="fa fa-download "></i>
                        </a>
                    </div>
                    }
                </div>
            } else if (pdf.includes(fileType.toLowerCase())) {
                content = <div className="d-flex chat-file-box-wrapper">
                    <div className="chat-file-box" >
                        <div className="file-info d-flex" onClick={() => {
                            this.props.setMultipleChatData(channelId, {
                                buildUrl,
                                previewModal: true
                            })
                        }} >
                            <div className="icon" >
                                <i className="fa fa-file-pdf"></i>
                            </div>
                            <div className="text">
                                {fileMap.origFileName}
                            </div>
                        </div>
                    </div>
                    {!tempMessage && <div className="download-url">
                        <a href={downLoadUrl} download className="files-down">
                            <i className="fa fa-download "></i>
                        </a>
                    </div>
                    }
                </div>
            }
            return content
        })
        let returnDiv = contentDiv
        if (showRowType) {
            returnDiv = <div className="row">
                {contentDiv}
            </div>
        }


        return returnDiv;
    }

    chatEditFileView(file) {
        let { loginId } = this.props;
        let buildUrl = MyConstant.keyList.apiURL + "vp?action=" + file.processType + "&key=" + file.fileName + "&id=" + loginId;
        let fileType = file.fileType;
        return this.fileIcon(buildUrl, fileType);
    }

    deleteChatMessage(messageId, key) {
        let { channelId, loginId, userChatHistory } = this.props
        delete userChatHistory[key];

        this.props.setMultipleChatData(channelId, {
            editMessageId: "",
            userChatHistory
        })

        //messageId: String, channelId: String, senderId: String
        let postJson = { reqId: getUuidv4(), messageId: messageId, channelId: channelId, senderId: loginId }
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.deleteChannelChatSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                if (responseData.response === GlobalMsgCons.success) {
                    console.log("deleted!")
                }
            }
        });

    }

    joinCall(data) {
        let { id, callData } = data;
        let { channelId, path, guestRole } = this.props;
        let { roomId } = callData;
        let url = path + "/join-call/" + channelId + "/" + roomId + "/" + id;
        // window.open(url, "_blank")
        // this.props.history.push(url)
        this.props.setCallWindowData({
            channelId,
            roomId,
            messageId: id,
            calltype: "join",
            guestRole
        })
    }

    declineCall(data) {
        let { id } = data;
        let { loginId, channelId } = this.props;
        this.updateCallStatus(loginId, channelId, id, "reject-call")
    }

    updateCallStatus(senderId, channelId, messageId, status) {
        let postJson = { reqId: getUuidv4(), messageId, channelId, memberId: senderId, status };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.updateCallStatusSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {

            }
        });
    }

    updateEmoJiReactionForMsg(msgId, emojiId) {
        let { loginId, channelId, companyId } = this.props;
        let postJson = { reqId: getUuidv4(), channelId, msgId, userId: loginId, orgId: companyId, emojiId };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.updateEmoJiReactionForMsg);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {

            }
        });
    }

    joinMeetingCall(data) {
        let { meetingsData } = data;
        let meetingId = meetingsData.id;
        let { path } = this.props

        let url = path + "/join-meeting-call/" + meetingId;
        // window.open(url, "_blank")
        this.props.history.push(url)
    }
    async getPageText() {
        let pageText = await getPageText('chat');
        this.setState({ pageText: pageText })
    }


    callStatus(status, data) {
        let { loginId } = this.props;
        let str = "";
        switch (status) {
            case "call-started":
                str = "Call Started."
                break;
            case "line-busy":
                if (data.userId === loginId) {
                    let name = "Receiver";
                    // Todo name display
                    str = name + " is on another call."
                } else {
                    str = "Missed call."
                }
                break;
            case "call-ended":
                str = "Call Ended."
                break;
            case "call-declined":
                str = "Call Declined."
                break;
            case "missed-call":
                str = "Missed call."
                break;
            default:
                str = "Calling.";
                break;
        }

        return str;
    }

    setUserSelected(event) {
        let channelId = this.getChannelId()
        this.props.setMultipleChatData(channelId, {
            channelUser: event
        })
    }



    toggleNotification(channelMuteStatus, selectedMessageSound) {
        let { channelId, loginId } = this.props;
        let postJson = { reqId: getUuidv4(), channelId, memberId: loginId, muteStatus: channelMuteStatus, sound: selectedMessageSound };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.updateChannelNotificationSoundStatusSE);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {

            }
        });

    }

    timeStampToDate = (d) => {
        let { activeCompanyDetails } = this.props;
        return getOnlyDateWithFormat(d, activeCompanyDetails)
    }


    getMemberOnlineStatusApiCall = async () => {
        let { paginateData, showMorePeopleModal, channelId } = this.props;
        let responseData = await this.getMemberOnlineStatus()
        let _this = this;
        activeStatusIntervalId = setTimeout(function () {
            if (responseData) {
                _this.getMemberOnlineStatusApiCall()
            }
        }, 1000);
        if (responseData) {
            paginateData.paginateStatusList = responseData;
            if (!showMorePeopleModal) {
                paginateData.initialStatusList = responseData;
            }
            this.props.setMultipleChatData(channelId, {
                paginateData
            });
        }

    }

    getMemberOnlineStatus = (UserIds) => {
        let { loginId, paginateData } = this.props;
        let userIdList = paginateData.channelOnlineUserId;
        if (UserIds) {
            userIdList = UserIds;
        }
        let postJson = { reqId: getUuidv4(), senderId: loginId, memberList: userIdList };
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.pollSetDomain);
        request.setEvent(GlobalEventCons.getMemberOnlineStatusSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        let result = new Promise(resolve => {
            getGrpcPollingClient(this.props.grpcPollingClient).pollZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request.Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    resolve(responseData.statusMap);
                }
            });
        });
        return result;
    }

    convertStatus(status) {
        let returnMsg = ""
        switch (status) {
            case GlobalMsgCons.status_available:
                returnMsg = <div className="mx-2" style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: "#28a745", display: "inline-block" }}>
                </div>
                break;
            case GlobalMsgCons.status_onCall:
                returnMsg = <div className="mx-2" style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: "red", display: "inline-block" }}>
                </div>
                break;
            case GlobalMsgCons.status_notOnline:
            case GlobalMsgCons.status_notAvailable:
                returnMsg = <div className="mx-2" style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: "grey", display: "inline-block" }}>
                </div>
                break;

            case GlobalMsgCons.status_steppedAway:
                returnMsg = <div className="mx-2" style={{ width: 10, height: 10, borderRadius: 10 / 2, backgroundColor: "orange", display: "inline-flex", alignItems: "center", justifyContent: "center" }}>
                    {/* <i class="fa fa-bolt" style={{ fontSize: 10, position: "absolute", color: "#fff" }} aria-hidden="true"></i> */}
                </div>
                break;

            default:
                returnMsg = "";
                break;
        }
        return returnMsg;
    }
    convertCallStatus(status) {
        let returnMsg = "";
        switch (status) {
            case GlobalMsgCons.status_onCall:
                returnMsg = <div className="mx-2" style={{ display: "inline-flex", alignItems: "center", justifyContent: "center", color: "#3a7c5e" }}>
                    <i className="fa fa-phone"></i>
                </div>
                break;
            default:
                returnMsg = "";
                break;
        }
        return returnMsg;
    }

    movePageStatus(page) {
        let { paginateData, channelId } = this.props;
        let { next, prev, noOfPage, } = paginateData
        let pageNo = page
        if (page === "next") {
            if (next) {
                pageNo = noOfPage + 1;
            }
        } else if (page === "prev") {
            if (prev) {
                pageNo = noOfPage - 1;
            }
        }

        if (pageNo !== "next" && pageNo !== "prev") {
            paginateData.processing = true;
            paginateData.noOfPage = pageNo;
            this.props.setMultipleChatData(channelId, {
                paginateData
            })
            this.getChannelMembersWithPaginate(channelId, paginateData);
        }
    }


    getChannelMembersWithPaginate = (channelId, paginate) => {
        let { paginateData } = this.props;
        if (paginate) {
            paginateData.noOfPage = paginate.noOfPage
        }
        let postJson = { reqId: getUuidv4(), channelId, pageLimit: 10, noOfPage: paginateData.noOfPage };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.channelMembersSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, async (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                let result = responseData.result;
                clearTimeout(activeStatusIntervalId);
                let onlineMemberIdList = await this.getMemberOnlineStatus(Object.keys(result))
                let next = false;
                let prev = false;
                let totalResult = responseData.totalResult
                let currentResult = paginateData.noOfPage * 10;
                if (currentResult < totalResult) {
                    next = true;
                }
                if (paginateData.noOfPage > 1) {
                    prev = true;
                }
                paginateData.prev = prev;
                paginateData.next = next;
                paginateData.processing = false;
                let pageCount = Math.ceil(totalResult / 10);
                let pagination = pageNumbers(pageCount, paginateData.noOfPage, 5);
                paginateData.pagination = pagination;
                paginateData.tableData = result;
                paginateData.channelOnlineUserId = Object.keys(result);
                if (!paginate || paginateData.noOfPage === 1) {
                    paginateData.initialStatusList = onlineMemberIdList;
                }

                this.props.setMultipleChatData(channelId, {
                    paginateData,
                });
                this.getMemberOnlineStatusApiCall()
            }
        });
    }

    checkUserDetailsDisplay(chatMessage, index, type) {
        let nameDisplay = false
        if (lastMessage !== undefined && (type !== "live" || index > 0)) {
            let diff = chatMessage.sendAt - lastMessage.sendAt
            if (lastMessage.userId === chatMessage.userId && diff < 60000 && lastMessage.deviceInfo === chatMessage.deviceInfo) {
                nameDisplay = false
            } else {
                nameDisplay = true
            }
        } else {
            nameDisplay = true
        }
        lastMessage = chatMessage

        return nameDisplay;
    }

    updateChatTypingInfo(event) {
        let { userId, companyId, firstName, channelId } = this.props;
        let postJson = { reqId: getUuidv4(), channelId, userId, orgId: companyId, userName: firstName, actionType: event };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.updateChatTypingInfo);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, async (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {

            }
        });
    }

    openThread(message) {
        let channelId = this.getChannelId()
        this.props.setMultipleChatData(channelId, {
            threadData: {
                message,
                channelId
            },
            infoShow: true
        });
    }

    allowDrop(ev) {
        ev.preventDefault();
        if (ev.dataTransfer.types.includes("Files")) {
            this.setState({
                fileDrop: true
            })
        }
    }

    dropHandler(ev) {
        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();
        if (ev.dataTransfer.files.length > 0) {
            if (ev.dataTransfer.files.length === 1) {
                this.fileChange(ev.dataTransfer.files)
            } else {
                alert("Maximum number of files is one.")
            }
        }
        this.setState({
            fileDrop: false
        })
    }

    leaveDrop(e) {
        if (e.target.className === "dragAndDropWindow") {
            this.setState({
                fileDrop: false
            })
        }
    }

    async surveyValidation() {
        let { surveyData, surveyAction, channelId, chatSurveyTimeData } = this.props;
        let errorObj = {};
        let optionsValidate = false;
        errorObj.surveyOption = {};

        if (surveyData.surveyQuestion == "") {
            optionsValidate = true;
            errorObj.surveyQuestion = "Plese enter question";
        }

        if (chatSurveyTimeData.hourSelected === 0 && chatSurveyTimeData.minuteSelected === 0 && chatSurveyTimeData.daySelected === 0) {
            errorObj.duration = "Please enter duration!";
            optionsValidate = true;
        }
        let getTimer = await this.timeConversion();
        surveyData.totalSeconds = getTimer

        Object.keys(surveyData.surveyOption).map((ival, i) => {
            let objLabel = surveyData.surveyOption[ival];
            if (objLabel.label === "") {
                optionsValidate = true;
                errorObj.surveyOption[ival] = "Plese enter option";
            }
        })


        await this.props.setMultipleChatData(channelId, {
            errorSurveyData: errorObj, surveyData
        });


        if (!optionsValidate) {
            if (surveyAction === "edit") {
                this.editSurveySubmit()
            }
            else {
                this.chatSurveyPost()
            }
        }
    }
    async chatSurveyPost() {
        let { loginId, channelId, message, companyId, firstName, profilePhoto, surveyData, userChatHistory, chatSurveyTimeData } = this.props;

        let createdAt = new Date();
        let deviceInfo = getAppInfo()
        let messageId = getUuidv4();
        let tempMessage = {
            chatMessage: {
                id: messageId,
                userId: loginId,
                message,
                sendAt: createdAt.getTime(),
                createdAt: createdAt.getTime(),
                messageType: GlobalMsgCons.surveyText,
                fileList: [],
                objUrl: "",
                chatSurveyData: {
                    question: surveyData.surveyQuestion,
                    optionMap: surveyData.surveyOption,
                    surveyType: surveyData.surveyType,
                    duration: surveyData.totalSeconds
                },
                deviceInfo
            },
            name: firstName,
            userPhoto: profilePhoto
        }
        userChatHistory[createdAt.getTime()] = tempMessage

        await this.props.setMultipleChatData(channelId, {
            userChatHistory,
            surveyModal: false,
            surveyData: {
                surveyQuestion: "",
                surveyOption: { 1: "", 2: "" },
                surveyType: "single"
            },
            surveyAction: "add",
            chatSurveyTimeData: {
                ...chatSurveyTimeData, daySelected: 0, hourSelected: 0, minuteSelected: 30,
            }
        })
        this.scrollBottom();
        let postJson = {
            reqId: getUuidv4(),
            messageId,
            channelId,
            orgId: companyId,
            senderId: loginId,
            message: "",
            fileList: [],
            sendAt: createdAt.getTime(),
            objUrl: "",
            messageType: GlobalMsgCons.surveyText,
            deviceInfo,
            chatSurveyData: {
                question: surveyData.surveyQuestion,
                optionMap: surveyData.surveyOption,
                surveyType: surveyData.surveyType,
                duration: surveyData.totalSeconds
            },
            userType: GlobalMsgCons.type_user
        };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.sendMsgASyncSetEvent);
        request.setMessage(postJson);
        request.setDatetime(createdAt.toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, async (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                if (responseData === GlobalMsgCons.success) {

                }
            }
        });

    }

    replyChatSurveyQuestion(msgId, optionId, chatMessage) {
        let { userId, companyId, channelId, userChatHistory } = this.props;

        let { surveyType, responseData } = chatMessage.chatSurveyData;

        responseData = responseData !== undefined ? responseData : {}

        if (surveyType === "single" && responseData[optionId] !== undefined) {
            Object.keys(responseData).map((loopOptionId) => {
                if (optionId !== loopOptionId) {
                    if (responseData[loopOptionId][userId] !== undefined) {
                        delete responseData[loopOptionId][userId]
                    }
                }
            })
        }

        if (responseData[optionId] !== undefined && responseData[optionId][userId] !== undefined) {
            delete responseData[optionId][userId]
        } else {
            if (responseData[optionId] === undefined) {
                responseData[optionId] = {}
            }
            responseData[optionId][userId] = {
                userId,
                createdAt: ""
            }
        }
        chatMessage.chatSurveyData.responseData = responseData

        userChatHistory[chatMessage.sendAt].chatMessage = chatMessage
        this.props.setMultipleChatData(channelId, {
            userChatHistory
        })

        let postJson = { reqId: getUuidv4(), channelId, msgId, userId, orgId: companyId, optionId };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.updateChatSurveyReplySetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, async (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {

            }
        });
    }

    async editSurveySubmit() {
        let { channelId, fileList, loginId, editMessageText, editMessageId, userChatHistory, editMessageDetails, setEmptyText, surveyData, chatSurveyTimeData } = this.props;
        let postJson = {
            reqId: getUuidv4(),
            messageId: editMessageId,
            channelId,
            senderId: loginId,
            message: "",
            editType: GlobalMsgCons.surveyText,
            fileList: fileList,
            objUrl: "",
            messageType: GlobalMsgCons.surveyText,
            deviceInfo: getAppInfo(),
            chatSurveyData: {
                question: surveyData.surveyQuestion,
                optionMap: surveyData.surveyOption,
                surveyType: surveyData.surveyType,
                duration: surveyData.totalSeconds,
            },
            userType: GlobalMsgCons.type_user
        };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.editChannelChatSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                if (responseData.response === GlobalMsgCons.success) {
                    editMessageDetails.message = editMessageText;
                    editMessageDetails.fileList = fileList;
                    userChatHistory[editMessageDetails.sendAt].chatMessage = editMessageDetails
                    this.props.setMultipleChatData(channelId, {
                        editMessageId: "",
                        editMessageText: "",
                        editMessageDetails: {},
                        userChatHistory,
                        setEmptyText: !setEmptyText,
                        emojiPicker: false,
                        fileList: [],
                        gifURL: "",
                        surveyModal: false,
                        surveyData: {
                            surveyQuestion: "",
                            surveyOption: { 1: "", 2: "" },
                            surveyType: "single"
                        },
                        surveyAction: "add",
                        chatSurveyTimeData: {
                            ...chatSurveyTimeData, daySelected: 0, hourSelected: 0, minuteSelected: 30,
                        }
                    })
                }
            }
        });

    }
    timeReturn(createdAt) {
        let { activeCompanyDetails, hoursSelected } = this.props;
        return getTimeFormate(createdAt, activeCompanyDetails, hoursSelected)
    }

    getChatSurveyReplyDetails(msgId, channelId, surveyQues, optdata) {
        let postJson = { reqId: getUuidv4(), channelId, msgId };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.chatSurveyReplyDetailsSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, async (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                let result = responseData.result
                let sortData = result;
                sortData = Object.keys(sortData)
                    .map(function (k) { return { key: k, value: sortData[k] }; })
                    .sort(function (a, b) { return b.value.length - a.value.length; });
                this.props.setMultipleChatData(channelId, { surveyPollModal: true, surveyModalData: sortData, surveyQuestionStr: surveyQues, surveyModalOptData: optdata })
            }
        });
    }

    surveyOptionSet() {
        let { chatSurveyTimeData, channelId } = this.props;
        let dayOptions = [];
        for (let i = 0; i <= 31; i++) {
            let label = i.toString().padStart(2, "0");
            dayOptions.push({ label: label, value: i })
        }
        let hourOption = [];
        for (let h = 0; h < 25; h++) {
            let label = h.toString().padStart(2, "0");
            hourOption.push({ label: label, value: h })
        }
        let minuteOption = [];
        for (let m = 0; m < 61; m++) {
            let label = m.toString().padStart(2, "0");
            minuteOption.push({ label: label, value: m })
        }
        chatSurveyTimeData.dayOptions = dayOptions
        chatSurveyTimeData.minuteOption = minuteOption
        chatSurveyTimeData.hourOption = hourOption
        this.props.setMultipleChatData(channelId, { chatSurveyTimeData })

    }
    timeConversion() {
        let { chatSurveyTimeData } = this.props
        let { daySelected, minuteSelected, hourSelected, } = chatSurveyTimeData
        let convDuration = 0;
        if (minuteSelected > 0 && hourSelected === 0 && daySelected == 0) {
            convDuration = 60 * minuteSelected;
        }
        if (hourSelected > 0 && minuteSelected === 0 && daySelected === 0) {
            convDuration = 60 * hourSelected * 60;
        }
        if (daySelected > 0 && minuteSelected === 0 && hourSelected === 0) {
            convDuration = 60 * daySelected * 60 * 24;
        }
        if (minuteSelected > 0 && hourSelected > 0 && daySelected === 0) {
            convDuration = (60 * hourSelected * 24 * 60) + 60 * minuteSelected;
        }
        if (minuteSelected > 0 && hourSelected > 0 && daySelected > 0) {
            convDuration = (60 * daySelected * 60 * 24) + (60 * hourSelected * 60) + (60 * minuteSelected);
        }
        if (daySelected > 0 && hourSelected > 0 && minuteSelected === 0) {
            convDuration = (60 * daySelected * 60 * 24) + (60 * hourSelected * 60);
        }
        if (daySelected > 0 && minuteSelected > 0 && hourSelected === 0) {
            convDuration = (60 * daySelected * 60 * 24) + (60 * minuteSelected);
        }
        return convDuration
    }

    textOnchange = (value) => {
        let { editMessageId, channelId } = this.props;
        if (editMessageId) {
            this.props.setMultipleChatData(channelId, {
                editMessageText: value
            })
        } else {
            this.setChatMessage(value)
        }
    }

    submit = (e) => {
        let { editMessageId, gifURL } = this.props
        if (editMessageId) {
            this.editChannelSubmit(e, gifURL)
        } else {
            this.addChannelChat(e, gifURL)
        }
    }
    hideChatMenu(expandQuill){
        this.setState({expandQuillEditor:expandQuill});
    }
    render() {
        let { guestAuth, fromAdmin, voiceToTextModal, voiceToTextFileType, activeWindow, activeUserType, systemLevelRole, threadData, showMorePeopleModal, channelUser, existChannelMemberIds, userId, companyType, message, editBlink, editMessageDetails, appsLayout, gifURL, messageSound, selectedMessageSound, loginId, channelMuteStatus, addPeopleModal, userListOption, excludeMembers, errors, channelType, userChatHistory, channelMembers, fileList, emojiPicker, gifPicker, currentChannelName, currentResult, previewModal, buildUrl, insertText, insertTextToggle, recording, previewBlob, previewType, recordingModal, time, selectedMessage, editMessageId, editMessageText, infoShow, setEmptyText, changeText, channelMembersDetails, channelId, isFeedPage, path, deactivatedMembersIds, paginateData, controlFeature, surveyModal, surveyData, errorSurveyData, channelMembersTotalCount, surveyAction, surveyPollModal, surveyModalData, surveyQuestionStr, surveyModalOptData, chatSurveyTimeData, fromCustomerSupport, fromCustomerSupportTeam, companyId, callIcon, welcomeModalTxt, selectedAgent, aiAgentDataDomainType, agentOptions } = this.props;
        let { viewFeedback, pageText, typingUserList, messageEmojiPicker, messageEmojiPickerPosition, messageIdEmojiPicker, emojiHoverView, fileDrop, chatMinimized, readMore, readMoreId, processAudioToText, resultAudioToText, welcomeModal, agentDropdown,expandQuillEditor } = this.state;
        let _this = this;
        var dateArray = []
        return <div className="main-content">
            <ReactTooltip place="bottom" type="dark" effect="float" html={true} />
            <form className="chat-form" onSubmit={(e) => {
                if (editMessageId) {
                    this.editChannelSubmit(e, gifURL)
                } else {
                    this.addChannelChat(e, gifURL)
                }
            }} >
                <div className={`chat-window ${activeWindow ? "minimized" : ""} ${chatMinimized ? "chat-minimized" : ""}`} onDragOver={(e) => this.allowDrop(e)} onDragLeave={(e) => this.leaveDrop(e)} onDrop={(e) => this.dropHandler(e)}>
                    {
                        activeWindow && <div className='minimize' data-tip={`${chatMinimized ? "Maximize" : "Minimize"} Chat`} onClick={() => {
                            this.setState({
                                chatMinimized: !chatMinimized
                            })
                        }}>
                            <i className={`fas fa-${chatMinimized ? "chevron-up" : "minus"}`}></i>
                        </div>
                    }
                    {
                        fileDrop && <div className="dragAndDropWindow">
                            <div className="icon">
                                <i className="fas fa-photo-video"></i>
                            </div>
                            <div className="text">Upload to {currentChannelName}</div>
                        </div>
                    }

                    {
                        chatMinimized ? <div className="chat-header">
                            {
                                appsLayout && <div className="chat-header-back"><Link to={path + "/chat"} >
                                    <i className="fa fa-arrow-left" />
                                </Link>
                                </div>
                            }
                            <div className="chat-header-title">
                                # {currentChannelName}
                            </div>
                        </div> : <React.Fragment>
                            <div className="chat-header">
                                {
                                    appsLayout && <div className="chat-header-back"><Link to={path + "/chat"} >
                                        <i className="fa fa-arrow-left" />
                                    </Link>
                                    </div>
                                }
                                <div className="chat-header-title">
                                    # {currentChannelName}
                                </div>
                                <div className="chat-header-extra">
                                    {
                                        <React.Fragment>
                                            <button type="button" className="btn btn-sm btn-success ml-2" onClick={() => {
                                                this.call()
                                            }} disabled={!callIcon && !fromAdmin}>
                                                <i className="fa fa-phone"></i>
                                            </button>
                                        </React.Fragment>
                                    }
                                    {
                                        <button type="button" className="btn btn-sm btn-secondary mx-2" onClick={() => this.toggleInfo()} data-tip={getValidTooltip(pageText, 'sideMenuHideIcon')}>
                                            <i className="fa fa-bars"></i>
                                        </button>
                                    }

                                    <button className="btn btn-sm btn-danger" onClick={() => {
                                        this.props.setCallWindowData("")

                                        let url = MyConstant.keyList.projectUrl + "/guest-thanks"
                                        if (fromCustomerSupportTeam) {
                                            url = MyConstant.keyList.projectUrl + "/customer-support/" + companyId + "/guest-feedback"
                                        } else if (fromCustomerSupport) {
                                            url = MyConstant.keyList.projectUrl + "/customer-support-guest/" + companyId + "/feedback"
                                            if(channelType === GlobalMsgCons.channelTypeEmail) {
                                                url = MyConstant.keyList.projectUrl + "/customer-support-guest/" + companyId +"/mail-templates"
                                            } 
                                        } 
                                        this.props.history.push(url)
                                    }}>
                                        <i className="fa fa-times"></i>
                                    </button>
                                </div>
                            </div>
                            {
                                channelId && channelId !== "nill" && <div className="chat-body">
                                    <div className="chat-body-message" ref={(el) => { this.messageEnd = el; }} onScroll={(e) => this.handleScroll(e)}>
                                        {
                                            (currentResult > 0 && currentResult > Object.keys(userChatHistory).length) ? <div className="load-more-message" onClick={() => this.loadMore()}><span>{getValidLabels(pageText, 'loadMoreMessagesBtnTxt')}</span></div> : null
                                        }
                                        {
                                            userChatHistory && Object.keys(userChatHistory).sort().map(function (ival, i) {
                                                var showLine = false
                                                var datetime = _this.timeStampToDate(new Date(parseInt(ival)))
                                                if (!dateArray.includes(datetime)) {
                                                    dateArray.push(datetime)
                                                    showLine = true
                                                }
                                                var dateView = datetime
                                                var datetime_2 = _this.timeStampToDate(new Date())

                                                if (datetime_2 === datetime) {
                                                    dateView = "Today"
                                                }

                                                let { chatMessage, tempMessageStatus } = userChatHistory[ival]
                                                let userName = userChatHistory[ival].name
                                                let profilePhoto = userChatHistory[ival].userPhoto
                                                let emojiReactionWithMemberNameData = userChatHistory[ival].emojiReactionWithMemberNameData
                                                let userProfilePhoto = ""
                                                if (profilePhoto) {
                                                    let split = profilePhoto.split(".")
                                                    userProfilePhoto = MyConstant.keyList.apiURL + "vp?action=profile&key=" + split[0] + "." + split[1] + "&id=" + chatMessage.userId
                                                }

                                                let { editedAt, messageType, threadsCount, deviceInfo, chatSurveyData } = chatMessage
                                                deviceInfo = deviceInfo ? JSON.parse(deviceInfo) : ""
                                                let message = <ChatMessage
                                                    channelType={channelType}
                                                    channelId={channelId}
                                                    loggedEmail={""}
                                                    requestReceivingChannelId={""}
                                                    newMessage={[]}
                                                    controlFeature={controlFeature}
                                                    grpcClient={_this.props.grpcClient}
                                                    pageText={pageText}
                                                    userChatHistory={userChatHistory}
                                                    ival={ival}
                                                    callStatus={(callStatus, message) => _this.callStatus(callStatus, message)}
                                                    joinCall={(chatMessage) => _this.joinCall(chatMessage)}
                                                    declineCall={(chatMessage) => _this.declineCall(chatMessage)}
                                                    updateMeetingDetails={(meetingId, messageId, status) => _this.updateMeetingDetails(meetingId, messageId, status)}
                                                    joinMeetingCall={(chatMessage) => _this.joinMeetingCall(chatMessage)}
                                                    chatFileView={(ival) => _this.chatFileView(ival)}
                                                    replyChatSurveyQuestion={(id, jval, chatMessage) => _this.replyChatSurveyQuestion(id, jval, chatMessage)}
                                                    getChatSurveyReplyDetails={(id, channelId, question, optdata, userName) => _this.getChatSurveyReplyDetails(id, channelId, question, optdata, userName)}
                                                    scrollBottom={() => _this.scrollBottom()}
                                                    getCommunityServiceFormValue={(formType, formValue, createdBy) => _this.getCommunityServiceFormValue(formType, formValue, createdBy)}
                                                    sendCommunityServiceEvent={(data, type, id) => _this.sendCommunityServiceEvent(data, type, id)}
                                                    openApps={(name, url) => _this.props.openApps(name, url)}
                                                    setMultipleChatData={(channelId, value) => {
                                                        _this.props.setMultipleChatData(channelId, value)
                                                    }}
                                                    communityMemberViewHistory={(caseNo) => {
                                                        _this.props.setMultipleChatData(channelId, {
                                                            communityHistoryModal: true,
                                                            communityCaseNo: caseNo
                                                        })
                                                        _this.getCommunityHistoryData(caseNo)

                                                    }}
                                                    history={_this.props.history}
                                                    callAction={(chatmsgid) => {
                                                        _this.call("call-meeting", chatmsgid)
                                                    }}
                                                    chatMsgCallButton={_this.state.chatMsgCallButton}
                                                    fromCustomerSupport={fromCustomerSupport}
                                                    fromCustomerSupportTeam={fromCustomerSupportTeam}
                                                />

                                                let emojiList = [];
                                                if (emojiReactionWithMemberNameData) {
                                                    Object.keys(emojiReactionWithMemberNameData).map((emoji) => {
                                                        if (emojiReactionWithMemberNameData[emoji].length > 0) {
                                                            emojiList.push({
                                                                icon: emoji,
                                                                count: emojiReactionWithMemberNameData[emoji].length,
                                                                name: emojiReactionWithMemberNameData[emoji]
                                                            })
                                                        }
                                                    })
                                                }

                                                let checkUserDetailsDisplay = _this.checkUserDetailsDisplay(chatMessage, i, "live")
                                                let surveyEditValidate = false;
                                                if (messageType === GlobalMsgCons.surveyText && typeof (chatMessage.chatSurveyData.responseData) !== "undefined" && Object.values(chatMessage.chatSurveyData.responseData).length > 0) {
                                                    surveyEditValidate = true;
                                                }

                                                return <div key={i} id={ival}>
                                                    {showLine && <div className="" style={{ padding: 2, marginBottom: 5, marginTop: 5 }}>
                                                        <div className="date-bg-line" style={{}} >
                                                            <span className="date-bg-color">{dateView}</span>
                                                        </div>
                                                    </div>}
                                                    <div key={ival} className={`message-container p-1 ${chatMessage.userId === loginId ? "receiver" : "sender"}`}
                                                        onMouseEnter={() => {
                                                            if ((typeof messageType === "undefined" || (messageType !== "call" && messageType !== "calender" && messageType !== "meeting" && messageType !== "meeting-notification"))) {
                                                                _this.props.setMultipleChatData(channelId, {
                                                                    selectedMessage: chatMessage.id,
                                                                })
                                                            }

                                                        }
                                                        }
                                                        onMouseLeave={() => {
                                                            if (!messageEmojiPicker) {
                                                                _this.props.setMultipleChatData(channelId, {
                                                                    selectedMessage: "",
                                                                })
                                                            }
                                                        }
                                                        }>
                                                        {
                                                            checkUserDetailsDisplay ? <div className="user-icon">
                                                                {
                                                                    (userProfilePhoto && userProfilePhoto != "") ? <img className="name-bg" src={userProfilePhoto} alt="no img" /> : <div className="name-bg" style={{ backgroundColor: color[userName !== "" ? userName[0].toUpperCase() : "A"] }}>{userName !== "" ? userName[0].toUpperCase() : ""}</div>
                                                                }
                                                            </div> : <>
                                                                <div className="empty-user-icon">
                                                                </div>
                                                                {/* <span className="time-show">{_this.timeReturn(chatMessage.createdAt)}</span> */}
                                                            </>
                                                        }

                                                        <div className="message mx-2">
                                                            <div className="user-details">
                                                                <div className="user">
                                                                    {
                                                                        checkUserDetailsDisplay && <React.Fragment>
                                                                            <b className="mr-2 uppercase">{userName}</b>
                                                                            <span className="time-info">{_this.humanDateFormat(chatMessage.createdAt)}</span>
                                                                            {
                                                                                (checkActiveAdmin(activeUserType, systemLevelRole) && deviceInfo) && <span className="device-info">{deviceInfo.appPlatform}</span>
                                                                            }
                                                                            {
                                                                                tempMessageStatus === "loading" && <span className="spinner-border spinner-border-sm text-primary" role="status">
                                                                                    <span className="sr-only">Loading...</span>
                                                                                </span>
                                                                            }
                                                                            {
                                                                                tempMessageStatus === "failed" && <span style={{ color: "#E91E63" }}>
                                                                                    <i className="fas fa-exclamation-triangle"></i>
                                                                                </span>
                                                                            }
                                                                        </React.Fragment>
                                                                    }
                                                                    {
                                                                        typeof (chatSurveyData) !== "undefined" &&
                                                                        <span className="p-2">
                                                                            <i className="fas fa-poll-h"></i>
                                                                        </span>
                                                                    }
                                                                </div>
                                                                <div className="options">
                                                                    {
                                                                        selectedMessage === chatMessage.id && <div className="options-container">
                                                                            {
                                                                                <React.Fragment>
                                                                                    <div className="option" onClick={() => _this.updateEmoJiReactionForMsg(chatMessage.id, "2705")} data-tip={getValidTooltip(pageText, 'completedEmojiTxt')}>
                                                                                        &#x2705;
                                                                                    </div>
                                                                                    <div className="option" onClick={() => _this.updateEmoJiReactionForMsg(chatMessage.id, "1f64c")} data-tip={getValidTooltip(pageText, 'nicelyDoneEmojiTxt')}>
                                                                                        &#x1f64c;
                                                                                    </div>
                                                                                    <div className="option" onClick={() => _this.updateEmoJiReactionForMsg(chatMessage.id, "1f440")} data-tip={getValidTooltip(pageText, 'takingaLookEmojiTxt')}>
                                                                                        &#x1f440;
                                                                                    </div>
                                                                                    <div className="option emoji-from-picker" onClick={(e) => {
                                                                                        let { clientX, clientY } = e;
                                                                                        _this.setState({
                                                                                            messageEmojiPicker: true,
                                                                                            messageIdEmojiPicker: chatMessage.id,
                                                                                            messageEmojiPickerPosition: {
                                                                                                clientX,
                                                                                                clientY
                                                                                            }
                                                                                        })
                                                                                    }} data-tip={getValidTooltip(pageText, 'addMoreEmojiTxt')}>
                                                                                        <i className="far fa-smile"></i>
                                                                                    </div>
                                                                                    {/* <div className="option" onClick={() => _this.openThread(userChatHistory[ival])} data-tip={getValidTooltip(pageText, 'replyinThreadTooltipTxt')}>
                                                                                        <i className="fas fa-comment-dots"></i>
                                                                                    </div> */}
                                                                                    {
                                                                                        (chatMessage.userId === loginId && editMessageId !== chatMessage.id) && <React.Fragment>
                                                                                            <ReactTooltip place="bottom" type="dark" effect="float" html={true} />
                                                                                            <div className={"option " + (surveyEditValidate ? "disable" : "")} onClick={() => {
                                                                                                _this.edit(chatMessage)

                                                                                            }} data-tip={getValidTooltip(pageText, 'editBtnTxt')}>{getValidLabels(pageText, 'editBtnTxt')}</div>
                                                                                            <div className="option" onClick={() => {
                                                                                                let responseChoose = window.confirm(getValidLabels(pageText, 'deletethisAlert_Msg'))
                                                                                                if (responseChoose) {
                                                                                                    _this.deleteChatMessage(chatMessage.id, ival)
                                                                                                }
                                                                                            }} data-tip={getValidTooltip(pageText, 'deleteBtnTxt')}>{getValidLabels(pageText, 'deleteBtnTxt')}</div>
                                                                                            {
                                                                                                tempMessageStatus === "failed" && <div className="option" onClick={() => {
                                                                                                    _this.retryChatMessage(userChatHistory[ival])
                                                                                                }} data-tip={"Retry"}><i className="fas fa-redo"></i></div>
                                                                                            }
                                                                                        </React.Fragment>
                                                                                    }
                                                                                </React.Fragment>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="message-box">
                                                                {
                                                                    message
                                                                }
                                                                {
                                                                    threadsCount && <div onClick={() => _this.openThread(userChatHistory[ival])} className="thread-count">{`${threadsCount} ${threadsCount > 1 ? "replies" : "reply"}`}</div>
                                                                }
                                                                {
                                                                    emojiList.length > 0 && <div className="emoji-reaction">
                                                                        {
                                                                            emojiList.map((item, index) => {
                                                                                return <div key={index} className="emoji-reaction-item" onClick={() => _this.updateEmoJiReactionForMsg(chatMessage.id, item.icon)}
                                                                                    onMouseEnter={(e) => {
                                                                                        let rect = e.currentTarget.getBoundingClientRect()
                                                                                        let clientX = rect.left + rect.width / 2;
                                                                                        let clientY = rect.top + rect.height + 5;
                                                                                        _this.setState({
                                                                                            emojiHoverView: { id: chatMessage.id, icon: item.icon, clientX, clientY }
                                                                                        })
                                                                                    }}
                                                                                    onMouseLeave={() => {
                                                                                        _this.setState({
                                                                                            emojiHoverView: ""
                                                                                        })
                                                                                    }}>
                                                                                    <div className="icon">{String.fromCodePoint("0x" + item.icon)}</div>
                                                                                    <div className="count">{item.count}</div>
                                                                                    {
                                                                                        (chatMessage.id === emojiHoverView.id && item.icon === emojiHoverView.icon) && <div className="names" style={{ position: "fixed", top: emojiHoverView.clientY, left: emojiHoverView.clientX }}>{item.name.join(", ")}</div>
                                                                                    }

                                                                                </div>
                                                                            })
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                    {
                                        infoShow && <React.Fragment>
                                            <div className={`chat-body-info ${infoShow ? "open" : ""}`}>
                                                <React.Fragment>
                                                    <div className="chat-body-info-header d-flex">
                                                        <div style={{ flex: 1 }} data-tip={getValidTooltip(pageText, 'peopleHeadingTxt')}>{((fromAdmin || fromCustomerSupport)) ? getValidLabels(pageText, 'peopleHeadingTxt') : "Participants"}</div>
                                                    </div>
                                                    <div className="chat-body-info-body">
                                                        {
                                                            channelMembers.map((member, memberIndex) => {
                                                                let showType = `${member.roleType ? (equalEducation(companyType) ? memberTypeReturn(member.roleType) : companyMemberType(member.roleType)) : "Admin"}`
                                                                showType = member.userId === loginId ? "(You are" + " " + showType + ")" : "(" + showType + ")"
                                                                let { status } = member;
                                                                return <div className={`member ${status}`} key={memberIndex}>
                                                                    <div className="icon">
                                                                        {paginateData.initialStatusList && paginateData.initialStatusList[member.userId] ? this.convertStatus(paginateData.initialStatusList[member.userId]) : ""}
                                                                    </div>
                                                                    <div className="name">
                                                                        {member.firstName}
                                                                        {paginateData.initialStatusList && paginateData.initialStatusList[member.userId] ? this.convertCallStatus(paginateData.initialStatusList[member.userId]) : ""}
                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                        {
                                                            <div className="my-3 text-center">
                                                                <button type="button" className="btn btn-primary btn-sm m-1" onClick={() => this.props.setMultipleChatData(channelId, {
                                                                    showMorePeopleModal: true
                                                                })} data-tip={getValidTooltip(pageText, 'viewMoreBtnTxt')}>
                                                                    {getValidLabels(pageText, 'viewMoreBtnTxt')}
                                                                </button>
                                                                {
                                                                    channelType === GlobalMsgCons.channelType1 && <button type="button" className="btn btn-success btn-sm m-1" onClick={() => {
                                                                        let excludeMembers = [];
                                                                        userListOption.map((item) => {
                                                                            if (channelMembersDetails[item.value] === undefined) {
                                                                                excludeMembers.push(item)
                                                                            }
                                                                        })
                                                                        this.props.setMultipleChatData(channelId, {
                                                                            excludeMembers,
                                                                            addPeopleModal: true
                                                                        })
                                                                    }} data-tip={getValidTooltip(pageText, 'addPeopleText')}>+ {getValidLabels(pageText, 'addPeopleText')}</button>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </React.Fragment>
                                                {
                                                    fromCustomerSupport && <React.Fragment>
                                                        <div className="chat-body-info-header d-flex">
                                                            <div style={{ flex: 1 }}>{"Feedback"}</div>
                                                        </div>
                                                        <div className="chat-body-info-body">
                                                            <div className="text-center text-center mx-5 my-2">
                                                                <button className="btn btn-block btn-sm btn-success" onClick={() => {
                                                                    this.setState({
                                                                        viewFeedback: true
                                                                    })
                                                                }}>View</button>
                                                            </div>
                                                        </div>
                                                        {
                                                            viewFeedback && <ViewFeedback
                                                                visible={viewFeedback}
                                                                closeModal={() => {
                                                                    this.setState({
                                                                        viewFeedback: false
                                                                    })
                                                                }}
                                                                viewData={""}
                                                                grpcClient={this.props.grpcClient}
                                                                searchGrpcClient={this.props.searchGrpcClient}
                                                                auth={guestAuth}
                                                                feedbackId={channelId}
                                                            />
                                                        }

                                                        {
                                                            <React.Fragment>
                                                                <div className="chat-body-info-header d-flex">
                                                                    <div style={{ flex: 1 }}>{"Docs"}</div>
                                                                </div>
                                                                <div className="chat-body-info-body">
                                                                    <div className="mx-5">
                                                                        <CreateFeedbackDocs
                                                                            grpcClient={this.props.grpcClient}
                                                                            docsGrpcClient={this.props.docsGrpcClient}
                                                                            history={this.props.history}
                                                                            path={this.props.path}
                                                                            auth={guestAuth}
                                                                            feedbackId={channelId}
                                                                            fromAdmin={fromAdmin}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="chat-body-info-header d-flex">
                                                                    <div style={{ flex: 1 }}>{"Whiteboard"}</div>
                                                                </div>
                                                                <div className="chat-body-info-body mb-5">
                                                                    <div className="mx-5">
                                                                        <CreateFeedbackWhiteboard
                                                                            grpcClient={this.props.grpcClient}
                                                                            docsGrpcClient={this.props.docsGrpcClient}
                                                                            history={this.props.history}
                                                                            path={this.props.path}
                                                                            auth={guestAuth}
                                                                            feedbackId={channelId}
                                                                            fromAdmin={fromAdmin}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                        }

                                                    </React.Fragment>
                                                }
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>}

                            {
                            (channelType !== GlobalMsgCons.channelTypeEmail && channelId && channelId !== "nill" && !deactivatedMembersIds.includes(this.props.userId)) && <div className="chat-input-container">
                                <div className="chat-input-container-element">

                                    {
                                        emojiPicker && <div className="chat-smiley">
                                            <Picker
                                                pickerStyle={{ width: '100%', height: 300 }}
                                                disableSkinTonePicker={true}
                                                onEmojiClick={(event, emojiObject) => this.onEmojiClick(event, emojiObject)}
                                            />
                                        </div>
                                    }
                                    {
                                        gifPicker && <div className="chat-gif">
                                            <GifPicker select={(e, url) => {
                                                this.props.setMultipleChatData(channelId, {
                                                    gifPicker: false,
                                                    gifURL: url
                                                })
                                                if (editMessageId) {
                                                    this.editChannelSubmit(e, url)
                                                } else {
                                                    this.addChannelChat(e, url)
                                                }
                                            }} />
                                        </div>
                                    }
                                    <div className="">
                                        {
                                            <CustomQuillEditor
                                                editorFocused={(e) => {
                                                    this.props.setMultipleChatData(channelId, {
                                                        emojiPicker: false,
                                                        gifPicker: false,
                                                        surveyModal: false,
                                                    })
                                                }}
                                                editorClassName={editBlink ? "editor-blink" : ""}
                                                pagename={"chat"}
                                                updateChatTypingInfo={(event) => this.updateChatTypingInfo(event)}
                                                insertText={insertText}
                                                insertTextToggle={insertTextToggle}
                                                setEmptyText={setEmptyText}
                                                text={editMessageId ? editMessageText : message}
                                                changeText={changeText}
                                                textOnchange={this.textOnchange}
                                                submit={this.submit}
                                                channelId={channelId}
                                                hideChatMenu={(e)=>{this.hideChatMenu(e)}}
                                            />
                                        }
                                    </div>
                                    {
                                        fileList.length > 0 && <div className="chat-files">
                                            <div className="row mx-0">
                                                {
                                                    fileList.map((file, i) => {
                                                        return file.file instanceof File ? <div className="col-2" key={i}>
                                                            <div className="chat-file-holder" title={file.origFileName} onClick={() => {
                                                                let preview = { filePath: file.fileUrl, fileType: file.fileType }
                                                                _this.props.setMultipleChatData(channelId, {
                                                                    previewBlob: preview,
                                                                    previewType: "blob",
                                                                    previewModal: true
                                                                })
                                                            }}>
                                                                {file.uploadProcess === "pending" && <div className="spin-delete" onClick={(e) => {
                                                                    this.fileDelete(e, i)
                                                                }}><i className="fa fa-times" /></div>}
                                                                {file.uploadProcess === "processing" && <div className="spin-loader"></div>}
                                                                {
                                                                    this.fileIcon(file.fileUrl, file.fileType)
                                                                }
                                                                {
                                                                    <span>{file.processType === "chat-record" ? "Record" : file.origFileName}</span>
                                                                }
                                                            </div>
                                                        </div> : <div className="col-2" key={i}>
                                                            <div className="chat-file-holder">
                                                                <div className="spin-delete" onClick={(e) => {
                                                                    this.fileDelete(e, i)
                                                                }} ><i className="fa fa-times" /></div>
                                                                {_this.chatEditFileView(file)}
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                   {!expandQuillEditor&&<div className="chat-input-extra">
                                        <div style={{ display: 'flex', flex: 1 }}>
                                            <div className="icon" data-tip={getValidTooltip(pageText, 'attchmentIconTxt')}>
                                                <input className="file-input-hidden" type="file" accept="image/*,video/*,.csv,application/pdf,application/vnd.ms-excel,.doc,.docx,.json" onChange={(e) => this.fileChange(e.target.files)} />
                                                <i className="fa fa-plus" ></i>
                                            </div>
                                            <div className={`icon ${recording ? "record" : ""}`} onClick={() => {
                                                this.props.setMultipleChatData(channelId, {
                                                    recordingModal: true,
                                                    surveyModal: false,
                                                })
                                            }} data-tip={getValidTooltip(pageText, 'audioRecIconTxt')}>
                                                <i className="fa fa-microphone"></i>
                                            </div>
                                            <div className={`icon ${emojiPicker ? "active" : ""}`} onClick={() => {
                                                this.props.setMultipleChatData(channelId, {
                                                    gifPicker: false,
                                                    emojiPicker: !emojiPicker,
                                                    surveyModal: false,
                                                })
                                            }} data-tip={getValidTooltip(pageText, 'smileIconTxt')}>
                                                <i className="fa fa-smile"></i>
                                            </div>
                                            <div className={`icon ${gifPicker ? "active" : ""}`} style={{ fontWeight: 'bold' }} onClick={() => {
                                                this.props.setMultipleChatData(channelId, {
                                                    emojiPicker: false,
                                                    gifPicker: !gifPicker,
                                                    surveyModal: false,
                                                })
                                            }} data-tip={getValidTooltip(pageText, 'gifIconTxt')}>
                                                {getValidLabels(pageText, 'gIFLabelTxt')}
                                            </div>

                                            <div className={`icon ${surveyModal ? "active" : ""}`} onClick={async () => {
                                                await this.props.setMultipleChatData(channelId, {
                                                    surveyModal: true,
                                                    gifPicker: false,
                                                    emojiPicker: false,

                                                })
                                            }} data-tip={"Create survey"}>
                                                <i className="fas fa-poll-h"></i>
                                            </div>
                                            <div className={`icon ${voiceToTextModal ? "active" : ""}`} onClick={() => {
                                                this.setState({
                                                    processAudioToText: ""
                                                }, async () => {
                                                    await this.props.setMultipleChatData(channelId, {
                                                        voiceToTextModal: true,
                                                        gifPicker: false,
                                                        emojiPicker: false,
                                                    })
                                                })
                                            }} data-tip={"Audio To Text"}>
                                                <i className="fas fa-file-audio"></i>
                                            </div>
                                            {
                                                (fromCustomerSupport && !fromCustomerSupportTeam && workflowUsers.includes(loginId)) && <div className={`icon agent-modal ${agentDropdown ? "active" : ""}`} onClick={() => {
                                                    this.setState({
                                                        agentDropdown: !agentDropdown
                                                    })
                                                }}>
                                                    <div className="agent-selection-box d-flex align-items-center">
                                                        <i className="fas fa-robot"></i>
                                                        <div className="flex-1 px-2 agent-selection-text">{agentOptions.length > 0 && agentOptions.filter((e) => { return e.value === selectedAgent })[0].label} </div>
                                                        {
                                                            selectedAgent && <span className="remove-agent" onClick={(e) => {
                                                                e.stopPropagation();
                                                                this.props.setMultipleChatData(channelId, {
                                                                    selectedAgent: ""
                                                                })
                                                            }}><i className="fas fa-times"></i></span>
                                                        }
                                                    </div>
                                                    <div className="agent-modal-select" style={{ height: 60, top: -60 }}>
                                                        {
                                                            agentOptions.map((agent, index) => {
                                                                return <div key={index} className="modal-data" onClick={(e) => {
                                                                    this.props.setMultipleChatData(channelId, {
                                                                        selectedAgent: agent.value
                                                                    })
                                                                }}>
                                                                    {agent.label}
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="d-flex">
                                            <button className="message-submit-button reset" type="button" onClick={() => {
                                                this.props.setMultipleChatData(channelId, {
                                                    editMessageId: "",
                                                    editMessageText: "",
                                                    editMessageDetails: {},
                                                    userChatHistory,
                                                    setEmptyText: !setEmptyText,
                                                    emojiPicker: false,
                                                    fileList: [],
                                                    gifURL: ""
                                                })
                                            }} data-tip={getValidTooltip(pageText, 'resetTypeIconTxt')}><i className="fa fa-times"></i></button>
                                            <button className="message-submit-button" type="submit" data-tip={getValidTooltip(pageText, 'sendIconTxt')}><i className="fa fa-paper-plane"></i></button>
                                        </div>
                                    </div>
                            }
                                </div>
                            </div>}
                            <div className="typing">
                                {
                                    typingUserList.length > 0 && <div data-tip={typingUserList.join(" and ") + getValidTooltip(pageText, 'isTypingTxt')}>{typingUserList.join(" and ")} {getValidLabels(pageText, 'isTypingTxt')}...</div>
                                }
                            </div>
                        </React.Fragment>
                    }



                    {channelId && channelId === "nill" && <p style={{ margin: "auto", fontSize: 30, fontWeight: "bold" }}> {getValidLabels(pageText, 'feedIdMissingTxt')}</p>}

                </div>
            </form>

            {
                messageEmojiPicker && <div className="emoji-message" style={{ top: messageEmojiPickerPosition.clientY, left: messageEmojiPickerPosition.clientX }}>
                    <Picker
                        pickerStyle={{ width: 300, height: 300 }}
                        disableSkinTonePicker={true}
                        onEmojiClick={(event, emojiObject) => {
                            _this.setState({
                                messageEmojiPicker: false
                            }, () => {
                                _this.updateEmoJiReactionForMsg(messageIdEmojiPicker, emojiObject.unified)
                            })
                        }}
                    />
                </div>
            }


            {
                previewModal && <Modal
                    visible={previewModal}
                    size={"modal-xl"}
                    closeModal={() => this.props.setMultipleChatData(channelId, {
                        previewModal: false,
                        viewModal: true,
                        previewBlob: {},
                        previewType: "",
                    })}
                    heading={getValidLabels(pageText, 'previewHeaderTxt')}
                    body={<div style={{ minHeight: "75vh" }}>
                        <div className="row">
                            <div className="col-1">
                            </div>
                            <div className="col-10">
                                {
                                    previewType === "blob" ? this.previewBlobContent() : this.previewContent(buildUrl)
                                }
                            </div>
                            <div className="col-1">
                            </div>
                        </div>

                    </div>
                    }
                />
            }

            {
                recordingModal && <Modal
                    visible={recordingModal}
                    closeModal={() => this.props.setMultipleChatData(channelId, {
                        recordingModal: false
                    })}
                    heading={getValidLabels(pageText, 'recordHeaderTxt')}
                    body={
                        <Recorder
                            saveAudio={this.saveAudio} />
                    }
                />
            }
            {
                voiceToTextModal && <Modal
                    visible={voiceToTextModal}
                    closeModal={() => {
                        this.props.setMultipleChatData(channelId, {
                            voiceToTextModal: false
                        })
                    }}
                    heading={"Audio to Text"}
                    body={<div>
                        {
                            processAudioToText ? <ProcessAudioToText
                                processAudioToText={processAudioToText}
                                resultAudioToText={resultAudioToText}
                                addTextToChat={(message) => {
                                    this.props.setMultipleChatData(channelId, {
                                        changeText: message,
                                        voiceToTextModal: false
                                    })
                                }} /> : <React.Fragment>
                                <div className="tabs">
                                    <div className={`tab ${voiceToTextFileType === "record" ? "active" : ""}`} onClick={() => this.props.setMultipleChatData(channelId, {
                                        voiceToTextFileType: "record"
                                    })
                                    }>
                                        Record
                                    </div>
                                    <div className={`tab ${voiceToTextFileType === "file" ? "active" : ""}`} onClick={() => this.props.setMultipleChatData(channelId, {
                                        voiceToTextFileType: "file"
                                    })
                                    }>
                                        File
                                    </div>
                                </div>
                                {
                                    voiceToTextFileType === "record" ? <Recorder maxTime={10000} saveAudio={this.processAudioToText} /> : <VoiceToText saveAudio={this.processAudioToText} />
                                }
                            </React.Fragment>
                        }
                    </div>
                    }
                />
            }

            {
                showMorePeopleModal && <Modal
                    visible={showMorePeopleModal}
                    closeModal={() => {
                        paginateData.pagination = [];
                        paginateData.noOfPage = 1;
                        paginateData.prev = false;
                        paginateData.next = false;
                        paginateData.processing = false;
                        paginateData.tableData = {};
                        paginateData.paginateStatusList = {};
                        this.props.setMultipleChatData(channelId, {
                            showMorePeopleModal: false,
                            paginateData
                        })
                        this.getChannelMembersWithPaginate(channelId, paginateData);
                    }}
                    size={"modal-xl"}
                    heading={((fromAdmin || fromCustomerSupport)) ? "People" : "Participants"}
                    body={<div>
                        <div className={`row ${paginateData.processing && "table-processing"}`}>
                            {paginateData.processing && <div className="processing"><span>{"Processing"}...</span></div>}
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">{"First Name"}</th>
                                                <th scope="col">{"Last Name"}</th>
                                                <th scope="col">{"Email"}</th>
                                                <th scope="col">{"Status"}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Object.keys(paginateData.tableData).length > 0 && Object.keys(paginateData.tableData).map((key, index) => {
                                                    let { firstName, lastName, email, userId } = paginateData.tableData[key];
                                                    let { paginateStatusList } = paginateData
                                                    return (<tr key={index}>
                                                        <td className="moreview_fname">{firstName}</td>
                                                        <td className="moreview_fname">{lastName}</td>
                                                        <td>{email}</td>
                                                        <td>{this.convertStatus(paginateStatusList[userId])}
                                                            {this.convertCallStatus(paginateStatusList[userId])}
                                                        </td>
                                                    </tr>)
                                                })
                                            }

                                        </tbody>
                                    </table>
                                </div>
                                <Pagination
                                    pagination={paginateData.pagination}
                                    currentPage={paginateData.noOfPage}
                                    prev={paginateData.prev}
                                    next={paginateData.next}
                                    movePage={(type) => {
                                        this.movePageStatus(type)
                                    }} />
                            </div>
                        </div>

                    </div>
                    }
                />
            }

            {
                addPeopleModal && <Modal
                    visible={addPeopleModal}
                    closeModal={() => this.props.setMultipleChatData(channelId, {
                        addPeopleModal: false
                    })}
                    heading={"Add People"}
                    body={<div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label>{"Select Users"}</label>
                                <SearchDropdown
                                    selectedOption={channelUser}
                                    onChange={(e) => {
                                        this.setUserSelected(e)
                                    }}
                                    isDisabled={false}
                                    isMulti={true}
                                    placeholder={"Select Users"}
                                    error={errors['channelUser'] ? true : false}
                                    buildOptions={(e) => {
                                        let options = [];
                                        e.forEach((option) => {
                                            option = searchDropdownOptionConvert(option)
                                            if (option.userId !== userId && !existChannelMemberIds.includes(option.userId)) {
                                                options.push({ value: option.userId, label: option.firstName + " " + option.lastName + " " + "(" + option.email + ")", disabled: false })
                                            }
                                        })
                                        return options
                                    }}
                                    grpcClient={this.props.grpcClient}
                                    searchGrpcClient={this.props.searchGrpcClient}
                                    parentId={GlobalSearchDataTypes.User}
                                    cacheDataType={GlobalSearchDataTypes.User}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                                {(typeof errors['channelUser'] != "undefined" && errors['channelUser'] != "") ? <div className="custom-invalid-feedback">{errors['channelUser']}</div> : null}
                            </div>
                        </div>
                    </div>}
                    footer={<React.Fragment>
                        <button type="button" className="btn theme-bg" onClick={() => this.channelSubmit()}>Save</button>
                    </React.Fragment>}
                />
            }
            {
                surveyModal && <Modal
                    visible={surveyModal}
                    size={"modal-xl"}
                    closeModal={() => this.props.setMultipleChatData(channelId, {
                        surveyModal: false,
                        surveyData: {
                            surveyQuestion: "",
                            surveyOption: { 1: "", 2: "" },
                            surveyType: "single"
                        },
                        surveyAction: "add",
                        editMessageId: "",
                        editMessageDetails: {},
                        editMessageText: "",
                        chatSurveyTimeData: {
                            ...chatSurveyTimeData, daySelected: 0, hourSelected: 0, minuteSelected: 30,
                        }
                    })}
                    heading={surveyAction === "add" ? "Create" : "Edit" + " survey"}
                    body={<div style={{ minHeight: "75vh" }}>

                        {errorSurveyData['duration'] && errorSurveyData['duration'] !== "" && <div style={{ color: "red", fontSize: 12 }}>{errorSurveyData['duration']}</div>}
                        <div className="row">
                            <div className="col-4">
                                <label>Survey Type</label>
                                <DropDown
                                    options={MyConfig.surveyOptions}
                                    isSearchable={true}
                                    placeholder={"Survey Type"}
                                    onChange={(e) => {
                                        surveyData.surveyType = e.value;
                                        this.props.setMultipleChatData(channelId, {
                                            surveyData
                                        })
                                    }}
                                    selectedOption={MyConfig.surveyOptions.filter((val => { return val.value.toString() === surveyData.surveyType.toString() }))}
                                    isMulti={false}
                                />

                                <div className="mt-3" style={{ display: "flex", flexDirection: "row" }}>
                                    <label > How long should the survey be open?</label><br />
                                </div>
                                <div className='mb-3' style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ flex: 1, padding: 5 }}>
                                        <label >Day</label>
                                        <DropDown
                                            selectedOption={chatSurveyTimeData.dayOptions.filter((e) => { return e.value === chatSurveyTimeData.daySelected })}
                                            onChange={(e) => {
                                                chatSurveyTimeData.daySelected = e.value
                                                this.props.setMultipleChatData(channelId, { daySelected: e.value })
                                            }}
                                            options={chatSurveyTimeData.dayOptions}
                                            isDisabled={surveyAction === "edit"}
                                        />
                                    </div>
                                    <div style={{ flex: 1, padding: 5 }}>
                                        <label >Hour</label>
                                        <DropDown
                                            selectedOption={chatSurveyTimeData.hourOption.filter((e) => { return e.value === chatSurveyTimeData.hourSelected })}
                                            onChange={(e) => {
                                                chatSurveyTimeData.hourSelected = e.value
                                                this.props.setMultipleChatData(channelId, { chatSurveyTimeData })
                                            }}
                                            options={chatSurveyTimeData.hourOption}
                                            isDisabled={surveyAction === "edit"}
                                        />
                                    </div>
                                    <div style={{ flex: 1, padding: 5 }}>
                                        <label >Minute</label>
                                        <DropDown
                                            selectedOption={chatSurveyTimeData.minuteOption.filter((e) => { return e.value === chatSurveyTimeData.minuteSelected })}
                                            onChange={(e) => {
                                                chatSurveyTimeData.minuteSelected = e.value
                                                this.props.setMultipleChatData(channelId, { chatSurveyTimeData })
                                            }}
                                            options={chatSurveyTimeData.minuteOption}
                                            isDisabled={surveyAction === "edit"}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-8">
                                <label>Question</label>
                                <div className="form-group linebr">
                                    <CustomEditor
                                        title={"Question"}
                                        text={surveyData.surveyQuestion}
                                        textOnchange={(e) => {
                                            surveyData.surveyQuestion = e;
                                            this.props.setMultipleChatData(channelId, {
                                                surveyData
                                            })
                                        }}
                                    />
                                    {errorSurveyData['surveyQuestion'] && errorSurveyData['surveyQuestion'] !== "" && <div style={{ color: "red", fontSize: 12 }}>{errorSurveyData['surveyQuestion']}</div>}
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3">
                            {Object.keys(surveyData.surveyOption).map((sId, i) => {
                                let iop = surveyData.surveyOption[sId];
                                return <>
                                    <div className="col-4" key={i}>
                                        <label>Option - {i + 1}</label>
                                        {Object.keys(surveyData.surveyOption).length > 2 && <span style={{ color: "red", float: "right", cursor: "pointer" }} onClick={async () => {
                                            let filterOptions = surveyData.surveyOption;
                                            let newObj = {};
                                            let currentValues = Object.keys(filterOptions);
                                            currentValues = currentValues.filter((e) => { return sId !== e })
                                            Promise.all(
                                                currentValues.map((jval, j) => {
                                                    let objValue = filterOptions[jval];
                                                    newObj[j + 1] = objValue;
                                                })
                                            )
                                            surveyData.surveyOption = newObj;
                                            await this.props.setMultipleChatData(channelId, {
                                                surveyData
                                            })
                                        }}>
                                            <i className="fa fa-trash" aria-hidden="true"></i>
                                        </span>}

                                        <div className="form-group linebr">
                                            <CustomEditor
                                                title={"Question"}
                                                text={iop}
                                                textOnchange={(e) => {
                                                    surveyData.surveyOption[sId] = e;
                                                    this.props.setMultipleChatData(channelId, {
                                                        surveyData
                                                    })
                                                }}
                                                changeText={iop}
                                            />
                                            {errorSurveyData['surveyOption'] && errorSurveyData['surveyOption'][i] && errorSurveyData['surveyOption'][i] && errorSurveyData['surveyOption'][i] !== "" && <div style={{ color: "red", fontSize: 12 }}>{errorSurveyData['surveyOption'][i]}</div>}
                                        </div>
                                    </div></>
                            })
                            }
                        </div>
                        <div className="row mt-3">
                            <div className="col-4">
                            </div>
                            <div className="col-2">
                                <button className="btn btn-primary" onClick={async () => {
                                    this.surveyValidation()
                                }} >
                                    {surveyAction == "add" ? "Create" : "Update"}

                                </button>
                            </div>
                            <div className="col-2">
                                <button className="btn btn-secondary" onClick={() => {
                                    surveyData.surveyOption[Object.keys(surveyData.surveyOption).length + 1] = "";
                                    this.props.setMultipleChatData(channelId, {
                                        surveyData
                                    })
                                }} >
                                    Add option
                                </button>
                            </div>
                        </div>
                    </div>
                    }
                />
            }
            {
                surveyPollModal && <Modal
                    visible={surveyPollModal}
                    size={"modal-md"}
                    closeModal={() => this.props.setMultipleChatData(channelId, {
                        surveyPollModal: false,
                    })}
                    heading={"Survey details"}
                    body={<div style={{ minHeight: "75vh" }}>
                        <h5 dangerouslySetInnerHTML={{ __html: surveyQuestionStr }} />
                        {surveyModalData && surveyModalData.map((data) => {
                            let userSurveyData = data.value
                            return <><div className="row mt-2">
                                <div className="surveyModal">
                                    <div className="col-sm-9">
                                        <div className="optionSurveyMd mt-3 mx-2" dangerouslySetInnerHTML={{ __html: surveyModalOptData[data.key] }} />
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="optionSurveyMd mt-1" style={{ float: "right" }}>
                                            {userSurveyData.length} {userSurveyData.length > 1 ? "Votes" : "Vote"}
                                        </div>
                                    </div>
                                </div>
                            </div>

                                {userSurveyData && userSurveyData.map((dataArr, j) => {
                                    return <div className="row">
                                        <div className="nameSurveybody mx-2">
                                            <div className="logoSurvey">
                                                <div className="userLogoSurvey">
                                                    {dataArr.firstName[0]}
                                                </div>
                                            </div>
                                            <div className="mx-1 mt-1 nameSurvey" ><p className="mx-2 mt-3">{dataArr.firstName}<br />
                                                <span style={{ fontSize: "12px" }}>{getTimeFormate(dataArr.createdAt)}</span>
                                            </p>
                                            </div>
                                            {/* <div className="mx-2 emailSurvey"><p className="mx-3 mt-3">{dataArr.email}</p>
                                            </div> */}
                                        </div>
                                    </div>
                                })
                                }
                            </>
                        })}
                    </div>
                    }
                />
            }
            {
                welcomeModal && !fromAdmin && !welcomeModalTxt && <Modal
                    visible={welcomeModal}
                    size={"modal-md"}
                    closeModal={() => { this.setState({ welcomeModal: false }) }}
                    heading={"Welcome Message"}
                    body={<div className="d-flex flex-column align-items-center justify-content-center p-2">
                        <p className="" style={{ fontSize: 20, fontWeight: "bold" }}> Welcome to Meetings by Teqit !</p>
                        <p style={{ fontSize: 16, fontWeight: "bold" }}>The meeting will start soon.</p>
                    </div>}
                />
            }
        </div >
    }
}


const mapStateToProps = (state, ownProps) => {
    let { channelId, fromCustomerSupportTeam } = ownProps;
    if (!channelId) {
        channelId = ownProps.match.params.channelId
    }

    let chatReducerData = state.chatReducer[channelId];
    let authReducer = state.authReducer;
    let { isLoggedIn } = authReducer
    isLoggedIn = parseInt(isLoggedIn)

    return {
        ...state,
        guestAuth: fromCustomerSupportTeam ? state.authReducer : state.guestAuthReducer,
        userId: isLoggedIn ? state.authReducer.loginId : state.guestAuthReducer.loginId,
        loginId: isLoggedIn ? state.authReducer.loginId : state.guestAuthReducer.loginId,
        profilePhoto: isLoggedIn ? state.authReducer.profilePhoto : state.guestAuthReducer.profilePhoto,
        sessionId: isLoggedIn ? state.authReducer.sessionId : state.guestAuthReducer.sessionId,
        companyId: isLoggedIn ? state.authReducer.activeCompany : state.guestAuthReducer.activeCompany,
        activeUserType: isLoggedIn ? state.authReducer.activeUserType : state.guestAuthReducer.activeUserType,
        systemLevelRole: isLoggedIn ? state.authReducer.systemLevelRole : state.guestAuthReducer.systemLevelRole,
        activeCompanyDetails: isLoggedIn ? state.authReducer.activeCompanyDetails : state.guestAuthReducer.activeCompanyDetails,
        firstName: isLoggedIn ? state.authReducer.firstName : state.guestAuthReducer.firstName,
        hoursSelected: isLoggedIn ? state.authReducer.hoursSelected : state.guestAuthReducer.hoursSelected,
        controlFeature: isLoggedIn ? state.authReducer.controlFeature : state.guestAuthReducer.controlFeature,
        channelKeys: Object.keys(state.chatReducer),
        currentChannelName: (chatReducerData && chatReducerData.currentChannelName !== undefined) ? chatReducerData.currentChannelName : "",
        channelType: (chatReducerData && chatReducerData.channelType !== undefined) ? chatReducerData.channelType : "",
        channelId: channelId,
        channelMembers: (chatReducerData && chatReducerData.channelMembers !== undefined) ? chatReducerData.channelMembers : [],
        userChatHistory: (chatReducerData && chatReducerData.userChatHistory !== undefined) ? chatReducerData.userChatHistory : {},
        currentResult: (chatReducerData && chatReducerData.currentResult !== undefined) ? chatReducerData.currentResult : 0,
        message: (chatReducerData && chatReducerData.message !== undefined) ? chatReducerData.message : "",
        fileList: (chatReducerData && chatReducerData.fileList !== undefined) ? chatReducerData.fileList : [],
        noOfPage: (chatReducerData && chatReducerData.noOfPage !== undefined) ? chatReducerData.noOfPage : 1,
        emojiPicker: (chatReducerData && chatReducerData.emojiPicker !== undefined) ? chatReducerData.emojiPicker : false,
        gifPicker: (chatReducerData && chatReducerData.gifPicker !== undefined) ? chatReducerData.gifPicker : false,
        buildUrl: (chatReducerData && chatReducerData.buildUrl !== undefined) ? chatReducerData.buildUrl : "",
        insertTextToggle: (chatReducerData && chatReducerData.insertTextToggle !== undefined) ? chatReducerData.insertTextToggle : false,
        setEmptyText: (chatReducerData && chatReducerData.setEmptyText !== undefined) ? chatReducerData.setEmptyText : false,
        insertText: (chatReducerData && chatReducerData.insertText !== undefined) ? chatReducerData.insertText : "",
        recording: (chatReducerData && chatReducerData.recording !== undefined) ? chatReducerData.recording : false,
        recordingModal: (chatReducerData && chatReducerData.recordingModal !== undefined) ? chatReducerData.recordingModal : false,
        previewBlob: (chatReducerData && chatReducerData.previewBlob !== undefined) ? chatReducerData.previewBlob : {},
        previewType: (chatReducerData && chatReducerData.previewType !== undefined) ? chatReducerData.previewType : "",
        selectedMessage: (chatReducerData && chatReducerData.selectedMessage !== undefined) ? chatReducerData.selectedMessage : "",
        editMessageId: (chatReducerData && chatReducerData.editMessageId !== undefined) ? chatReducerData.editMessageId : "",
        editMessageDetails: (chatReducerData && chatReducerData.editMessageDetails !== undefined) ? chatReducerData.editMessageDetails : {},
        editMessageText: (chatReducerData && chatReducerData.editMessageText !== undefined) ? chatReducerData.editMessageText : "",
        infoShow: (chatReducerData && chatReducerData.infoShow !== undefined) ? chatReducerData.infoShow : true,
        isRender: (chatReducerData && chatReducerData.isRender !== undefined) ? chatReducerData.isRender : false,
        scrollBottom: (chatReducerData && chatReducerData.scrollBottom !== undefined) ? chatReducerData.scrollBottom : true,
        gifURL: (chatReducerData && chatReducerData.gifURL !== undefined) ? chatReducerData.gifURL : "",
        deleteMessageId: (chatReducerData && chatReducerData.deleteMessageId !== undefined) ? chatReducerData.deleteMessageId : [],
        channelMembersDetails: (chatReducerData && chatReducerData.channelMembersDetails !== undefined) ? chatReducerData.channelMembersDetails : {},
        isFeedPage: (chatReducerData && chatReducerData.isFeedPage !== undefined) ? chatReducerData.isFeedPage : false,
        pageText: (chatReducerData && chatReducerData.pageText !== undefined) ? chatReducerData.pageText : null,
        addPeopleModal: (chatReducerData && chatReducerData.addPeopleModal !== undefined) ? chatReducerData.addPeopleModal : false,
        channelUser: (chatReducerData && chatReducerData.channelUser !== undefined) ? chatReducerData.channelUser : [],
        userListOption: (chatReducerData && chatReducerData.userListOption !== undefined) ? chatReducerData.userListOption : [],
        errors: (chatReducerData && chatReducerData.errors !== undefined) ? chatReducerData.errors : {},
        excludeMembers: (chatReducerData && chatReducerData.excludeMembers !== undefined) ? chatReducerData.excludeMembers : [],
        channelMuteStatus: (chatReducerData && chatReducerData.channelMuteStatus !== undefined) ? chatReducerData.channelMuteStatus : "unmute",
        messageSound: (chatReducerData && chatReducerData.messageSound !== undefined) ? chatReducerData.messageSound : MyConfig.messageSound,
        selectedMessageSound: (chatReducerData && chatReducerData.selectedMessageSound !== undefined) ? chatReducerData.selectedMessageSound : "sound1",
        deactivatedMembersIds: (chatReducerData && chatReducerData.deactivatedMembersId !== undefined) ? chatReducerData.deactivatedMembersIds : [],
        previewModal: (chatReducerData && chatReducerData.previewModal !== undefined) ? chatReducerData.previewModal : false,
        changeText: (chatReducerData && chatReducerData.changeText !== undefined) ? chatReducerData.changeText : "",
        editBlink: (chatReducerData && chatReducerData.editBlink !== undefined) ? chatReducerData.editBlink : false,
        showMorePeopleModal: (chatReducerData && chatReducerData.showMorePeopleModal !== undefined) ? chatReducerData.showMorePeopleModal : false,
        existChannelMemberIds: (chatReducerData && chatReducerData.existChannelMemberIds !== undefined) ? chatReducerData.existChannelMemberIds : [],
        paginateData: (chatReducerData && chatReducerData.paginateData !== undefined) ? chatReducerData.paginateData : { "pagination": [], "noOfPage": 1, "prev": false, "next": false },
        threadData: (chatReducerData && chatReducerData.threadData !== undefined) ? chatReducerData.threadData : {},
        surveyModal: (chatReducerData && chatReducerData.surveyModal !== undefined) ? chatReducerData.surveyModal : false,
        surveyData: (chatReducerData && chatReducerData.surveyData !== undefined) ? chatReducerData.surveyData : { "surveyQuestion": "", "surveyOption": { "1": "", "2": "" }, "surveyType": "single", "totalSeconds": 0, },
        errorSurveyData: (chatReducerData && chatReducerData.errorSurveyData !== undefined) ? chatReducerData.errorSurveyData : {},
        channelMembersTotalCount: (chatReducerData && chatReducerData.channelMembersTotalCount !== undefined) ? chatReducerData.channelMembersTotalCount : 0,
        surveyAction: (chatReducerData && chatReducerData.surveyAction !== undefined) ? chatReducerData.surveyAction : "add",
        voiceToTextModal: (chatReducerData && chatReducerData.voiceToTextModal !== undefined) ? chatReducerData.voiceToTextModal : false,
        voiceToTextFileType: (chatReducerData && chatReducerData.voiceToTextFileType !== undefined) ? chatReducerData.voiceToTextFileType : "record",
        getChannelChatsFetched: (chatReducerData && chatReducerData.getChannelChatsFetched !== undefined) ? chatReducerData.getChannelChatsFetched : false,
        getChannelDetailsFetched: (chatReducerData && chatReducerData.getChannelDetailsFetched !== undefined) ? chatReducerData.getChannelDetailsFetched : false,
        getChannelMembersFetched: (chatReducerData && chatReducerData.getChannelMembersFetched !== undefined) ? chatReducerData.getChannelMembersFetched : false,
        surveyPollModal: (chatReducerData && chatReducerData.surveyPollModal !== undefined) ? chatReducerData.surveyPollModal : false,
        surveyModalData: (chatReducerData && chatReducerData.surveyModalData !== undefined) ? chatReducerData.surveyModalData : {},
        surveyQuestionStr: (chatReducerData && chatReducerData.surveyQuestionStr !== undefined) ? chatReducerData.surveyQuestionStr : "",
        surveyModalOptData: (chatReducerData && chatReducerData.surveyModalOptData) ? chatReducerData.surveyModalOptData : {},
        scrollPosition: (chatReducerData && chatReducerData.scrollPosition) ? chatReducerData.scrollPosition : 0,
        aiAgentDataDomainType: (chatReducerData && chatReducerData.aiAgentDataDomainType) ? chatReducerData.aiAgentDataDomainType : "",
        selectedAgent: (chatReducerData && chatReducerData.selectedAgent) ? chatReducerData.selectedAgent : "",
        agentOptions: (chatReducerData && chatReducerData.agentOptions) ? chatReducerData.agentOptions : [],
        chatSurveyTimeData: (chatReducerData && chatReducerData.chatSurveyTimeData) ? chatReducerData.chatSurveyTimeData : {
            dayOptions: [],
            daySelected: 0,
            hourOption: [],
            hourSelected: 0,
            minuteOption: [],
            minuteSelected: 30,
        },
    };
};

const mapDispatchToProps = {
    setMultipleChatData
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Chat));