import React from 'react';
import { withRouter } from 'react-router-dom';
import {  toast } from 'react-toastify';
import { generateId, getGrpcClient, getUuidv4, } from '../config/Common';
import { setRegisterData, setMultipleRegisterData, reSetRegisterData } from '../redux/actions';
import { connect } from 'react-redux';
import DropDown from '../components/DropDown';
import countriesJSON from "../json/countries.json"
import GlobalMsgCons from '../config/GlobalMsgCons';
import GlobalDomainCons from '../config/GlobalDomainCons';
import GlobalEventCons from '../config/GlobalEventCons';
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { analyticsUserTrack } from '../config/AnalyticsTrack';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import MyConfig from '../config/MyConfig';
import { ZiFetchRequest as UmedmeZiFetchRequest } from "../protobuf/umedme/ZiFetchService_grpc_web_pb";

class RegisterParentWorkspace extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageText: null,
            orgName: "",
            formView: true
        }
        this.captchaRef = React.createRef();

    }
    componentDidMount() {
        this.getCountryList()
        this.getLanguageList()
        analyticsUserTrack("Register Child Workspace", "Landing");
    }

    componentDidUpdate(prevProps) {
        if (prevProps.firstName !== this.props.firstName || prevProps.lastName !== this.props.lastName || prevProps.email !== this.props.email || prevProps.password !== this.props.password || prevProps.countrySelected !== this.props.countrySelected || prevProps.pincode !== this.props.pincode) {
            this.checkValidation()
        }
    }

    async checkValidation() {
        let { errors } = this.props;
        var filtered = Object.values(errors).filter(function (el) {
            return el !== "";
        });
        if (filtered.length > 0) {
            let errors = await this.getValidation("every");

            if (Object.keys(errors).length > 0) {
                this.props.setRegisterData("errors", errors)
                this.props.setRegisterData("loadingRegister", false)
            } else {
                this.props.setRegisterData("errors", {
                    firstName: "",
                    lastName: "",
                    email: "",
                    password: "",
                    country: "",
                    pincode: ""
                })
            }
        }
    }

    async getCountryList() {
        this.props.setRegisterData("countryOptions", countriesJSON.countries)
    }

    getLanguageList() {
        let postJson = { reqId: getUuidv4(), };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.uiSetDomain);
        request.setEvent(GlobalEventCons.languagesDataSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                let resMapData = json.uiMap
                let languageOptions = []
                let selectedVal = ""
                if (json && json.uiMap && Object.keys(json.uiMap).length !== 0) {
                    Object.keys(json.uiMap).map((key) => {
                        let lan = json.uiMap[key]
                        var obj = JSON.parse(lan)
                        if (key === "eng") {
                            selectedVal = { label: obj["name"], value: key }
                        }
                        languageOptions.push({ label: obj["name"], value: key })
                    })
                }

                this.props.setRegisterData("languageOptions", languageOptions)
                this.props.setRegisterData("languageSelected", selectedVal)
            }
        });
    }

    async getValidation(from) {
        let { email, password, firstName, lastName, countrySelected, pincode } = this.props;
        let { orgName } = this.state;
        let errors = {};
        var reg = MyConfig.emaildIdValidation;
        let passwordReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(\W|_)).{6,15}$/;

        if (email.trim() === '') {
            errors["email"] = "Please enter email";
        } else if (reg.test(email) === false) {
            errors["email"] = "Please enter valid email";
        }
        if (password.trim() === '') {
            errors["password"] = "Please enter password";
        } else if (passwordReg.test(password) === false) {
            errors["password"] = "Your password must be between 6 to 15 characters long and must include at least one uppercase letter, one lowercase letter, one number, and one symbol to meet the security requirements.";
        }

        if (firstName.trim() === '') {
            errors["firstName"] = "Please enter first name";
        }
        if (lastName.trim() === '') {
            errors["lastName"] = "Please enter last name";
        }
        if (!countrySelected) {
            errors["country"] = "Please Choose Country";
        }
        if (pincode.trim() === '') {
            errors["pincode"] = "Please Enter Postal Code";
        }
        if (orgName.trim() === '') {
            errors["orgName"] = "Please Enter Workspace Name";
        }

        return errors
    }
    async register() {
        let { email, password, firstName, lastName, countrySelected, pincode, languageSelected, address, genderSelected } = this.props;
        analyticsUserTrack("Register Child Workspace", "Register Submit");
        this.props.setRegisterData("loadingRegister", true)

        let errors = await this.getValidation("submit");

        const token = this.captchaRef.current.getValue();
        if(!token) {
            errors["captcha"] = "reCAPTCHA not valid"
        }
        if (Object.keys(errors).length > 0) {
            this.props.setRegisterData("errors", errors)
            this.props.setRegisterData("loadingRegister", false)
        } else {
            this.props.setRegisterData("errors", {
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                country: "",
                pincode: ""
            })

            let event = GlobalEventCons.userSignUpSetEvent
            let postJson = {
                reqId: getUuidv4(),
                email: email.toLowerCase(),
                password,
                firstName,
                lastName,
                country: countrySelected.value.toString(),
                pincode: pincode,
                languagePreference: languageSelected.value,
                address,
                gender: genderSelected,
                emailSend: true,
                notes: "",
                requestFrom: "teqit.me"
            };

            const request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.manageSetDomain);
            request.setEvent(event);
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    var response = responseData.response;
                    this.captchaRef.current.reset();
                    if (response === GlobalMsgCons.success) {
                        let { userId } = responseData;
                        this.createOrg(userId)

                        // toast.success("Your registration has been successfully completed! Please check your email inbox for confirmation, and don't forget to also check your spam folder.", {
                        //     position: "top-center",
                        //     autoClose: GlobalMsgCons.autoClose,
                        //     hideProgressBar: false,
                        //     closeOnClick: true,
                        //     pauseOnHover: true,
                        //     draggable: true,
                        //     progress: undefined,
                        // });
                    } else {
                        this.props.setRegisterData("loadingRegister", false)
                        toast.error("This email already exist", {
                            position: "top-center",
                            autoClose: GlobalMsgCons.autoClose,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }
            });
        }
    };

    checkNameExist(name) {
        let postJson = { reqId: getUuidv4(), organizationName: name };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.companySetDomain);
        request.setEvent(GlobalEventCons.checkOrganizationNameExist);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        let result = new Promise(resolve => {
            getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    resolve(responseData.response);
                }
            })

        });

        return result;
    }

    createOrg(userId) {
        let { orgName } = this.state;
        let { pincode, countrySelected } = this.props;
        let { parentOrgId } = this.props.match.params;

        let postJson = {
            reqId: getUuidv4(),
            sessionId: '123',
            userId,
            organizationName: orgName,
            organizationType: "company",
            themeColor: "#1e325c",
            weekStartOn: "Monday",
            defaultCapacity: { perWeekMin: "40:00", perWeekMax: "40:00", perDayMin: "08:00", perDayMax: "08:00" },
            dateFormat: "MM/DD/YYYY",
            currencyCode: { code: "USD", name: "United States Dollar - USD" },
            numberFormat: "",
            logo: "",
            country: countrySelected.value.toString(),
            pincode: pincode,
            address: "",
            flowType: "user",
            noOfWorkingDays: parseInt("5"),
            administrationUrl: "",
            notes: "",
            openRegistration: false,
            controlFeatureSameAsParent: true,
            parentOrgId,
            workspaceSubType: "cType"
        };

        if(parentOrgId === "8251218d4ad04e9a81e12b0dc508879b") {
            //AWDF - 8251218d4ad04e9a81e12b0dc508879b
            postJson.defaultAppFeature = ["awdf"]
        }
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.companySetDomain);
        request.setEvent(GlobalEventCons.createOrganizationSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                if (responseData.response === GlobalMsgCons.success) {
                    this.props.reSetRegisterData();
                    this.setState({
                        orgName: "",
                        formView: false
                    })

                    if(parentOrgId === "3082a5d7dee84265a1be2bf05f549994") {
                        //Salud en movimiento - 3082a5d7dee84265a1be2bf05f549994
                        this.umedmeCreateCommunity(orgName)
                    }

                    this.props.setRegisterData("loadingRegister", false)
                    analyticsUserTrack("Register Child Workspace", "Register Success");
                } else {
                    this.props.setRegisterData("loadingRegister", false)
                    toast.error("Workspace name already exist.", {
                        position: "top-center",
                        autoClose: GlobalMsgCons.autoClose,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        });
    }

    umedmeCreateCommunity(communityName) {
        let communityCode = generateId(communityName);
        let postJson = { reqId: getUuidv4(), authkey: "123#!Teqbahn@", communityCode, communityName };
        const request = new UmedmeZiFetchRequest();
        request.setDomain('adminUserManage');
        request.setEvent('createCommunityViaTeqit');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.umedmegrpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request') {
                console.warn('error', 'Invalid Request. Please try again later', "");
            } else {
                let responseData = JSON.parse(response.getMessage());
                console.log("success", responseData)
            }
        })
    }

    buildForm() {
        let { email, password, firstName, lastName, errors, passwordVisible, countryOptions, countrySelected, pincode, languageOptions, languageSelected, genderOptions, genderSelected, loadingRegister } = this.props;
        let { orgName } = this.state;
        let build = <form>
            <div className='row mx-0 mt-2'>
                <div className='col-sm-4'>
                    <div className="form-group mb-3">
                        <label className="form-label m-none text-dark fw-bolder">{"First Name"}</label>
                        <input type="text" value={firstName} className={`form-control form-control-solid form-control-lg ${errors['firstName'] ? "is-invalid" : ""}`} placeholder={"First Name"}
                            onChange={(e) => {
                                this.props.setRegisterData("firstName", e.target.value)
                            }} />
                        {
                            errors['firstName'] && <div className="invalid-feedback">{errors['firstName']}</div>
                        }
                    </div>
                </div>
                <div className='col-sm-4'>
                    <div className="form-group mb-3">
                        <label className="form-label m-none text-dark fw-bolder">{"Last Name"}</label>
                        <input type="text" value={lastName} className={`form-control form-control-solid form-control-lg ${errors['lastName'] ? "is-invalid" : ""}`} placeholder={"Last Name"}
                            onChange={(e) => {
                                this.props.setRegisterData("lastName", e.target.value)
                            }} />
                        {
                            errors['lastName'] && <div className="invalid-feedback">{errors['lastName']}</div>
                        }
                    </div>
                </div>
                <div className='col-sm-4'>
                    <div className="form-group mb-3">
                        <label className="form-label m-none text-dark fw-bolder">{"Email"}</label>
                        <input type="email" value={email} className={`form-control form-control-solid form-control-lg ${errors['email'] ? "is-invalid" : ""}`} placeholder={"Email"} autoComplete="new-password"
                            onChange={(e) => {
                                this.props.setRegisterData("email", e.target.value)
                            }} />
                        {
                            errors['email'] && <div className="invalid-feedback">{errors['email']}</div>
                        }
                    </div>
                </div>
            </div>


            <div className='row mx-0'>
                <div className='col-sm-4'>
                    <div className="form-group mb-3">
                        <label className="form-label m-none text-dark fw-bolder" >{"Password"}</label>
                        <div className="input-group">
                            <input type={passwordVisible ? "text" : "password"} value={password} className={`form-control form-control-solid form-control-lg ${errors['password'] ? "is-invalid" : ""}`} placeholder={"Password"} autoComplete="new-password"
                                onChange={(e) => {
                                    this.props.setRegisterData("password", e.target.value)
                                }} />
                            <div className="input-group-append" onClick={() => {
                                this.props.setRegisterData("passwordVisible", !passwordVisible)
                            }}>
                                <span className="input-group-text border-0 cursor-pointer"><i className={`fa ${passwordVisible ? "fa-eye-slash" : "fa-eye"}`} /></span>
                            </div>
                            {
                                errors['password'] ? <div className="invalid-feedback">{errors['password']}</div> : <div>
                                    <small className="form-text text-muted">Your password must be between 6 to 15 characters long and must include at least one uppercase letter, one lowercase letter, one number, and one symbol to meet the security requirements.</small>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className='col-sm-4'>
                    <div className="form-group mb-4">
                        <label className="form-label m-none text-dark fw-bolder" >{"Country"}</label>
                        <div className="col-12 register-select p-0">
                            <DropDown
                                options={countryOptions}
                                isSearchable={true}
                                placeholder={"Select Country"}
                                onChange={(e) => {
                                    this.props.setRegisterData("countrySelected", e)
                                }}
                                selectedOption={countrySelected}
                                isDisabled={false}
                                isMulti={false}
                                error={errors['country'] ? true : false}
                            />
                            {
                                errors['country'] && <div className="d-block invalid-feedback">{errors['country']}</div>
                            }
                        </div>
                    </div>
                </div>
                <div className='col-sm-4'>
                    <div className="form-group mb-3">
                        <label className="form-label m-none text-dark fw-bolder">{"Postal Code"}</label>
                        <input type="text" value={pincode} className={`form-control form-control-solid form-control-lg ${errors['pincode'] ? "is-invalid" : ""}`} placeholder={"Postal Code"}
                            onChange={(e) => {
                                this.props.setRegisterData("pincode", e.target.value)
                            }} />
                        {
                            errors['pincode'] && <div className="invalid-feedback">{errors['pincode']}</div>
                        }
                    </div>
                </div>
            </div>
            <div className='row mx-0' >
                <div className='col-sm-4'>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-6">
                                <label className="form-label m-none text-dark fw-bolder" >{"Language"}</label>
                            </div>
                        </div>
                        <div className="input-group">
                            <div className="col-12 register-select p-0">
                                <DropDown
                                    options={languageOptions}
                                    isSearchable={true}
                                    placeholder={"Select Language"}
                                    onChange={(e) => {
                                        this.props.setRegisterData("languageSelected", e)
                                    }}
                                    selectedOption={languageSelected}
                                    isDisabled={false}
                                    isMulti={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-sm-4'>
                    <div className="form-group mb-3">
                        <label className="form-label m-none text-dark fw-bolder">
                            {"Workspace Name"}
                        </label>
                        <input type="text" value={orgName} className={`form-control form-control-solid form-control-lg ${errors['orgName'] ? "is-invalid" : ""}`} placeholder={"Workspace Name"}
                            onChange={(e) => {
                                this.setState({
                                    orgName: e.target.value
                                }, () => {
                                    this.checkValidation()
                                })
                            }} />
                        {
                            errors['orgName'] && <div className="invalid-feedback">{errors['orgName']}</div>
                        }
                    </div>
                </div>
                <div className='col-sm-4'>
                    <ReCAPTCHA
                        ref={this.captchaRef}
                        sitekey={MyConfig.siteKey}
                        onChange={() => {
                            errors['captcha'] = ""
                            this.props.setRegisterData("errors", errors)
                        }}
                    />
                    {
                        errors['captcha'] && <div className="d-block invalid-feedback">{errors['captcha']}</div>
                    }
                </div>
            </div>

            <div className='text-center my-3'>
                <button type="button" className="btn btn-lg register-btn" disabled={loadingRegister} onClick={() => {
                    this.register()
                }}>{loadingRegister && <i className="fa fa-spinner fa-spin"></i>}
                    <span className={`${loadingRegister && ("pl-1")}`}>
                        {"Sign Up"}
                    </span>
                </button>
            </div>


        </form>

        return build;
    }
    render() {
        let { formView } = this.state;

        return <div className='container pt-5'>
            <div className='register-form'>
                <div className="register-form-header text-center mb-3">
                    <h2>Sign Up</h2>
                </div>
                {
                    formView ? this.buildForm() : <div className="success-calendly">
                        <div className="icon pb-3"><i className="fas fa-check-circle"></i></div>
                        <div className="success-content px-5">Congratulations, Your sign up has been successfully completed!
                            <br />
                            <small>Please check your email inbox for confirmation, and don't forget to also check your spam folder.</small>
                        </div>
                        <div className='py-2'>
                            <Link to={"/"} className="btn btn-primary">Back to Home</Link>
                        </div>
                    </div>
                }
            </div>
        </div>;
    }
}

const mapStateToProps = (state) => {
    return {
        email: state.registerReducer.email,
        password: state.registerReducer.password,
        firstName: state.registerReducer.firstName,
        lastName: state.registerReducer.lastName,
        passwordVisible: state.registerReducer.passwordVisible,
        errors: state.registerReducer.errors,
        countryOptions: state.registerReducer.countryOptions,
        countrySelected: state.registerReducer.countrySelected,
        address: state.registerReducer.address,
        pincode: state.registerReducer.pincode,
        languageOptions: state.registerReducer.languageOptions,
        languageSelected: state.registerReducer.languageSelected,
        genderOptions: state.registerReducer.genderOptions,
        genderSelected: state.registerReducer.genderSelected,
        loadingRegister: state.registerReducer.loadingRegister,
        roleType: state.registerReducer.roleType,
        state,
    };
};

const mapDispatchToProps = {
    setRegisterData,
    setMultipleRegisterData,
    reSetRegisterData
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterParentWorkspace));