import { combineReducers } from 'redux';

import authReducer from './authReducer';
import loginReducer from './loginReducer';
import registerReducer from './registerReducer';
import forgotPasswordReducer from './forgotPasswordReducer';
import switchAccountReducer from './switchAccountReducer';
import workspaceReducer from './workspaceReducer';
import dailyUpdateReducer from './dailyUpdateReducer';
import dailyReportOrgReducer from './dailyReportOrgReducer';
import expensesReducer from './expensesReducer';
import qatListReducer from './qatListReducer';
import qatCreateReducer from './qatCreateReducer';
import qatEditReducer from './qatEditReducer';
import appsReducer from './appsReducer';
import projectDiagramReducer from './projectDiagramReducer';
import feedbackListReducer from './feedbackListReducer';
import userOrgFeedbackListReducer from './userOrgFeedbackListReducer';
import feedbackAddReducer from './feedbackAddReducer';
import feedbackEditReducer from './feedbackEditReducer';
import reportReducer from './reportReducer';
import entryReportReducer from './entryReportReducer';
import expenseReportReducer from './expenseReportReducer';
import calendarReducer from './calendarReducer';
import membersReducer from './membersReducer';
import clientReducer from './clientReducer';
import expensesCategoryReducer from './expensesCategoryReducer';
import serviceTypeReducer from './serviceTypeReducer';
import designationReducer from './designationReducer';
import boardStatusTypeReducer from './boardStatusTypeReducer';
import appMasterReducer from './appMasterReducer';
import projectReducer from './projectReducer';
import teamReducer from './teamReducer';
import taskReducer from './taskReducer';
import cloudProviderUserMapReducer from './cloudProviderUserMapReducer';
import boardReducer from './boardReducer';
import myProfileReducer from './myProfileReducer';
import preferencesReducer from './preferencesReducer';
import notificationReducer from './notificationReducer';
import timezoneReducer from './timezoneReducer';
import chatReducer from './chatReducer';
import chatChannelReducer from './chatChannelReducer';
import chatThreadReducer from './chatThreadReducer';
import analyticsReducer from './analyticsReducer';
import expenseManageReducer from './expenseManageReducer';
import clientMasterReducer from './clientMasterReducer';
import subjectReducer from './subjectReducer';
import classRoomReducer from './classRoomReducer';
import educationTaskReducer from './educationTaskReducer';
import educationWorkspaceReducer from './educationWorkspaceReducer';
import educationEntryReportReducer from './educationEntryReportReducer';
import educationBoardReducer from './educationBoardReducer';
import educationBoardStatusTypeReducer from './educationBoardStatusTypeReducer';
import educationExpensesReducer from './educationExpensesReducer';
import educationExpenseManageReducer from './educationExpenseManageReducer';
import educationExpensesCategoryReducer from './educationExpensesCategoryReducer';
import educationCsvFileHistoryReducer from './educationCsvFileHistoryReducer';
import educationExpenseReportReducer from './educationExpenseReportReducer'
import educationReportsReducer from './educationReportsReducer'
import faqReducer from './faqReducer'
import educationAppsReducer from './educationAppsReducer'
import notesReducer from './notesReducer'
import verifyAccountReducer from './verifyAccountReducer'
import hiddenChannelsReducer from './hiddenChannelsReducer';
import userSessionReportReducer from './userSessionReportReducer';
import calendlyGuestReducer from './calendlyGuestReducer';
import calendlyEventReducer from './calendlyEventReducer';
import guestAuthReducer from './guestAuthReducer';
import timingWindowReducer from './timingWindowReducer';
import userleaverequestReducer from './userleaverequestReducer';
import userIpHistoryReducer from './userIpHistoryReducer';
import subServiceTypeReducer from './subServiceTypeReducer';
import donationReducer from './donationReducer'
import languageReducer from './languageReducer'
import roleRegistryReducer from './roleRegistryReducer'
import driveReducer from './driveReducer'
import bobAnalyticsReducer from './bobAnalyticsReducer'







const rootReducer = combineReducers({
  authReducer: authReducer,
  loginReducer: loginReducer,
  registerReducer: registerReducer,
  forgotPasswordReducer: forgotPasswordReducer,
  switchAccountReducer: switchAccountReducer,
  workspaceReducer: workspaceReducer,
  dailyUpdateReducer: dailyUpdateReducer,
  dailyReportOrgReducer: dailyReportOrgReducer,
  expensesReducer: expensesReducer,
  qatListReducer: qatListReducer,
  qatCreateReducer: qatCreateReducer,
  qatEditReducer: qatEditReducer,
  appsReducer: appsReducer,
  projectDiagramReducer: projectDiagramReducer,
  feedbackListReducer: feedbackListReducer,
  userOrgFeedbackListReducer: userOrgFeedbackListReducer,
  feedbackAddReducer: feedbackAddReducer,
  feedbackEditReducer: feedbackEditReducer,
  reportReducer: reportReducer,
  entryReportReducer: entryReportReducer,
  expenseReportReducer: expenseReportReducer,
  calendarReducer: calendarReducer,
  membersReducer: membersReducer,
  clientReducer: clientReducer,
  expensesCategoryReducer: expensesCategoryReducer,
  boardStatusTypeReducer: boardStatusTypeReducer,
  appMasterReducer: appMasterReducer,
  projectReducer: projectReducer,
  teamReducer: teamReducer,
  taskReducer: taskReducer,
  cloudProviderUserMapReducer: cloudProviderUserMapReducer,
  boardReducer: boardReducer,
  myProfileReducer: myProfileReducer,
  preferencesReducer: preferencesReducer,
  notificationReducer: notificationReducer,
  timezoneReducer: timezoneReducer,
  chatReducer: chatReducer,
  chatChannelReducer: chatChannelReducer,
  chatThreadReducer: chatThreadReducer,
  analyticsReducer: analyticsReducer,
  expenseManageReducer: expenseManageReducer,
  clientMasterReducer: clientMasterReducer,
  subjectReducer: subjectReducer,
  classRoomReducer: classRoomReducer,
  educationTaskReducer: educationTaskReducer,
  educationWorkspaceReducer: educationWorkspaceReducer,
  educationEntryReportReducer: educationEntryReportReducer,
  educationBoardReducer: educationBoardReducer,
  educationBoardStatusTypeReducer: educationBoardStatusTypeReducer,
  educationExpensesReducer: educationExpensesReducer,
  educationExpenseManageReducer: educationExpenseManageReducer,
  educationExpensesCategoryReducer: educationExpensesCategoryReducer,
  educationCsvFileHistoryReducer: educationCsvFileHistoryReducer,
  educationExpenseReportReducer: educationExpenseReportReducer,
  educationReportsReducer: educationReportsReducer,
  faqReducer: faqReducer,
  educationAppsReducer: educationAppsReducer,
  notesReducer,
  verifyAccountReducer,
  hiddenChannelsReducer: hiddenChannelsReducer,
  userSessionReportReducer: userSessionReportReducer,
  calendlyGuestReducer,
  calendlyEventReducer,
  guestAuthReducer,
  timingWindowReducer,
  userleaverequestReducer,
  userIpHistoryReducer,
  serviceTypeReducer,
  designationReducer,
  subServiceTypeReducer,
  donationReducer,
  languageReducer,
  roleRegistryReducer,
  driveReducer,
  bobAnalyticsReducer
});

export default rootReducer;
