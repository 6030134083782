import React, { useEffect, useState } from 'react';
import './MailTemplates.css';
import { capitalize, coversionED, getAppInfo, getDateWithFormat, getGrpcClient, getUuidv4, removeUniqueFromArray, sortByKey } from '../../config/Common';
import { useSelector } from 'react-redux';
import GlobalMsgCons from '../../config/GlobalMsgCons';
import MyConfig from '../../config/MyConfig';
import { ZiFetchRequest } from '../../protobuf/generated/ZiFetchService_grpc_web_pb';
import GlobalDomainCons from '../../config/GlobalDomainCons';
import GlobalEventCons from '../../config/GlobalEventCons';
import { Link, useParams } from 'react-router-dom';
import CustomEditor from '../../components/CustomEditor';
import Loader from '../../components/Loader';

let workflowUsers = ["406ad4d42e054e859a436bdac3f43dc2", "5e88cb3c17fb4b689f9dcbf179a923d7", "7b32789d3dca4da0a0957d57087e4da8", "a1164bfd87894c668ad4356f5f9e10ce"]

let menus = [
    { label: "Compose", value: "compose" },
    { label: "Inbox", value: "inbox" },
    { label: "Sent Items", value: "sentItems" },
]

let pageLimit = 25

const MailTemplatesAdmin = (props) => {
    let { path } = props;
    const auth = useSelector(state => state.authReducer);
    const { customerUserId  } = useParams()
    let { activeCompany, activeCompanyDetails } = auth;

    const [loader, setLoader] = useState(false);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [editContent, setEditContent] = useState(false);
    const [activeTab, setActiveTab] = useState("inbox");
    const [activeMail, setActiveMail] = useState(null);
    const [inboxList, setInboxList] = useState({ result: []});
    const [sentList, setSentList] = useState({ result: []});
    const [mails] = useState([
        {
            id: 1,
            subject: "Book Trip",
            content: `
                    <div>
                        <p>Dear Timothy,</p>
                        <br/>
                        <p>I hope you are doing well. I would like to request your assistance in arranging the following for my upcoming trip:</p>
                        <br/>
                        <p>Flight from San Francisco to New York on January 25, 2025</p>
                        <p>Car rental for the duration of the trip</p>
                        <p>Hotel accommodation for one night</p>
                        <p>Please let me know if you need any further details to complete the booking. I appreciate your help and look forward to your confirmation.</p>
                        <br/>
                        <p>Best regards,</p>
                        <p>${capitalize("Raven")}</p>
                    </div>  
                    `,
        },
        {
            id: 2,
            subject: "Cancel Trip",
            content: `
                    <div>
                        <p>Dear Timothy,</p>
                        <br/>
                        <p>I hope this message finds you well. I would like to request the cancellation of my flight with the following details:</p>
                        <br/>
                        <p>Ticket Number: EK00370823</p>
                        <p>Flight from San Francisco to New York on January 25, 2025</p>
                        <p>Please let me know if you need any further details to complete the cancellation of the booking. I appreciate your help and look forward to your confirmation.</p>
                        <br/>
                        <p>Best regards,</p>
                        <p>${capitalize("Raven")}</p>
                    </div>  
                    `,
        },
    ]
    );

    useEffect(() => {
        setActiveMail(null)
        setLoader(false)
        if(activeTab === "inbox") {
            getList("receive", 1)
        } else if(activeTab === "sentItems") {
            getList("sent", 1)
        }
    }, [activeTab])

    useEffect(() => {
        setEditContent(false)
    }, [activeMail])


    const handleMailClick = (mail) => {
        setActiveMail(mail);
    };

    function getProjectId() {
        let projectId = ""
        if (workflowUsers.includes(customerUserId)) {
            projectId = "f9caf5d1e97b402ab6b4712269c828af"
            if (activeCompany === "f9d096a42c8a4285aec48e39f706ea36") {
                projectId = "b8b0a1f2acde4a679ca6a69bcee3bf20"
            }
        }

        return projectId
    }

    function getList(type, noOfPage) {
        setLoader(true)
        let postJson = {
            reqId: getUuidv4(),
            userId: customerUserId,
            orgId: activeCompany, 
            mailType: type,
            pageLimit, 
            noOfPage
        };

        console.log("postJson", postJson)
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.userSetDomain);
        request.setEvent(GlobalEventCons.getMailChannelLogs);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage())
                setLoader(false)
                switch(type) {
                    case "receive":
                        responseData.result = removeUniqueFromArray(inboxList.result.concat(responseData.result), "id")
                        setInboxList(responseData)
                        break;
                        case "sent":
                        responseData.result = removeUniqueFromArray(sentList.result.concat(responseData.result), "id")
                        setSentList(responseData)
                        break;
                }
            }   
        });
    }
    
    
    function loadMore() {
        if(activeTab === "inbox") {
            let currentPage = parseInt(inboxList.result.length / pageLimit)
            getList("receive", currentPage+1)
        } else if(activeTab === "sentItems") {
            let currentPage = parseInt(sentList.result.length / pageLimit)
            getList("sent", currentPage+1)
        }
    }

    function getActiveList() {
        let list = mails
        let totalResult = 2

        switch (activeTab) {
            case "inbox":
                list = inboxList.result.sort((a, b) => b.createdAt - a.createdAt)
                totalResult = inboxList.totalResult
                break;
            case "sentItems":
                list = sentList.result.sort((a, b) => b.createdAt - a.createdAt)
                totalResult = sentList.totalResult
                break;
        }

        return <React.Fragment>
            {
                loader && <Loader />
            }
            {
                (!loader && list.length === 0) ? <div className='text-center my-5'>
                    No record found.
                </div> : <React.Fragment>
                    {
                        list.map((mail) => {
                            let { id, toEmail, subject, createdAt, fromEmail } = mail;
                            return <li
                                key={id}
                                onClick={() => handleMailClick(mail)}
                                className={`mail-item ${(activeMail && activeMail.id === id) ? "active" : ""}`}
                            >
                                {
                                    (activeTab === "inbox" || activeTab === "sentItems") && <div className='d-flex mb-2'>
                                        <div className='flex-1'>
                                            { activeTab === "inbox" ? fromEmail : toEmail}
                                        </div>
                                        <div className=''>
                                            <small>{getDateWithFormat(createdAt, activeCompanyDetails)}</small>
                                        </div>
                                    </div>
                                }
                                {subject}
                            </li>
                        })
                    }
                    {
                        list.length < totalResult && <div className='text-center my-2' onClick={() => loadMore()}>
                            <button className='btn btn-sm btn-primary'>Load More</button>
                        </div>
                    }
                </React.Fragment>
            }
        </React.Fragment>
    }
    return (
        <div className="mail-container">
            <div className="left-mail-menu d-flex flex-column">
                <div className='d-flex align-items-center my-2'>
                    <Link className="btn btn-sm btn-secondary mx-2" to={path + "/customer-analytics"}>
                        <i className='fa fa-chevron-left'/>
                    </Link>
                    <h3 className='mb-0'>Mail</h3>
                </div>
                <ul className="menu-list flex-1">
                    {
                        menus.map((menu, index) => {
                            let { label, value } = menu
                            return <li key={index} className={`cursor-pointer ${activeTab === value ? 'active' : ''}`} onClick={() => {
                                setActiveTab(value)
                            }}>{label}</li>
                        })
                    }
                </ul>
            </div>

            <div className="mail-content">
                <div className="mail-list">
                    <ul className="mail-list-items">
                        {getActiveList()}
                    </ul>
                </div>

                <div className="mail-details">
                    {
                        activeMail && <div>
                            <b>{activeMail.subject}</b> 
                            {
                                (activeTab === "inbox" || activeTab === "sentItems") && <React.Fragment>
                                    <br/>
                                    <small>{getDateWithFormat(activeMail.createdAt, activeCompanyDetails)}</small>
                                </React.Fragment>
                            }
                            
                            <hr />
                            {
                                editContent ? <CustomEditor
                                            title={"Edit Template"}
                                            text={activeMail.content}
                                            textOnchange={(e) => {
                                                activeMail.content = e
                                                setActiveMail(activeMail)
                                            }}
                                        /> : <div dangerouslySetInnerHTML={{ __html: activeMail.content }} />
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default MailTemplatesAdmin;
