import React, { useState } from "react";
import CardToggle from "./CardToggle";
import { toast } from 'react-toastify';
import { downloadObjectAsJson, isJson } from "../config/Common";
import { useEffect } from "react";
import GlobalMsgCons from "../config/GlobalMsgCons";


export default function JSONViewer({ title, json, defaultExpand }) {
    let [expand, setExpand] = useState(true);

    useEffect(() => {
        if(defaultExpand !== undefined) {
            setExpand(defaultExpand)
        }
    }, [])
    return <div className="json-viewer">
        <CardToggle
            title={title}
            toggleMenuOpen={expand}
            bodyClassName={"p-0"}
            options={<React.Fragment>
                <button className="btn btn-sm btn-copy mx-2" onClick={(e) => {
                    e.stopPropagation()
                    navigator.clipboard.writeText(json);
                    toast(`Copied Successfully.`, {
                        position: "bottom-center",
                        autoClose: GlobalMsgCons.autoClose,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }}>Copy</button>
                <button className="btn btn-sm btn-download" onClick={(e) => {
                    e.stopPropagation()
                    downloadObjectAsJson(json, title.replaceAll(" ", "_"))
                }}>Download</button>
            </React.Fragment>}
            toggleOnchange={() => {
                setExpand(!expand)
            }}
        >
            <div className="json-container flow-graph">
                <pre>
                    {`${isJson(json) ? JSON.stringify(JSON.parse(json), null, 2) : json}`}
                </pre>
            </div>
        </CardToggle>
    </div>
}
