import { useSelector } from "react-redux";
import { esStatusdesign, getCaseId, getChatPlatformTxt, getDateWithFormat, getReportName, urlify, getUuidv4, getGrpcClient, coversionED, checkActiveAdmin, getReportStatusLabel } from "../config/Common";
import GlobalMsgCons from "../config/GlobalMsgCons";
import MyConfig from "../config/MyConfig";
import MyConstant from "../config/MyConstant";
import UpdateStatus from "../pages/communityService/UpdateStatus";
import ReportsFileView from "../pages/communityService/components/ReportsFileView";
import React from "react";
import { ZiFetchRequest } from "../protobuf/generated/ZiFetchService_grpc_web_pb";
import GlobalDomainCons from "../config/GlobalDomainCons";
import GlobalEventCons from "../config/GlobalEventCons";
import { toast } from 'react-toastify';



export default function CsEventData(props) {
    let { fromPage, channelType, csEventData, csMessageMetaInfo, chatMessage, messageType, channelId, requestReceivingChannelId, sendCommunityServiceEvent, userId, tempSessionId } = props;
    const auth = useSelector(state => state.authReducer);
    let { loginId, activeCompany } = auth;


    function getCommunityServiceFormValue(formType, formValue, userId) {
        let str = formValue;
        if (formType === "emoji") {
            let emojiSelected = MyConfig.communityServiceEmojiOption.filter(option => option.value === formValue);
            if (emojiSelected.length > 0) {
                str = <div className="d-flex" style={{ flexWrap: "wrap", gap: 10 }}>
                    <div><img src={emojiSelected[0].img} alt={emojiSelected[0].label} style={{ width: 50 }} /></div>
                    <div className="my-auto badge badge-success">{emojiSelected[0].label}</div>
                </div>
            }
        } else if (formType === "voice-record") {
            formValue = JSON.parse(formValue);

            if (Object.keys(formValue).length === 0) {
                str = "No file found."
            } else {
                let buildUrl = MyConstant.keyList.apiURL + "vp?action=" + formValue.processType + "&key=" + formValue.fileName + "&id=" + userId;
                str = <audio width="100%" controls>
                    <source src={buildUrl} />
                </audio>
            }
        }

        return str;
    }
    function notAuthenticated(eventCaseNo) {
        let postJson = { reqId: getUuidv4(), eventCaseNo, orgId: activeCompany, updatedBy: loginId };
        //console.log("postJson", postJson)
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.csEventSetDomain);
        request.setEvent(GlobalEventCons.updateCSEventNotAuthenticStatus);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, async (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                console.log("responseData", responseData)
                if (responseData.response === GlobalMsgCons.success) {
                    toast.success("Deleted successfully.", {
                        position: "top-center",
                        autoClose: GlobalMsgCons.autoClose,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        });
    }
    function getSubmitType(submitType, platformFrom) {
        let str = submitType

        switch (submitType) {
            case "sms":
                str = "SMS"
                break;
            case "ussd":
                str = "USSD"
                break;
        }

        if (platformFrom) {
            let platform = getChatPlatformTxt(platformFrom, {})

            if (str === "") {
                str = platform
            } else if (platform !== str) {
                str = platform + " - " + str
            }
        }
        return str;
    }
    function getContent() {
        let { eventCaseNo, data, receivedAt, status, assignedUserId, lnscStatus, caseStatus } = csEventData;
        let assignedUserName = ""
        if (assignedUserId === "admin") {
            assignedUserName = "Admin"
        } else if (csMessageMetaInfo) {
            assignedUserName = csMessageMetaInfo.assignedUserName
        }

        let caseStatusStr = caseStatus
        if (caseStatus) {
            let filterCaseStatus = MyConfig.caseStatusOption.filter(option => option.value === caseStatus)
            if (filterCaseStatus.length) {
                caseStatusStr = filterCaseStatus[0].label
            }
        }
        let { id, serviceType, requestName, levelOfUrgency, requsterInfo, requestByMemberType, communityMemberInfo, listOfQuestion, listOfQuestionsResponse, createdBy, contactPersonInfo, summary, formType, formValue, subServiceType, fileList, reportFrom, submitType, platformFrom } = data;
        let { name, phone, email, gender, genderCategory, latitude, longitude, address, pinCode, phoneCode, ageRange, reachedHere, cityOrState } = requsterInfo;
        let { contactInfo, relationshipToSurvivor, survivorDistance, phoneCode: communityMemberPhoneCode, email: communityMemberEmail, address: communityMemberAddress, reachedHere: cmReachedHere } = communityMemberInfo;
        let answers = listOfQuestionsResponse.answer;
        let formTypeSelected = MyConfig.communityServiceFormOption.filter(option => option.value === formType)
        let prioritySelected = MyConfig.communityServicePriority.filter(option => option.value === levelOfUrgency.toString())

        let genderTypeLabel = MyConfig.csEventGenderCategory.filter(option => option.value === genderCategory)
        let reportStatusLabel = status
        reportStatusLabel = getReportStatusLabel(status)

        let msgText = chatMessage ? chatMessage.message : ""
        if (msgText && msgText !== "") {
            var values = msgText.split('<br>');
            if (Array.isArray(values)) {
                msgText = msgText.replace(values[0], `<span style='background-color:#ed760e; padding: 6px 10px;border-radius: 5px;font-size: 16px;color: #fff;}'>${values[0]}</span><br>`)
                msgText = msgText.replace(values[1], `<span style='background-color: blue; padding: 6px 10px;border-radius: 5px;font-size: 16px;color: #fff;margin-top:5px;}'>${values[1]}</span><br><br>`)
            }
            msgText = msgText.replace(`${getCaseId(eventCaseNo)}`, `<span style='background-color: #036268; padding: 3px 10px;margin-left:3px;border-radius: 10px;font-size: 13px;color: #fff;}'>${getCaseId(eventCaseNo)}</span>`);
        }


        return <>
            {(messageType === GlobalMsgCons.messageTypeCSEventAutomaticResponse && msgText) && <div className="d-inline-block mb-1 mt-2" dangerouslySetInnerHTML={{
                __html: urlify(msgText)
            }}
            />}
            <div className="es-event">
                {
                    (formType && formType !== "form") && <div className="form-details d-flex">
                        <div>
                            <b>Form Type</b>
                            <div>{formTypeSelected.length > 0 ? formTypeSelected[0].label : "-"}</div>
                        </div>
                        <div>
                            <b>Form Value</b>
                            <div>{getCommunityServiceFormValue(formType, formValue, createdBy)}</div>
                        </div>
                    </div>
                }
                {
                    fromPage !== "InProcess" && <div className="requester-info ">
                        <div className="d-flex">
                            <div>
                                <b>Case Number</b>
                                <div><span className="case-number">{getCaseId(eventCaseNo)}</span></div>
                            </div>
                            <div>
                                <b>Received At</b>
                                <div>{getDateWithFormat(receivedAt, auth.activeCompanyDetails)}</div>
                            </div>
                            <div>
                                <b>Files</b>
                                <div>
                                    {
                                        fileList && <ReportsFileView fileList={fileList} createdBy={createdBy} />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3">
                            <div>
                                <b>Case Status</b>
                                <div className="first-letter-caps">
                                    <span className={`case-status ${caseStatus && esStatusdesign(caseStatus)}`}>{caseStatus ? caseStatusStr : "-"}</span>
                                </div>
                            </div>
                            <div>
                                <b>Report Status</b>
                                <div className="text-capitalize">
                                    <span className={`case-status ${esStatusdesign(status)}`}>{reportStatusLabel}</span>
                                </div>
                                {
                                    assignedUserName && <div className="my-2">
                                        <b className="text-capitalize">{assignedUserName}</b>{" updated as "}<b className="text-capitalize">{status}</b>
                                    </div>
                                }

                                {
                                    (chatMessage && (typeof (chatMessage.metaStatus) === "undefined" || (chatMessage.metaStatus && chatMessage.metaStatus !== GlobalMsgCons.msgExpired)) && ((assignedUserId && assignedUserId === auth.loginId || assignedUserId === GlobalMsgCons.type_admin || assignedUserId === "") && (auth.activeUserType === GlobalMsgCons.roleTypeCbo || auth.activeUserType === GlobalMsgCons.roleTypeAdmin || auth.activeUserType === GlobalMsgCons.roleTypeCommunityServiceAdmin) && channelId === requestReceivingChannelId)) && <UpdateStatus
                                        csEventData={csEventData}
                                        sendCommunityServiceEvent={(data, type) => {
                                            sendCommunityServiceEvent(data, type, chatMessage.id)
                                        }}
                                        grpcClient={props.grpcClient}
                                    />
                                }
                            </div>
                            <div>
                                <b>LNSC Status</b>
                                <div className="first-letter-caps">
                                    <span className={`case-status ${lnscStatus && esStatusdesign(lnscStatus)}`}>{lnscStatus ? lnscStatus : ""}</span>
                                </div>
                                {
                                    (chatMessage && (typeof (chatMessage.metaStatus) === "undefined" || (chatMessage.metaStatus && chatMessage.metaStatus !== GlobalMsgCons.msgExpired)) && (auth.activeUserType === GlobalMsgCons.roleTypeLNSC) && channelId === requestReceivingChannelId) && <UpdateStatus
                                        csEventData={csEventData}
                                        sendCommunityServiceEvent={(data, type) => {
                                            sendCommunityServiceEvent(data, type, chatMessage.id)
                                        }}
                                        grpcClient={props.grpcClient}
                                    />
                                }
                            </div>
                        </div>
                        <div className="d-flex mt-3">
                            <div>
                                <b>Request from</b>
                                <div>
                                    {reportFrom ? reportFrom : "-"}
                                </div>
                            </div>
                            {
                                props.communityMemberViewHistory && <React.Fragment>
                                    <div>
                                        <div>
                                            <b>History</b>
                                            <div>
                                                <button className="btn btn-sm btn-success" onClick={() => {
                                                    props.communityMemberViewHistory(eventCaseNo)
                                                }}>View History</button>
                                            </div>
                                        </div>
                                        <div></div>
                                    </div>
                                </React.Fragment>
                            }
                            <div>
                                {(channelType === "communityServiceRequestReceivingChannel" && checkActiveAdmin(auth.activeUserType, auth.systemLevelRole)) ? <button className="btn btn-sm btn-warning" onClick={() => {
                                    if (window.confirm(`If you delete this case number(${getCaseId(eventCaseNo)}), the data cannot be retrieved again`)) {
                                        notAuthenticated(eventCaseNo)
                                    }
                                }}>Not Authentic Case</button> : <div />}
                            </div>
                        </div>
                    </div>
                }


                {(assignedUserId && assignedUserId === loginId && status && status.toLowerCase() === "pending") && <>
                    <div className="event-details d-flex">
                        <div>
                            <b className="nearest-text">You are near of this survivor</b>
                        </div>
                    </div></>}

                {contactPersonInfo && Object.keys(contactPersonInfo).length > 0 ? <>
                    <div className="requester-info">
                        <h5 >Contact information</h5>
                        <div className="d-flex">
                            <div>
                                <b>Name</b>
                                <div>{contactPersonInfo.name}</div>
                            </div>
                            <div>
                                <b>Email</b>
                                <div>{contactPersonInfo.email}</div>
                            </div>
                            <div>
                                <b>Address</b>
                                <div>{contactPersonInfo.address}</div>
                            </div>

                        </div>

                        <div className="d-flex">
                            <div>
                                <b>Phone</b>
                                <div>{contactPersonInfo.phone}</div>
                            </div>
                        </div>
                    </div>

                </> : ""}

                {summary ? <div className="requester-info">
                    <div className="d-flex">
                        <div>
                            <b>Summary</b>
                            <div dangerouslySetInnerHTML={{ __html: summary }}></div>
                        </div>
                    </div>
                </div> : null}

                <div className="requester-info">
                    <div className="d-flex">
                        <div>
                            <b>Type of Violence</b>
                            <div>{serviceType}</div>
                        </div>
                        {
                            fromPage !== "InProcess" && <div>
                                <b>Report Name</b>
                                <div>{<span className={`report-name ${prioritySelected.length > 0 ? prioritySelected[0].label.toLowerCase() : ""}`}>{requestName ? requestName : getReportName(eventCaseNo, serviceType, receivedAt)}</span>}</div>
                            </div>
                        }

                        {(channelType !== "communityServiceRequestSendingChannel") &&
                            <div>
                                <b>Level of urgency</b>
                                <div>{prioritySelected.length > 0 ? prioritySelected[0].label : "-"}</div>
                            </div>
                        }
                    </div>
                    <div className="d-flex mt-2">
                        <div>
                            <b>Type of Sub Violence</b>
                            <div>{subServiceType ? subServiceType : "-"}</div>
                        </div>

                        {
                            <React.Fragment>
                                <div>
                                    <b>Submit Type</b>
                                    <div className="text-capitalize">{getSubmitType(submitType, platformFrom)}</div>
                                </div>
                                <div></div>
                            </React.Fragment>
                        }
                        {
                            fromPage === "InProcess" && <React.Fragment>
                                <div className="d-flex">
                                    <div>
                                        <b>Files</b>
                                        <div>
                                            {
                                                fileList && <ReportsFileView fileList={fileList} createdBy={userId === "" ? tempSessionId : createdBy} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        }

                    </div>
                </div>
                <div className="requester-info">
                    <h5>Survivor Info</h5>
                    <div className="d-flex">
                        <div>
                            <b>Name</b>
                            <div>{name}</div>
                        </div>
                        <div>
                            <b>Phone</b>
                            <div>
                                {phone ? (phoneCode ? "(" + phoneCode + ")" : "") + phone : "-"} <br />
                                {reachedHere && "Reach here: " + reachedHere}
                            </div>
                        </div>
                        <div>
                            <b>Email</b>
                            <div>{email ? email : "-"}</div>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div>
                            <b>Gender</b>
                            <div>{`${gender} ${genderTypeLabel.length > 0 ? "(" + genderTypeLabel[0].label + ")" : ""}`}</div>
                        </div>
                        {/* <div>
                            <b>Age Group</b>
                            <div>{ageRange ? ageRange : "-"}</div>
                        </div> */}
                        <div>
                            <b>Address</b>
                            <div>{address ? address : "-"}</div>
                        </div>
                        <div></div>
                    </div>
                    <div className="d-flex">
                        <div>
                            <b>City/State</b>
                            <div>{cityOrState ? cityOrState : "-"}</div>
                        </div>
                        <div>
                            <b>Latitude</b>
                            <div>{latitude}</div>
                        </div>
                        <div>
                            <b>Longitude</b>
                            <div>{longitude}</div>
                        </div>
                    </div>
                </div>
                {/* <div className="community-member-info">
                    <h5>Community Member Info</h5>
                    <div className="d-flex">
                        <div>
                            <b>Name</b>
                            <div>{communityMemberInfo.name ? communityMemberInfo.name : "-"}</div>
                        </div>
                        <div>
                            <b>Phone</b>
                            <div>
                                {contactInfo ? (communityMemberPhoneCode ? "(" + communityMemberPhoneCode + ")" : "") + contactInfo : "-"}
                                <br />
                                {cmReachedHere && "Reach here: " + cmReachedHere}
                            </div>
                        </div>
                        <div>
                            <b>Relationship To Survivor</b>
                            <div>{relationshipToSurvivor ? relationshipToSurvivor : "-"}</div>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div>
                            <b>How close are you to the survivor?</b>
                            <div>{survivorDistance ? survivorDistance : "-"}</div>
                        </div>
                        <div>
                            <b>Address</b>
                            <div>{communityMemberAddress ? communityMemberAddress : "-"}</div>
                        </div>
                        <div>
                            <b>Email</b>
                            <div>{communityMemberEmail ? communityMemberEmail : "-"}</div>
                        </div>
                    </div>
                </div> */}
                {
                    listOfQuestion.questions.length > 0 && <div className="questions">
                        {
                            listOfQuestion.questions.map((row) => {
                                let { id, question } = row;
                                return <div className="question" key={id}>
                                    <b>{question}</b>
                                    {
                                        answers[id] ? <div dangerouslySetInnerHTML={{ __html: answers[id] }}></div> : <div>-</div>
                                    }
                                </div>
                            })
                        }
                    </div>
                }
            </div>
        </>
    }
    return getContent();
}
