import {
  SET_CALENDAR,
  SET_MULTIPLE_CALENDAR,
  RESET_CALENDAR,
} from '../constants/actionTypes';

let time = new Date()

const initialState = {
  userId: "",
  companyId: "",
  events: [],
  meetingModal: false,
  meetingViewModal: false,
  viewDetails: {},
  title: "",
  description: "",
  startTime: time,
  endTime: time,
  members: [],
  timezone: "",
  errors: {
  },
  editId: "",
  usersOptions: [],
  usersAllOptions: {},
  membersOptions: [],
  membersAllOptions: {},
  timezoneOptions: [
    { label: "IST", value: "IST" },
    { label: "EST", value: "EST" }
  ],
  fromDate: "",
  endDate: "",
  pageText: null,
  editTextChanges: false,
  editResponseData: {},
  membersTypes: [],
  loaderCalendar: false,
  existData: {},
  leaveAddModal: false,
  noOfLeaveDays: 1,
  reasonforLeave: "",
  leaves: [],
  birthday: [],
  leaveViewModal: false,
  selfCancelNotes: "",
  cancelModal: false,
  leaveStatus: "pending",
  approvalModal: false,
  leaveApprovalNotes: "",
  taskEventList: [],
  taskListModalShow: false,
  holidayList: [],
  calendlyEvent: [],
  schedulerEventModal: false,
  repeatTypeSelected: "oneTime",
  repeatStartDate: new Date(),
  repeatEndDate: new Date(),
};

const calendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CALENDAR: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_CALENDAR: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_CALENDAR: {
      return {
        ...state,
        userId: "",
        companyId: "",
        events: [],
        meetingModal: false,
        meetingViewModal: false,
        viewDetails: {},
        title: "",
        description: "",
        startTime: "",
        endTime: "",
        members: [],
        timezone: "",
        errors: {},
        editId: "",
        membersOptions: [],
        membersAllOptions: {},
        timezoneOptions: [
          { label: "IST", value: "IST" },
          { label: "EST", value: "EST" }
        ],
        fromDate: "",
        endDate: "",
        pageText: null,
        editTextChanges: false,
        editResponseData: {},
        loaderCalendar: false,
        existData: {},
        leaveAddModal: false,
        noOfLeaveDays: 1,
        reasonforLeave: "",
        leaves: [],
        birthday: [],
        leaveViewModal: false,
        selfCancelNotes: "",
        cancelModal: false,
        leaveStatus: "pending",
        approvalModal: false,
        leaveApprovalNotes: "",
        taskEventList: [],
        taskListModalShow: false,
        holidayList: [],
        calendlyEvent: [],
        schedulerEventModal: false,
        repeatTypeSelected: "oneTime",
        repeatStartDate: new Date(),
        repeatEndDate: new Date(),
      };
    }
    default: {
      return state;
    }
  }
};

export default calendarReducer;
