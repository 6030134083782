import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    ReactFlow,
    useNodesState,
    useEdgesState,
    Controls,
    Background,
    NodeToolbar,
    Handle,
    Position
} from '@xyflow/react';
import dagre from '@dagrejs/dagre';
import '@xyflow/react/dist/style.css';
import { withRouter } from 'react-router-dom';
import { generateShades } from '../../../config/Common';
import Modal from '../../../components/Modal';

const position = { x: 0, y: 0 };
const edgeType = 'smoothstep';
const dagreGraph = new dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));

const nodeWidth = 172;
const nodeHeight = 36;
const proOptions = { hideAttribution: true };
const nameRegex = /^[a-z_]+$/;

const calculateNodeHeight = (data) => {
    let { aiAgentFlow, properties } = data

    const baseHeight = 20;
    let additionalHeight = 0; // Add extra height 
    // if(aiAgentFlow) {
    //     additionalHeight = 40
    // } else if(properties) {
    //     additionalHeight = 50
    // }
    return baseHeight + additionalHeight;
};

const getLayoutedElements = (nodes, edges, direction = 'LR') => {
    const isHorizontal = direction === 'LR';
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => {
        const nodeHeight = calculateNodeHeight(node.data);
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    const newNodes = nodes.map((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);
        const newNode = {
            ...node,
            targetPosition: isHorizontal ? 'left' : 'top',
            sourcePosition: isHorizontal ? 'right' : 'bottom',
            // We are shifting the dagre node position (anchor=center center) to the top left
            // so it matches the React Flow node anchor point (top left).
            position: {
                x: nodeWithPosition.x - nodeWidth / 2,
                y: nodeWithPosition.y - nodeHeight / 2,
            },
        };

        return newNode;
    });

    return { nodes: newNodes, edges };
};

const nodeTypes = {
    'node-with-toolbar': NodeWithToolbar,
    'node-with-properties': NodeWithProperties
};

function NodeWithToolbar(props) {
    let { data } = props;
    let { label, backgroundColor, toolbarButtons, aiAgentFlow, updateDiagramData } = data;
    function getAiAgentDetails() {
        let str;

        if (aiAgentFlow) {
            let { toolCall } = aiAgentFlow;
            let { description, parameters } = toolCall.function
            str = <React.Fragment>
                {/* <div className='toolcall-description'>
                    <span className='text-capitalize'>{description}</span>
                    <button className='btn btn-parameters' onClick={(e) => {
                        e.stopPropagation()
                        updateDiagramData()
                    }}>
                        <i className='fa fa-plus'></i> Additional Parameters
                    </button>
                </div> */}
            </React.Fragment>
        }

        return str
    }

    return (
        <>
            <NodeToolbar
                position={data.toolbarPosition}
            >
                {
                    toolbarButtons && toolbarButtons.map((button, index) => (
                        <button key={index} onClick={button.onClick}>
                            {button.label}
                        </button>
                    ))
                }
            </NodeToolbar>
            <Handle type="source" position="right" />
            <Handle type="target" position="left" />
            <div className="react-flow__node-default" style={{ backgroundColor }}>
                {label}
                {getAiAgentDetails()}
            </div>
        </>
    );
}

function NodeWithProperties(props) {
    let { data } = props;
    let { label, backgroundColor, toolbarButtons, properties, required, updateDiagramData } = data;

    return (
        <>
            <NodeToolbar
                position={data.toolbarPosition}
            >
                {
                    toolbarButtons && toolbarButtons.map((button, index) => (
                        <button key={index} onClick={button.onClick}>
                            {button.label}
                        </button>
                    ))
                }
            </NodeToolbar>
            <Handle type="source" position="right" />
            <Handle type="target" position="left" />
            <div className="react-flow__node-default text-left properties-form" style={{ backgroundColor }}>
                <div className='required-box'>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={required.includes(label)} onChange={(e) => {
                            updateDiagramData("isRequired", e.target.checked)
                        }} />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            Required
                        </label>
                    </div>
                </div>
                <div className='form-group'>
                    <label>Name</label>
                    <div><b>{label}</b></div>
                </div>
                <div className='form-group'>
                    <label>Description</label>
                    <input className='form-control' defaultValue={properties.description} onChange={(e) => {
                        updateDiagramData("description", e.target.value)
                    }} />
                </div>
            </div>
        </>
    );
}

const Flow = (props) => {
    let { initialNodes, initialEdges } = props
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    useEffect(() => {
        const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
            initialNodes,
            initialEdges,
            'LR' // 'LR' for Left to Right, 'TB' for Top to Bottom
        );

        setNodes(layoutedNodes);
        setEdges(layoutedEdges);
    }, [initialNodes, initialEdges]);


    return <React.Fragment>
        <ReactFlow proOptions={proOptions} nodes={nodes.map((node) => {
            return {
                ...node,
            }
        }
        )}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            nodeTypes={nodeTypes}
            fitView>
            <Background />
            <Controls />
        </ReactFlow>
    </React.Fragment>
};

function FlowDiagram(props) {
    let { diagramData, editingUserId, userId } = props;
    let [initialNodes, setInitialNodes] = useState([]);
    let [initialEdges, setInitialEdges] = useState([]);
    let [addParameter, setAddParameter] = useState(null);
    let [parameterName, setParameterName] = useState("");

    useEffect(() => {
        initData()
    }, [diagramData])

    function initData() {
        let initialNodes = [];
        let initialEdges = [];

        for (let i = 0; i < diagramData.length; i++) {
            let { id, name, blocks, color } = diagramData[i]

            initialNodes.push({
                id,
                data: {
                    label: name, backgroundColor: color ? color : "#63ad71",
                    toolbarPosition: Position.Right,
                    toolbarButtons: (editingUserId == "" || editingUserId === userId) ? [
                        { label: 'Edit', onClick: () => { props.editLane(diagramData[i]) } },
                        { label: 'Delete', onClick: () => { props.deleteLane(i) } },
                    ] : [],
                },
                position: position,
                style: {
                    backgroundColor: color ? color : "#63ad71",
                    color: "#fff",
                },
                type: 'node-with-toolbar',
            })

            let shades = generateShades(color, 5)
            for (let j = 0; j < blocks.length; j++) {
                if (Object.keys(blocks[j]).length > 0) {
                    let { id: childId, title, parentFlow, type, aiAgentFlow } = blocks[j];
                    initialNodes.push({
                        id: childId,
                        data: {
                            label: title,
                            aiAgentFlow,
                            backgroundColor: shades[1],
                            toolbarPosition: Position.Right,
                            toolbarButtons: (editingUserId == "" || editingUserId === userId) ? [
                                { label: 'View', onClick: () => { props.viewBlock(blocks[j], name) } },
                                { label: 'Edit', onClick: () => { props.editBlock(blocks[j], name) } },
                                { label: 'Delete', onClick: () => { props.deleteBlock(blocks[j], name) } },
                            ] : [
                                { label: 'View', onClick: () => { props.viewBlock(blocks[j], name) } },
                            ],
                            updateDiagramData: () => {
                                setAddParameter({
                                    laneIndex: i,
                                    blockIndex: j
                                })
                            }
                        },
                        position: position,
                        style: {
                            backgroundColor: shades[1],
                            color: "#fff"
                        },
                        type: 'node-with-toolbar',
                    })

                    if (parentFlow) {
                        initialEdges.push({ id: 'edges-' + childId, source: parentFlow, target: childId, type: edgeType, animated: true })
                    } else {
                        initialEdges.push({ id: 'parent-edges-' + childId, source: id, target: childId, type: edgeType, animated: true })
                    }
                }
            }
        }
        setInitialNodes(initialNodes)
        setInitialEdges(initialEdges)
    }

    return <div style={{ width: '100%', height: '100%' }}>
        <Flow initialNodes={initialNodes} initialEdges={initialEdges} {...props} />
        {
            addParameter && <Modal
                visible={addParameter ? true : false}
                heading={`Add Parameters`}
                closeModal={() => setAddParameter(null)}
                body={<React.Fragment>
                    <div className='form-group'>
                        <label className='custom'>Parameter Name</label>
                        <input
                            type="text"
                            name="name"
                            placeholder="Name"
                            className={`form-control ${(parameterName !== "" && !nameRegex.test(parameterName)) ? 'is-invalid' : ''}`}
                            value={parameterName}
                            onChange={(e) => setParameterName(e.target.value)}
                        />
                        {
                            (parameterName !== "" && !nameRegex.test(parameterName)) && <div className="invalid-feedback">{'Function name must be lowercase letters and underscores only'}</div>
                        }
                    </div>
                    <div className='text-center mt-3'>
                        <button className='btn btn-sm btn-primary' onClick={() => {
                            let { laneIndex, blockIndex } = addParameter
                            diagramData[laneIndex].blocks[blockIndex].aiAgentFlow.toolCall.function.parameters.properties[parameterName] = {
                                description: "",
                                type: 'string'
                            }
                            props.updateDiagramData(diagramData, true)
                            setAddParameter(null)
                        }} disabled={parameterName === "" || !nameRegex.test(parameterName)}>Save</button>
                        <button className='btn btn-sm btn-secondary ml-2' onClick={() => {
                            setAddParameter(null)
                        }}>Cancel</button>
                    </div>
                </React.Fragment>
                }
            />
        }
    </div>;
}

export default withRouter(FlowDiagram)