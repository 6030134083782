import sound1 from '../assets/message/sound1.wav';
import sound2 from '../assets/message/sound2.wav';
import sound3 from '../assets/message/sound3.wav';
import sound4 from '../assets/message/sound4.wav';
import sound5 from '../assets/message/sound5.wav';
import sound6 from '../assets/message/sound6.wav';
import sound7 from '../assets/message/sound7.wav';
import sound8 from '../assets/message/sound8.wav';
import GlobalMsgCons from './GlobalMsgCons';
import Emoji from "../assets/community/request/Emoji.png";
import Record from "../assets/community/Record.png";
import ChildAbuse from "../assets/community/ChildAbuse.png";
import DomesticViolence from "../assets/community/request/DomesticViolence.png";
import HumanTrafficking from "../assets/community/request/HumanTrafficking.png";
import SexualViolence from "../assets/community/SexualViolence.png";
import Stalking from "../assets/community/request/Stalking.png";
import TechnologyAssistedAbuse from "../assets/community/request/TechnologyAssistedAbuse.png";

const repeatTypeOneTime = "oneTime";
const repeatTypeDaily = "daily";
const repeatTypeWeekly = "weekly";
const repeatTypeMonthly = "monthly";
const MyConfig = {
  siteKey: "6LebX4EpAAAAABixdz98JTyTUBaAFwy9KRwDaxML", //Register page - recpatcha 
  languageSetup: "languageSetup",
  tsquareOrgId: "0e7aa5df22ae40bc939bc3bbe98878d0",
  communityServiceOrgId: "fa0d55047bb94797a4615628939f1388",
  communityServicePriority: [
    // { label: "Low", value: 1 },
    { label: "Mid", value: "5" },
    { label: "High", value: "10" }
  ],
  communityServiceRoles: [
    { label: "Survivor", value: GlobalMsgCons.roleTypeSurvivor },
    { label: "Community Member", value: GlobalMsgCons.roleTypeCommunityMember }
  ],
  communityServiceRolesInvite: [
    { label: "Admin", value: GlobalMsgCons.roleTypeCommunityServiceAdmin },
    { label: "Survivor", value: GlobalMsgCons.roleTypeSurvivor },
    { label: "Community Member", value: GlobalMsgCons.roleTypeCommunityMember },
    { label: "CBO", value: GlobalMsgCons.roleTypeCbo },
    { label: "LNSC", value: GlobalMsgCons.roleTypeLNSC }
  ],
  healthcareServiceRolesInvite: [
    { label: "Admin", value: GlobalMsgCons.roleTypeHealthServiceAdmin },
    { label: "Doctor", value: GlobalMsgCons.roleTypeDoctor },
    { label: "Nurse", value: GlobalMsgCons.roleTypeNurse },
  ],
  customerSupportRolesInvite: [
    { label: "Customer Support Member", value: GlobalMsgCons.roleTypeCustomerSupportMember },
    { label: "Customer Support Manager", value: GlobalMsgCons.roleTypeCustomerSupportMemberAdmin },
    { label: "Forward deployed engineer", value: GlobalMsgCons.roleTypeForwardDeployedEngineer },
    { label: "Client Engineer", value: GlobalMsgCons.roleTypeClientEngineer },
    { label: "Client Manager", value: GlobalMsgCons.roleTypeClientManger },
    { label: "Manager", value: GlobalMsgCons.roleTypeMemberAdmin }
  ],
  multiplayerGamingRolesInvite: [
    { label: "Player", value: GlobalMsgCons.roleTypePlayer },
    { label: "Leader", value: GlobalMsgCons.roleTypeLeader }
  ],
  healthcareServiceRolesAssistant: [
    { label: "Doctor", value: GlobalMsgCons.roleTypeDoctor },
    { label: "Nurse", value: GlobalMsgCons.roleTypeNurse },
  ],
  healthcareServicePatientSex: [
    { label: "Male", value: "M" },
    { label: "Female", value: "F" },
    { label: "Other", value: "O" },
    { label: "Unknown", value: "U" },
  ],
  communityServiceFormOption: [
    { label: "Report Here", value: "form" },
    { label: "Emoji", value: "emoji", img: Emoji },
    { label: "Voice Record", value: "voice-record", img: Record },
  ],
  communityServiceRelationShip: [
    { label: "Relative", value: "Relative" },
    { label: "Friend", value: "Friend" },
    { label: "Other", value: "Other" },
  ],
  communityMemberDistance: [
    { label: "In the same space", value: "In the same space" },
    { label: "Within 500 feet", value: "Within 500 feet" },
    { label: "Within .5 kilometer", value: "Within .5 kilometer" },
    { label: "Within 1 kilometer", value: "Within 1 kilometer" },
    { label: "More than 1 kilometer", value: "More than 1 kilometer" },
  ],
  communityServiceEmojiOption: [
    { label: "Child Abuse", img: ChildAbuse, value: "Child Abuse", typeOfViolence: "Child Abuse" },
    { label: "Domestic Violence", img: DomesticViolence, value: "Domestic Violence", typeOfViolence: "Domestic Violence" },
    { label: "Human Trafficking", img: HumanTrafficking, value: "Human Trafficking", typeOfViolence: "Human Trafficking" },
    { label: "Sexual Violence", img: SexualViolence, value: "Sexual Violence", typeOfViolence: "Sexual Violence" },
    { label: "Stalking", img: Stalking, value: "Stalking", typeOfViolence: "Stalking" },
    { label: "Technology Assisted Abuse", img: TechnologyAssistedAbuse, value: "Technology Assisted Abuse", typeOfViolence: "Technology Assisted Abuse" }
  ],
  LanguagedataLoadFromApi: false,
  gifPickerAPIkey: "0UFZAE1GXL8M",
  environmentData: [{ label: "Python", value: "python" }, { label: "Java", value: "java" }, { label: "Scala", value: "scala" }, { label: "Node", value: "node" }, { label: "Julia", value: "julia" },],
  status: [
    { value: "pending", label: "Pending" },
    { value: "completed", label: "Completed" },
    { value: "processing", label: "Processing" },
    { value: "feature addition", label: "Feature Addition" },
    { value: "issue fixed", label: "Issue Fixed" },
    { value: "not an app issue", label: "Not an App Issue" },
    { value: "duplicate", label: "Duplicate" },
    { value: "clarification required", label: "Clarification Required" }
  ],
  customerFeedbackStatus: [
    { value: "pending", label: "Pending" },
    { value: "completed", label: "Completed" },
    { value: "processing", label: "Processing" },
    { value: "feature addition", label: "Feature Addition" },
    { value: "issue fixed", label: "Issue Fixed" },
    { value: "not an app issue", label: "Not an App Issue" },
    { value: "duplicate", label: "Duplicate" },
    { value: "clarification required", label: "Clarification Required" }
  ],
  dailyReportStatus: [
    { value: "inProgress", label: "In Progress" },
    { value: "completed", label: "Completed" },
  ],
  leaveApprovalStatus: [
    { value: "rejected", label: "Rejected" },
    { value: "approved", label: "Approved" },
  ],
  gender: [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" }
  ],
  csEventGender: [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Prefer not to say", label: "Prefer not to say" }
  ],
  csEventGenderCategory: [
    { value: "Person with Disabilities", label: "Person with Disabilities" },
    { value: "Minor", label: "Minor" },
    { value: "Elder", label: "Adult" },
  ],
  csEventAgeRange: [
    { value: "Under 18", label: "Under 18" },
    { value: "18-21", label: "18-21" },
    { value: "22-29", label: "22-29" },
    { value: "30-40", label: "30-40" },
    { value: "41-50", label: "41-50" },
    { value: "51-60", label: "51-60" },
    { value: "61-70", label: "61-70" },
    { value: "Above 70", label: "Above 70" }
  ],
  deviceOs: [
    { value: "android", label: "Android" },
    { value: "ios", label: "IOS" }
  ],
  feedbackType: [
    { value: "bug", label: "Bug" },
    { value: "feature for later", label: "Feature for Later" },
    { value: "feature for now", label: "Feature for Now" }
  ],
  andriodOsVersion: [
    { value: "Android 15", label: "Android 15" },
    { value: "Android 14", label: "Android 14" },
    { value: "Android 13", label: "Android 13" },
    { value: "Android 12", label: "Android 12" },
    { value: "Android 11", label: "Android 11" },
    { value: "Android 10", label: "Android 10" },
    { value: "Pie", label: "Pie" },
    { value: "Oreo", label: "Oreo" },
    { value: "Nougat (7.1.0 - 7.1.2)", label: "Nougat (7.1.0 - 7.1.2)" },
    { value: "Nougat (7.0)", label: "Nougat (7.0)" },
    { value: "Marshmallow", label: "Marshmallow" },
    { value: "Lollipop", label: "Lollipop" },
    { value: "Others", label: "Others" },
  ],
  iosOsVersion: [
    { value: "15.3.1", label: "15.3.1" },
    { value: "15.4 Beta 2", label: "15.4 Beta 2" },
    { value: "14.8.1", label: "14.8.1" },
    { value: "13.7", label: "13.7" },
    { value: "12.5.5", label: "12.5.5" },
    { value: "11.4.1", label: "11.4.1" },
    { value: "10.3.4", label: "10.3.4" },
    { value: "10.3.3", label: "10.3.3" },
    { value: "9.3.6", label: "9.3.6" },
    { value: "8.4.1", label: "8.4.1" },
    { value: "Others", label: "Others" },
  ],
  weekStart: [
    { value: "Sunday", label: "Sunday" },
    { value: "Monday", label: "Monday" },
    { value: "Saturday", label: "Saturday" }
  ],
  dateFormat: [
    { value: "MM/DD/YYYY", label: "MM/DD/YYYY (e.g. 01/01/2022)" },
    { value: "DD/MM/YYYY", label: "DD/MM/YYYY (e.g. 01/01/2022)" },
    { value: "YYYY-MM-DD", label: "YYYY-MM-DD (e.g. 2022-01-01)" },
    { value: "DD.MM.YYYY", label: "DD.MM.YYYY (e.g. 01.01.2022)" },
    { value: "YYYY.MM.DD", label: "YYYY.MM.DD (e.g. 2022.01.01)" },
    { value: "YYYY/MM/DD", label: "YYYY/MM/DD (e.g. 2022/01/01)" }
  ],
  companyType: [
    { value: GlobalMsgCons.orgTypeCompany, label: "Company" },
    { value: GlobalMsgCons.orgTypeEducation, label: "Education" },
    { value: GlobalMsgCons.orgTypeCommunityService, label: "Community Service" },
    { value: GlobalMsgCons.orgTypeHealthcareService, label: "Healthcare Service" },
    { value: GlobalMsgCons.orgTypeCustomerSupport, label: "Customer Support" },
    { value: GlobalMsgCons.orgTypeMultiplayerGaming, label: "Multiplayer Gaming" }
  ],
  currencyFormat: [
    { "value": "USD", "label": "United States Dollar - USD" },
    { "value": "ALL", "label": "Albania Lek - ALL" },
    { "value": "AFN", "label": "Afghanistan Afghani - AFN" },
    { "value": "ARS", "label": "Argentina Peso - ARS" },
    { "value": "AWG", "label": "Aruba Guilder - AWG" },
    { "value": "AUD", "label": "Australia Dollar - AUD" },
    { "value": "AZN", "label": "Azerbaijan New Manat - AZN" },
    { "value": "BSD", "label": "Bahamas Dollar - BSD" },
    { "value": "BBD", "label": "Barbados Dollar - BBD" },
    { "value": "BYN", "label": "Belarus Ruble - BYN" },
    { "value": "BZD", "label": "Belize Dollar - BZD" },
    { "value": "BMD", "label": "Bermuda Dollar - BMD" },
    { "value": "BOB", "label": "Bolivia BolÃ­viano - BOB" },
    { "value": "BAM", "label": "Bosnia and Herzegovina Convertible Marka - BAM" },
    { "value": "BWP", "label": "Botswana Pula - BWP" },
    { "value": "BGN", "label": "Bulgaria Lev - BGN" },
    { "value": "BRL", "label": "Brazil Real - BRL" },
    { "value": "BND", "label": "Brunei Darussalam Dollar - BND" },
    { "value": "XAF", "label": "Cameroon - XAF" },
    { "value": "KHR", "label": "Cambodia Riel - KHR" },
    { "value": "CAD", "label": "Canada Dollar - CAD" },
    { "value": "KYD", "label": "Cayman Islands Dollar - KYD" },
    { "value": "CLP", "label": "Chile Peso - CLP" },
    { "value": "CNY", "label": "China Yuan Renminbi - CNY" },
    { "value": "COP", "label": "Colombia Peso - COP" },
    { "value": "CRC", "label": "Costa Rica Colon - CRC" },
    { "value": "HRK", "label": "Croatia Kuna - HRK" },
    { "value": "CUP", "label": "Cuba Peso - CUP" },
    { "value": "CZK", "label": "Czech Republic Koruna - CZK" },
    { "value": "DKK", "label": "Denmark Krone - DKK" },
    { "value": "DOP", "label": "Dominican Republic Peso - DOP" },
    { "value": "XCD", "label": "East Caribbean Dollar - XCD" },
    { "value": "EGP", "label": "Egypt Pound - EGP" },
    { "value": "SVC", "label": "El Salvador Colon - SVC" },
    { "value": "EUR", "label": "Euro Member Countries - EUR" },
    { "value": "FKP", "label": "Falkland Islands (Malvinas) Pound - FKP" },
    { "value": "FJD", "label": "Fiji Dollar - FJD" },
    { "value": "GHS", "label": "Ghana Cedi - GHS" },
    { "value": "GIP", "label": "Gibraltar Pound - GIP" },
    { "value": "GTQ", "label": "Guatemala Quetzal - GTQ" },
    { "value": "GGP", "label": "Guernsey Pound - GGP" },
    { "value": "GYD", "label": "Guyana Dollar - GYD" },
    { "value": "HNL", "label": "Honduras Lempira - HNL" },
    { "value": "HKD", "label": "Hong Kong Dollar - HKD" },
    { "value": "HUF", "label": "Hungary Forint - HUF" },
    { "value": "ISK", "label": "Iceland Krona - ISK" },
    { "value": "INR", "label": "India Rupee - INR" },
    { "value": "IDR", "label": "Indonesia Rupiah - IDR" },
    { "value": "IRR", "label": "Iran Rial - IRR" },
    { "value": "IMP", "label": "Isle of Man Pound - IMP" },
    { "value": "ILS", "label": "Israel Shekel - ILS" },
    { "value": "JMD", "label": "Jamaica Dollar - JMD" },
    { "value": "JPY", "label": "Japan Yen - JPY" },
    { "value": "JEP", "label": "Jersey Pound - JEP" },
    { "value": "KZT", "label": "Kazakhstan Tenge - KZT" },
    { "value": "KPW", "label": "Korea (North) Won - KPW" },
    { "value": "KRW", "label": "Korea (South) Won - KRW" },
    { "value": "KGS", "label": "Kyrgyzstan Som - KGS" },
    { "value": "LAK", "label": "Laos Kip - LAK" },
    { "value": "LBP", "label": "Lebanon Pound - LBP" },
    { "value": "LRD", "label": "Liberia Dollar - LRD" },
    { "value": "MKD", "label": "Macedonia Denar - MKD" },
    { "value": "MYR", "label": "Malaysia Ringgit - MYR" },
    { "value": "MUR", "label": "Mauritius Rupee - MUR" },
    { "value": "MXN", "label": "Mexico Peso - MXN" },
    { "value": "MNT", "label": "Mongolia Tughrik - MNT" },
    { "value": "MZN", "label": "Mozambique Metical - MZN" },
    { "value": "NAD", "label": "Namibia Dollar - NAD" },
    { "value": "NPR", "label": "Nepal Rupee - NPR" },
    { "value": "ANG", "label": "Netherlands Antilles Guilder - ANG" },
    { "value": "NZD", "label": "New Zealand Dollar - NZD" },
    { "value": "NIO", "label": "Nicaragua Cordoba - NIO" },
    { "value": "NGN", "label": "Nigeria Naira - NGN" },
    { "value": "KPW", "label": "Korea (North) Won - KPW" },
    { "value": "NOK", "label": "Norway Krone - NOK" },
    { "value": "OMR", "label": "Oman Rial - OMR" },
    { "value": "PKR", "label": "Pakistan Rupee - PKR" },
    { "value": "PAB", "label": "Panama Balboa - PAB" },
    { "value": "PYG", "label": "Paraguay Guarani - PYG" },
    { "value": "PEN", "label": "Peru Sol - PEN" },
    { "value": "PHP", "label": "Philippines Peso - PHP" },
    { "value": "PLN", "label": "Poland Zloty - PLN" },
    { "value": "QAR", "label": "Qatar Riyal - QAR" },
    { "value": "RWF", "label": "Rwanda - RWF" },
    { "value": "RON", "label": "Romania New Leu - RON" },
    { "value": "RUB", "label": "Russia Ruble - RUB" },
    { "value": "SHP", "label": "Saint Helena Pound - SHP" },
    { "value": "SAR", "label": "Saudi Arabia Riyal - SAR" },
    { "value": "RSD", "label": "Serbia Dinar - RSD" },
    { "value": "SCR", "label": "Seychelles Rupee - SCR" },
    { "value": "SGD", "label": "Singapore Dollar - SGD" },
    { "value": "SBD", "label": "Solomon Islands Dollar - SBD" },
    { "value": "SOS", "label": "Somalia Shilling - SOS" },
    { "value": "ZAR", "label": "South Africa Rand - ZAR" },
    { "value": "KRW", "label": "Korea (South) Won - KRW" },
    { "value": "LKR", "label": "Sri Lanka Rupee - LKR" },
    { "value": "SEK", "label": "Sweden Krona - SEK" },
    { "value": "CHF", "label": "Switzerland Franc - CHF" },
    { "value": "SRD", "label": "Suriname Dollar - SRD" },
    { "value": "SYP", "label": "Syria Pound - SYP" },
    { "value": "TWD", "label": "Taiwan New Dollar - TWD" },
    { "value": "THB", "label": "Thailand Baht - THB" },
    { "value": "TTD", "label": "Trinidad and Tobago Dollar - TTD" },
    { "value": "TRY", "label": "Turkey Lira - TRY" },
    { "value": "TVD", "label": "Tuvalu Dollar - TVD" },
    { "value": "UAH", "label": "Ukraine Hryvnia - UAH" },
    { "value": "UGX", "label": "Uganda - UGX" },
    { "value": "GBP", "label": "United Kingdom Pound - GBP" },
    { "value": "UYU", "label": "Uruguay Peso - UYU" },
    { "value": "UZS", "label": "Uzbekistan Som - UZS" },
    { "value": "VEF", "label": "Venezuela Bolivar - VEF" },
    { "value": "VND", "label": "Viet Nam Dong - VND" },
    { "value": "YER", "label": "Yemen Rial - YER" },
    { "value": "ZWD", "label": "Zimbabwe Dollar - ZWD" }
  ],
  project_Name: "Teqit",
  project_LocalStorage: "teqit_",
  project_RouteName: "/teqit",
  statusQas: [
    { value: "pending", label: "Pending" },
    { value: "taken", label: "Taken" },
    { value: "assign", label: "Assign" },
    { value: "completed", label: "Completed" },
    { value: "processing", label: "Processing" },
    { value: "feature addition", label: "Feature Addition" },
    { value: "issue fixed", label: "Issue Fixed" },
    { value: "not an app issue", label: "Not an App Issue" },
    { value: "duplicate", label: "Duplicate" },
    { value: "clarification required", label: "Clarification Required" }
  ],
  messageSound: [
    { value: "sound1", label: "Sound 1", audio: sound1 },
    { value: "sound2", label: "Sound 2", audio: sound2 },
    { value: "sound3", label: "Sound 3", audio: sound3 },
    { value: "sound4", label: "Sound 4", audio: sound4 },
    { value: "sound5", label: "Sound 5", audio: sound5 },
    { value: "sound6", label: "Sound 6", audio: sound6 },
    { value: "sound7", label: "Sound 7", audio: sound7 },
    { value: "sound8", label: "Sound 8", audio: sound8 },
  ],
  workSpaceStatusOption: [{ label: "Verified", value: GlobalMsgCons.workspaceStaus_verified }, { label: "Need Documents", value: GlobalMsgCons.workspaceStaus_needDocument }, { label: "Reject", value: GlobalMsgCons.reject }],
  hourFormate: [{ label: "12 Hours", value: 12 }, { label: "24 Hours", value: 24 }],
  surveyOptions: [
    { value: "single", label: "Single" },
    { value: "multiple", label: "Multiple" },
  ],
  eventTypeOptions: [
    { value: "timeRange", label: "Time range" },
    { value: "fixedTime", label: "Without time Range" },
    { value: "preEventSurvey", label: "Pre Event Survey" },
  ],
  timezoneList: [
    {
      "label": "Africa/Abidjan",
      "value": "Africa/Abidjan"
    },
    {
      "label": "Africa/Accra",
      "value": "Africa/Accra"
    },
    {
      "label": "Africa/Addis_Ababa",
      "value": "Africa/Addis_Ababa"
    },
    {
      "label": "Africa/Algiers",
      "value": "Africa/Algiers"
    },
    {
      "label": "Africa/Asmera",
      "value": "Africa/Asmera"
    },
    {
      "label": "Africa/Bamako",
      "value": "Africa/Bamako"
    },
    {
      "label": "Africa/Bangui",
      "value": "Africa/Bangui"
    },
    {
      "label": "Africa/Banjul",
      "value": "Africa/Banjul"
    },
    {
      "label": "Africa/Bissau",
      "value": "Africa/Bissau"
    },
    {
      "label": "Africa/Blantyre",
      "value": "Africa/Blantyre"
    },
    {
      "label": "Africa/Brazzaville",
      "value": "Africa/Brazzaville"
    },
    {
      "label": "Africa/Bujumbura",
      "value": "Africa/Bujumbura"
    },
    {
      "label": "Africa/Cairo",
      "value": "Africa/Cairo"
    },
    {
      "label": "Africa/Casablanca",
      "value": "Africa/Casablanca"
    },
    {
      "label": "Africa/Ceuta",
      "value": "Africa/Ceuta"
    },
    {
      "label": "Africa/Conakry",
      "value": "Africa/Conakry"
    },
    {
      "label": "Africa/Dakar",
      "value": "Africa/Dakar"
    },
    {
      "label": "Africa/Dar_es_Salaam",
      "value": "Africa/Dar_es_Salaam"
    },
    {
      "label": "Africa/Djibouti",
      "value": "Africa/Djibouti"
    },
    {
      "label": "Africa/Douala",
      "value": "Africa/Douala"
    },
    {
      "label": "Africa/El_Aaiun",
      "value": "Africa/El_Aaiun"
    },
    {
      "label": "Africa/Freetown",
      "value": "Africa/Freetown"
    },
    {
      "label": "Africa/Gaborone",
      "value": "Africa/Gaborone"
    },
    {
      "label": "Africa/Harare",
      "value": "Africa/Harare"
    },
    {
      "label": "Africa/Johannesburg",
      "value": "Africa/Johannesburg"
    },
    {
      "label": "Africa/Juba",
      "value": "Africa/Juba"
    },
    {
      "label": "Africa/Kampala",
      "value": "Africa/Kampala"
    },
    {
      "label": "Africa/Khartoum",
      "value": "Africa/Khartoum"
    },
    {
      "label": "Africa/Kigali",
      "value": "Africa/Kigali"
    },
    {
      "label": "Africa/Kinshasa",
      "value": "Africa/Kinshasa"
    },
    {
      "label": "Africa/Lagos",
      "value": "Africa/Lagos"
    },
    {
      "label": "Africa/Libreville",
      "value": "Africa/Libreville"
    },
    {
      "label": "Africa/Lome",
      "value": "Africa/Lome"
    },
    {
      "label": "Africa/Luanda",
      "value": "Africa/Luanda"
    },
    {
      "label": "Africa/Lubumbashi",
      "value": "Africa/Lubumbashi"
    },
    {
      "label": "Africa/Lusaka",
      "value": "Africa/Lusaka"
    },
    {
      "label": "Africa/Malabo",
      "value": "Africa/Malabo"
    },
    {
      "label": "Africa/Maputo",
      "value": "Africa/Maputo"
    },
    {
      "label": "Africa/Maseru",
      "value": "Africa/Maseru"
    },
    {
      "label": "Africa/Mbabane",
      "value": "Africa/Mbabane"
    },
    {
      "label": "Africa/Mogadishu",
      "value": "Africa/Mogadishu"
    },
    {
      "label": "Africa/Monrovia",
      "value": "Africa/Monrovia"
    },
    {
      "label": "Africa/Nairobi",
      "value": "Africa/Nairobi"
    },
    {
      "label": "Africa/Ndjamena",
      "value": "Africa/Ndjamena"
    },
    {
      "label": "Africa/Niamey",
      "value": "Africa/Niamey"
    },
    {
      "label": "Africa/Nouakchott",
      "value": "Africa/Nouakchott"
    },
    {
      "label": "Africa/Ouagadougou",
      "value": "Africa/Ouagadougou"
    },
    {
      "label": "Africa/Porto-Novo",
      "value": "Africa/Porto-Novo"
    },
    {
      "label": "Africa/Sao_Tome",
      "value": "Africa/Sao_Tome"
    },
    {
      "label": "Africa/Tripoli",
      "value": "Africa/Tripoli"
    },
    {
      "label": "Africa/Tunis",
      "value": "Africa/Tunis"
    },
    {
      "label": "Africa/Windhoek",
      "value": "Africa/Windhoek"
    },
    {
      "label": "America/Adak",
      "value": "America/Adak"
    },
    {
      "label": "America/Anchorage",
      "value": "America/Anchorage"
    },
    {
      "label": "America/Anguilla",
      "value": "America/Anguilla"
    },
    {
      "label": "America/Antigua",
      "value": "America/Antigua"
    },
    {
      "label": "America/Araguaina",
      "value": "America/Araguaina"
    },
    {
      "label": "America/Argentina/La_Rioja",
      "value": "America/Argentina/La_Rioja"
    },
    {
      "label": "America/Argentina/Rio_Gallegos",
      "value": "America/Argentina/Rio_Gallegos"
    },
    {
      "label": "America/Argentina/Salta",
      "value": "America/Argentina/Salta"
    },
    {
      "label": "America/Argentina/San_Juan",
      "value": "America/Argentina/San_Juan"
    },
    {
      "label": "America/Argentina/San_Luis",
      "value": "America/Argentina/San_Luis"
    },
    {
      "label": "America/Argentina/Tucuman",
      "value": "America/Argentina/Tucuman"
    },
    {
      "label": "America/Argentina/Ushuaia",
      "value": "America/Argentina/Ushuaia"
    },
    {
      "label": "America/Aruba",
      "value": "America/Aruba"
    },
    {
      "label": "America/Asuncion",
      "value": "America/Asuncion"
    },
    {
      "label": "America/Bahia",
      "value": "America/Bahia"
    },
    {
      "label": "America/Bahia_Banderas",
      "value": "America/Bahia_Banderas"
    },
    {
      "label": "America/Barbados",
      "value": "America/Barbados"
    },
    {
      "label": "America/Belem",
      "value": "America/Belem"
    },
    {
      "label": "America/Belize",
      "value": "America/Belize"
    },
    {
      "label": "America/Blanc-Sablon",
      "value": "America/Blanc-Sablon"
    },
    {
      "label": "America/Boa_Vista",
      "value": "America/Boa_Vista"
    },
    {
      "label": "America/Bogota",
      "value": "America/Bogota"
    },
    {
      "label": "America/Boise",
      "value": "America/Boise"
    },
    {
      "label": "America/Buenos_Aires",
      "value": "America/Buenos_Aires"
    },
    {
      "label": "America/Cambridge_Bay",
      "value": "America/Cambridge_Bay"
    },
    {
      "label": "America/Campo_Grande",
      "value": "America/Campo_Grande"
    },
    {
      "label": "America/Cancun",
      "value": "America/Cancun"
    },
    {
      "label": "America/Caracas",
      "value": "America/Caracas"
    },
    {
      "label": "America/Catamarca",
      "value": "America/Catamarca"
    },
    {
      "label": "America/Cayenne",
      "value": "America/Cayenne"
    },
    {
      "label": "America/Cayman",
      "value": "America/Cayman"
    },
    {
      "label": "America/Chicago",
      "value": "America/Chicago"
    },
    {
      "label": "America/Chihuahua",
      "value": "America/Chihuahua"
    },
    {
      "label": "America/Ciudad_Juarez",
      "value": "America/Ciudad_Juarez"
    },
    {
      "label": "America/Coral_Harbour",
      "value": "America/Coral_Harbour"
    },
    {
      "label": "America/Cordoba",
      "value": "America/Cordoba"
    },
    {
      "label": "America/Costa_Rica",
      "value": "America/Costa_Rica"
    },
    {
      "label": "America/Creston",
      "value": "America/Creston"
    },
    {
      "label": "America/Cuiaba",
      "value": "America/Cuiaba"
    },
    {
      "label": "America/Curacao",
      "value": "America/Curacao"
    },
    {
      "label": "America/Danmarkshavn",
      "value": "America/Danmarkshavn"
    },
    {
      "label": "America/Dawson",
      "value": "America/Dawson"
    },
    {
      "label": "America/Dawson_Creek",
      "value": "America/Dawson_Creek"
    },
    {
      "label": "America/Denver",
      "value": "America/Denver"
    },
    {
      "label": "America/Detroit",
      "value": "America/Detroit"
    },
    {
      "label": "America/Dominica",
      "value": "America/Dominica"
    },
    {
      "label": "America/Edmonton",
      "value": "America/Edmonton"
    },
    {
      "label": "America/Eirunepe",
      "value": "America/Eirunepe"
    },
    {
      "label": "America/El_Salvador",
      "value": "America/El_Salvador"
    },
    {
      "label": "America/Fort_Nelson",
      "value": "America/Fort_Nelson"
    },
    {
      "label": "America/Fortaleza",
      "value": "America/Fortaleza"
    },
    {
      "label": "America/Glace_Bay",
      "value": "America/Glace_Bay"
    },
    {
      "label": "America/Godthab",
      "value": "America/Godthab"
    },
    {
      "label": "America/Goose_Bay",
      "value": "America/Goose_Bay"
    },
    {
      "label": "America/Grand_Turk",
      "value": "America/Grand_Turk"
    },
    {
      "label": "America/Grenada",
      "value": "America/Grenada"
    },
    {
      "label": "America/Guadeloupe",
      "value": "America/Guadeloupe"
    },
    {
      "label": "America/Guatemala",
      "value": "America/Guatemala"
    },
    {
      "label": "America/Guayaquil",
      "value": "America/Guayaquil"
    },
    {
      "label": "America/Guyana",
      "value": "America/Guyana"
    },
    {
      "label": "America/Halifax",
      "value": "America/Halifax"
    },
    {
      "label": "America/Havana",
      "value": "America/Havana"
    },
    {
      "label": "America/Hermosillo",
      "value": "America/Hermosillo"
    },
    {
      "label": "America/Indiana/Knox",
      "value": "America/Indiana/Knox"
    },
    {
      "label": "America/Indiana/Marengo",
      "value": "America/Indiana/Marengo"
    },
    {
      "label": "America/Indiana/Petersburg",
      "value": "America/Indiana/Petersburg"
    },
    {
      "label": "America/Indiana/Tell_City",
      "value": "America/Indiana/Tell_City"
    },
    {
      "label": "America/Indiana/Vevay",
      "value": "America/Indiana/Vevay"
    },
    {
      "label": "America/Indiana/Vincennes",
      "value": "America/Indiana/Vincennes"
    },
    {
      "label": "America/Indiana/Winamac",
      "value": "America/Indiana/Winamac"
    },
    {
      "label": "America/Indianapolis",
      "value": "America/Indianapolis"
    },
    {
      "label": "America/Inuvik",
      "value": "America/Inuvik"
    },
    {
      "label": "America/Iqaluit",
      "value": "America/Iqaluit"
    },
    {
      "label": "America/Jamaica",
      "value": "America/Jamaica"
    },
    {
      "label": "America/Jujuy",
      "value": "America/Jujuy"
    },
    {
      "label": "America/Juneau",
      "value": "America/Juneau"
    },
    {
      "label": "America/Kentucky/Monticello",
      "value": "America/Kentucky/Monticello"
    },
    {
      "label": "America/Kralendijk",
      "value": "America/Kralendijk"
    },
    {
      "label": "America/La_Paz",
      "value": "America/La_Paz"
    },
    {
      "label": "America/Lima",
      "value": "America/Lima"
    },
    {
      "label": "America/Los_Angeles",
      "value": "America/Los_Angeles"
    },
    {
      "label": "America/Louisville",
      "value": "America/Louisville"
    },
    {
      "label": "America/Lower_Princes",
      "value": "America/Lower_Princes"
    },
    {
      "label": "America/Maceio",
      "value": "America/Maceio"
    },
    {
      "label": "America/Managua",
      "value": "America/Managua"
    },
    {
      "label": "America/Manaus",
      "value": "America/Manaus"
    },
    {
      "label": "America/Marigot",
      "value": "America/Marigot"
    },
    {
      "label": "America/Martinique",
      "value": "America/Martinique"
    },
    {
      "label": "America/Matamoros",
      "value": "America/Matamoros"
    },
    {
      "label": "America/Mazatlan",
      "value": "America/Mazatlan"
    },
    {
      "label": "America/Mendoza",
      "value": "America/Mendoza"
    },
    {
      "label": "America/Menominee",
      "value": "America/Menominee"
    },
    {
      "label": "America/Merida",
      "value": "America/Merida"
    },
    {
      "label": "America/Metlakatla",
      "value": "America/Metlakatla"
    },
    {
      "label": "America/Mexico_City",
      "value": "America/Mexico_City"
    },
    {
      "label": "America/Miquelon",
      "value": "America/Miquelon"
    },
    {
      "label": "America/Moncton",
      "value": "America/Moncton"
    },
    {
      "label": "America/Monterrey",
      "value": "America/Monterrey"
    },
    {
      "label": "America/Montevideo",
      "value": "America/Montevideo"
    },
    {
      "label": "America/Montreal",
      "value": "America/Montreal"
    },
    {
      "label": "America/Montserrat",
      "value": "America/Montserrat"
    },
    {
      "label": "America/Nassau",
      "value": "America/Nassau"
    },
    {
      "label": "America/New_York",
      "value": "America/New_York"
    },
    {
      "label": "America/Nipigon",
      "value": "America/Nipigon"
    },
    {
      "label": "America/Nome",
      "value": "America/Nome"
    },
    {
      "label": "America/Noronha",
      "value": "America/Noronha"
    },
    {
      "label": "America/North_Dakota/Beulah",
      "value": "America/North_Dakota/Beulah"
    },
    {
      "label": "America/North_Dakota/Center",
      "value": "America/North_Dakota/Center"
    },
    {
      "label": "America/North_Dakota/New_Salem",
      "value": "America/North_Dakota/New_Salem"
    },
    {
      "label": "America/Ojinaga",
      "value": "America/Ojinaga"
    },
    {
      "label": "America/Panama",
      "value": "America/Panama"
    },
    {
      "label": "America/Pangnirtung",
      "value": "America/Pangnirtung"
    },
    {
      "label": "America/Paramaribo",
      "value": "America/Paramaribo"
    },
    {
      "label": "America/Phoenix",
      "value": "America/Phoenix"
    },
    {
      "label": "America/Port-au-Prince",
      "value": "America/Port-au-Prince"
    },
    {
      "label": "America/Port_of_Spain",
      "value": "America/Port_of_Spain"
    },
    {
      "label": "America/Porto_Velho",
      "value": "America/Porto_Velho"
    },
    {
      "label": "America/Puerto_Rico",
      "value": "America/Puerto_Rico"
    },
    {
      "label": "America/Punta_Arenas",
      "value": "America/Punta_Arenas"
    },
    {
      "label": "America/Rainy_River",
      "value": "America/Rainy_River"
    },
    {
      "label": "America/Rankin_Inlet",
      "value": "America/Rankin_Inlet"
    },
    {
      "label": "America/Recife",
      "value": "America/Recife"
    },
    {
      "label": "America/Regina",
      "value": "America/Regina"
    },
    {
      "label": "America/Resolute",
      "value": "America/Resolute"
    },
    {
      "label": "America/Rio_Branco",
      "value": "America/Rio_Branco"
    },
    {
      "label": "America/Santa_Isabel",
      "value": "America/Santa_Isabel"
    },
    {
      "label": "America/Santarem",
      "value": "America/Santarem"
    },
    {
      "label": "America/Santiago",
      "value": "America/Santiago"
    },
    {
      "label": "America/Santo_Domingo",
      "value": "America/Santo_Domingo"
    },
    {
      "label": "America/Sao_Paulo",
      "value": "America/Sao_Paulo"
    },
    {
      "label": "America/Scoresbysund",
      "value": "America/Scoresbysund"
    },
    {
      "label": "America/Sitka",
      "value": "America/Sitka"
    },
    {
      "label": "America/St_Barthelemy",
      "value": "America/St_Barthelemy"
    },
    {
      "label": "America/St_Johns",
      "value": "America/St_Johns"
    },
    {
      "label": "America/St_Kitts",
      "value": "America/St_Kitts"
    },
    {
      "label": "America/St_Lucia",
      "value": "America/St_Lucia"
    },
    {
      "label": "America/St_Thomas",
      "value": "America/St_Thomas"
    },
    {
      "label": "America/St_Vincent",
      "value": "America/St_Vincent"
    },
    {
      "label": "America/Swift_Current",
      "value": "America/Swift_Current"
    },
    {
      "label": "America/Tegucigalpa",
      "value": "America/Tegucigalpa"
    },
    {
      "label": "America/Thule",
      "value": "America/Thule"
    },
    {
      "label": "America/Thunder_Bay",
      "value": "America/Thunder_Bay"
    },
    {
      "label": "America/Tijuana",
      "value": "America/Tijuana"
    },
    {
      "label": "America/Toronto",
      "value": "America/Toronto"
    },
    {
      "label": "America/Tortola",
      "value": "America/Tortola"
    },
    {
      "label": "America/Vancouver",
      "value": "America/Vancouver"
    },
    {
      "label": "America/Whitehorse",
      "value": "America/Whitehorse"
    },
    {
      "label": "America/Winnipeg",
      "value": "America/Winnipeg"
    },
    {
      "label": "America/Yakutat",
      "value": "America/Yakutat"
    },
    {
      "label": "America/Yellowknife",
      "value": "America/Yellowknife"
    },
    {
      "label": "Antarctica/Casey",
      "value": "Antarctica/Casey"
    },
    {
      "label": "Antarctica/Davis",
      "value": "Antarctica/Davis"
    },
    {
      "label": "Antarctica/DumontDUrville",
      "value": "Antarctica/DumontDUrville"
    },
    {
      "label": "Antarctica/Macquarie",
      "value": "Antarctica/Macquarie"
    },
    {
      "label": "Antarctica/Mawson",
      "value": "Antarctica/Mawson"
    },
    {
      "label": "Antarctica/McMurdo",
      "value": "Antarctica/McMurdo"
    },
    {
      "label": "Antarctica/Palmer",
      "value": "Antarctica/Palmer"
    },
    {
      "label": "Antarctica/Rothera",
      "value": "Antarctica/Rothera"
    },
    {
      "label": "Antarctica/Syowa",
      "value": "Antarctica/Syowa"
    },
    {
      "label": "Antarctica/Troll",
      "value": "Antarctica/Troll"
    },
    {
      "label": "Antarctica/Vostok",
      "value": "Antarctica/Vostok"
    },
    {
      "label": "Arctic/Longyearbyen",
      "value": "Arctic/Longyearbyen"
    },
    {
      "label": "Asia/Aden",
      "value": "Asia/Aden"
    },
    {
      "label": "Asia/Almaty",
      "value": "Asia/Almaty"
    },
    {
      "label": "Asia/Amman",
      "value": "Asia/Amman"
    },
    {
      "label": "Asia/Anadyr",
      "value": "Asia/Anadyr"
    },
    {
      "label": "Asia/Aqtau",
      "value": "Asia/Aqtau"
    },
    {
      "label": "Asia/Aqtobe",
      "value": "Asia/Aqtobe"
    },
    {
      "label": "Asia/Ashgabat",
      "value": "Asia/Ashgabat"
    },
    {
      "label": "Asia/Atyrau",
      "value": "Asia/Atyrau"
    },
    {
      "label": "Asia/Baghdad",
      "value": "Asia/Baghdad"
    },
    {
      "label": "Asia/Bahrain",
      "value": "Asia/Bahrain"
    },
    {
      "label": "Asia/Baku",
      "value": "Asia/Baku"
    },
    {
      "label": "Asia/Bangkok",
      "value": "Asia/Bangkok"
    },
    {
      "label": "Asia/Barnaul",
      "value": "Asia/Barnaul"
    },
    {
      "label": "Asia/Beirut",
      "value": "Asia/Beirut"
    },
    {
      "label": "Asia/Bishkek",
      "value": "Asia/Bishkek"
    },
    {
      "label": "Asia/Brunei",
      "value": "Asia/Brunei"
    },
    {
      "label": "India/Calcutta",
      "value": "Asia/Calcutta"
    },
    {
      "label": "Asia/Chita",
      "value": "Asia/Chita"
    },
    {
      "label": "Asia/Choibalsan",
      "value": "Asia/Choibalsan"
    },
    {
      "label": "Asia/Colombo",
      "value": "Asia/Colombo"
    },
    {
      "label": "Asia/Damascus",
      "value": "Asia/Damascus"
    },
    {
      "label": "Asia/Dhaka",
      "value": "Asia/Dhaka"
    },
    {
      "label": "Asia/Dili",
      "value": "Asia/Dili"
    },
    {
      "label": "Asia/Dubai",
      "value": "Asia/Dubai"
    },
    {
      "label": "Asia/Dushanbe",
      "value": "Asia/Dushanbe"
    },
    {
      "label": "Asia/Famagusta",
      "value": "Asia/Famagusta"
    },
    {
      "label": "Asia/Gaza",
      "value": "Asia/Gaza"
    },
    {
      "label": "Asia/Hebron",
      "value": "Asia/Hebron"
    },
    {
      "label": "Asia/Hong_Kong",
      "value": "Asia/Hong_Kong"
    },
    {
      "label": "Asia/Hovd",
      "value": "Asia/Hovd"
    },
    {
      "label": "Asia/Irkutsk",
      "value": "Asia/Irkutsk"
    },
    {
      "label": "Asia/Jakarta",
      "value": "Asia/Jakarta"
    },
    {
      "label": "Asia/Jayapura",
      "value": "Asia/Jayapura"
    },
    {
      "label": "Asia/Jerusalem",
      "value": "Asia/Jerusalem"
    },
    {
      "label": "Asia/Kabul",
      "value": "Asia/Kabul"
    },
    {
      "label": "Asia/Kamchatka",
      "value": "Asia/Kamchatka"
    },
    {
      "label": "Asia/Karachi",
      "value": "Asia/Karachi"
    },
    {
      "label": "Asia/Katmandu",
      "value": "Asia/Katmandu"
    },
    {
      "label": "Asia/Khandyga",
      "value": "Asia/Khandyga"
    },
    {
      "label": "Asia/Krasnoyarsk",
      "value": "Asia/Krasnoyarsk"
    },
    {
      "label": "Asia/Kuala_Lumpur",
      "value": "Asia/Kuala_Lumpur"
    },
    {
      "label": "Asia/Kuching",
      "value": "Asia/Kuching"
    },
    {
      "label": "Asia/Kuwait",
      "value": "Asia/Kuwait"
    },
    {
      "label": "Asia/Macau",
      "value": "Asia/Macau"
    },
    {
      "label": "Asia/Magadan",
      "value": "Asia/Magadan"
    },
    {
      "label": "Asia/Makassar",
      "value": "Asia/Makassar"
    },
    {
      "label": "Asia/Manila",
      "value": "Asia/Manila"
    },
    {
      "label": "Asia/Muscat",
      "value": "Asia/Muscat"
    },
    {
      "label": "Asia/Nicosia",
      "value": "Asia/Nicosia"
    },
    {
      "label": "Asia/Novokuznetsk",
      "value": "Asia/Novokuznetsk"
    },
    {
      "label": "Asia/Novosibirsk",
      "value": "Asia/Novosibirsk"
    },
    {
      "label": "Asia/Omsk",
      "value": "Asia/Omsk"
    },
    {
      "label": "Asia/Oral",
      "value": "Asia/Oral"
    },
    {
      "label": "Asia/Phnom_Penh",
      "value": "Asia/Phnom_Penh"
    },
    {
      "label": "Asia/Pontianak",
      "value": "Asia/Pontianak"
    },
    {
      "label": "Asia/Pyongyang",
      "value": "Asia/Pyongyang"
    },
    {
      "label": "Asia/Qatar",
      "value": "Asia/Qatar"
    },
    {
      "label": "Asia/Qostanay",
      "value": "Asia/Qostanay"
    },
    {
      "label": "Asia/Qyzylorda",
      "value": "Asia/Qyzylorda"
    },
    {
      "label": "Asia/Rangoon",
      "value": "Asia/Rangoon"
    },
    {
      "label": "Asia/Riyadh",
      "value": "Asia/Riyadh"
    },
    {
      "label": "Asia/Saigon",
      "value": "Asia/Saigon"
    },
    {
      "label": "Asia/Sakhalin",
      "value": "Asia/Sakhalin"
    },
    {
      "label": "Asia/Samarkand",
      "value": "Asia/Samarkand"
    },
    {
      "label": "Asia/Seoul",
      "value": "Asia/Seoul"
    },
    {
      "label": "Asia/Shanghai",
      "value": "Asia/Shanghai"
    },
    {
      "label": "Asia/Singapore",
      "value": "Asia/Singapore"
    },
    {
      "label": "Asia/Srednekolymsk",
      "value": "Asia/Srednekolymsk"
    },
    {
      "label": "Asia/Taipei",
      "value": "Asia/Taipei"
    },
    {
      "label": "Asia/Tashkent",
      "value": "Asia/Tashkent"
    },
    {
      "label": "Asia/Tbilisi",
      "value": "Asia/Tbilisi"
    },
    {
      "label": "Asia/Tehran",
      "value": "Asia/Tehran"
    },
    {
      "label": "Asia/Thimphu",
      "value": "Asia/Thimphu"
    },
    {
      "label": "Asia/Tokyo",
      "value": "Asia/Tokyo"
    },
    {
      "label": "Asia/Tomsk",
      "value": "Asia/Tomsk"
    },
    {
      "label": "Asia/Ulaanbaatar",
      "value": "Asia/Ulaanbaatar"
    },
    {
      "label": "Asia/Urumqi",
      "value": "Asia/Urumqi"
    },
    {
      "label": "Asia/Ust-Nera",
      "value": "Asia/Ust-Nera"
    },
    {
      "label": "Asia/Vientiane",
      "value": "Asia/Vientiane"
    },
    {
      "label": "Asia/Vladivostok",
      "value": "Asia/Vladivostok"
    },
    {
      "label": "Asia/Yakutsk",
      "value": "Asia/Yakutsk"
    },
    {
      "label": "Asia/Yekaterinburg",
      "value": "Asia/Yekaterinburg"
    },
    {
      "label": "Asia/Yerevan",
      "value": "Asia/Yerevan"
    },
    {
      "label": "Atlantic/Azores",
      "value": "Atlantic/Azores"
    },
    {
      "label": "Atlantic/Bermuda",
      "value": "Atlantic/Bermuda"
    },
    {
      "label": "Atlantic/Canary",
      "value": "Atlantic/Canary"
    },
    {
      "label": "Atlantic/Cape_Verde",
      "value": "Atlantic/Cape_Verde"
    },
    {
      "label": "Atlantic/Faeroe",
      "value": "Atlantic/Faeroe"
    },
    {
      "label": "Atlantic/Madeira",
      "value": "Atlantic/Madeira"
    },
    {
      "label": "Atlantic/Reykjavik",
      "value": "Atlantic/Reykjavik"
    },
    {
      "label": "Atlantic/South_Georgia",
      "value": "Atlantic/South_Georgia"
    },
    {
      "label": "Atlantic/St_Helena",
      "value": "Atlantic/St_Helena"
    },
    {
      "label": "Atlantic/Stanley",
      "value": "Atlantic/Stanley"
    },
    {
      "label": "Australia/Adelaide",
      "value": "Australia/Adelaide"
    },
    {
      "label": "Australia/Brisbane",
      "value": "Australia/Brisbane"
    },
    {
      "label": "Australia/Broken_Hill",
      "value": "Australia/Broken_Hill"
    },
    {
      "label": "Australia/Currie",
      "value": "Australia/Currie"
    },
    {
      "label": "Australia/Darwin",
      "value": "Australia/Darwin"
    },
    {
      "label": "Australia/Eucla",
      "value": "Australia/Eucla"
    },
    {
      "label": "Australia/Hobart",
      "value": "Australia/Hobart"
    },
    {
      "label": "Australia/Lindeman",
      "value": "Australia/Lindeman"
    },
    {
      "label": "Australia/Lord_Howe",
      "value": "Australia/Lord_Howe"
    },
    {
      "label": "Australia/Melbourne",
      "value": "Australia/Melbourne"
    },
    {
      "label": "Australia/Perth",
      "value": "Australia/Perth"
    },
    {
      "label": "Australia/Sydney",
      "value": "Australia/Sydney"
    },
    {
      "label": "Europe/Amsterdam",
      "value": "Europe/Amsterdam"
    },
    {
      "label": "Europe/Andorra",
      "value": "Europe/Andorra"
    },
    {
      "label": "Europe/Astrakhan",
      "value": "Europe/Astrakhan"
    },
    {
      "label": "Europe/Athens",
      "value": "Europe/Athens"
    },
    {
      "label": "Europe/Belgrade",
      "value": "Europe/Belgrade"
    },
    {
      "label": "Europe/Berlin",
      "value": "Europe/Berlin"
    },
    {
      "label": "Europe/Bratislava",
      "value": "Europe/Bratislava"
    },
    {
      "label": "Europe/Brussels",
      "value": "Europe/Brussels"
    },
    {
      "label": "Europe/Bucharest",
      "value": "Europe/Bucharest"
    },
    {
      "label": "Europe/Budapest",
      "value": "Europe/Budapest"
    },
    {
      "label": "Europe/Busingen",
      "value": "Europe/Busingen"
    },
    {
      "label": "Europe/Chisinau",
      "value": "Europe/Chisinau"
    },
    {
      "label": "Europe/Copenhagen",
      "value": "Europe/Copenhagen"
    },
    {
      "label": "Europe/Dublin",
      "value": "Europe/Dublin"
    },
    {
      "label": "Europe/Gibraltar",
      "value": "Europe/Gibraltar"
    },
    {
      "label": "Europe/Guernsey",
      "value": "Europe/Guernsey"
    },
    {
      "label": "Europe/Helsinki",
      "value": "Europe/Helsinki"
    },
    {
      "label": "Europe/Isle_of_Man",
      "value": "Europe/Isle_of_Man"
    },
    {
      "label": "Europe/Istanbul",
      "value": "Europe/Istanbul"
    },
    {
      "label": "Europe/Jersey",
      "value": "Europe/Jersey"
    },
    {
      "label": "Europe/Kaliningrad",
      "value": "Europe/Kaliningrad"
    },
    {
      "label": "Europe/Kiev",
      "value": "Europe/Kiev"
    },
    {
      "label": "Europe/Kirov",
      "value": "Europe/Kirov"
    },
    {
      "label": "Europe/Lisbon",
      "value": "Europe/Lisbon"
    },
    {
      "label": "Europe/Ljubljana",
      "value": "Europe/Ljubljana"
    },
    {
      "label": "Europe/London",
      "value": "Europe/London"
    },
    {
      "label": "Europe/Luxembourg",
      "value": "Europe/Luxembourg"
    },
    {
      "label": "Europe/Madrid",
      "value": "Europe/Madrid"
    },
    {
      "label": "Europe/Malta",
      "value": "Europe/Malta"
    },
    {
      "label": "Europe/Mariehamn",
      "value": "Europe/Mariehamn"
    },
    {
      "label": "Europe/Minsk",
      "value": "Europe/Minsk"
    },
    {
      "label": "Europe/Monaco",
      "value": "Europe/Monaco"
    },
    {
      "label": "Europe/Moscow",
      "value": "Europe/Moscow"
    },
    {
      "label": "Europe/Oslo",
      "value": "Europe/Oslo"
    },
    {
      "label": "Europe/Paris",
      "value": "Europe/Paris"
    },
    {
      "label": "Europe/Podgorica",
      "value": "Europe/Podgorica"
    },
    {
      "label": "Europe/Prague",
      "value": "Europe/Prague"
    },
    {
      "label": "Europe/Riga",
      "value": "Europe/Riga"
    },
    {
      "label": "Europe/Rome",
      "value": "Europe/Rome"
    },
    {
      "label": "Europe/Samara",
      "value": "Europe/Samara"
    },
    {
      "label": "Europe/San_Marino",
      "value": "Europe/San_Marino"
    },
    {
      "label": "Europe/Sarajevo",
      "value": "Europe/Sarajevo"
    },
    {
      "label": "Europe/Saratov",
      "value": "Europe/Saratov"
    },
    {
      "label": "Europe/Simferopol",
      "value": "Europe/Simferopol"
    },
    {
      "label": "Europe/Skopje",
      "value": "Europe/Skopje"
    },
    {
      "label": "Europe/Sofia",
      "value": "Europe/Sofia"
    },
    {
      "label": "Europe/Stockholm",
      "value": "Europe/Stockholm"
    },
    {
      "label": "Europe/Tallinn",
      "value": "Europe/Tallinn"
    },
    {
      "label": "Europe/Tirane",
      "value": "Europe/Tirane"
    },
    {
      "label": "Europe/Ulyanovsk",
      "value": "Europe/Ulyanovsk"
    },
    {
      "label": "Europe/Uzhgorod",
      "value": "Europe/Uzhgorod"
    },
    {
      "label": "Europe/Vaduz",
      "value": "Europe/Vaduz"
    },
    {
      "label": "Europe/Vatican",
      "value": "Europe/Vatican"
    },
    {
      "label": "Europe/Vienna",
      "value": "Europe/Vienna"
    },
    {
      "label": "Europe/Vilnius",
      "value": "Europe/Vilnius"
    },
    {
      "label": "Europe/Volgograd",
      "value": "Europe/Volgograd"
    },
    {
      "label": "Europe/Warsaw",
      "value": "Europe/Warsaw"
    },
    {
      "label": "Europe/Zagreb",
      "value": "Europe/Zagreb"
    },
    {
      "label": "Europe/Zaporozhye",
      "value": "Europe/Zaporozhye"
    },
    {
      "label": "Europe/Zurich",
      "value": "Europe/Zurich"
    },
    {
      "label": "Indian/Antananarivo",
      "value": "Indian/Antananarivo"
    },
    {
      "label": "Indian/Chagos",
      "value": "Indian/Chagos"
    },
    {
      "label": "Indian/Christmas",
      "value": "Indian/Christmas"
    },
    {
      "label": "Indian/Cocos",
      "value": "Indian/Cocos"
    },
    {
      "label": "Indian/Comoro",
      "value": "Indian/Comoro"
    },
    {
      "label": "Indian/Kerguelen",
      "value": "Indian/Kerguelen"
    },
    {
      "label": "Indian/Mahe",
      "value": "Indian/Mahe"
    },
    {
      "label": "Indian/Maldives",
      "value": "Indian/Maldives"
    },
    {
      "label": "Indian/Mauritius",
      "value": "Indian/Mauritius"
    },
    {
      "label": "Indian/Mayotte",
      "value": "Indian/Mayotte"
    },
    {
      "label": "Indian/Reunion",
      "value": "Indian/Reunion"
    },
    {
      "label": "Pacific/Apia",
      "value": "Pacific/Apia"
    },
    {
      "label": "Pacific/Auckland",
      "value": "Pacific/Auckland"
    },
    {
      "label": "Pacific/Bougainville",
      "value": "Pacific/Bougainville"
    },
    {
      "label": "Pacific/Chatham",
      "value": "Pacific/Chatham"
    },
    {
      "label": "Pacific/Easter",
      "value": "Pacific/Easter"
    },
    {
      "label": "Pacific/Efate",
      "value": "Pacific/Efate"
    },
    {
      "label": "Pacific/Enderbury",
      "value": "Pacific/Enderbury"
    },
    {
      "label": "Pacific/Fakaofo",
      "value": "Pacific/Fakaofo"
    },
    {
      "label": "Pacific/Fiji",
      "value": "Pacific/Fiji"
    },
    {
      "label": "Pacific/Funafuti",
      "value": "Pacific/Funafuti"
    },
    {
      "label": "Pacific/Galapagos",
      "value": "Pacific/Galapagos"
    },
    {
      "label": "Pacific/Gambier",
      "value": "Pacific/Gambier"
    },
    {
      "label": "Pacific/Guadalcanal",
      "value": "Pacific/Guadalcanal"
    },
    {
      "label": "Pacific/Guam",
      "value": "Pacific/Guam"
    },
    {
      "label": "Pacific/Honolulu",
      "value": "Pacific/Honolulu"
    },
    {
      "label": "Pacific/Johnston",
      "value": "Pacific/Johnston"
    },
    {
      "label": "Pacific/Kiritimati",
      "value": "Pacific/Kiritimati"
    },
    {
      "label": "Pacific/Kosrae",
      "value": "Pacific/Kosrae"
    },
    {
      "label": "Pacific/Kwajalein",
      "value": "Pacific/Kwajalein"
    },
    {
      "label": "Pacific/Majuro",
      "value": "Pacific/Majuro"
    },
    {
      "label": "Pacific/Marquesas",
      "value": "Pacific/Marquesas"
    },
    {
      "label": "Pacific/Midway",
      "value": "Pacific/Midway"
    },
    {
      "label": "Pacific/Nauru",
      "value": "Pacific/Nauru"
    },
    {
      "label": "Pacific/Niue",
      "value": "Pacific/Niue"
    },
    {
      "label": "Pacific/Norfolk",
      "value": "Pacific/Norfolk"
    },
    {
      "label": "Pacific/Noumea",
      "value": "Pacific/Noumea"
    },
    {
      "label": "Pacific/Pago_Pago",
      "value": "Pacific/Pago_Pago"
    },
    {
      "label": "Pacific/Palau",
      "value": "Pacific/Palau"
    },
    {
      "label": "Pacific/Pitcairn",
      "value": "Pacific/Pitcairn"
    },
    {
      "label": "Pacific/Ponape",
      "value": "Pacific/Ponape"
    },
    {
      "label": "Pacific/Port_Moresby",
      "value": "Pacific/Port_Moresby"
    },
    {
      "label": "Pacific/Rarotonga",
      "value": "Pacific/Rarotonga"
    },
    {
      "label": "Pacific/Saipan",
      "value": "Pacific/Saipan"
    },
    {
      "label": "Pacific/Tahiti",
      "value": "Pacific/Tahiti"
    },
    {
      "label": "Pacific/Tarawa",
      "value": "Pacific/Tarawa"
    },
    {
      "label": "Pacific/Tongatapu",
      "value": "Pacific/Tongatapu"
    },
    {
      "label": "Pacific/Truk",
      "value": "Pacific/Truk"
    },
    {
      "label": "Pacific/Wake",
      "value": "Pacific/Wake"
    },
    {
      "label": "Pacific/Wallis",
      "value": "Pacific/Wallis"
    },
    {
      "label": "Asia/Kolkata",
      "value": "Asia/Kolkata"
    },
  ],
  agentTypeOptions: [
    { label: "Code Gen Agent", value: "codeGenAgent", details: "CodeGenAgent :: Llama3.2 :: 3b :: 2.0GB" },
    // { label: "Doc Summarizer Agent", value: "docSummarizerAgent" },
    { label: "General Chat Agent", value: "generalChatAgent", details: "GeneralChatAgent :: Llama3.2 :: 3b :: 2.0GB" },
    { label: "SLM Agent", value: "slmAgentQwen2_05b", details: "SLMAgent :: Qwen2:0.5b" },
    // { label: "Image Gen Agent", value: "imageGenAgent" },
    // { label: "Logo Gen Agent", value: "logoGenAgent" },
    // { label: "Sql Data Agent", value: "sqlDataAgent" }
  ],
  carePlaneTypeOption: [
    { label: "carePlanAgent", value: "carePlanAgent", details: "CarePlanAgent :: medllama2:7b" },
    { label: "carePlanAgent1", value: "carePlanAgent1", details: "CarePlanAgent :: Medical-Llama3-8B" },
    { label: "carePlanAgent::carePlanAgent1", value: "carePlanAgent::carePlanAgent1", details: "CarePlanAgent :: medllama2:7b, Medical-Llama3-8B" },
    { label: "carePlanAgent1::carePlanAgent", value: "carePlanAgent1::carePlanAgent", details: "CarePlanAgent :: Medical-Llama3-8B, medllama2:7b" }
  ],
  patientOptions: [
    { label: "Search Patient (FHIR2)", value: "searchPatient", context: "Search New Patient Via Fhir2", apiType: "fhir2" },
    { label: "Create Patient (REST)", value: "createPatient", context: "Create New Patient", apiType: "rest" },
    { label: "Search Patient (REST)", value: "searchPatient", context: "search New Patient", apiType: "rest" },
  ],
  patientBuildJson: {
    "uuid": "",
    "person": {
      "uuid": "",
      "names": [
        {
          "preferred": true,
          "givenName": "",
          "middleName": "",
          "familyName": ""
        }
      ],
      "gender": "M",
      "birthdate": "",
      "birthdateEstimated": true,
      "attributes": [],
      "addresses": [
        {
          "country": "",
          "stateProvince": "",
          "countyDistrict": "",
          "address1": "",
          "cityVillage": ""
        }
      ],
      "dead": false
    },
    "identifiers": [
      {
        "identifier": "",
        "identifierType": "05a29f94-c0ed-11e2-94be-8c13b969e334",
        "location": "44c3efb0-2583-4c80-a79e-1f756a03c0a1",
        "preferred": true
      }
    ]
  },
  searchPatientBuildJson: {
    limit: "",
    startIndex: "",
    v: "",
    q: "",
    identifier: "",
    includeDead: "",
    searchType: ""
  },
  monitoringAccessWorkspace: ["bda302d080624e19ae7108cd3a87f27c"],
  monitoringUrl: "https://teqbahn.com/monitoring-web/auth",
  patientParameterOptionv: [
    { label: "--", value: "", },
    { label: "Ref", value: "ref", },
    { label: "Default", value: "default" },
    { label: "Full", value: "full" },
    { label: "Custom", value: "custom" },
  ],
  openMRSDoctoreMessageSendEmailid: "jakedoctor@openmrsdemo.com",
  openMRSJackDoctorProviderUuid: "705f5791-07a7-44b8-932f-a81f3526fc98",
  openMRSDoctoreMessageSendEmailidObj: {
    "jakedoctor@openmrsdemo.com": "705f5791-07a7-44b8-932f-a81f3526fc98",
    "charlesdoctor@openmrsdemo.com": "2d363897-c9aa-4d35-85e8-3d911ba9b8f1",
    "josephdoctor@openmrsdemo.com": "ecd52134-8670-4313-9d0d-668087758c72",
    "stevendoctor@openmrsdemo.com": "aa76c8b4-73b5-4425-bc5b-c9e22ed0e385",
  },
  openMRSPatientFullUrl: "https://ext.teqbahn.com/openmrs/spa/patient/",
  openMRSAppointmentUrl: "/chart/Appointments",
  projectTypeOptions: [
    { label: "Ongoing", value: "Ongoing" },
    { label: "TimeBound", value: "TimeBound" }
  ],
  teqitFeatures: [
    "Day Entry",
    "Apps",
    "Docs",
    "Sheets",
    "Notes",
    "Task Channels",
    "Group Messages",
    "Direct Messages",
    "Feed",
    "Document Summarizer",
    "AI Channel",
    "OpenMRS",
    "Daily Report",
    "Expenses",
    "TeqReq",
    "WhiteBoard",
    "Boards",
    "Project Entities",
    "Holidays",
    "Calendar",
    "Org Feedback",
    "Members",
    "Teqit TV",
    "Community Report",
  ],
  currencyCodeHtml: {
    "ALL": "&#76",
    "AFN": "&#1547",
    "ARS": "&#36",
    "AWG": "&#402",
    "AUD": "&#36",
    "AZN": "&#1084",
    "BSD": "&#36",
    "BBD": "&#36",
    "BYN": "&#66",
    "BZD": "&#66",
    "BMD": "&#36",
    "BOB": "&#36",
    "BAM": "&#75",
    "BWP": "&#80",
    "BGN": "&#1083",
    "BRL": "&#82",
    "BND": "&#36",
    "KHR": "&#6107",
    "CAD": "&#36",
    "KYD": "&#36",
    "CLP": "&#36",
    "CNY": "&#165",
    "COP": "&#36",
    "CRC": "&#8353",
    "HRK": "&#107",
    "CUP": "&#8369",
    "CZK": "&#75",
    "DKK": "&#107",
    "DOP": "&#82",
    "XCD": "&#36",
    "EGP": "&#163",
    "SVC": "&#36",
    "EUR": "&#8364",
    "FKP": "&#163",
    "FJD": "&#36",
    "GHS": "&#162",
    "GIP": "&#163",
    "GTQ": "&#81",
    "GGP": "&#163",
    "GYD": "&#36",
    "HNL": "&#76",
    "HKD": "&#36",
    "HUF": "&#70",
    "ISK": "&#107",
    "INR": "&#8377",
    "IDR": "&#82",
    "IRR": "&#65020",
    "IMP": "&#163",
    "ILS": "&#8362",
    "JMD": "&#74",
    "JPY": "&#165",
    "JEP": "&#163",
    "KZT": "&#1083",
    "KPW": "&#8361",
    "KRW": "&#8361",
    "KGS": "&#1083",
    "LAK": "&#8365",
    "LBP": "&#163",
    "LRD": "&#36",
    "MKD": "&#1076",
    "MYR": "&#82",
    "MUR": "&#8360",
    "MXN": "Mex&#36",
    "MNT": "&#8366",
    "MZN": "&#77",
    "NAD": "&#36",
    "NPR": "&#8360",
    "ANG": "&#402",
    "NZD": "&#36",
    "NIO": "&#67",
    "NGN": "&#8358",
    "KPW": "&#8361",
    "NOK": "&#107",
    "OMR": "&#65020",
    "PKR": "&#8360",
    "PAB": "&#66",
    "PYG": "&#71",
    "PEN": "&#83",
    "PHP": "&#8369",
    "PLN": "&#122",
    "QAR": "&#65020",
    "RON": "&#108",
    "RUB": "&#1088",
    "SHP": "&#163",
    "SAR": "&#65020",
    "RSD": "&#1044",
    "SCR": "&#8360",
    "SGD": "&#36",
    "SBD": "&#36",
    "SOS": "&#83",
    "ZAR": "&#82",
    "KRW": "&#8361",
    "LKR": "&#8360",
    "SEK": "&#107",
    "CHF": "&#67",
    "SRD": "&#36",
    "SYP": "&#163",
    "TWD": "&#78",
    "THB": "&#3647",
    "TTD": "&#84",
    "TRY": "&#",
    "TVD": "&#36",
    "UAH": "&#8372",
    "GBP": "&#163",
    "USD": "&#36",
    "UYU": "&#36",
    "UZS": "&#1083",
    "VEF": "&#66",
    "VND": "&#8363",
    "YER": "&#65020",
    "ZWD": "&#90",
    "XAF": "FCFA",
    "UGX": "UGX",
    "RWF": "RWF",
  },
  volunteersTypeOne: "volunteerType1",
  volunteersTypeTwo: "volunteerType2",
  volunteersTypes: [{ label: "Volunteer 1", value: "volunteerType1" }, { label: "Volunteer 2", value: "volunteerType2" }],
  subWorkSpacePcType: "pcType",
  subWorkSpaceCType: "cType",
  workSpaceSubTypeOptions: [
    { value: "pcType", label: "PC Type" },
    { value: "cType", label: "C Type" },
  ],
  vulnerablePopulationOption: [
    { label: "Mental disability", value: "MentalDisability" },
    { label: "Physical disability", value: "PhysicalDisability" },
    { label: "PLHIV", value: "PLHIV" },
    { label: "Female sex worker", value: "FemaleSexWorker" },
    { label: "Drug user", value: "DrugUser" },
    { label: "IDP", value: "IDP" },
    { label: "Out of school", value: "OutOfSchool" },
    { label: "Minor", value: "Minor" },
    { label: "House maid/Domestic worker", value: "HouseMaid/DomesticWorker" },
    { label: "Widow/widower", value: "Widow/widower" },
  ],
  survivorRelationshipOption: [
    { label: "Spouse", value: "Spouse" },
    { label: "Current intimate partner", value: "CurrentIntimatePartner" },
    { label: "Former intimate partner", value: "FormerIntimatePartner" },
    { label: "Parents", value: "Parents" },
    { label: "Relative", value: "Relative" },
    { label: "Stranger", value: "Stranger" },
    { label: "Service provider", value: "ServiceProvider" },
    { label: "Law enforcement agent", value: "LawEnforcementAgent" },
    { label: "Religious leader", value: "ReligiousLeader" },
    { label: "Teacher/Lecturer", value: "Teacher/Lecturer" },
    { label: "Care giver", value: "CareGiver" },
    { label: "Other", value: "Other" },
  ],
  firstExperienceAbuseOption: [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ],
  protectionConcernsOption: [
    { label: "Physical Abuse/ Violence", value: "PhysicalAbuse/Violence" },
    { label: "Rape", value: "Rape" },
    { label: "Battery", value: "Battery" },
    { label: "Defilement", value: "Defilement" },
    { label: "Child in conflict with the law", value: "ChildInConflictWithTheLaw" },
    { label: "Emotional/psychological abuse", value: "Emotional/psychologicalAbuse" },
    { label: "Child associated with arm forces or group", value: "ChildAssociatedAithArmForcesorGroup" },
    { label: "Assault Occasioning Harm", value: "AssaultOccasioningHarm" },
    { label: "Denial of resources", value: "DenialOfResources" },
    { label: "Deprived of liberty/In detention", value: "DeprivedOfLiberty/InDetention" },
    { label: "Serious medical condition", value: "SeriousMedicalCondition" },
    { label: "Failure to provide necessaries /Neglect", value: "FailureToProvideNecessaries/Neglect" },
    { label: "Spousal infidelity", value: "SpousalInfidelity" },
    { label: "Female genital mutilation (FGM)", value: "FemaleGenitalMutilation(FGM)" },
    { label: "Child labour", value: "ChildLabour" },
    { label: "Forced marriage", value: "ForcedMarriage" },
    { label: "Abandonment", value: "Abandonment" },
    { label: "Violation of property and inheritance rights", value: "ViolationOfPropertyAndInheritanceRights" },
    { label: "Other", value: "Other" },
  ],
  whoIsReportingOption: [
    { label: "Self", value: "Self" },
    { label: "Parents", value: "Parents" },
    { label: "Guardian", value: "Guardian" },
    { label: "Witness", value: "Witness" },
    { label: "Spouse", value: "Spouse" },
    { label: "Others", value: "Others" },
  ],
  actualLocationIncidentOption: [
    { label: "Survivor's house", value: "SurvivorsHouse" },
    { label: "Perpetrator's house", value: "PerpetratorsHouse" },
    { label: "Bush/forest", value: "Bush/forest" },
    { label: "Road", value: "Road" },
    { label: "Other", value: "Other" },
  ],
  continuousThreatOption: [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ],
  educationalHistoryOption: [
    { label: "PRIMARY EDUCATION", value: "PrimaryEducation" },
    { label: "SECONDARY EDUCATION", value: "SecondaryEducation" },
    { label: "TETIARY EDUCATION", value: "TetiaryEducation" },
    { label: "VOCATIONAL TRAINING", value: "VocationalTraining" },
    { label: "SPECIFIC PLACE OF SCHOOLS ATTENDED", value: "SpecificPlaceOfSchoolsAttended" },
    { label: "COMPLETED OR WITHDRAWN FROM SCHOOL", value: "CompletedOrWithdrawnFromSchool" },
    { label: "LEVEL ATTAINED", value: "LevelAttained" },
  ],
  occupationalHistoryOption: [
    { label: "EMPLOYED", value: "Emploed" },
    { label: "SELF EMPLOYED", value: "SelfEmployed" },
    { label: "CURRENT JOB", value: "CurrentJob" },
    { label: "PAST JOB", value: "PastJob" },
  ],
  psychosocialInterventionOption: [
    { label: "ARREST", value: "Arrest" },
    { label: "COURT FILING", value: "CourtFiling" },
    { label: "COUNSELING", value: "Counsiling" },
    { label: "RESCUE", value: "Rescue" },
    { label: "WATCHING BRIEF", value: "WacthingBrief" },
    { label: "REFERRAL", value: "Referrel" },
    { label: "SHELTER PLACEMENT", value: "ShelterPlacement" },
  ],
  appearanceOption: [
    { label: "APPROPRATE", value: "Approprate" },
    { label: "INAPPROPRAIE DRESSING", value: "InapproprateDressing" },
  ],
  affectOption: [
    { label: "HAPPY", value: "Happy" },
    { label: "SAD", value: "Sad" },
    { label: "ANXIOUS", value: "Anxious" },
  ],
  orientationOption: [
    { label: "TIME", value: "Time" },
    { label: "PLACE", value: "Place" },
    { label: "PERSON", value: "Person" },
  ],
  perceptualDisorderOption: [
    { label: "VISUAL", value: "Visual" },
    { label: "AUDITORY HALLUCINATION", value: "AudiotoryHallucination" },
  ],
  insightOption: [
    { label: "IRRATIONAL IRRELEVANT", value: "IrrationalIrrelevant" },
    { label: "MUTE", value: "Mute" },
    { label: "TALKATIVE", value: "Talkative" },
  ],
  judgementOption: [
    { label: "IMPAIRED", value: "Impaired" },
    { label: "GOOD", value: "Good" },
  ],
  caseStatusOption: [
    { value: "caseAccepted", label: "Case Accepted" },
    { value: "psychosocialSupport/TherapyInProgress", label: "Psychosocial Support/Therapy in Progress" },
    { value: "mediationInProgress", label: "Mediation in Progress" },
    { value: "caseUnderInvestigation", label: "Case Under Investigation" },
    { value: "caseReferred", label: "Case Referred" },
    { value: "caseInCourt", label: "Case in Court" },
    { value: "caseClosed", label: "Case Closed" },
  ],
  caseStatusNotAuthenticValue: "notAuthenticProblem",
  ReportStatusOptionList: [
    { label: "Pending", value: "pending" },
    { label: "CBO Accepted", value: "CBO Accepted" },
    { label: "Shared with Ireti Resource", value: "Shared with Ireti Resource" },
    { label: "Received Request", value: "Received Request" },
    { label: "Declined", value: "declined" },
    { label: "LNSC Accepeted", value: "lnscAccepted" },
    { label: "Admin Accepted", value: "Admin Accepted" },
  ],
  superadminEmails: ["ravimadhu@teqbahn.com", "vidyasri@teqbahn.com", "praveen@teqbahn.com", "mathan@teqbahn.com"],
  iretiEncareOptionHide: ["d265d7d2194d45f59cdbed43cece82df"],
  emaildIdValidation: /^[a-zA-Z0-9._%'+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  passwordValidation: /^(?!.* ).{4,15}$/,
  tabToken: "tabToken",
  aiAgentDataDomainTypeOptions: [
    { label: "Trip", value: "trip" },
    { label: "Docs Analysis", value: "documentAnalyzer" },
    { label: "Hospital Management", value: "hospitalManagement" },
  ],
  sundanceCompanyId: "319132238196409a91fac5ccb3472a3f",
  accessPastHealthNotes: "accessPastHealthNotes",
  addNewHealthNotes: "addNewHealthNotes",
  accessPastMentalHealthNotes: "accessPastMentalHealthNotes",
  addNewMentalHealthNotes: "addNewMentalHealthNotes",
  accessPastDocuments: "accessPastDocuments",
  uploadNewDocuments: "uploadNewDocuments",
  viewMasterCalendar: "viewMasterCalendar",
  editMasterCalendar: "editMasterCalendar",
  ontologyView: [
    { label: "Flow Ontology", value: "flow" },
    { label: "Domain ontology", value: "domain" }
  ],
  teqitAIAgentWorkspace: ["f9d096a42c8a4285aec48e39f706ea36"],
  ravenAiCompanyId: "bb626a5724524fd084d4666f9d61e143",
  repeatTypeOneTime: repeatTypeOneTime,
  repeatTypeDaily: repeatTypeDaily,
  repeatTypeWeekly: repeatTypeWeekly,
  repeatTypeMonthly: repeatTypeMonthly,
  repeatTypeYearly: "yearly",
  teqAgentsForGuest: {
    "8186f3a7e28746f4a704c75132681f91": {
      "trip": "teqAgent1",
      "documentAnalyzer": "teqAgent1",
      "hospitalManagement": "teqAgent1",
      "health": "teqAgent1"
    },
    "e256f8f9344d49a7af069afdbcd1b59e": {
      "trip": "teqAgent1",
      "documentAnalyzer": "teqAgent1",
      "hospitalManagement": "teqAgent1",
      "health": "teqAgent1"
    },
    "cb13c38c62b1480bbc0f056c1e704208": {
      "trip": "teqAgent1",
      "documentAnalyzer": "teqAgent1",
      "hospitalManagement": "teqAgent1",
      "health": "teqAgent1"
    },
    "7b27005820bd46279b7e47153c0065eb": {
      "trip": "teqAgent1",
      "documentAnalyzer": "teqAgent1",
      "hospitalManagement": "teqAgent1",
      "health": "teqAgent1"
    },
    "8b5416b991c54c2992dddf3ceae03857": {
      "trip": "teqAgent1",
      "documentAnalyzer": "teqAgent1",
      "hospitalManagement": "teqAgent1",
      "health": "teqAgent1"
    },
    "adb2a4128c3a4de4921873ae848b683f": {
      "trip": "teqAgent2",
      "documentAnalyzer": "teqAgent2",
      "hospitalManagement": "teqAgent2",
      "health": "teqAgent7"
    },
    "0fef251897254f09a6ed0d655e4bbd34": {
      "trip": "teqAgent3",
      "documentAnalyzer": "teqAgent3",
      "hospitalManagement": "teqAgent3",
      "health": "teqAgent8"
    },
    "406ad4d42e054e859a436bdac3f43dc2": {
      "trip": "teqAgent4",
      "documentAnalyzer": "teqAgent4",
      "hospitalManagement": "teqAgent4",
      "health": "teqAgent9"
    },
    "a1164bfd87894c668ad4356f5f9e10ce": {
      "trip": "teqAgent5",
      "documentAnalyzer": "teqAgent5",
      "hospitalManagement": "teqAgent5",
      "health": "teqAgent10"
    },
    "5e88cb3c17fb4b689f9dcbf179a923d7": {
      "trip": "teqAgent6",
      "documentAnalyzer": "teqAgent6",
      "hospitalManagement": "teqAgent6",
      "health": "teqAgent11"
    },
    "7b32789d3dca4da0a0957d57087e4da8": {
      "trip": "teqAgent7",
      "documentAnalyzer": "teqAgent7",
      "hospitalManagement": "teqAgent7",
      "health": "teqAgent11"
    },
  },
  repeatTypeOptions: [
    { label: "One Time", value: repeatTypeOneTime },
    { label: "Daily", value: repeatTypeDaily },
    { label: "Weekly", value: repeatTypeWeekly },
    { label: "Monthly", value: repeatTypeMonthly },
    // { label: "Yearly", value: MyConfig.repeatTypeYearly }
  ]
};

//https://www.javatpoint.com/android-versions
//https://www.gkgigs.com/list-apple-ios-version-history/
//https://html-css-js.com/html/character-codes/currency/

export default MyConfig;