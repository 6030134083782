import { useSelector } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import { aiChatAgentDetails, getDateWithFormat, getOnlyDateWithFormat, getValidLabels, parseJsonBotReponse, surveyTimingRun, urlify, getTimeFormate, getReportName, getUuidv4, getLocation, stripHtml, getGrpcClient, isJson, formatAMPM, capitalize, formatUTCAMPM } from "../config/Common";
import MyConfig from "../config/MyConfig";
import GlobalMsgCons from "../config/GlobalMsgCons";
import Typewriter from "./Typewriter";
import Card from "../components/Card"
import openmrsLogo from '../assets/logo/openmrsLogo.png'
import CsEventData from "./CsEventData";
import Modal from "./Modal";
import MyConstant from "../config/MyConstant";
import DropDown from "./DropDown";
import { toast } from 'react-toastify';
import GlobalDomainCons from "../config/GlobalDomainCons";
import GlobalEventCons from "../config/GlobalEventCons";
import { ZiFetchRequest } from "../protobuf/generated/ZiFetchService_grpc_web_pb";
import { setMultipleLangugeData } from "../redux/actions";
import { useDispatch } from "react-redux";
import AgentResponse from "./AgentResponse";


let days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
let month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

export default function ChatMessage(props) {
    let { loggedEmail, openApps, channelType, sendCommunityServiceEvent, requestReceivingChannelId, scrollBottom, newMessage, userChatHistory, ival, callStatus, controlFeature, joinCall, declineCall, pageText, updateMeetingDetails, joinMeetingCall, chatFileView, setMultipleChatData, channelId, replyChatSurveyQuestion, getChatSurveyReplyDetails, chatMsgCallButton, fromCustomerSupport, fromCustomerSupportTeam } = props;
    const auth = useSelector(state => state.authReducer);
    const languageReducer = useSelector(state => state.languageReducer);
    let { languageOptions } = languageReducer;
    const dispatch = useDispatch();

    let { loginId, activeCompany, hoursSelected, languagePreference } = auth;
    let [translateModal, setTranslateModal] = useState(false)
    let [submitLoader, setSubmitLoader] = useState(false)
    let [srcLang, setSrcLang] = useState("eng")
    let [targetLang, setTargetLang] = useState(languagePreference)
    let [tabType, setTabType] = useState("automatic")

    const contentRef = useRef(null);


    useEffect(() => {
        if (translateModal) {
            if (languageOptions.length === 0) {
                getLanguageList()
            }
            setTabType("automatic")
        }
    }, [translateModal])

    useEffect(() => {
        // Make sure the ref is attached to the DOM element
        const parentElement = contentRef.current;

        if (parentElement) {
            // Add click event listener to parent element
            const handleClick = (event) => {
                if (event.target.classList.contains('copy-code')) {
                    event.preventDefault();
                    const clickedDiv = event.target.parentNode;
                    const nextElement = clickedDiv.nextElementSibling;

                    navigator.clipboard.writeText(nextElement.innerText);
                    toast(`Copied Successfully.`, {
                        position: "bottom-center",
                        autoClose: GlobalMsgCons.autoClose,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            };

            parentElement.addEventListener('click', handleClick);

            // Cleanup: Remove event listener when component unmounts
            return () => {
                parentElement.removeEventListener('click', handleClick);
            };
        }
    }, []);

    function getLanguageList() {
        let postJson = { reqId: getUuidv4(), };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.uiSetDomain);
        request.setEvent(GlobalEventCons.languagesDataSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                let languageOptions = []
                if (json && json.uiMap && Object.keys(json.uiMap).length !== 0) {
                    Object.keys(json.uiMap).map((key) => {
                        let lan = json.uiMap[key]
                        var obj = JSON.parse(lan)
                        let { translationMethod, translationLangCode } = obj;
                        languageOptions.push({ label: obj["name"], value: key, translationMethod, translationLangCode })
                    })
                }
                languageOptions.sort((a, b) => {
                    return (a.label > b.label ? 1 : (a.label === b.label ? 0 : -1))
                })

                dispatch(setMultipleLangugeData({
                    languageOptions: languageOptions
                }))
            }
        });
    }

    function clickHandler(e) {
        e.preventDefault();
        let el = e.target;
        while (el && el !== e.currentTarget && el.tagName.toLowerCase() !== "a") {
            el = el.parentNode;
        }
        if (el && el.tagName.toLowerCase() === "a") {
            let url = el.getAttribute("href");
            // if (url.includes(window.location.host) && url.includes("/teqit/")) {
            if ((url.includes("teqbahn.com") || url.includes("teqit.me")) && url.includes("/teqit/")) {
                let location = getLocation(url)
                props.history.push(location.pathname)
            } else {
                window.open(url, '_blank');
            }
        }
    }

    function formatString(str, codeFormat) {
        if (codeFormat) {
            str = str.replace(/</g, "&lt;").replace(/>/g, "&gt;");
        }
        // Replace \n with <br/>
        str = str.replace(/\n/g, '<br/>');

        // Replace \t with &emsp;
        str = str.replace(/\t/g, '&emsp;');

        const regex = /```(.*?)<br\/>/g;
        const matches = [...str.matchAll(regex)];
        const results = matches.map(match => match[1]);

        for (let i = 0; i < results.length; i++) {
            let program = results[i]
            if (program) {
                str = str.replace("```" + program, '```<div class="d-flex align-items-center copy-code-container"><div>' + program + '</div><button class="btn btn-sm btn-primary copy-code">Copy</button></div><code>')
            }
        }

        // Replace ``` with <code> tags
        let codeOpen = true;
        str = str.replace(/```/g, () => {
            if (codeOpen) {
                codeOpen = false;
                return `<pre>`;
            } else {
                codeOpen = true;
                return '</code></pre>';
            }
        });

        return str;
    }

    function getBotResponse(agent, str) {
        let response;
        switch (agent) {
            case "codeGenAgent":
                response = <span ref={contentRef} dangerouslySetInnerHTML={{ __html: formatString(str, true) }}></span>
                break
            default:
                let msgStr = str.replace(/\[([/INST]|[/SYS]|\\n)*\]/g, '');
                response = <p className="mt-2">{msgStr} </p>

        }

        return response
    }

    function getContent() {
        let message;
        let { chatMessage, csMessageMetaInfo, translatedText } = userChatHistory[ival]
        let userName = userChatHistory[ival].name

        let { editedAt, messageType, deviceInfo, csEventData, openMrsData, healthcareInfo } = chatMessage;
        deviceInfo = deviceInfo ? JSON.parse(deviceInfo) : ""

        if (typeof messageType !== "undefined" && messageType === "call") {
            let callData = chatMessage.callData;
            let { joinedPeople, declinedPeople } = callData
            declinedPeople = declinedPeople ? declinedPeople : []
            message = <div className="call-details" id={ival}>
                <div className="call-info">
                    {controlFeature.audioEnable && <div className={`call-icon ${callData.callStatus}`}>
                        <i className="fa fa-phone"></i>
                    </div>}
                    <div className="call-info-message">
                        <b>{callStatus(callData.callStatus, chatMessage)}</b><br />
                        <small> {callData.endAt > 0 ? "Ended at " + getDateWithFormat(callData.endAt, auth.activeCompanyDetails,auth.hoursSelected) : (callData.startAt > 0 ? "Started at " + getDateWithFormat(callData.startAt, auth.activeCompanyDetails,auth.hoursSelected) : "")}</small>
                    </div>
                    <div className="call-info-button">
                        {
                            (callData.callStatus === "" || callData.callStatus === "call-started") && <React.Fragment>
                                {
                                    (!joinedPeople.includes(auth.loginId) && !declinedPeople.includes(auth.loginId)) ? <div>
                                        <button onClick={() => {
                                            joinCall(chatMessage)
                                        }} className="btn btn-success">Accept
                                        </button>
                                        <button onClick={() => {
                                            declineCall(chatMessage)
                                        }} className="btn btn-danger ml-2">Decline
                                        </button>
                                    </div> : <button onClick={() => {
                                        joinCall(chatMessage)
                                    }} className="btn btn-success">Join</button>
                                }
                            </React.Fragment>
                        }
                    </div>
                </div>
                <div className="user-info">
                    <div className="user-icon">
                    </div>
                    <div className="users-count">
                        {callData.joinedPeople.length} {getValidLabels(pageText, 'peopleJoinedTxt')}
                    </div>
                </div>
            </div>
        } else if (typeof messageType !== "undefined" && (messageType === "calender" || messageType === "meeting" || messageType === "meetingNotification")) {
            let { meetingsData, id } = chatMessage;
            let startTime = new Date(meetingsData.startTime);
            message = <div className="calendar-details" id={ival}>
                <div className="date-details">
                    <div className="month">{month[startTime.getMonth()]}</div>
                    <div className="date-info">
                        <div className="date">{startTime.getDate()}</div>
                        <div className="day">{days[startTime.getDay()]}</div>
                    </div>
                </div>
                <div className={`message-details ${meetingsData.status}`}>
                    <div className="d-flex">
                        <div className="flex-1">
                            <div className="title ">{meetingsData.title}</div>
                            <div className="desc" dangerouslySetInnerHTML={{ __html: meetingsData.description }}></div>
                        </div>

                        {(meetingsData.endTime > new Date().getTime()) ? <div style={{ width: 40, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <button type="button" className="btn btn-sm btn-success mx-2" onClick={() => {
                                if (typeof (props.callAction) !== undefined) {
                                    props.callAction(chatMessage.id)
                                }
                            }} >
                                {chatMsgCallButton[id] ? <i className="fa fa-spinner fa-spin"></i> : <i className="fa fa-phone"></i>}
                            </button>
                        </div> : null}
                    </div>
                    <div className="separator" />
                    <div className="message">
                        <div className="meeting-message">{chatMessage.message}</div>
                        <div className="sub-message my-2">
                            {meetingsData.repeatType ? <span className="pr-1"><b>Schedule</b> : {MyConfig.repeatTypeOptions.map((val) => {
                                if (meetingsData.repeatType === val.value) {
                                    return val.label
                                }
                                return ""
                            })},</span> : ""}
                            <b>When</b> : {meetingsData.repeatType && meetingsData.repeatType !== MyConfig.repeatTypeOneTime ? <>
                                {getOnlyDateWithFormat(meetingsData.startDate, auth.activeCompanyDetails) + " - " + getOnlyDateWithFormat(meetingsData.endDate, auth.activeCompanyDetails) + "," + getTimeFormate(meetingsData.startTime, auth.activeCompanyDetails, auth.hoursSelected) + " - " + getTimeFormate(meetingsData.endTime, auth.activeCompanyDetails, auth.hoursSelected)}
                            </> : <>
                                {getOnlyDateWithFormat(meetingsData.startTime, auth.activeCompanyDetails) + " - " + getOnlyDateWithFormat(meetingsData.endTime, auth.activeCompanyDetails) + "," + getTimeFormate(meetingsData.startTime, auth.activeCompanyDetails, auth.hoursSelected) + " - " + getTimeFormate(meetingsData.endTime, auth.activeCompanyDetails, auth.hoursSelected)}
                            </>}
                        </div>
                    </div>
                </div>
                {
                    messageType === "meeting" && <div className="update-details">
                        <div className={`button ${(meetingsData.acceptedMembers && meetingsData.acceptedMembers.includes(auth.loginId)) ? "accepted" : ""}`} onClick={() => {
                            if (!meetingsData.acceptedMembers || !meetingsData.acceptedMembers.includes(loginId)) {
                                updateMeetingDetails(meetingsData.id, id, "accept")
                            }
                        }}>{getValidLabels(pageText, 'acceptBtnTxt')}</div>
                        <div className={`button ${(meetingsData.rejectedMembers && meetingsData.rejectedMembers.includes(auth.loginId)) ? "rejected" : ""}`} onClick={() => {
                            if (!meetingsData.rejectedMembers || !meetingsData.rejectedMembers.includes(loginId)) {
                                updateMeetingDetails(meetingsData.id, id, "reject")
                            }
                        }}>{getValidLabels(pageText, 'rejectBtnTxt')}</div>
                    </div>
                }
                {
                    (messageType === "meeting-notification") && <div className="update-details">
                        <button onClick={() => {
                            if (new Date().getTime() < meetingsData.endTime) {
                                joinMeetingCall(chatMessage)
                            }
                        }} className={`btn ${new Date().getTime() < meetingsData.endTime ? "btn-success" : "btn-secondary disabled"}`}>{getValidLabels(pageText, 'joinBtnTxt')}</button>
                    </div>
                }

            </div>
        } else if (typeof chatMessage.fileList != "undefined" && chatMessage.fileList != null && chatMessage.fileList.length > 0) {
            message = <div id={ival}>
                <div className="">
                    <div dangerouslySetInnerHTML={{ __html: urlify(chatMessage.message) }} />
                    {
                        editedAt && <div className="edited">
                            (edited)
                            <div className="time">{getDateWithFormat(editedAt, auth.activeCompanyDetails, hoursSelected)}</div>
                        </div>
                    }
                    {
                        messageType === GlobalMsgCons.messageTypeSharedChat && <div className="edited">
                            (shared)
                        </div>
                    }
                </div>
                {chatFileView(ival)}
            </div>;
        } else if (chatMessage.objUrl !== undefined && chatMessage.objUrl) {
            message = <div id={ival}>
                <div className="">
                    <div dangerouslySetInnerHTML={{ __html: urlify(chatMessage.message) }} />
                    {
                        editedAt && <div className="edited">
                            (edited)
                            <div className="time">{getDateWithFormat(editedAt, auth.activeCompanyDetails)}</div>
                        </div>
                    }
                    {
                        messageType === GlobalMsgCons.messageTypeSharedChat && <div className="edited">
                            (shared)
                        </div>
                    }
                </div>
                <div style={{ cursor: "pointer" }} onClick={() => {
                    setMultipleChatData(channelId, {
                        buildUrl: chatMessage.objUrl,
                        previewModal: true
                    })
                }}>
                    <img src={chatMessage.objUrl} />
                </div>
            </div>
        }
        else if (typeof messageType !== "undefined" && (messageType === GlobalMsgCons.surveyText && chatMessage.chatSurveyData)) {
            let chatDuration = 0;
            let currentTiming = new Date().getTime();
            let surveyCreatedTiming = parseInt(chatMessage.createdAt);
            let showSurveyDuration = 0
            let checkSurveyEnd = false;
            let timeRunShow = "00:00:00"
            if (typeof (chatMessage.chatSurveyData.duration) !== "undefined") {
                chatDuration = chatMessage.chatSurveyData.duration;
                let d = Math.floor(chatDuration / (3600 * 24));
                let h = Math.floor(chatDuration % (3600 * 24) / 3600);
                let m = Math.floor(chatDuration % 3600 / 60);
                showSurveyDuration = ("0" + d).slice(-2) + ":" + ("0" + h).slice(-2) + ":" + ("0" + m).slice(-2);
                let subtractTime = (currentTiming - surveyCreatedTiming)
                if (subtractTime >= (chatMessage.chatSurveyData.duration * 1000)) {
                    checkSurveyEnd = true
                }
                if (!checkSurveyEnd) {
                    timeRunShow = surveyTimingRun(chatDuration - Math.floor((subtractTime) / 1000))
                }
            }
            let maxValues = 0;
            let createObj = {};
            let attendSurvey = chatMessage.chatSurveyData.responseData;
            if (attendSurvey && Object.keys(attendSurvey).length > 0) {
                Object.keys(attendSurvey).map((s, si) => {
                    if (attendSurvey[s] && Object.keys(attendSurvey[s]).length > 0) {
                        createObj[s] = Object.keys(attendSurvey[s]).length
                    }

                })
                maxValues = Object.values(createObj).reduce((r, s) => r > s ? r : s, 0);
            }


            message = <div id={ival} className={`chat-survey-box ${(checkSurveyEnd ? "survey-end" : "")}`}>
                <div className="" >
                    {showSurveyDuration && <div style={{ display: "flex", flexDirection: "row" }}>
                        {checkSurveyEnd &&
                            <div style={{ flex: 1 }}>
                                <label className="survey-closed-txt mr-1">
                                    Survey Closed
                                </label>
                            </div>
                        }
                        <div style={{ flex: 1 }}>
                            <span className="mr-1">
                                <label>Survey Duration</label>
                            </span>
                            <span className="ml-1">{timeRunShow}</span>
                        </div>
                    </div>}

                    <div className="d-inline-block mb-2" dangerouslySetInnerHTML={{ __html: chatMessage.chatSurveyData.question }} />

                    <div className="chat-survey-progress" >
                        {
                            Object.keys(chatMessage.chatSurveyData.optionMap).map((jval, j) => {
                                let checkedUserIds = false;
                                let countCheckBox = 0;
                                let calData = 0;
                                let optdata = chatMessage.chatSurveyData.optionMap;
                                if (typeof (chatMessage.chatSurveyData.responseData) !== "undefined" && chatMessage.chatSurveyData.responseData[jval] && Object.keys(chatMessage.chatSurveyData.responseData[jval]).length > 0) {
                                    checkedUserIds = chatMessage.chatSurveyData.responseData[jval][auth.userId]

                                    countCheckBox = Object.values(chatMessage.chatSurveyData.responseData[jval]).length;
                                    calData = Math.round((Object.values(chatMessage.chatSurveyData.responseData[jval]).length / maxValues * 100)).toString()

                                }
                                return <React.Fragment key={j}>
                                    <div>
                                        <label className="chat-survey-container">
                                            <div className="d-inline-block" dangerouslySetInnerHTML={{ __html: optdata[jval] }} />
                                            <input type={"checkbox"} checked={checkedUserIds}
                                                onChange={() => {
                                                    replyChatSurveyQuestion(chatMessage.id, jval, chatMessage)
                                                }}
                                                disabled={checkSurveyEnd}
                                            />
                                            <span className={"checkmark " + (chatMessage.chatSurveyData.surveyType === "single" ? "" : "check-box")}></span>
                                        </label>
                                    </div>
                                    {<div style={{ display: "flex", marginBottom: 10, alignItems: "center" }}>
                                        <div className="chat-survey-count"
                                            onClick={(e) => { getChatSurveyReplyDetails(chatMessage.id, channelId, chatMessage.chatSurveyData.question, optdata, userName) }}
                                        >
                                            {countCheckBox}
                                        </div>

                                        <div className="progress" style={{ flex: 1 }}>
                                            <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={calData.toString()} aria-valuemin="0" aria-valuemax="100" style={{ width: calData.toString() + "%", }}>
                                                {/* {(calData.toString() + "%"} */}
                                            </div>
                                        </div>

                                    </div>}
                                </React.Fragment>
                            })
                        }
                        <button className="btn btn-sm btn-info" onClick={(e) => { getChatSurveyReplyDetails(chatMessage.id, channelId, chatMessage.chatSurveyData.question, chatMessage.chatSurveyData.optionMap,) }}><p style={{ fontSize: "12px" }}>View Survey</p></button>
                    </div>
                </div>
            </div>
        } else if (messageType === GlobalMsgCons.botResponse) {
            let botMessage = chatMessage.message
            let botData = parseJsonBotReponse(botMessage, { request: "", response: "" })
            if (botData.request) {
                let aiAgentType = aiChatAgentDetails(chatMessage.aiAgentType)
                message = <div className="bot-chat">
                    <div className="request">
                        <div dangerouslySetInnerHTML={{ __html: urlify(botData.request) }} />
                        {
                            aiAgentType && <div className="agent-box">
                                Type of LLM - {aiAgentType}
                            </div>
                        }
                    </div>
                    <div className="response">
                        {
                            newMessage.includes(ival) && !botData.response.includes(GlobalMsgCons.botResponseDefault) ? <Typewriter scrollBottom={() => scrollBottom()} text={botData.response} delay={50} /> : botData.response
                        }
                    </div>
                </div>
            } else if (chatMessage.message && botData.length > 0) {
                let careReponseMsg = botData

                if (careReponseMsg.length > 0) {
                    let { agent, response } = careReponseMsg[0]
                    let { agentTypeId } = agent
                    if (agentTypeId.includes("teqAgent") || agentTypeId === "teqCustomerAgent") {
                        if (isJson(response)) {
                            let agentRespone = JSON.parse(response)
                            let { functionResponse, toolsCallResponse } = agentRespone
                            if (isJson(functionResponse) && toolsCallResponse) {
                                message = <AgentResponse grpcClient={props.grpcClient} bookingDisabled={!fromCustomerSupport || fromCustomerSupportTeam} formatString={formatString} openApps={openApps} channelId={channelId} chatMessage={chatMessage} agentRespone={agentRespone} />
                            } else {
                                message = <div>
                                    <span ref={contentRef} dangerouslySetInnerHTML={{ __html: formatString(functionResponse, false) }}></span>
                                </div>
                            }
                        } else {
                            message = <div>
                                <span ref={contentRef} dangerouslySetInnerHTML={{ __html: formatString(response, false) }}></span>
                            </div>
                        }
                    } else {
                        message = <div className="">
                            <div className="hs-event">
                                <div className="row p-1">
                                    {
                                        careReponseMsg.map((cmap, cin) => {
                                            ///[[INST]|]|\\n|[SYS]|]/g

                                            let msgStr = cmap.response.replace(/\[([/INST]|[/SYS]|\\n)*\]/g, '');
                                            return <div className={`col-sm-${careReponseMsg.length === 1 ? "12" : "6"}`} key={"care_" + cin}>
                                                <Card
                                                    title={<>
                                                        <div className="text-capitalize">{cmap.agent.label}{" (" + cmap.agent.modelInfo.modelName + ")"}</div>
                                                    </>}>
                                                    <div className="bot-response">
                                                        {newMessage.includes(ival) ? <Typewriter scrollBottom={() => scrollBottom()} text={msgStr} delay={50} /> : getBotResponse(cmap.agent.label, cmap.response)}
                                                    </div>
                                                </Card>
                                            </div>

                                        })
                                    }
                                </div>
                            </div >
                        </div >
                    }
                }
            } else {
                message = <div className="bot-chat-wrong">
                    Something went wrong with this response.
                </div>
            }
        } else if (csEventData && (messageType === GlobalMsgCons.messageTypeCSEventRequest || messageType === GlobalMsgCons.messageTypeCSEventCaseStatusResponse || messageType === GlobalMsgCons.messageTypeCSEventResponse || messageType === GlobalMsgCons.messageTypeCSEventAutomaticResponse || messageType === GlobalMsgCons.messageTypeCSEventLnscResponse)) {
            message = <CsEventData
                {...props}
                channelType={channelType}
                channelId={channelId}
                sendCommunityServiceEvent={sendCommunityServiceEvent}
                requestReceivingChannelId={requestReceivingChannelId}
                messageType={messageType}
                chatMessage={chatMessage} csEventData={csEventData} csMessageMetaInfo={csMessageMetaInfo}
            />
        }
        else if (messageType === GlobalMsgCons.messageTypeHealthcareBotRequest && healthcareInfo) {
            let { conditionNotes, location, patientRefNo, prompt, patient } = healthcareInfo;
            message = <div className="hs-event">
                <div className="d-flex px-2">
                    <div>
                        <b>Patient name</b>
                        <div>{patient ? patient : "-"}</div>
                    </div>
                    {/* <div>
                        <b>Location</b>
                        <div>{location}</div>
                    </div> */}
                    <div>
                        <b>Notes</b>
                        <div>{conditionNotes}</div>
                    </div>
                </div>
                <div className="d-flex px-2 pt-2">
                    <div>
                        <b>Prompt</b>
                        <div>{prompt}</div>
                    </div>
                </div>
            </div>
        }
        else if (messageType === GlobalMsgCons.messageTypeOpenMrsResponse && openMrsData) {
            let { typeOfData, response } = JSON.parse(chatMessage.message)

            if (openMrsData.intent && openMrsData.intent === "addMedications" && openMrsData.apiType === "debezium") {
                response = JSON.parse(response)
                let { drug, patient, drug_order, orders } = response
                let { duration, quantity, dose, num_refills } = drug_order
                let { order_reason } = orders
                let { name, strength } = drug
                let { family_name, given_name, middle_name, person_identifier } = patient
                message = <div id={ival} className="openmrs mb-1">
                    <Card
                        title={<span className="openmrs">
                            <b>Medications Added <span className="openmrs-bdge" >({openMrsData.apiType})</span></b>
                            <img src={openmrsLogo} className="img-fluid ml-2" style={{ maxWidth: 90 }} />
                        </span>}
                        options={""}
                        bodyClassName={"openmrs-body"}
                        themeColor={"openmrs-theme"}
                    >
                        <b>Patient Info :</b>
                        <div >Name  - {given_name ? given_name : ""} {middle_name ? middle_name : ""} {family_name ? family_name : ""}</div>
                        <div>OpenMRS ID - {person_identifier}</div>
                        <br />
                        <b>Medications :</b>
                        <div>{name ? name : ""} - {strength ? strength : ""}</div>
                        <div>DOSE {dose} tablet - for {duration} days - {num_refills}</div>
                        <div className="mb-1">INDICATION {order_reason} - QUANTITY {quantity} Tablet</div>
                    </Card>
                    {/* <div className="openmrs-txt">{JSON.stringify(response)}</div> */}
                </div>
            }
            else if (typeOfData === "text") {
                message = <div id={ival} className="openmrs">
                    <div className="openmrs-txt">{response}</div>
                </div>
            } else if (response) {
                response = JSON.parse(response)
                let { results } = response;
                let { intent, requestDataJson } = openMrsData
                let { v, } = JSON.parse(requestDataJson)
                let dataPush = [];
                if (Array.isArray(results)) {
                    results.map((idd, idi) => {
                        let { display, person, } = idd;
                        let personalInfoShow = "";
                        if (intent && intent === "createAppointment") {
                            dataPush.push(<div key={idi + "_" + messageType}>
                                <div className="requester-info">
                                    <Card
                                        title={<span>
                                            <img src={openmrsLogo} className="img-fluid" style={{ maxWidth: 90 }} />
                                        </span>}
                                        options={""}
                                        bodyClassName={"openmrs-body"}
                                        themeColor={"openmrs-theme"}
                                    >
                                        <div> Appointment created successfully  </div>
                                    </Card>
                                    <br />
                                </div>
                            </div>)
                        }
                        else if (intent && intent === "searchPatient") {
                            if (person) {
                                try {
                                    let { birthdate, gender, age, addresses, names } = person
                                    if (v === "full") {
                                        let nameInfoShow = "";
                                        let addressShow = "";
                                        if (names) {
                                            let { givenName, middleName, familyName, } = names[0]
                                            nameInfoShow = <div className="d-flex">
                                                {givenName ? <div className="mb-2">
                                                    <b>Given Name</b>
                                                    <div> {givenName}</div>
                                                </div> : <div>{"-"}</div>}
                                                {middleName ? <div className="mb-2">
                                                    <b>Middle Name</b>
                                                    <div> {middleName}</div>
                                                </div> : <div>{"-"}</div>}
                                                {familyName ? <div className="mb-2">
                                                    <b>Family Name</b>
                                                    <div> {familyName}</div>
                                                </div> : <div>{"-"}</div>}
                                            </div>
                                        }
                                        if (addresses) {
                                            let { cityVillage, stateProvince, countyDistrict, } = addresses[0];
                                            addressShow = <div className="d-flex">
                                                {cityVillage ? <div className="mb-2">
                                                    <b>City Village</b>
                                                    <div> {cityVillage}</div>
                                                </div> : <div>{"-"}</div>}
                                                {stateProvince ? <div className="mb-2">
                                                    <b>State Province</b>
                                                    <div> {stateProvince}</div>
                                                </div> : <div>{"-"}</div>}
                                                {countyDistrict ? <div className="mb-2">
                                                    <b>County District</b>
                                                    <div> {countyDistrict}</div>
                                                </div> : <div>{"-"}</div>}
                                            </div>
                                        }
                                        personalInfoShow = <>
                                            <div className="d-flex">
                                                {gender ? <div className="mb-2">
                                                    <b>Gender</b>
                                                    <div> {gender}</div>
                                                </div> : <div>{"-"}</div>}
                                                {age ? <div className="mb-2">
                                                    <b>Age</b>
                                                    <div> {age}</div>
                                                </div> : <div>{"-"}</div>}
                                                {birthdate ? <div className="mb-2">
                                                    <b>Birth date</b>
                                                    <div> {getOnlyDateWithFormat(new Date(birthdate).getTime(), auth.activeCompanyDetails)}</div>
                                                </div> : <div>{"-"}</div>}
                                            </div>

                                            {nameInfoShow}
                                            {addressShow}

                                        </>
                                    } else if (v === "default") {
                                        let { birthdate, gender, age, } = person
                                        personalInfoShow = <>
                                            <div className="d-flex">
                                                {gender ? <div className="mb-2">
                                                    <b>Gender</b>
                                                    <div> {gender}</div>
                                                </div> : <div>{"-"}</div>}
                                                {age ? <div className="mb-2">
                                                    <b>Age</b>
                                                    <div> {age}</div>
                                                </div> : <div>{"-"}</div>}
                                                {birthdate ? <div className="mb-2">
                                                    <b>Birth date</b>
                                                    <div> {getOnlyDateWithFormat(new Date(birthdate).getTime(), auth.activeCompanyDetails)}</div>
                                                </div> : <div>{"-"}</div>}
                                            </div></>

                                    }
                                }
                                catch (err) {
                                    // console.log("err",err)
                                }
                            }
                        }

                        dataPush.push(<div key={idi + "_" + messageType}>
                            <div className="requester-info">
                                {display ? <b>{display}</b> : "-"}
                                {personalInfoShow}
                            </div>
                        </div>)

                    })

                } else if (response.display) {
                    dataPush = <div className="requester-info">
                        <div className="d-flex">
                            {/* {JSON.stringify(userChatHistory[ival])}<br /> */}
                            <Card
                                title={<img src={openmrsLogo} className="img-fluid" style={{ maxWidth: 90 }} />}
                                options={""}
                                bodyClassName={"openmrs-body"}
                                themeColor={"openmrs-theme"}
                            >
                                <div>
                                    {!(MyConfig.openMRSDoctoreMessageSendEmailidObj.hasOwnProperty(loggedEmail)) ? "Message sent to  doctor regarding appointment for patient " : "Message arrived from admin regarding Appointment  creation for "}
                                    <span>"{response.display}"</span>
                                </div>
                                <br />
                                {(MyConfig.openMRSDoctoreMessageSendEmailidObj.hasOwnProperty(loggedEmail)) && <div className="text-start mb-3">
                                    <button className="btn btn-sm btn-success" onClick={() => {
                                        setMultipleChatData(channelId, {
                                            createAppoimentModal: true,
                                            openMRSPatientUid: response.uuid
                                        });

                                    }}>Create appointment</button>
                                </div>}
                            </Card>
                        </div>

                    </div>
                } else if (response.entry) {
                    var entryObj = response.entry
                    if (Array.isArray(entryObj)) {
                        entryObj.map((entryData, entryDatai) => {
                            let { resource } = entryData;
                            if (resource) {
                                let { text } = resource
                                if (text) {
                                    if (text.status == "generated") {
                                        dataPush = <div className="requester-info">

                                            <div className="d-flex">
                                                <div>
                                                    <div className="desc" dangerouslySetInnerHTML={{ __html: text.div }}></div>
                                                </div>
                                            </div>
                                        </div>


                                    }
                                }
                            }
                        })
                    }
                    //     dataPush = <div className="requester-info">
                    //     <div className="d-flex">
                    //         <div>
                    //             <div>{JSON.stringify(response.entry)}</div>
                    //         </div>
                    //     </div>
                    // </div>
                }
                else if (response.error && response.error.message) {
                    let errorMessage = response.error.message;
                    let showMessage = errorMessage.slice(1, -1);
                    if (showMessage.includes("birthdate on") && showMessage.includes("Error converting date")) {
                        dataPush = <div className="requester-info">
                            <div>
                                <div>
                                    <span>Birthdate - </span>
                                    <span style={{ color: "red" }}>Error Converting Date</span>
                                </div>
                                <div>
                                    <span>Correct format (ISO8601 Long): yyyy-MM-dd'T'HH:mm:ss.SSSZ </span>
                                    <span style={{ color: "red" }}>Invalid format: ""</span>
                                </div>
                            </div>
                        </div>
                    } else {
                        dataPush = <div className="requester-info">
                            <div className="d-flex">
                                <div style={{ color: "red", fontSize: 15, fontWeight: "bold" }}>{showMessage}</div>
                            </div>
                        </div>
                    }
                }
                else if (response && Object.keys(response).length && response.appointmentNumber) {
                    let { intent } = openMrsData;
                    if (intent === "createAppointment") {
                        dataPush = <div className="openmrs-response">
                            <div className="">
                                <Card
                                    title={<span>
                                        <img src={openmrsLogo} className="img-fluid" style={{ maxWidth: 90 }} />
                                    </span>}
                                    options={""}
                                    bodyClassName={"openmrs-body"}
                                    themeColor={"openmrs-theme"}
                                >
                                    <div> Appointment created successfully  </div>
                                    <br />
                                    {response.patient && <button className="btn btn-sm btn-success" onClick={() => {
                                        let openMRSURL = MyConfig.openMRSPatientFullUrl + response.patient.uuid + MyConfig.openMRSAppointmentUrl
                                        let openMRSUrl = {
                                            "title": "OpenMRS",
                                            "description": "",
                                            "appsUrl": openMRSURL,
                                            "color": "#4e567d",
                                            "logo": "",
                                            "id": "OpenMRS_" + auth.activeCompany,
                                            "iframeUrl": openMRSURL,
                                        }
                                        openApps("OpenMRS", openMRSUrl)
                                    }}>View appointment</button>}
                                </Card>
                            </div>
                        </div>
                    }

                }
                else if ((intent && (intent === "endVisit" || intent === "createVisit"))) {
                    let { response } = JSON.parse(chatMessage.message)
                    response = JSON.parse(response)
                    let { patient, visit, location } = response
                    let { person_identifier, family_name, middle_name, given_name } = patient
                    let { name } = location
                    let { date_started, date_stopped, visit_type_name } = visit
                    dataPush = <div className="openmrs-response">

                        <div className="">
                            <Card
                                title={<span className="openmrs">
                                    <b>{openMrsData.context} <span className="openmrs-bdge" >({openMrsData.apiType})</span></b>
                                    <img src={openmrsLogo} className="img-fluid ml-2" style={{ maxWidth: 90 }} />
                                </span>}
                                options={""}
                                bodyClassName={"openmrs-body"}
                                themeColor={"openmrs-theme"}
                            >
                                <b>Patient Info :</b>
                                <div >Name  - {given_name ? given_name : ""} {middle_name ? middle_name : ""} {family_name ? family_name : ""}</div>
                                <div>OpenMRS ID - {person_identifier}</div>
                                <br />
                                <div><b>Location : </b> {name ? name : ""}</div>
                                <br />
                                <div><b>Visit : </b> {visit_type_name ? visit_type_name : ""}</div>

                                <div>{date_started ? <> Start : {getDateWithFormat(date_started, auth.activeCompanyDetails)} </> : null}</div>
                                <div>{date_stopped ? <> End : {getDateWithFormat(date_stopped, auth.activeCompanyDetails)} </> : null}</div>

                            </Card>
                        </div>
                    </div>

                }
                else if ((intent && (intent === "newEncounter"))) {
                    let { response } = JSON.parse(chatMessage.message)
                    response = JSON.parse(response)
                    let { patient, visit, location, obs, encounter } = response
                    let { person_identifier, family_name, middle_name, given_name } = patient
                    let { name } = location
                    let { date_started, date_stopped, visit_type_name } = visit
                    let { encounter_datetime, encounter_type_name } = encounter
                    dataPush = <div className="openmrs-response">

                        <div className="">
                            <Card
                                title={<span className="openmrs">
                                    <b>{openMrsData.context} <span className="openmrs-bdge" >({openMrsData.apiType})</span></b>
                                    <img src={openmrsLogo} className="img-fluid ml-2" style={{ maxWidth: 90 }} />
                                </span>}
                                options={""}
                                bodyClassName={"openmrs-body"}
                                themeColor={"openmrs-theme"}
                            >
                                <b>Patient Info :</b>
                                <div >Name  - {given_name ? given_name : ""} {middle_name ? middle_name : ""} {family_name ? family_name : ""}</div>
                                <div>OpenMRS ID - {person_identifier}</div>
                                <br />
                                <div><b>Location : </b> {name ? name : ""}</div>
                                <br />
                                <div><b>Visit : </b> {visit_type_name ? visit_type_name : ""}</div>

                                <div>{date_started ? <> Start : {getDateWithFormat(date_started, auth.activeCompanyDetails)} </> : null}</div>
                                <div>{date_stopped ? <> End : {getDateWithFormat(date_stopped, auth.activeCompanyDetails)} </> : null}</div>
                                <br />
                                <b>Encounter :</b>
                                <div>Type :  {encounter_type_name}</div>
                                <div>Date : {getTimeFormate(parseInt(encounter_datetime))}</div>

                                <br />
                                <b>Observation :</b>
                                {Object.keys(obs).length && Object.keys(obs).map((obskey) => {
                                    //obs_datetime
                                    let { concept_name, value_text, value_numeric } = obs[obskey]
                                    return <div>{concept_name} : {value_numeric ? value_numeric : value_text ? value_text : ""}</div>
                                })}


                                {/* {JSON.stringify(response)} */}

                            </Card>
                        </div>
                    </div>

                }
                else if (intent && intent === "addAllergy") {
                    let { response } = JSON.parse(chatMessage.message)
                    response = JSON.parse(response)
                    let { patient, allergy, allergy_reaction } = response
                    let { person_identifier, family_name, middle_name, given_name } = patient
                    let { allergen_type, allergen, severity, comments, date_created } = allergy
                    dataPush = <div className="openmrs-response">
                        <div className="">
                            <Card
                                title={<span className="openmrs">
                                    <b>{openMrsData.context} <span className="openmrs-bdge" >({openMrsData.apiType})</span></b>
                                    <img src={openmrsLogo} className="img-fluid ml-2" style={{ maxWidth: 90 }} />
                                </span>}
                                options={""}
                                bodyClassName={"openmrs-body"}
                                themeColor={"openmrs-theme"}
                            >
                                <b>Patient Info :</b>
                                <div >Name  - {given_name ? given_name : ""} {middle_name ? middle_name : ""} {family_name ? family_name : ""}</div>
                                <div>OpenMRS ID - {person_identifier}</div>
                                <br />

                                <b>Allergies :</b>
                                <div >Allergen Type  - {allergen_type} </div>
                                <div >Allergen  - {allergen} </div>
                                <div >Comments  - {comments} </div>
                                <div >Severity  - {severity} </div>
                                <div >Created  - {getTimeFormate(parseInt(date_created))} </div>
                                <br />


                                <b>Reaction :</b>
                                {Object.keys(allergy_reaction).length && Object.keys(allergy_reaction).map((alkey) => {
                                    let { reaction_name, reaction_non_coded } = allergy_reaction[alkey]
                                    return <div> {reaction_name}{reaction_non_coded ? " - " + reaction_non_coded : ""}</div>
                                })}

                            </Card>
                        </div>
                    </div>

                }
                else if (intent && intent === "addConditions") {
                    let { response } = JSON.parse(chatMessage.message)
                    response = JSON.parse(response)
                    let { allergy, patient } = response
                    let { condition_name, clinical_status, date_created, onset_date } = allergy
                    let { person_identifier, family_name, middle_name, given_name } = patient
                    dataPush = <div className="openmrs-response">
                        <div className="">
                            <Card
                                title={<span className="openmrs">
                                    <b>{openMrsData.context} <span className="openmrs-bdge" >({openMrsData.apiType})</span></b>
                                    <img src={openmrsLogo} className="img-fluid ml-2" style={{ maxWidth: 90 }} />
                                </span>}
                                options={""}
                                bodyClassName={"openmrs-body"}
                                themeColor={"openmrs-theme"}
                            >
                                <b>Patient Info :</b>
                                <div >Name  - {given_name ? given_name : ""} {middle_name ? middle_name : ""} {family_name ? family_name : ""}</div>
                                <div>OpenMRS ID - {person_identifier}</div>
                                <br />
                                <b>Conditions :</b>
                                <div>Condition Name  - {condition_name} </div>
                                <div>Status  - {clinical_status} </div>
                                <div>Created  - {getTimeFormate(parseInt(date_created))} </div>
                                <div>Date of onset - {getTimeFormate(parseInt(onset_date))} </div>
                            </Card>
                        </div>
                    </div>

                }
                message = <div id={ival} className="">
                    <div>{dataPush}</div>
                    {/* <div>{JSON.stringify(results)}</div> */}
                </div>
            }


        }
        else if (messageType === GlobalMsgCons.messageTypeOpenMrsRequest && openMrsData) {
            let { intent, requestDataJson, apiType } = openMrsData
            if (intent && intent === "searchPatient") {
                let { limit, startIndex, v, q, identifier, includeDead, searchType, } = JSON.parse(requestDataJson)
                message = <div id={ival} className="">
                    <div className="requester-info">
                        <Card
                            title={<span>
                                <b>Search Patient Info ({apiType})</b>
                                <img src={openmrsLogo} className="img-fluid ml-2" style={{ maxWidth: 90 }} />
                            </span>}
                            options={""}
                            bodyClassName={"openmrs-body"}
                            themeColor={"openmrs-theme"}
                        >
                            {
                                apiType === "fhir2" ? <div className="d-flex">
                                    <div className="mb-2">
                                        <b>Identifier</b>
                                        <div>{identifier}</div>
                                    </div>
                                </div> : <React.Fragment>
                                    <div className="d-flex">
                                        <div className="mb-2">
                                            <b>Limit</b>
                                            <div>{limit}</div>
                                        </div>
                                        <div className="mb-2">
                                            <b>Start index</b>
                                            <div>{startIndex}</div>
                                        </div>
                                        <div className="mb-2">
                                            <b>V</b>
                                            <div>{v}</div>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="mb-2">
                                            <b>Q</b>
                                            <div>{q}</div>
                                        </div>
                                        <div className="mb-2">
                                            <b>Identifier</b>
                                            <div>{identifier}</div>
                                        </div>
                                        <div className="mb-2">
                                            <b>Include dead</b>
                                            <div>{includeDead}</div>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="mb-2">
                                            <b>search Type</b>
                                            <div>{searchType}</div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                        </Card>
                    </div>

                </div>
            } else if (intent && intent === "createPatient") {
                let { person, } = JSON.parse(requestDataJson)
                let { names, gender, birthdate, addresses } = person;
                let { givenName, middleName, familyName } = names[0]
                let { country, stateProvince, countyDistrict, address1, cityVillage } = addresses[0]

                message = <div id={ival} className="requester-info">
                    <Card
                        title={<span>
                            <b>Create Patient Info</b>
                            <img src={openmrsLogo} className="img-fluid ml-2" style={{ maxWidth: 90 }} />
                        </span>}
                        options={""}
                        bodyClassName={"openmrs-body"}
                        themeColor={"openmrs-theme"}
                    >
                        <div className="d-flex">
                            <div className="mb-2">
                                <b>First Name</b>
                                <div>{givenName}</div>
                            </div>
                            <div className="mb-2">
                                <b>Middle Name</b>
                                <div>{middleName}</div>
                            </div>
                            <div className="mb-2">
                                <b>Family Name</b>
                                <div>{familyName}</div>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="mb-2">
                                <b>Sex</b>
                                <div>{gender}</div>
                            </div>
                            <div className="mb-2">
                                <b>Date of Birth</b>
                                <div>{birthdate}</div>
                            </div>
                            <div className="mb-2">
                                <b>Country</b>
                                <div>{country}</div>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="mb-2">
                                <b>Province</b>
                                <div>{stateProvince}</div>
                            </div>
                            <div>
                                <b>impl.commune</b>
                                <div>{address1}</div>
                            </div>
                            <div className="mb-2">
                                <b>District</b>
                                <div>{countyDistrict}</div>
                            </div>
                        </div>

                        <div className="d-flex">
                            <div className="mb-2">
                                <b>Village</b>
                                <div>{cityVillage}</div>
                            </div>
                        </div>

                    </Card>

                    {/* <div>{JSON.stringify(openMrsData)}</div> */}
                </div>
            }
            else if (intent && intent === "createAppointment") {
                message = <div id={ival} className="openmrs-request">
                    <div className="requester-info">
                        <div className=""> Create Appointment request initialized  </div>
                    </div>
                </div>
            }
        }
        else {
            message = <div id={ival} className="">
                <div style={{ maxWidth: "100%" }} className="d-inline-block" onClick={(e) => clickHandler(e)} dangerouslySetInnerHTML={{
                    __html: urlify(chatMessage.message)
                }}
                />

                {
                    (translatedText && Object.keys(translatedText).length > 0) && <React.Fragment>
                        {
                            Object.keys(translatedText).map((lang) => {
                                return <div className="translate-text" key={lang}>
                                    Translated: {translatedText[lang]}
                                </div>
                            })
                        }
                    </React.Fragment>
                }
                {
                    editedAt && <div className="edited">
                        (edited)
                        <div className="time">{getDateWithFormat(editedAt, auth.activeCompanyDetails)}</div>
                    </div>
                }
                {
                    messageType === GlobalMsgCons.messageTypeSharedChat && <div className="edited">
                        (shared)
                    </div>
                }
                {
                    (controlFeature.translateChatEnable) && <div className="translate-chat" onClick={() => {
                        setTranslateModal(true)
                    }}>
                        Translate
                    </div>
                }
            </div>
        }
        return message;
    }

    async function translateChat() {
        let { chatMessage, translatedText } = userChatHistory[ival]
        let prompt = stripHtml(chatMessage.message)
        setSubmitLoader(true)

        let url = "";
        let translationSrc = languageOptions.filter(option => option.value === srcLang)
        let translationTarget = languageOptions.filter(option => option.value === targetLang)

        let postSrcLang = "";
        let postTargetLang = "";
        if (translationTarget.length) {
            postSrcLang = translationSrc[0].translationLangCode;
            postTargetLang = translationTarget[0].translationLangCode;
            let { translationMethod, translationLangCode, } = translationTarget[0]
            let { translationMethod: srcTranslationMethod, translationLangCode: srcTranslationLangCode } = translationSrc[0]

            if (srcLang === "eng") {
                postSrcLang = "eng_Latn"
            }
            if (targetLang === "eng") {
                postTargetLang = "eng_Latn"
            }

            if (translationMethod === "madlad400" || srcTranslationMethod === "madlad400") {
                url = "global"
                if (targetLang === "eng") {
                    prompt = "<2en> " + prompt
                } else {
                    prompt = "<2" + translationLangCode + "> " + prompt
                }
            } else {
                if (tabType === "manual") {
                    if (srcLang === "eng") {
                        url = "en-indic"
                    } else if (targetLang === "eng") {
                        url = "indic-en"
                    } else {
                        url = "indic-global"
                    }
                } else {
                    url = "indic-global"
                }
            }
        }

        if (url && translationTarget.length) {
            const postFileUpload = new FormData();
            postFileUpload.append('reqId', getUuidv4());
            postFileUpload.append('orgId', activeCompany);
            postFileUpload.append('userId', loginId);
            postFileUpload.append('prompt', prompt);
            postFileUpload.append('srcLang', postSrcLang);
            postFileUpload.append('destLang', postTargetLang);
            let result = await fetch(MyConstant.keyList.translateUrl + url + "/generate", {
                method: "POST",
                mode: 'cors',
                redirect: 'follow',
                body: postFileUpload,
            })
                .then((response) => response.json())
                .then((result) => {
                    return result;
                })
                .catch((error) => {
                    console.error('Error:', error);
                });

            setSubmitLoader(false)
            setTranslateModal(false)

            if (result) {
                let { text } = result;
                let { response } = JSON.parse(text)

                let cTranslatedText = { ...translatedText }
                cTranslatedText[targetLang] = response

                userChatHistory[ival].translatedText = cTranslatedText
                setMultipleChatData(channelId, {
                    userChatHistory
                });
            } else {
                toast.error("Something went wrong.", {
                    position: "top-right",
                    autoClose: GlobalMsgCons.autoClose,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            }
        } else {
            setSubmitLoader(false)
            alert("The development is currently in progress.")
        }
    }

    return <React.Fragment>
        {
            getContent()
        }

        {
            translateModal && <Modal
                visible={translateModal}
                closeModal={() => {
                    setTranslateModal(false)
                }}
                heading={"Translate"}
                body={<React.Fragment>
                    <div className="my-3">
                        <b>Message : </b>
                        <div className="d-inline-block" onClick={(e) => clickHandler(e)} dangerouslySetInnerHTML={{
                            __html: urlify(userChatHistory[ival].chatMessage.message)
                        }}
                        />
                    </div>
                    <div className="tabs-container">
                        <div className="tabs">
                            <div className={`tab ${tabType === "manual" ? "active" : ""}`} onClick={() => {
                                setTabType("manual")
                            }}>
                                Manual
                            </div>
                            <div className={`tab ${tabType === "automatic" ? "active" : ""}`} onClick={() => {
                                setTabType("automatic")
                            }}>
                                Automatic
                            </div>
                        </div>
                        <div className="tabs-body p-2">
                            {
                                tabType === "manual" && <div className="form-group">
                                    <label>{"Please Select Source Language"}</label>
                                    <DropDown
                                        selectedOption={languageOptions.filter(option => option.value === srcLang)}
                                        onChange={(e) => {
                                            setSrcLang(e.value)
                                        }}
                                        options={languageOptions}
                                        isDisabled={false}
                                        isMulti={false}
                                        placeholder={"Please Select"}
                                    />
                                </div>
                            }

                            <div className="form-group">
                                <label>{"Please Select Target Language"}</label>
                                <DropDown
                                    selectedOption={languageOptions.filter(option => option.value === targetLang)}
                                    onChange={(e) => {
                                        setTargetLang(e.value)
                                    }}
                                    options={languageOptions}
                                    isDisabled={false}
                                    isMulti={false}
                                    placeholder={"Please Select"}
                                />
                            </div>
                        </div>
                    </div>
                </React.Fragment>}
                footer={<React.Fragment>
                    <button type="button"
                        className={"btn btn-primary"}
                        onClick={() => {
                            translateChat()
                        }}
                    >{submitLoader && <i className="fa fa-spinner fa-spin"></i>} {"Translate"}</button>
                    <button disabled={submitLoader} className="btn btn-secondary" onClick={() => {
                        setTranslateModal(false)
                    }}>Cancel</button>
                </React.Fragment>}
            />
        }
    </React.Fragment>;
}
